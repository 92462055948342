import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { Header } from 'widgets/header';
import { ReactNode } from 'react';
import { useAuthentication } from 'common/auth/AuthenticationProvider';
import { HeaderMobile } from 'widgets/header-mobile';
import { StickyContainer, Sticky } from 'react-sticky';
import { CookieCard } from 'widgets/cookie-card';
import { SurveyNotification } from 'widgets/survey-notification';

interface BaseLayoutProps {
    children: ReactNode;
}

const BaseLayout = ({ children }: BaseLayoutProps) => {
    const { isAuthenticated } = useAuthentication();

    return (
        <StickyContainer>
            <Layout className="site-layout">
                <div className="HeaderWeb">
                    <Sticky style={{ zIndex: 999 }}>
                        {({ style }) => (
                            <div style={{ ...style, zIndex: 999 }}>
                                <Header isLoggedIn={isAuthenticated}></Header>
                            </div>
                        )}
                    </Sticky>
                </div>
                <div className="HeaderMobile">
                    <Sticky style={{ zIndex: 999 }}>
                        {({ style }) => (
                            <div style={{ ...style, zIndex: 999 }}>
                                <HeaderMobile
                                    isLoggedIn={isAuthenticated}
                                ></HeaderMobile>
                            </div>
                        )}
                    </Sticky>
                </div>
                <Content
                    style={{
                        overflow: 'hidden',
                        zIndex: 998,
                    }}
                >
                    {children}
                </Content>
                <CookieCard />
                <SurveyNotification />
            </Layout>
        </StickyContainer>
    );
};

export default BaseLayout;
