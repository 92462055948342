import { ColumnType } from 'antd/lib/table';
import { ActionItem, ActionMenu } from 'components/action-menu';
import { Pagination, PaginationOptions } from 'components/pagination';
import { SvgIcon } from 'components/svg-icon';

import { ReactNode, useCallback, useMemo } from 'react';
import * as S from './index.styles';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

class DataTableColsTypes {
    dataTableActions?: string;
}

export type DataTableRow<T = {}> = {
    title: string;
    dataIndex: keyof T | string;
    key: keyof T | string;
    width?: number;
    align?: 'left' | 'right' | 'center';
    className?: string;
    render?: (recordItem: any, record: T) => ReactNode | JSX.Element;
};

export type DataTableActionsFunc<T> = (row: T) => ActionItem[];

export type DataTableProps<T = object, P = {}> = {
    cols?: DataTableRow<T>[];
    rowKey?: string;
    source?: T[];
    actions?: ActionItem[] | DataTableActionsFunc<T>;
    pagination?: PaginationOptions;
    isLoading?: boolean;
    className?: string;
    size?: SizeType;
    maxHeight?: number | string;
    onChangePage?: (page: number) => void;
    onActionClick?: (actionKey: P, row: Omit<T, 'dataTableActions'>) => void;
    onRowClick?: (record: T) => void;
};

export const DataTable = function <T, P = {}>({
    cols = [],
    source = [],
    actions,
    size,
    onActionClick,
    pagination,
    onChangePage,
    className,
    rowKey = 'id',
    isLoading = false,
    maxHeight,
    onRowClick,
}: DataTableProps<T & DataTableColsTypes, P>) {
    const handleChangePage = useCallback(
        (page: number) => {
            onChangePage && onChangePage(page);
        },
        [onChangePage]
    );

    const generateCols = useCallback(() => {
        if (cols.length === 0 && source && source.length > 0) {
            const keys = Object.keys(source[0]) as unknown as string[];
            const customCols: DataTableRow<T>[] = keys.map((item: string) => ({
                title: item,
                dataIndex: item as keyof T | string,
                key: item as keyof T | string,
            }));

            return customCols;
        }
    }, [cols, source]);

    const columns: ColumnType<T & DataTableColsTypes>[] = useMemo(() => {
        if (cols && cols.length === 0 && source && source?.length > 0) {
            const customCols = generateCols();
            if (customCols) {
                customCols.forEach((item) => {
                    cols.push(item);
                });
            }
        }
        if (
            actions &&
            onActionClick &&
            !cols.find((e) => e.key === 'dataTableActions')
        ) {
            const actionsCol: DataTableRow<T & DataTableColsTypes> = {
                key: 'dataTableActions',
                dataIndex: 'dataTableActions',
                title: '',
                width: 50,
                render: (_recordItem, record) => {
                    const handleSelect = (actionKey: string) => {
                        onActionClick(actionKey as unknown as P, record);
                    };

                    return (
                        <ActionMenu
                            items={
                                typeof actions === 'function'
                                    ? actions(record)
                                    : actions
                            }
                            onSelect={handleSelect}
                            placement="bottomRight"
                        >
                            <SvgIcon
                                className="datatable-action-btn"
                                name="action-menu-horizontal"
                            />
                        </ActionMenu>
                    );
                },
            };
            cols.push(actionsCol);
        }

        return cols as ColumnType<T>[];
    }, [cols, source, actions, onActionClick, generateCols]);

    return (
        <>
            <S.Container<T>
                columns={columns}
                dataSource={source}
                pagination={false}
                rowKey={rowKey}
                loading={isLoading}
                className={className}
                scroll={{ y: maxHeight }}
                size={size}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            onRowClick && onRowClick(record);
                        }, // click row
                    };
                }}
            />
            <S.DataTableFooter className="data-table-footer">
                {pagination && (
                    <Pagination
                        handleChange={handleChangePage}
                        options={pagination}
                    />
                )}
            </S.DataTableFooter>
        </>
    );
};
