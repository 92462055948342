import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';
import { fonts } from 'common/theme/font';

const mobileCss = css`
    .ant-tabs {
        .ant-tabs-nav-list {
            width: 100%;
            .ant-tabs-tab {
                width: 50%;
                justify-content: center;
                padding: 9px 0;
                margin: 0 0 !important;
                background: ${({ theme }) => theme.colors.color1.base};
                &:nth-child(1) {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                    &:after {
                        display: none;
                    }
                }
                &:nth-child(2) {
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                }
                .ant-tabs-tab-btn {
                    font-size: ${fonts.size.xSmall} !important;
                }
                &.ant-tabs-tab-active {
                    background: ${({ theme }) =>
                        theme.colors.staticColor.color2.base};
                    .ant-tabs-tab-btn {
                        color: ${({ theme }) =>
                            theme.colors.color1.base} !important;
                    }
                }
            }
            .ant-tabs-ink-bar {
                display: none !important;
            }
        }
    }
    .ant-table {
        overflow-x: auto;
        .ant-table-container {
            .ant-table-content {
                .ant-table-thead {
                    background: ${({ theme }) =>
                        theme.colors.staticColor.color2.base}!important;
                    .ant-table-cell {
                        vertical-align: middle;
                        font-size: ${fonts.size.small} !important;
                    }
                }
                .ant-table-tbody {
                    .ant-table-row {
                        .ant-table-cell {
                            font-size: ${fonts.size.small} !important;
                        }
                    }
                }
            }
        }
    }
    .detail {
        .text:first-child {
            .title {
                margin-top: 0;
            }
        }
    }
    .buy-sell-table {
        .ant-table-thead {
            .ant-table-cell {
                &:first-child {
                    width: 100px !important;
                }
            }
        }
    }
    .kdv {
        padding-top: 30px;
    }
`;
const tabletCss = css`
    .ant-tabs {
        .ant-tabs-nav-list {
            width: 100%;
            .ant-tabs-tab {
                width: 50%;
                justify-content: center;
                padding: 9px 0;
                margin: 0 0 !important;
                background: ${({ theme }) => theme.colors.color1.base};
                &:nth-child(1) {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                    &:after {
                        display: none;
                    }
                }
                &:nth-child(2) {
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                }
                .ant-tabs-tab-btn {
                    font-size: ${fonts.size.small} !important;
                }
                &.ant-tabs-tab-active {
                    background: ${({ theme }) =>
                        theme.colors.staticColor.color2.base};
                    .ant-tabs-tab-btn {
                        color: ${({ theme }) =>
                            theme.colors.color1.base} !important;
                    }
                }
            }
            .ant-tabs-ink-bar {
                display: none !important;
            }
        }
    }
    .ant-table {
        .ant-table-container {
            .ant-table-content {
                .ant-table-thead {
                    background: ${({ theme }) =>
                        theme.colors.staticColor.color2.base}!important;
                    .ant-table-cell {
                        vertical-align: middle;
                        font-size: ${fonts.size.small} !important;
                    }
                }
                .ant-table-tbody {
                    .ant-table-row {
                        .ant-table-cell {
                            font-size: ${fonts.size.small} !important;
                        }
                    }
                }
            }
        }
    }
    .detail {
        .text:first-child {
            .title {
                margin-top: 0;
            }
        }
    }
    .buy-sell-table {
        .ant-table-thead {
            .ant-table-cell {
                &:first-child {
                    width: 100px !important;
                }
            }
        }
    }
`;

export const ComissionPageStyled = styled.section`
    display: block;
    .ant-table {
        .ant-table-container {
            .ant-table-content {
                .ant-table-thead {
                    .ant-table-cell {
                        color: ${({ theme }) =>
                            theme.colors.staticColor.color1.base}!important;
                    }
                    background: ${({ theme }) =>
                        theme.colors.staticColor.color2.base}!important;
                }
            }
        }
    }
    .ant-tabs {
        .ant-tabs-nav {
            &:before {
                display: none;
            }
            .ant-tabs-nav-list {
                .ant-tabs-tab {
                    position: relative;
                    .ant-tabs-tab-btn {
                        font-size: 18px;
                        font-weight: ${fonts.weight.semibold};
                        color: ${({ theme }) => theme.colors.color4.base};
                    }
                    &.ant-tabs-tab-active {
                        .ant-tabs-tab-btn {
                            color: ${({ theme }) =>
                                theme.colors.staticColor.color2.base};
                        }
                    }
                    &:first-child {
                        &:after {
                            content: '';
                            position: absolute;
                            top: 16px;
                            right: -15px;
                            width: 1px;
                            height: 20px;
                            background-color: ${({ theme }) =>
                                theme.colors.color2.base};
                        }
                    }
                }
                .ant-tabs-ink-bar {
                    background: ${({ theme }) =>
                        theme.colors.staticColor.color2.base};
                }
            }
        }
    }
    .detail {
        margin-bottom: 30px;
        .text {
            .title {
                font-size: ${fonts.size.large};
                font-weight: ${fonts.weight.semibold};
                color: ${({ theme }) => theme.colors.color4.second1};
            }
            .info {
                font-size: ${fonts.size.medium};
                font-weight: ${fonts.weight.regular};
                color: ${({ theme }) => theme.colors.color4.base};
            }
        }
    }
    .sub-title {
        margin-bottom: 20px;
        color: ${({ theme }) => theme.colors.color4.base};
    }
    .bank-transfer {
        p {
            background-color: ${({ theme }) => theme.colors.color2.base};
            height: 48px;
            line-height: 48px;
            padding: 0 15px;
            font-size: ${fonts.size.medium};
            color: ${({ theme }) => theme.colors.color4.base};
            margin-bottom: 15px;
        }
    }
    .kdv {
        font-size: ${fonts.size.medium};
        font-weight: ${fonts.weight.regular};
        color: ${({ theme }) => theme.colors.color4.base};
    }
    .coin-pair {
        display: flex;
        align-items: center;
        i.app-icon {
            display: flex;
            align-items: center;
        }
        span {
            margin-left: 12px;
        }
    }
    ${mediaQuery.lessThan('tablet')`${mobileCss}`}
    ${mediaQuery.between('tablet', 'desktop')`${tabletCss}`}
`;
