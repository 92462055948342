// import { changeLanguage } from 'i18next';
import { HeaderMenuItemProps, SideBarMenuItemProps } from 'components';
import moment from 'moment';
import 'moment/locale/tr';
import 'moment/locale/de';
import { changeLanguage } from 'i18next';

export function sleep(time: number) {
    return new Promise<void>((resolve) => setTimeout(() => resolve(), time));
}

// This replace string element...
export function replaceAtString(
    value: string,
    index: number,
    replacement: string
) {
    return (
        value.substring(0, index) +
        replacement +
        value.substring(index + replacement.length)
    );
}

export function getSidebarRedirectLink(
    menuItems: SideBarMenuItemProps[],
    title: string
): string {
    const items = [
        ...menuItems,
        ...menuItems.flatMap((item) => item.subTitles),
    ];
    const mathchingItems = items.filter((item) => item?.title === title);
    if (mathchingItems.length > 0 && mathchingItems[0]) {
        return mathchingItems[0].link;
    }
    return '/';
}

export function getHeaderMenuRedirectLink(
    menuItems: HeaderMenuItemProps[],
    title: string
): string {
    const items = [...menuItems];
    const mathchingItems = items.filter((item) => item?.title === title);
    if (mathchingItems.length > 0 && mathchingItems[0]) {
        return mathchingItems[0].redirectLink;
    }
    return '/';
}

export function getAuthenticatorIcon(authMethod: AuthMethod): string {
    if (authMethod === 'sms') {
        return 'security-telephone';
    } else if (authMethod === 'google-authenticator') {
        return 'security-auth';
    }
    return 'security-email';
}

export function dateFormat(createDate: number) {
    const date = new Date(createDate * 1000);
    var formatted = moment(date).format('DD.MM.YYYY');
    return formatted;
}

export function dateFormatWithHour(timestamp: number): string {
    const date = new Date(timestamp * 1000);
    var formatted = moment(date).format('DD.MM.YYYY HH:mm');
    return formatted;
}

export function dateFormatWithSecond(createDate: number) {
    const date = new Date(createDate * 1000);
    var formatted = moment(date).format('DD.MM.YYYY HH:mm:ss');
    return formatted;
}
export function getLocaleDateFormat(dateData: string) {
    return moment(dateData).locale(getLanguage()).format('DD MMMM YYYY');
}
export function getLocaleDateFromNow(dateData: string) {
    return moment(dateData).locale(getLanguage()).fromNow();
}
export function getLocaleTimeFromISOFormat(dateData: string) {
    return moment(dateData).locale(getLanguage()).format('HH:mm:ss');
}
export function currencyToCoin(amount: number, price: number) {
    return amount && Number(price) ? amount / price : 0;
}
export function coinToCurrency(amount: number, price: number) {
    return amount ? amount * price : 0;
}
export function NumberFormat(amount: string | number) {
    if (!amount) {
        return 0;
    }
    let trFormat = Intl.NumberFormat('en-US', {
        useGrouping: true,
        maximumFractionDigits: 10,
        minimumFractionDigits: 2,
    });
    return trFormat.format(amount as unknown as number);
}
export function NumberFormatTwoDigit(amount: string | number) {
    if (!amount) {
        return 0;
    }
    let trFormat = Intl.NumberFormat('en-US', {
        useGrouping: true,
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    });
    return trFormat.format(amount as unknown as number);
}

export function toTimestamp(date: moment.Moment): number {
    return +moment(date).format('X');
}

export const formatDigit = (
    digitLengthRule: number,
    value: string | number,
    notRound: boolean = false
) => {
    if (!value) {
        return '0';
    }
    if (notRound) {
        let val = String(value).split('.');

        value =
            val[0] +
            '.' +
            String(
                val[1] !== undefined ? val[1].slice(0, digitLengthRule) : '00'
            );
    }
    let baseFormat = Intl.NumberFormat('en-US', {
        useGrouping: true,
        maximumFractionDigits: digitLengthRule,
        minimumFractionDigits: digitLengthRule,
    });

    return baseFormat.format(value.toString() as unknown as number);
};

export const formatDigitLight = (digitLengthRule: number, value?: any) => {
    let floatType = value;
    if (floatType === 0) {
        floatType = 0;
    } else {
        floatType = parseFloat(value || '');
    }
    const formatted = (
        Math.floor(floatType * Math.pow(10, digitLengthRule)) /
        Math.pow(10, digitLengthRule)
    ).toFixed(digitLengthRule);
    return formatted;
};

export function setLanguage(lang: string) {
    // let l = document.cookie
    //     .split(';')
    //     .find((x: any) => x.includes('i18next'))
    //     ?.split('=')[1];
    // console.log('lang', lang);

    localStorage.setItem('currentLanguage', String(lang));
    // const url = window.location.pathname.split('/');
    changeLanguage(lang);
}

export function getLanguage() {
    // let l = navigator.language.split('-')[0];

    let l = document.cookie
        .split(';')
        .find((x: any) => x.includes('i18next'))
        ?.split('=')[1];

    return String(l);
    // if (l === 'en' || l === 'tr' || l === 'de') {
    //     return String(
    //         localStorage.getItem('currentLanguage') == null
    //             ? navigator.language.split('-')[0]
    //             : localStorage.getItem('currentLanguage')
    //     );
    // } else {
    //     return 'en';
    // }
}
export function getLocalLanguage() {
    return String(
        localStorage.getItem('currentLanguage') == null
            ? navigator.language.split('-')[0]
            : localStorage.getItem('currentLanguage')
    );
}

export function clearCurrency(amount: string | number) {
    return parseFloat(
        amount.toString().replace('.', '').replace(',', '.')
    ).toFixed(2);
}
export function clearNumberFormat(amount: string | number | undefined) {
    if (!amount) {
        return 0;
    }
    return Number(amount.toString().replaceAll(',', ''));
}
export function changeLangUrl(url: string, lang: string) {
    if (url === '/') {
        return url + lang;
    }
    var a = String(url).split('/');
    a.shift();
    a.shift();
    a.unshift('/' + lang);
    var b = a.toString().replaceAll(',', '/');
    return b;
}

export function isSurveyControl(code: number = 0, index: number) {
    return Number(String(code).split('')[index - 1]) === 1 ? false : true;
}
