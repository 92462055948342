import { t } from 'i18next';
import { Helmet } from 'react-helmet';
import {
    InfoPageContainer,
    InfoPageStatusType,
} from 'widgets/InfoPageContainer';

export interface UnderConstructionProps {}

const UnderConstruction = () => {
    const pageStatus: InfoPageStatusType = 'under_construction';
    let pageData = {
        warningTitle: t('under_construction.warningTitle'),
        description: t('under_construction.description'),
    };
    return (
        <>
            <Helmet>
                <title>{t('seo.underConstruction.title')}</title>
                <meta
                    name="description"
                    content={t('seo.underConstruction.description')}
                ></meta>
                <meta
                    name="keywords"
                    content={t('seo.underConstruction.keywords')}
                ></meta>
                <meta name="robots" content="noindex"></meta>
            </Helmet>
            <InfoPageContainer
                status={pageStatus}
                warningTitle={pageData.warningTitle}
                description={pageData.description}
            ></InfoPageContainer>
        </>
    );
};

export default UnderConstruction;
