import styled from 'styled-components';
import { Tabs as AntTabs } from 'antd';
import { fonts } from 'common/theme/font';

export const Container = styled(AntTabs)`
    .ant-tabs-nav {
        &:before {
            border-bottom: 1px solid ${({ theme }) => theme.colors.color1.base};
        }
        .ant-tabs-nav-list {
            .ant-tabs-tab {
                font-size: ${fonts.size.xSmall};
                font-weight: ${fonts.weight.regular};
                width: inherit;
                margin: 0;
                padding: 0px 8px 6px 8px;
                color: ${({ theme }) => theme.colors.color4.base};
                .ant-tabs-tab-btn {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    & > i {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        padding-left: 5px;
                        color: ${({ theme }) => theme.colors.color4.base};
                    }
                }
                &.ant-tabs-tab-active {
                    .ant-tabs-tab-btn {
                        color: ${({ theme }) =>
                            theme.colors.staticColor.color2.base};
                    }
                }
            }
            .ant-tabs-ink-bar {
                margin-bottom: 1px;
                background-color: ${({ theme }) =>
                    theme.colors.staticColor.color2.base};
                border-radius: 3px 3px 0px 0px;
            }
        }
    }
`;

export const Tab = styled(AntTabs.TabPane)`
    color: white;
`;
