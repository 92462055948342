import usePlaceOrderApi from 'api/placeOrder/placeOrder.api';
import { DataTable, DataTableRow } from 'components/data-table';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CommissionInformationTableStled } from './index.styles';

interface CommissionRow {
    id: string | number;
    level: string;
    volume: string;
    maker: string;
    taker: string;
}

export const CommissionInformationTable = () => {
    const { t } = useTranslation();
    const [data, setData] = useState<any>([]);
    const { getComissionListZero } = usePlaceOrderApi();

    const fetchComissionList = useCallback(async () => {
        const response = await getComissionListZero();
        setData(response.list);
    }, []);

    useEffect(() => {
        fetchComissionList();
    }, [fetchComissionList]);

    const cols: DataTableRow<CommissionRow>[] = [
        {
            title: t('commissionInformationTable.levelTitle'),
            dataIndex: 'level',
            key: 'level',
            align: 'left',
            render: (item: any) => {
                return <div className="body-level">{item}</div>;
            },
        },
        {
            title: t('commissionInformationTable.operationTitle'),
            dataIndex: 'volume',
            key: 'volume',
            align: 'left',
        },
        {
            title: t('commissionInformationTable.makerTitle'),
            dataIndex: 'maker',
            key: 'maker',
            align: 'left',
        },
        {
            title: t('commissionInformationTable.takerTitle'),
            dataIndex: 'taker',
            key: 'taker',
            align: 'left',
        },
    ];

    return (
        <CommissionInformationTableStled>
            <DataTable<CommissionRow> cols={cols} source={data} />
        </CommissionInformationTableStled>
    );
};
