import { fonts } from 'common/theme/font';
import styled from 'styled-components';

export const CommissionInformationTableStled = styled.div`
    .data-table-footer {
        display: none;
    }

    .ant-table {
        .ant-table-container {
            .body-level {
                text-align: center;
                border-radius: 4px;
                min-width: 80px;
                padding: 4px 0px;
            }

            .ant-table-content {
                border-radius: 6px 6px 0px 0px;
                overflow: auto;
                .ant-table-thead {
                    font-size: ${fonts.size.normal};
                    font-weight: ${fonts.weight.semibold};
                    border-radius: 6px 6px 0px 0px;
                    background: ${({ theme }) =>
                        theme.colors.staticColor.color2.base};

                    .ant-table-cell {
                        padding: 14px 0px 14px 20px;
                        color: ${({ theme }) =>
                            theme.colors.staticColor.color1.base} !important;
                    }
                }

                .ant-table-tbody {
                    .ant-table-row {
                        font-weight: ${fonts.weight.regular};
                        font-size: ${fonts.size.normal};

                        .ant-table-cell-row-hover {
                            .body-level {
                                background: ${({ theme }) =>
                                    theme.colors.staticColor.color2
                                        .base} !important;
                                color: ${({ theme }) =>
                                    theme.colors.staticColor.color1
                                        .base} !important;
                            }
                        }
                    }
                    .ant-table-cell {
                        .body-level {
                            background: ${({ theme }) =>
                                theme.colors.color2.base} !important;
                        }
                    }
                }
            }
        }
    }
`;
