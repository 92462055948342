import { css } from 'styled-components';

export const EmailAntiPhishingCss = css`
    &.email-anti-phishing-modal {
        .ant-modal-content {
            max-width: 300px !important;
            width: 100%;
            .ant-modal-body {
                padding: 0px !important;
            }
        }
    }
`;
