import styled from 'styled-components';
import { Pagination } from 'antd';

export const Container = styled(Pagination)`
    .ant-pagination-options {
        display: none;
    }
    &.ant-pagination.mini {
        .ant-pagination-jump-prev,
        .ant-pagination-jump-next {
            display: inline-flex;
        }
        .ant-pagination-next,
        .ant-pagination-prev {
            display: inline-flex;
            .ant-pagination-item-link {
                display: inline-flex;
                justify-content: center;
                align-items: center;
            }
        }
        .ant-pagination-item-link,
        .ant-pagination-next,
        .ant-pagination-prev,
        .ant-pagination-item {
            margin: 1px;
            background: transparent;
            border-radius: 2px;
            border-color: transparent;

            a {
                color: ${({ theme }) =>
                    theme.colors.staticColor.color1.second2} !important;
            }

            &:hover {
                a {
                    color: ${({ theme }) =>
                        theme.colors.color4.base} !important;
                }
            }

            &.ant-pagination-item-active {
                border-radius: 4px;
                background-color: ${({ theme }) =>
                    theme.colors.staticColor.color2.base};
                color: ${({ theme }) =>
                    theme.colors.staticColor.color1.base} !important;
                border-color: ${({ theme }) =>
                    theme.colors.staticColor.color2.base};

                a {
                    color: ${({ theme }) =>
                        theme.colors.staticColor.color1.base} !important;
                }
            }

            .anticon-left,
            .anticon-right {
                svg {
                    fill: ${({ theme }) =>
                        theme.colors.staticColor.color2.base};
                }
            }
        }
    }

    &.ant-pagination.mini[data-pagination='active'] {
        .ant-pagination-item.ant-pagination-item-active {
            clear: both;
            margin: 1px;
            background: transparent;
            border-radius: 2px;
            border-color: transparent;
            a {
                font-weight: 400;
                color: ${({ theme }) => theme.colors.color1.second2} !important;
            }
        }
        .ant-pagination-item.ant-pagination-item-1 {
            border-radius: 4px;
            background-color: ${({ theme }) =>
                theme.colors.staticColor.color2.base};
            color: ${({ theme }) =>
                theme.colors.staticColor.color1.second2} !important;
            border-color: ${({ theme }) =>
                theme.colors.staticColor.color2.base};

            a {
                font-weight: 500;
                color: ${({ theme }) =>
                    theme.colors.staticColor.color1.base} !important;
            }
        }
    }
    .ant-pagination-jump-next-custom-icon .anticon {
        color: ${({ theme }) =>
            theme.colors.staticColor.color2.base} !important;
    }
`;
