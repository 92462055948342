import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';
import { Layout } from 'antd';
import { fonts } from 'common/theme/font';

const mobileCss = css`
    display: none;
`;
export const HeaderStyled = styled(Layout.Header)`
    height: 52px;

    .loading-logo {
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        background: ${({ theme }) => theme.colors.color1.base};
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
        position: relative;
        height: 52px;
        padding: 16px;
    }

    .header-desktop {
        display: flex;
        justify-content: left;
        box-sizing: border-box;
        padding: 0 0 0 16px;
        background: ${({ theme }) => theme.colors.color1.base};

        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    }

    .header-left-side {
        flex: 1;
        display: flex;
        align-items: center;
        img {
            cursor: pointer;
        }

        svg {
            display: inline-block;
            // width: 120px;
        }
    }

    .header-right-side {
        display: flex;
        align-items: center;
        .notification-item {
            position: relative;
            border-left: 1px solid ${({ theme }) => theme.colors.color2.base};
            border-right: 1px solid ${({ theme }) => theme.colors.color2.base};
            button {
                height: 100%;
                background-color: transparent;
                i {
                    font-size: ${fonts.size.xlarge};
                    color: ${({ theme }) =>
                        theme.colors.color4.base} !important;
                }
            }
            span {
                position: absolute;
                top: 9px;
                left: 0;
                right: 20px;
                margin: auto;
                background-color: ${({ theme }) =>
                    theme.colors.staticColor.color2.base};
                z-index: 1;
                color: white;
                display: flex;
                line-height: 0px;
                justify-content: center;
                align-content: center;
                align-items: center;
                height: 14px;
                border-radius: 14px;
                min-width: 14px;
                max-width: fit-content;
                padding: 0 5px;
                font-size: 9px;
            }
        }
        .change-language {
            .ant-select-selector {
                border: none !important;
                outline: none !important;
                box-shadow: none !important;
                padding: 10px 0;
                background: transparent !important;
                .ant-select-selection-item {
                    color: ${({ theme }) =>
                        theme.colors.color4.base} !important;
                    font-size: ${fonts.size.medium};
                    font-weight: 400;
                    padding: 0px;
                    &::after {
                        width: 20px;
                    }
                }
                .ant-selection-item {
                    max-width: 70px;
                }
            }
            .ant-select-arrow {
                right: 0;
                left: auto;
                color: ${({ theme }) => theme.colors.color4.base} !important;
            }
        }
        .change-theme {
            padding: 0 !important;
            border-left: 1px solid ${({ theme }) => theme.colors.color2.base};
            border-right: 1px solid ${({ theme }) => theme.colors.color2.base};
            button {
                height: 100%;
                padding: 0 20px !important;
                i {
                    font-size: ${fonts.size.xlarge};
                    svg {
                        color: ${({ theme }) =>
                            theme.colors.color4.base} !important;
                    }
                }
            }
        }
        .register-btn {
            margin-right: 24px;
            font-weight: ${fonts.weight.regular};
            span {
                color: ${({ theme }) =>
                    theme.colors.staticColor.color1.base} !important;
            }
        }
        .login-btn {
            margin: 0px 24px;
            font-weight: ${fonts.weight.regular};
            & > span {
                color: ${({ theme }) => theme.colors.color4.base} !important;
            }
        }

        .account-container-list-item {
            display: flex;
            height: 100%;
            align-items: center;
            padding: 0 20px;

            &:not(:first-child) {
                border-left: 1px solid
                    ${({ theme }) => theme.colors.color2.base};
            }
            .ant-btn {
                .app-icon {
                    svg {
                        color: ${({ theme }) =>
                            theme.colors.color4.base} !important;
                    }
                }
                &:hover {
                    .app-icon {
                        svg {
                            color: ${({ theme }) =>
                                theme.colors.staticColor.color2
                                    .base} !important;
                        }
                    }
                }
            }

            &.wallet-item {
                padding: 0px;
                .ant-btn {
                    padding: 0 20px !important;
                    & > span {
                        color: ${({ theme }) =>
                            theme.colors.color4.base} !important;
                    }

                    .app-icon {
                        svg {
                            font-size: ${fonts.size.xlarge};
                            color: ${({ theme }) =>
                                theme.colors.color4.base} !important;
                        }
                    }

                    &:hover {
                        .app-icon {
                            svg {
                                color: ${({ theme }) =>
                                    theme.colors.staticColor.color2
                                        .base} !important;
                            }
                        }
                    }
                }
            }
            &.account-item {
                padding: 0 !important;
                .ant-btn {
                    width: inherit;
                    padding: 0 20px;
                    height: 100%;
                    background-color: transparent;
                    .app-icon {
                        svg {
                            font-size: ${fonts.size.xlarge};
                        }
                    }
                }
            }
        }
    }

    .ant-menu {
        line-height: 52px !important;
        flex: 1;
        border-bottom: none !important;

        &.ant-menu-sub {
            color: ${({ theme }) => theme.colors.color4.base} !important;
            li {
                display: flex;
                justify-content: flex-start;
                flex-direction: row;
                align-items: center;
                &::after {
                    border-color: ${({ theme }) =>
                        theme.colors.color2.base} !important;
                }
            }
        }
        .app-icon {
            background-color: ${({ theme }) =>
                theme.colors.staticColor.color2.opacity2} !important;
            border-radius: 1rem;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                color: ${({ theme }) => theme.colors.staticColor.color2.base};
            }
        }
        li {
            &.ant-menu-overflow-item {
                &:after {
                    right: 0;
                    left: 0;
                }
            }
        }
    }
    ${mediaQuery.lessThan('tablet')`${mobileCss}`}
`;
