import { fonts } from 'common/theme/font';
import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';

const desktopCss = css`
    border-radius: 0;
    bottom: 0;
    transition: all 0.5s;
    box-shadow: 0px 2px -10px rgb(0 0 0 / 10%);
`;
const tabletCss = css`
    border-radius: 0;
    bottom: 0;
    transition: all 0.5s;
    box-shadow: 0px 2px -10px rgb(0 0 0 / 10%);
    .item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .left {
            margin-bottom: 15px;
        }
        button:last-child {
            margin-right: 0px;
        }
    }
`;
export const Container = styled.div`
    position: fixed;
    bottom: 30px;
    left: 0;
    right: 0;
    max-width: 1200px;
    width: 100%;
    margin: auto;
    border-radius: 16px;
    background-color: ${({ theme }) =>
        theme.colors.staticColor.color1.base} !important;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
    padding: 20px 30px;
    font-weight: ${fonts.weight.regular};
    font-size: ${fonts.size.normal};
    color: ${({ theme }) => theme.colors.staticColor.color1.second3};
    animation-name: animation;
    animation-duration: 1.5s;
    z-index: 999;
    .item {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-content: center;
        .left {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .image {
                min-width: 40px;
                min-height: 40px;
                background-color: ${({ theme }) =>
                    theme.colors.staticColor.color2.opacity2};
                border-radius: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: 24px;
                    height: 24px;
                }
            }
            .description {
                text-align: left;
                margin: 0px 20px;
            }
            a {
                color: ${({ theme }) => theme.colors.staticColor.color2.base};
                text-decoration: underline;
                font-weight: ${fonts.weight.medium};
            }
        }
        .right {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            button {
                max-width: 100%;
                margin-right: 15px;
                &:last-child {
                    margin-right: 30px;
                }
            }
        }
    }
    .close {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
        color: ${({ theme }) => theme.colors.staticColor.color1.second1};
    }
    @keyframes animation {
        0% {
            opacity: 0;
            transform: translateY(50px);
        }
        70% {
            opacity: 0;
            transform: translateY(50px);
        }
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }
    ${mediaQuery.between('tablet', 'desktop')`${desktopCss}`}
    ${mediaQuery.lessThan('tablet')`${tabletCss}`}
`;
