import { SocialStyled } from './index.styles';
import { IconButtonSocial } from 'components/icon-button-social';

const WrappedSocial = () => {
    return (
        <SocialStyled className="social-links">
            <ul>
                <li>
                    <a
                        href="https://twitter.com/yuexcom"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <IconButtonSocial icon="twitter"></IconButtonSocial>
                    </a>
                </li>
                <li>
                    <a
                        href="https://www.facebook.com/people/Yuex/100086800875933/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <IconButtonSocial icon="facebook"></IconButtonSocial>
                    </a>
                </li>
                <li>
                    <a
                        href="https://www.instagram.com/yuexofficial/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <IconButtonSocial icon="instagram"></IconButtonSocial>
                    </a>
                </li>
            </ul>
        </SocialStyled>
    );
};

export const SocialButtonList = WrappedSocial;
