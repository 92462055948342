import { Modal } from 'antd';
import { SvgIcon } from 'components/svg-icon';
import { IconType } from 'components/svg-icon/icon-types';
import { useCallback, useRef, useState } from 'react';

export interface UseConfirmProps {
    title?: string;
    content?: string | React.ReactNode;
    onOk?: () => void | Promise<void>;
    onCancel?: () => void;
    icon?: IconType;
    okText?: string;
    cancelText?: string;
    closable?: boolean;
}

type UseConfirmRef = {
    openConfirm: (customProps?: UseConfirmProps) => void;
};

export const useConfirm = (props: UseConfirmProps) => {
    const [, setModal] = useState<unknown>();
    const openConfirm = useCallback(
        (custom?: UseConfirmProps) => {
            const newObj = custom ? Object.assign(props, custom) : props;
            const extraProps: { icon?: React.ReactNode } = { icon: null };
            if (newObj.icon) {
                extraProps.icon = <SvgIcon name={newObj.icon} />;
            }

            const instanceModal = Modal.confirm({
                ...newObj,
                icon: null,
                closeIcon: <SvgIcon name="times-circle-filled" size={24} />,
                title: (
                    <div className="confirmation-modal-header">
                        {extraProps.icon} <span>{newObj.title}</span>
                    </div>
                ),
            });
            setModal(instanceModal);
        },
        [setModal, props]
    );

    const modalRef = useRef<UseConfirmRef>({
        openConfirm,
    });

    return modalRef.current;
};
