import {
    LastActionData,
    LastActionResponse,
    UserfavcoinsNewResponse,
    UserfavcoinsResponse,
} from './account.model';

export const mappingLastAction = (
    payload: LastActionResponse
): LastActionResponse => {
    let mapData: LastActionData[] = [];
    for (let item of payload.records) {
        mapData.push({
            createdat: item.createdat,
            action: item.action,
            data: JSON.parse(item.data),
            ipaddress: item.ipaddress,
            useragent: item.useragent,
        });
    }
    return {
        records: mapData,
        success: payload.success,
        totalrecords: payload.totalrecords,
    };
};
export const mappingUserfavcoins = (
    payload: UserfavcoinsResponse[]
): UserfavcoinsNewResponse[] => {
    let mapData: UserfavcoinsNewResponse[] = [];
    for (let item of payload) {
        mapData.push({
            coin: item.base,
            second: item.second,
            id: item.id,
        });
    }
    return mapData;
};
