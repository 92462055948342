import { fonts } from 'common/theme/font';
import styled from 'styled-components';

export const ModalWarningCardStyled = styled.div`
    &.modal-warning-card-container {
        .desc-list {
            padding-bottom: 8px;
            list-style: none;

            strong {
                font-weight: ${fonts.weight.bold};
            }

            &:before {
                content: '•';

                color: ${({ theme }) => theme.colors.staticColor.color2.base};
                display: inline-block;
                width: 1em;

                margin-left: -1em;
            }
        }
        .desc-colored {
            font-weight: ${fonts.weight.bold};
            padding-top: 15px;

            color: ${({ theme }) => theme.colors.staticColor.color2.base};
        }
        .buttons-container {
            .cancel-button {
                display: none;
            }
        }
    }
`;
