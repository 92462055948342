//@ts-nocheck
import styled, { css } from 'styled-components';
// import { Menu as AntMenu } from 'antd';
import AntMenu from 'antd/es/menu';

export const DropdownMenuCss = css`
    &.ant-dropdown {
        &.tunety-dropdown {
            border-radius: 2px;
        }
    }
`;

export const Menu = styled(AntMenu)`
    &.ant-dropdown-menu {
        padding: 8px 0;
        background-color: ${({ theme }) => theme.colors.color1.base};
        border-radius: 4px;
    }
`;

export const Divider = styled(AntMenu.Divider)`
    &.ant-dropdown-menu-item-divider {
        margin: 6px 12px !important;
        background-color: ${({ theme }) => theme.colors.color2.base};
    }
`;

export const MenuItem = styled(AntMenu.Item)`
    &.ant-dropdown-menu-item:not(:last-child) {
        margin-bottom: 0;
    }

    &.ant-dropdown-menu-item {
        display: flex;
        height: inherit;

        margin: 0;

        line-height: 1em;
        font-size: 14px;
        padding: 6px 16px;

        .dropdown-item-icon {
            font-size: 12px;

            fill: ${({ theme }) => theme.colors.staticColor.color2.base};
            margin-right: 8px;
            width: 12px;
            height: 12px;
        }

        .dropdown-item-label {
            display: flex;
            align-items: center;
            flex: 1;
            font-size: 14px;

            white-space: nowrap;
            color: ${({ theme }) => theme.colors.color3.base};
            text-overflow: ellipsis;
        }
        .ant-dropdown-menu-title-content {
            display: flex;
        }

        &:hover {
            background-color: transparent;
            .dropdown-item-icon {
                fill: ${({ theme }) => theme.colors.staticColor.color2.base};
            }
            .dropdown-item-label {
                color: ${({ theme }) => theme.colors.color4.base};
            }
        }
    }
`;
