import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';
import { fonts } from 'common/theme/font';

const mobile = css`
    .card-header {
        background-image: none;
    }
    .card-body {
        background-color: transparent;
        border: none;
        box-shadow: none;
        .content {
            margin-bottom: -75px;
        }
        .row-card {
            flex-direction: column;
            .ant-col {
                width: 100%;
                max-width: 100%;
                flex: 1;
            }
        }
    }
`;
export const Container = styled.div`
    .card-header {
        background-color: ${({ theme }) =>
            theme.colors.staticColor.color2.base};
        width: 100%;
        min-height: 300px;
        border-radius: 14px 14px 0 0;
        background-image: url('../assets/images/gift-card-bg.svg');
        background-repeat: no-repeat;
        background-position: 101% -1px;
        background-size: auto 101%;
        overflow: hidden;
        .content {
            max-width: 360px;
            margin: 40px;
            margin-bottom: 80px;
            color: ${({ theme }) => theme.colors.staticColor.color1.base};
            line-height: 1.3em;
            .title {
                font-size: ${fonts.size.xlarge};
                line-height: 1.3em;
                font-weight: ${fonts.weight.regular};
                margin: auto;
                padding-top: 10px;
                margin-top: 10px;
                margin-bottom: 15px;
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    width: 20px;
                    height: 4px;
                    background-color: ${({ theme }) =>
                        theme.colors.staticColor.color1.base};
                    border-radius: 4px;
                }
            }
            .text {
                max-width: 210px;
                font-size: ${fonts.size.medium};
                font-weight: ${fonts.weight.light};
                margin-bottom: 15px;
            }
            .ant-btn.ant-btn-link {
                background: #fff !important;
                padding: 6px 10px !important;
                span {
                    color: ${({ theme }) =>
                        theme.colors.staticColor.color1.second3}!important;
                }
                .app-icon {
                    margin-left: 5px;
                    svg {
                        color: ${({ theme }) =>
                            theme.colors.staticColor.color1.second3}!important;
                    }
                }
            }
        }
    }
    .card-body {
        background-color: ${({ theme }) => theme.colors.color1.base};
        width: 100%;
        border-radius: 0 0 4px 4px;
        padding: 25px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

        .content {
            background-color: ${({ theme }) => theme.colors.color1.base};
            color: ${({ theme }) => theme.colors.color4.base};
            width: 100%;
            border-radius: 14px;
            padding: 30px;
            max-width: 650px;
            position: relative;
            top: -75px;
            margin: auto;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
            line-height: 1.3em;
            .title {
                font-size: ${fonts.size.large};
                font-weight: ${fonts.weight.regular};
                margin: auto;
                padding-bottom: 10px;
                margin-bottom: 10px;
                max-width: 320px;
                position: relative;
                text-align: center;
                &::before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: 20px;
                    height: 4px;
                    margin: auto;
                    background-color: ${({ theme }) =>
                        theme.colors.staticColor.color2.base};
                    border-radius: 4px;
                }
            }
            .text {
                max-width: 320px;
                margin: auto;
                text-align: center;
                font-size: ${fonts.size.medium};
                font-weight: ${fonts.weight.light};
                margin-bottom: 30px;
            }
            .card {
                width: 100%;
                border-radius: 8px;
                box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
                transition: box-shadow 0.4s;
                &:hover {
                    transition: box-shadow 0.4s;
                    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.3);
                }
            }
        }
    }
    ${mediaQuery.lessThan('xltablet')`${mobile}`}
`;
