import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';

const mobileCss = css`
    align-items: start;
    height: inherit;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    .description {
        margin-bottom: 30px !important;
    }
    .login-form {
        .ant-btn {
            margin-top: 30px;
        }
    }
    .buttons-container {
        margin-top: 20px;
    }
`;

export const LoginPageCss = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 30px 0;
    min-height: calc(100vh - 52px);
    ${mediaQuery.lessThan('tablet')`${mobileCss}`}
`;
