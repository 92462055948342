import { RegisterResponse, RegisterResponseData } from 'api/auth/auth.model';
import { ThemeType } from 'common/theme/ThemeProvider';
import { AuthUser, UserResponse } from './auth.model';

export const mappingAuthUser = (payload: UserResponse): AuthUser => {
    return {
        twoFactorMethod: payload['2fmethod'],
        antiSpamKey: payload.antispamkey,
        firstName: payload.firstname,
        lastName: payload.lastname,
        email: payload.email,
        phone: payload.phone,
        theme: payload.darklayout ? ThemeType.Dark : ThemeType.Light,
        defaultCurrency: payload.defaultcurrency,
        language: payload.language,
        sessionTimeout: payload.sesstimeout,
        userState: payload.ustate,
        survey: payload.survey,
    };
};

export const mappingRegisterUser = (
    payload: RegisterResponse
): RegisterResponseData => {
    return {
        userGuid: payload.user_guid,
    };
};
