import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';
import { fonts } from 'common/theme/font';

const tabletCss = css``;
const mobileCss = css``;

export const Container = styled.div`
    ${mediaQuery.between('tablet', 'desktop')`${tabletCss}`}
    ${mediaQuery.lessThan('tablet')`${mobileCss}`}
`;

export const ConfirmationCss = css`
    &.ant-modal {
        display: flex;
        align-items: center;
        top: inherit;
        width: 100% !important;
        height: 100%;
        justify-content: center;
        margin: 0 auto;
        .ant-modal-content {
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
            border-radius: 6px;
            .ant-modal-close {
                top: 5px;
                right: 10px;

                .ant-modal-close-x {
                    width: inherit;
                    height: inherit;
                    line-height: inherit;
                    right: -3px;
                    top: 2px;
                    position: relative;
                    font-size: ${fonts.size.medium};
                    color: ${({ theme }) => theme.colors.color2.base};
                }
            }

            .ant-modal-body {
                font-family: ${fonts.family};
                font-weight: ${fonts.weight.regular};

                padding: 25px 20px 15px 20px;
                .ant-modal-confirm-body-wrapper {
                    .confirmation-modal-header {
                        display: flex;
                        align-items: center;
                        span {
                            font-size: ${fonts.size.medium};
                            color: ${({ theme }) => theme.colors.color3.base};
                        }
                        i {
                            color: ${({ theme }) => theme.colors.color3.base};
                            height: 34px;
                            margin-right: 5px;
                            font-size: 32px;
                        }
                    }

                    background: ${({ theme }) => theme.colors.color1.base};
                    box-sizing: border-box;
                    border-radius: 6px;

                    .ant-modal-confirm-btns {
                        font-weight: ${fonts.weight.semibold};
                        font-size: ${fonts.size.medium};
                        border-radius: 4px;
                        .ant-btn {
                            text-shadow: inherit;
                            border-radius: 4px;
                            font-weight: 600;
                            &.ant-btn-default {
                                padding: 0 22px;
                                color: ${({ theme }) =>
                                    theme.colors.color3.base};
                                background: ${({ theme }) =>
                                    theme.colors.color1.base};
                                border: 1px solid
                                    ${({ theme }) => theme.colors.color3.base};
                            }
                            &.ant-btn-primary {
                                margin-left: 15px;
                                padding: 0 18px;
                                border: none;
                                color: ${({ theme }) =>
                                    theme.colors.color1.base};
                            }
                            background: ${({ theme }) =>
                                theme.colors.staticColor.color2.base};
                        }
                    }
                }

                .app-icon {
                    height: 35px;
                    line-height: 35px;

                    margin: 0;
                    display: inline-block;
                    font-size: ${fonts.size.xxlarge};
                }
            }
        }
    }
`;
