import { FormikContextType } from 'formik';
import useFormikEvents from './useFormikEvents';
import { HelperMessage } from 'components/helper-message';
import classnames from 'classnames';
import { FormFieldContainer } from './index.syles';

export * from './fields';

export interface ErrorFields {
    error: boolean;
    errorMessage?: string;
}

export interface FormFieldProps<T, K = CommonFormProps> {
    form: FormikContextType<T>;
    name: keyof T;
    optional?: boolean;
    props?: Partial<K>;
    Component?: (props: Partial<K>) => JSX.Element;
}

export const FormField = <T extends {}, K extends CommonFormProps>({
    form,
    name,
    optional: _optional,
    props = {},
    Component = (_: Partial<K>) => <></>,
}: FormFieldProps<T, K>) => {
    const {
        name: _name,
        value: propValue,
        onChange,
        onBlur,
        onFocus,
        disabled,
        label,
        infoText: _infoText,
        ...rest
    } = props;
    const {
        handleChangeGenerator,
        handleBlurGenerator,
        handleFocusGenerator,
        getErrorText,
        isFocused,
    } = useFormikEvents<T>({
        form,
        onChange,
        onBlur,
        onFocus,
    });

    const errorText = getErrorText(name);

    const errorFields: ErrorFields = {
        error: !!errorText,
        errorMessage: errorText,
    };

    const computedProps = {
        name: name as string,
        value: propValue || (form.values[name] as unknown),
        onChange: handleChangeGenerator(name),
        onBlur: handleBlurGenerator(name),
        onFocus: handleFocusGenerator(name),
        disabled,
        checked: form.values[name],
        // error: errorFields.error ? String(errorFields.error) : undefined,
        ...rest,
    } as unknown as Partial<K>;

    return (
        <FormFieldContainer
            className={classnames('app-form-field', `app-form-field-${_name}`, {
                'app-form-field-focused': isFocused,
            })}
        >
            <div className="app-form-label">
                <label>{label}</label>
            </div>
            <div
                className={`app-form-control ${
                    errorFields.error ? 'error' : ''
                }`}
            >
                <Component {...computedProps} />
            </div>
            {errorFields.error && (
                <div className="app-form-helper">
                    <HelperMessage
                        error={errorFields.error}
                        text={errorFields.errorMessage}
                        disabled={disabled}
                    />
                </div>
            )}
        </FormFieldContainer>
    );
};
