import httpService from 'common/http/http.service';
import { useRef } from 'react';
import supportApiEndpoints from './support.endpoint';
import {
    CirculationResponse,
    NotificationRequest,
    NotificationResponse,
    SupportApiCalls,
} from './support.model';

const useSupportApi = (): SupportApiCalls => {
    const generateApiCalls = (): SupportApiCalls => {
        return {
            notificationList: async (
                payload: NotificationRequest
            ): Promise<NotificationResponse> => {
                const response: any = await httpService.client.post(
                    supportApiEndpoints.notificationOptions,
                    payload
                );
                return response;
            },
            circulationsList: async (
                base: string,
                second?: string
            ): Promise<CirculationResponse> => {
                const response: CirculationResponse =
                    await httpService.client.get(
                        `${
                            supportApiEndpoints.circulationOptions
                        }?Base=${base}&Second=${
                            second !== undefined ? second : ''
                        }`
                    );

                return response;
            },
        };
    };

    const songCallsRef = useRef<SupportApiCalls>(generateApiCalls());
    return songCallsRef.current;
};

export default useSupportApi;
