import { mediaQuery } from 'common/theme/media';
import { css } from 'styled-components';
const mobileCss = css`
    &.ant-modal.commission-information-modal {
        .ant-modal-content {
            max-width: 750px !important;
            width: 100%;
            margin: 0 auto;
        }
    }
`;
const tabletCss = css`
    &.ant-modal.commission-information-modal {
        .ant-modal-content {
            max-width: 850px;
            width: 100%;
            margin: 0 auto;
        }
    }
`;
export const CommissionInformationModalCss = css`
    &.ant-modal.commission-information-modal {
        .ant-modal-content {
            max-width: 850px;
            width: 100%;
            margin: 0 auto;
        }
    }
    ${mediaQuery.lessThan('tablet')`${mobileCss}`}
    ${mediaQuery.between('tablet', 'desktop')`${tabletCss}`}
`;
