import { css } from 'styled-components';

export const TransferDetailModalCss = css`
    &.ant-modal.transfer-detail-modal {
        .ant-modal-content {
            .ant-modal-body {
                margin: 0px !important;
                padding: 20px 0px !important;
            }
        }
    }
`;
