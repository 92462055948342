import { AppRoute } from 'components/app-routes';
import { lazy } from 'react';
import CentralizedScreenLayout from 'components/layouts/CentralizedScreenLayout';
import WideScreenLayout from 'components/layouts/WideScreenLayout';
import AuthGuard from 'common/auth/AuthGuard';
import SideBarScreenLayout from 'components/layouts/SideBarScreenLayout';
import ErrorPage from 'pages/Information/ErrorPage';
import CentralizedInfoScreenLayout from 'components/layouts/CentralizedInfoScreenLayout';
import GuideScreenLayout from 'components/layouts/GuideScreenLayout';
import ComingSoon from 'pages/Information/ComingSoon';
import UnderConstruction from 'pages/Information/UnderConstruction';
import CoinGuidePage from 'pages/Public/Guide/CoinDetailPage';
import StaticGuidePage from 'pages/Public/Guide/StaticDetailPage';
import CoinScreenLayout from 'components/layouts/CoinScreenLayout';
import { LoyaltyCards } from 'pages/Public/LoyaltyCards';
import { BlogPage } from 'pages/Public/Blog';
import { BlogDetail } from 'pages/Public/BlogDetail';
import { ComissionPage } from 'pages/Public/Commissions';
import CareerPage from 'pages/Public/Career';
import { AnnouncementPage } from 'pages/Public/Announcement';
import { GiftCard } from 'pages/Public/GiftCard';

export const languages = ['tr', 'en', 'de'];
const url = window.location.pathname.split('/');
let lang: any = document.cookie
    .split(';')
    .find((x: any) => x.includes('i18next'));
let urlLanguage = languages.includes(url[1])
    ? '/' + window.location.pathname.split('/')[1]
    : lang !== undefined
    ? '/' + lang.split('=')[1]
    : '/tr';

export function routePathLang2(lng: string) {
    urlLanguage = '/' + lng;
}

export const routesPaths = {
    base: '/',
    root: urlLanguage,
    public: {
        status: urlLanguage + '/status',
        market: urlLanguage + '/market',
        marketBasic: urlLanguage + '/market/basic',
        marketPro: urlLanguage + '/market/pro',
        support: urlLanguage,
        aboutUs: urlLanguage + '/about-us',
        reference: urlLanguage,
        giftCard: urlLanguage + '/gift-card',
        buysell: urlLanguage + '/buy-sell',
        career: urlLanguage + '/career',
        dictionary: urlLanguage + '/dictionary',
        security: urlLanguage + '/security',
    },
    authorization: {
        login: urlLanguage + '/login',
        register: urlLanguage + '/register',
    },
    survey: urlLanguage + '/survey',
    account: {
        main: urlLanguage + '/account',
        wallet: urlLanguage + '/account/wallet',
        reference: urlLanguage,
        security: urlLanguage + '/account/security',
        changepassword: urlLanguage + '/account/security/changepassword',
        googleauthenticator:
            urlLanguage + '/account/security/google-authenticator',
        // notification: '/account/notification',
        verification: urlLanguage + '/account/verification',
        smsVerification: urlLanguage + '/account/sms-verification',
    },
    operations: {
        withdraw: urlLanguage + '/withdraw',
        deposit: urlLanguage + '/deposit',
    },
    gift: {
        giftcardBuy: urlLanguage + '/giftcardbuy',
        giftcardSend: urlLanguage + '/giftcardsend',
    },
    reports: {
        main: urlLanguage + '/reports',
        withdrawHistory: urlLanguage + '/reports/withdraw-history',
        depositHistory: urlLanguage + '/reports/deposit-history',
        orderHistory: urlLanguage + '/reports/order-history',
        distributionHistory: urlLanguage + '/reports/distribution-history',
        userHistory: urlLanguage + '/reports/user-history',
    },
    guide: {
        root: urlLanguage + '/guide',
    },
    privacy: {
        root: urlLanguage + '/privacy',
    },
    loyaltyCards: {
        root: urlLanguage + '/loyalty-cards',
    },
    commissions: {
        root: urlLanguage + '/commissions',
    },
    announcement: {
        root: urlLanguage + '/announcement',
    },
    blog: {
        root: urlLanguage + '/blog',
    },
    error: urlLanguage + '/error',
    comingSoon: urlLanguage + '/comingSoon',
    underConstruction: urlLanguage + '/underConstruction',
};
export const appRoutes: AppRoute[] = [
    {
        exact: true,
        path: '/',
        layout: WideScreenLayout,
        component: lazy(() => import('pages/HomePage')),
    },
    {
        exact: true,
        path: routesPaths.root,
        layout: WideScreenLayout,
        component: lazy(() => import('pages/HomePage')),
    },
    {
        exact: true,
        path: routesPaths.public.buysell,
        layout: CentralizedScreenLayout,
        component: lazy(() => import('pages/Public/BuySell')),
    },
    {
        exact: true,
        path: routesPaths.public.status,
        layout: WideScreenLayout,
        component: lazy(() => import('pages/Public/Status')),
    },
    {
        exact: true,
        path: routesPaths.public.market,
        layout: WideScreenLayout,
        component: lazy(() => import('pages/Public/Market')),
    },
    {
        exact: true,
        path: routesPaths.public.marketBasic,
        layout: WideScreenLayout,
        component: lazy(() => import('pages/Public/Market/Basic')),
    },
    {
        exact: true,
        path: `${routesPaths.public.marketBasic}/:pair`,
        layout: WideScreenLayout,
        component: lazy(() => import('pages/Public/Market/Basic')),
    },
    {
        exact: true,
        path: `${routesPaths.blog.root}`,
        layout: WideScreenLayout,
        component: BlogPage,
    },
    {
        exact: true,
        path: `${routesPaths.blog.root}/:slug`,
        layout: WideScreenLayout,
        component: BlogDetail,
    },
    {
        exact: true,
        path: routesPaths.public.marketPro,
        layout: WideScreenLayout,
        component: lazy(() => import('pages/Public/Market/Pro')),
    },
    {
        exact: true,
        path: `${routesPaths.public.marketPro}/:pair`,
        layout: WideScreenLayout,
        component: lazy(() => import('pages/Public/Market/Pro')),
    },
    {
        exact: true,
        path: routesPaths.public.support,
        layout: WideScreenLayout,
        component: lazy(() => import('pages/Public/Support')),
    },
    {
        exact: true,
        path: routesPaths.public.security,
        layout: WideScreenLayout,
        component: lazy(() => import('pages/Public/Security')),
    },
    {
        exact: true,
        path: routesPaths.public.dictionary,
        layout: WideScreenLayout,
        component: lazy(() => import('pages/Public/Dictionary')),
    },
    {
        exact: true,
        path: routesPaths.public.aboutUs,
        layout: WideScreenLayout,
        component: lazy(() => import('pages/Public/AboutUs')),
    },
    {
        exact: true,
        path: routesPaths.public.reference,
        layout: GuideScreenLayout,
        component: lazy(() => import('pages/Public/Reference')),
    },
    {
        exact: true,
        path: routesPaths.authorization.login,
        layout: CentralizedScreenLayout,
        component: lazy(() => import('pages/Auth/LoginPage')),
        guard: AuthGuard,
        guardCondition: false,
        redirect: routesPaths.root,
    },
    {
        exact: true,
        path: routesPaths.authorization.register,
        layout: CentralizedScreenLayout,
        component: lazy(() => import('pages/Auth/RegisterPage')),
    },
    {
        path: `${routesPaths.survey}/:id`,
        layout: WideScreenLayout,
        component: lazy(() => import('pages/Survey')),
        guard: AuthGuard,
        guardCondition: true,
        redirect: routesPaths.root,
    },
    {
        path: routesPaths.account.main,
        layout: SideBarScreenLayout,
        component: lazy(() => import('pages/Account/AccountMain')),
        guard: AuthGuard,
        guardCondition: true,
        redirect: routesPaths.root,
        className: 'account',
    },
    {
        exact: true,
        path: routesPaths.account.verification,
        layout: SideBarScreenLayout,
        component: lazy(() => import('pages/Account/VerificationPage')),
        guard: AuthGuard,
        guardCondition: true,
        redirect: routesPaths.root,
    },
    {
        exact: true,
        path: routesPaths.account.reference,
        layout: SideBarScreenLayout,
        component: lazy(() => import('pages/Public/Reference/auth-references')),
        guard: AuthGuard,
        guardCondition: true,
        redirect: routesPaths.root,
    },
    {
        exact: true,
        path: routesPaths.account.wallet,
        layout: SideBarScreenLayout,
        component: lazy(() => import('pages/Account/Wallet')),
        guard: AuthGuard,
        guardCondition: true,
        redirect: routesPaths.root,
    },
    {
        exact: true,
        path: routesPaths.account.security,
        layout: SideBarScreenLayout,
        component: lazy(() => import('pages/Account/Security')),
        guard: AuthGuard,
        guardCondition: true,
        redirect: routesPaths.root,
    },
    {
        exact: true,
        path: routesPaths.account.changepassword,
        layout: SideBarScreenLayout,
        component: lazy(() => import('pages/Account/Security/ChangePassword')),
        guard: AuthGuard,
        guardCondition: true,
        redirect: routesPaths.root,
    },
    {
        exact: true,
        path: routesPaths.account.googleauthenticator,
        layout: SideBarScreenLayout,
        component: lazy(
            () => import('pages/Account/Security/GoogleAuthenticator')
        ),
        guard: AuthGuard,
        guardCondition: true,
        redirect: routesPaths.root,
    },
    // {
    //     exact: true,
    //     path: routesPaths.account.notification,
    //     layout: SideBarScreenLayout,
    //     component: lazy(() => import('pages/Account/NotificationOptionsPage')),
    //     guard: AuthGuard,
    //     guardCondition: true,
    //     redirect: routesPaths.root,
    // },
    {
        exact: true,
        path: routesPaths.account.smsVerification,
        layout: SideBarScreenLayout,
        component: lazy(() => import('pages/Account/Security/SmsVerification')),
        guard: AuthGuard,
        guardCondition: true,
        redirect: routesPaths.root,
    },
    {
        exact: true,
        path: routesPaths.operations.withdraw,
        layout: SideBarScreenLayout,
        component: lazy(() => import('pages/Operations/Withdraw')),
        guard: AuthGuard,
        guardCondition: true,
        redirect: routesPaths.root,
    },
    {
        exact: true,
        path: routesPaths.operations.deposit,
        layout: SideBarScreenLayout,
        component: lazy(() => import('pages/Operations/Deposit')),
        guard: AuthGuard,
        guardCondition: true,
        redirect: routesPaths.root,
    },
    {
        exact: true,
        path: routesPaths.gift.giftcardBuy,
        layout: SideBarScreenLayout,
        component: lazy(() => import('pages/Giftcard/GiftcardBuy')),
        guard: AuthGuard,
        guardCondition: true,
        redirect: routesPaths.root,
    },
    {
        exact: true,
        path: routesPaths.gift.giftcardSend,
        layout: SideBarScreenLayout,
        component: lazy(() => import('pages/Giftcard/GiftcardSend')),
        guard: AuthGuard,
        guardCondition: true,
        redirect: routesPaths.root,
    },
    {
        exact: true,
        path: routesPaths.reports.main,
        layout: SideBarScreenLayout,
        component: lazy(() => import('pages/Reports/ReportsMain')),
        guard: AuthGuard,
        guardCondition: true,
        redirect: routesPaths.root,
    },
    {
        exact: true,
        path: routesPaths.reports.withdrawHistory,
        layout: SideBarScreenLayout,
        component: lazy(() => import('pages/Reports/WithdrawHistory')),
        guard: AuthGuard,
        guardCondition: true,
        redirect: routesPaths.root,
    },
    {
        exact: true,
        path: routesPaths.reports.depositHistory,
        layout: SideBarScreenLayout,
        component: lazy(() => import('pages/Reports/DepositHistory')),
        guard: AuthGuard,
        guardCondition: true,
        redirect: routesPaths.root,
    },
    {
        exact: true,
        path: routesPaths.reports.orderHistory,
        layout: SideBarScreenLayout,
        component: lazy(() => import('pages/Reports/OrdertHistory')),
        guard: AuthGuard,
        guardCondition: true,
        redirect: routesPaths.root,
    },
    // TODO: will be actived after completing the service development
    // {
    //     exact: true,
    //     path: routesPaths.reports.distributionHistory,
    //     layout: SideBarScreenLayout,
    //     component: lazy(() => import('pages/Reports/DistributionHistory')),
    //     guard: AuthGuard,
    //     guardCondition: true,
    //     redirect: routesPaths.root,
    // },
    {
        exact: true,
        path: routesPaths.reports.userHistory,
        layout: SideBarScreenLayout,
        component: lazy(() => import('pages/Reports/UserHistory')),
        guard: AuthGuard,
        guardCondition: true,
        redirect: routesPaths.root,
    },
    {
        exact: true,
        path: routesPaths.error,
        layout: CentralizedInfoScreenLayout,
        component: ErrorPage,
    },
    {
        exact: true,
        path: routesPaths.comingSoon,
        layout: CentralizedInfoScreenLayout,
        component: ComingSoon,
    },
    {
        exact: true,
        path: routesPaths.underConstruction,
        layout: CentralizedInfoScreenLayout,
        component: UnderConstruction,
    },
    {
        exact: true,
        path: routesPaths.guide.root,
        layout: WideScreenLayout,
        component: lazy(() => import('pages/Public/Guide/GuideMain')),
    },
    {
        exact: true,
        path: `${routesPaths.guide.root}/:category`,
        layout: CoinScreenLayout,
        component: CoinGuidePage,
    },
    {
        exact: true,
        path: `${routesPaths.guide.root}/:category/:slug`,
        layout: CoinScreenLayout,
        component: CoinGuidePage,
    },
    {
        exact: true,
        path: `${routesPaths.privacy.root}/:slug`,
        layout: GuideScreenLayout,
        component: StaticGuidePage,
    },
    {
        exact: true,
        path: routesPaths.loyaltyCards.root,
        layout: GuideScreenLayout,
        component: LoyaltyCards,
    },
    {
        exact: true,
        path: routesPaths.public.giftCard,
        layout: GuideScreenLayout,
        component: GiftCard,
    },
    {
        exact: true,
        path: routesPaths.commissions.root,
        layout: GuideScreenLayout,
        component: ComissionPage,
    },
    {
        exact: true,
        path: routesPaths.announcement.root,
        layout: GuideScreenLayout,
        component: AnnouncementPage,
    },
    {
        exact: true,
        path: routesPaths.public.career,
        layout: WideScreenLayout,
        component: CareerPage,
    },
    {
        exact: true,
        path: '*',
        layout: CentralizedInfoScreenLayout,
        component: ErrorPage,
    },
];
