import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';
import { fonts } from 'common/theme/font';

const mobileCss = css`
    height: inherit;
    padding: 30px 0;
    .title-container {
        margin-bottom: 10px;
        hr {
            margin-top: 10px;
        }
    }
    .open-positions {
        .positions-row {
            width: 100%;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 0px;
            .position-item {
                width: 45%;
                margin: 10px 5px;
                span {
                    font-size: ${fonts.size.small};
                }
            }
        }
    }
    .ant-btn {
        margin-top: 20px;
    }
`;

export const Container = styled.section`
    display: block;
    height: 500px;
    padding-top: 70px;
    padding-bottom: 100px;
    .title-container {
        align-items: center;
        text-align: center;
        margin-bottom: 50px;
        .title {
            font-size: ${fonts.size.xlarge};
            font-weight: ${fonts.weight.bold};
            color: ${({ theme }) => theme.colors.color4.base};
        }
        hr {
            width: 30px;
            height: 4px;
            background-color: ${({ theme }) =>
                theme.colors.staticColor.color2.base};
            border: none;
            border-radius: 3px;
            margin-top: 10px;
        }
    }
    .open-positions {
        display: flex;
        flex-direction: column;
        align-items: center;

        .positions-row {
            display: flex;
            margin-bottom: 50px;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;
            gap: 30px;
            max-width: 1200px;

            .position-item {
                width: 210px;
                justify-content: center;
                display: flex;
                align-items: center;
                height: 65px;
                background: ${({ theme }) => theme.colors.color1.base};
                box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
                border-radius: 6px;
                padding: 16px 8px;
                color: ${({ theme }) => theme.colors.color4.base};
                img {
                    margin-right: 10px;
                }
            }
        }
    }
    ${mediaQuery.lessThan('xltablet')`${mobileCss}`}
`;
