import { fonts } from 'common/theme/font';
import { mediaQuery } from 'common/theme/media';
import styled, { css } from 'styled-components';

const mobileCss = css``;
export const ComingSoonStyled = styled.div`
    &.coming-soon {
        .img {
            max-width: 732px;
            max-height: 588px;
        }

        .text {
            display: none;
        }

        .ant-layout-content {
            .hr {
                width: 28px;
                height: 4px;

                background: ${({ theme }) =>
                    theme.colors.staticColor.color2.base};
                border-radius: 26px;
            }

            .coming-soon-text {
                padding-top: 25px;

                font-weight: ${fonts.weight.light};
                font-size: ${fonts.size.medium};
                color: ${({ theme }) => theme.colors.color4.base};
            }

            .input-container {
                display: flex;
                justify-content: center;
                padding-top: 56px;

                .app-form-field {
                    padding-right: 20px;

                    .app-form-label {
                        display: none;
                    }

                    .app-form-control {
                        min-width: 280px !important;
                        max-height: 48px !important;

                        .ant-input {
                            padding: 14px 0px 14px 16px !important;
                        }
                    }
                }
                .ant-btn {
                    margin: 0px !important;
                    padding: 14px 23px !important;
                }
            }
            .social-links {
                .ant-btn {
                    padding: 0px;
                    margin: 0px;
                }
                ul {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 15px;
                }
            }
        }
    }
    ${mediaQuery.lessThan('xltablet')`${mobileCss}`}
`;
