import React, { useCallback, useMemo, useState } from 'react';
import { ProfileDropDownStyled } from './index.styles';
import { SideBarMenu, SideBarMenuItemProps } from 'components/side-bar-menu';
import { SvgIcon } from 'components/svg-icon';
import { Button } from 'components/button';
import { IconButton } from 'components';
// import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import { useAuthentication } from 'common/auth/AuthenticationProvider';
import { routesPaths } from 'config/routes';
import { useNavigate } from 'react-router-dom';
import { replaceAtString } from 'common/utils';

export interface ProfileDropdownProps {
    activeLevel?: string;
    setLevel?: string;
    referanceId: number;
}

const WrappedProfileDropDown = ({ referanceId }: ProfileDropdownProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [copied, setCopied] = useState(false);
    const [isActive, setActive] = useState(false);
    const { user, logout, isAccountVerified } = useAuthentication();
    const [emailAddress, setEmailAddress] = useState(user?.email || '');

    const items = useMemo((): SideBarMenuItemProps[] => {
        return [
            {
                title: t('sidebar.wallet.title'),
                link: routesPaths.account.wallet,
                icon: 'wallet',
            },
            ...(isAccountVerified
                ? ([
                      {
                          title: t('sidebar.deposit.title'),
                          link: routesPaths.operations.deposit,
                          icon: 'coin',
                      },
                  ] as SideBarMenuItemProps[])
                : []),
            {
                title: t('sidebar.account.title'),
                link: routesPaths.account.main,
                icon: 'user-circle-filled',
            },
            {
                title: t('sidebar.security.title'),
                link: routesPaths.account.security,
                icon: 'security',
            },
            {
                title: t('sidebar.reports.title'),
                link: routesPaths.reports.depositHistory,
                icon: 'reports',
            },
        ];
    }, [t, user]);

    const onMenuItemClick = useCallback((item: any) => {
        navigate(item.key);
    }, []);

    const secretEmailAddress = replaceAtString(
        replaceAtString(user?.email || '', 0, '***') || '',
        (user ? user?.email.split('@')[0].length : 0) - 3,
        '***'
    );

    const successMessage = useCallback(() => {
        if (copied === true) {
            message.success(t('profileDropdown.copy.text'));
        }
    }, [copied]);

    // eslint-disable-next-line
    const onCopy = useCallback(() => {
        setCopied(true);
        successMessage();
    }, [referanceId, copied]);

    const toggleEmail = useCallback(() => {
        setActive(!isActive);
        if (emailAddress.includes('*')) {
            setEmailAddress(user?.email || '');
        } else {
            setEmailAddress(secretEmailAddress);
        }
    }, [emailAddress]);

    return (
        <ProfileDropDownStyled className="profile-dropdown">
            <div className="profile-dropdown-header">
                <h6>{t('profileDropdown.header.title')}</h6>
                <div className="profile-dropdown-header-item">
                    <p className="profile-dropdown-header-text">
                        {emailAddress}
                    </p>
                    <IconButton
                        icon={isActive ? 'password-hide' : 'password-show'}
                        onClick={toggleEmail}
                    />
                </div>
                {/* <div className="profile-dropdown-header-item">
                    <p className="profile-dropdown-header-text light-text">
                        {t('profileDropdown.header.referance.text')}
                        {referanceId}
                    </p>
                    <CopyToClipboard
                        onCopy={onCopy}
                        text={referanceId.toString()}
                    >
                        <IconButton icon="copy" />
                    </CopyToClipboard>
                </div> */}
            </div>
            {/* <div className="profile-dropdown-level">
                <span className="profile-dropdown-level-item active-item">
                    {activeLevel}
                </span>
                <span className="profile-dropdown-level-item disabled-item">
                    {setLevel}
                </span>
            </div> */}
            <div className="profile-dropdown-content">
                <SideBarMenu items={items} onClick={onMenuItemClick} />
            </div>
            <div className="profile-dropdown-footer">
                <Button type="link" onClick={() => logout && logout()}>
                    <SvgIcon name="logout" />
                    <span className="logout-text">
                        {t('profileDropdown.footer.button.text')}
                    </span>
                </Button>
            </div>
        </ProfileDropDownStyled>
    );
};

export const ProfileDropdown = React.memo(WrappedProfileDropDown);
