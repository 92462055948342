import * as S from './index.styles';
import { AlertProps as AntAlertProps } from 'antd';
import React from 'react';
import { SvgIcon } from 'components/svg-icon';
import { Button } from 'components/button';

export interface AlertProps extends AntAlertProps {
    model: 'notification' | 'information';
    buttonAction?: any;
    showButton?: boolean;
    buttonText?: string;
}

export const Alert = React.memo(function AlertConsumer(props: AlertProps) {
    let icon = null;
    let button = null;
    if (props.showIcon) {
        if (props.type === 'success') {
            icon = <SvgIcon name="check-circle-filled" size={24} />;
        } else if (props.type === 'error') {
            icon = <SvgIcon name="error-circle-filled" size={24} />;
        } else {
            icon = <SvgIcon name="warning-circle-filled" size={24} />;
        }
    }
    if (props.showButton) {
        button = (
            <Button type="primary" onClick={props.buttonAction}>
                {props.buttonText}
            </Button>
        );
    }
    return (
        <>
            <S.Container {...props} icon={icon} action={button}></S.Container>
        </>
    );
});
