import { css } from 'styled-components';

export const TimeoutPeriodModalCss = css`
    .timeout-period-modal {
        .ant-modal-content {
            width: 100%;
            max-width: 280px !important;
            .ant-modal-body {
                margin: 0px !important;
                padding: 20px 0px !important;
            }
        }
    }
`;
