import { Alert as AntAlert } from 'antd';
import styled from 'styled-components';
import { AlertProps } from '.';
import { fonts } from '../../common/theme/font';

export const Container = styled(AntAlert)<AlertProps>`
    &.ant-alert {
        align-items: center;
        padding: 16px 15px;
        background-color: ${({ theme }) => theme.colors.color1.base};
        box-shadow: 0px 0px 10px rgba(0, 85, 255, 0.14);
        border-radius: 4px;
        border: none;

        ${({ model }) => (model === 'information' ? 'padding: 8px 16px;' : '')}

        &:before {
            content: '';
            display: inline-block;
            position: absolute;
            left: -1px;
            width: 3px;
            border-radius: 0 6px 6px 0;
            height: calc(100% - 10px);

            ${({ model }) =>
                model === 'information' ? 'content: inherit;' : ''}
        }

        .ant-alert-content {
            padding-left: 12px;

            .ant-alert-message {
                font-size: ${fonts.size.normal};
                font-weight: ${fonts.weight.semibold};
                color: ${({ theme }) => theme.colors.color4.base};
            }

            .ant-alert-description {
                font-size: ${fonts.size.small};
                font-weight: ${fonts.weight.regular};
                color: ${({ theme }) => theme.colors.color4.base};

                ${({ model, type, theme }) =>
                    model === 'information'
                        ? `font-size: ${fonts.size.small};
                            font-weight: ${fonts.weight.regular};
                            color: ${
                                type === 'error'
                                    ? theme.colors.staticColor.color3.base
                                    : ''
                            }`
                        : ''}
            }
        }

        .ant-alert-icon {
            display: flex;
            align-self: flex-start;
            margin: 0px;

            ${({ showIcon }) => (!showIcon ? 'display: none !important;' : '')}
        }

        &-success {
            ${({ model, theme }) =>
                model === 'information'
                    ? `background-color: ${theme.colors.color5.base};`
                    : ''}

            .ant-alert-icon {
                color: ${({ theme }) =>
                    theme.colors.staticColor.color2.second2};
            }

            &:before {
                background: ${({ theme }) =>
                    theme.colors.staticColor.color2.second2};
            }
        }

        &-error {
            ${({ model, theme }) =>
                model === 'information'
                    ? `background-color: ${theme.colors.color7.base};`
                    : ''}

            .ant-alert-icon {
                color: ${({ theme }) => theme.colors.staticColor.color3.base};
            }

            &:before {
                background: ${({ theme }) =>
                    theme.colors.staticColor.color3.base};
            }
        }

        &-warning {
            ${({ model, theme }) =>
                model === 'information'
                    ? `background-color: ${theme.colors.color2.base};`
                    : ''}

            .ant-alert-icon {
                color: ${({ theme }) => theme.colors.staticColor.color5.base};
            }

            &:before {
                background: ${({ theme }) =>
                    theme.colors.staticColor.color5.base};
            }
        }

        &-info {
            ${({ model, theme }) =>
                model === 'information'
                    ? `background-color: ${theme.colors.color6.base};`
                    : ''}

            .ant-alert-icon {
                color: ${({ theme }) => theme.colors.staticColor.color4.base};
            }

            &:before {
                background: ${({ theme }) =>
                    theme.colors.staticColor.color4.base};
            }
        }
    }
`;
