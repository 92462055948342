import { WalletDropDownStyled } from './index.styles';
import { Button } from 'components/button';
import { IconButton } from 'components';
import { useTranslation } from 'react-i18next';
import { routesPaths } from 'config/routes';
import { CoinDisplay } from 'components/coin-display';
import { useAuthentication } from 'common/auth/AuthenticationProvider';
import { useNavigate } from 'react-router-dom';
import { NumberFormat } from 'common/utils';

const WrappedWalletDropDown = () => {
    const { currentBalance } = useAuthentication();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
        isAccountVerified,
        updateAuthOptions,
        options: authOptions,
    } = useAuthentication();
    const secretBalance = '*********';
    const secretCoin = '***';

    const toggleHiddenBalance = (value: boolean) => {
        updateAuthOptions && updateAuthOptions('isHiddenAccount', value);
    };
    return (
        <WalletDropDownStyled className="wallet-dropdown">
            <div className="wallet-dropdown-header">
                <div className="wallet-dropdown-header-item">
                    <p className="wallet-dropdown-header-text">
                        <span className="small-text">
                            {t('walletPage.totalBalance')}
                        </span>
                        <span className="avarge-total">
                            ≈{' '}
                            {currentBalance &&
                                (!authOptions?.isHiddenAccount
                                    ? NumberFormat(currentBalance.totalTRY)
                                    : secretBalance)}{' '}
                            {currentBalance?.unit}
                        </span>

                        <span className="small-text-total">
                            {t('walletPage.usableBalance')}
                        </span>
                        <span className="total">
                            ≈{' '}
                            {currentBalance &&
                                (!authOptions?.isHiddenAccount
                                    ? NumberFormat(currentBalance.usableUnit)
                                    : secretBalance)}{' '}
                            {currentBalance?.unit}
                        </span>
                    </p>
                    <div className="wallet-icons">
                        <IconButton
                            icon={
                                authOptions?.isHiddenAccount
                                    ? 'password-hide'
                                    : 'password-show'
                            }
                            onClick={() => {
                                toggleHiddenBalance(
                                    !authOptions?.isHiddenAccount
                                );
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className="wallet-dropdown-content">
                {currentBalance &&
                    Object.values(currentBalance?.assets).map((item) => {
                        return (
                            <div className="coin-balance">
                                <CoinDisplay
                                    value={item ? item.name : 'BTC'}
                                    type="horizontal"
                                />
                                <div className="balance">
                                    <span className="coin-total">
                                        {!authOptions?.isHiddenAccount
                                            ? NumberFormat(item?.total || '')
                                            : secretCoin}{' '}
                                        <span className="coin-name">
                                            {item?.name}
                                        </span>
                                    </span>
                                    <br />
                                    <span className="balance-total">
                                        ≈{' '}
                                        {!authOptions?.isHiddenAccount
                                            ? NumberFormat(item?.asunit || '')
                                            : secretCoin}{' '}
                                        {currentBalance?.unit}
                                    </span>
                                </div>
                            </div>
                        );
                    })}
            </div>
            <div className="wallet-dropdown-footer">
                {isAccountVerified ? (
                    <Button
                        type="link"
                        onClick={() => {
                            navigate(routesPaths.operations.deposit);
                        }}
                    >
                        <span className="deposit-withdraw-text">
                            {t('sidebar.deposit.title')}
                        </span>
                    </Button>
                ) : (
                    ''
                )}
                <Button
                    type="link"
                    onClick={() => {
                        navigate(routesPaths.account.wallet);
                    }}
                >
                    <span className="wallet-text">
                        {t('sidebar.wallet.title')}
                    </span>
                </Button>
            </div>
        </WalletDropDownStyled>
    );
};

export const WalletDropdown = WrappedWalletDropDown;
