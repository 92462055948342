import { useRef } from 'react';
import httpService from 'common/http/http.service';
import balanceApiEndpoints from './balance.endpoint';
import {
    AddressDeleteRequest,
    AddressDeleteResponse,
    AddressGetListRequest,
    AddressGetListResponse,
    AddressIbanAddFormRequest,
    AddressIbanAddFormResponse,
    BalanceApiCalls,
    BalanceLimits,
    BalanceLimitsResponse,
    BalanceNowResponse,
    BalanceNowResponseData,
    ConfirmWithdrawRequest,
    ConfirmWithdrawResponse,
    GetAddressDetailResponse,
    GetWithdrawalFeeRequest,
    GetWithdrawalFeeResponse,
    HistoryDeleteRequest,
    HistoryDeleteResponse,
    SendEmailVerificationCodeRequest,
    SendEmailVerificationCodeResponse,
    StartWithdrawalProcessRequest,
    StartWithdrawalProcessResponse,
    WithdrawalControlRequest,
    WithdrawalControlResponse,
} from './balance.model';
import { HttpResponse } from 'common/http/http.model';
import { mappingBalanceLimits, mappingBalanceNow } from './balance.mapping';

const useBalanceApi = (): BalanceApiCalls => {
    const generateApiCalls = (): BalanceApiCalls => {
        return {
            limits: async (): Promise<BalanceLimits | null> => {
                const response: BalanceLimitsResponse =
                    await httpService.client.get(balanceApiEndpoints.limits);
                if (!response?.success) {
                    return null;
                }
                return mappingBalanceLimits(response?.limits);
            },
            addressIbanAddForm: (
                payload: AddressIbanAddFormRequest
            ): HttpResponse<AddressIbanAddFormResponse> =>
                httpService.client.post(
                    balanceApiEndpoints.addressibanadd,
                    payload
                ),
            addressGetList: (
                payload: AddressGetListRequest
            ): HttpResponse<AddressGetListResponse> =>
                httpService.client.post(
                    balanceApiEndpoints.addressgetlist,
                    payload
                ),
            addressDelete: (
                payload: AddressDeleteRequest
            ): HttpResponse<AddressDeleteResponse> =>
                httpService.client.post(
                    balanceApiEndpoints.addressdelete,
                    payload
                ),
            currentBalance: async (): Promise<BalanceNowResponseData> => {
                const response: BalanceNowResponse =
                    await httpService.client.get(balanceApiEndpoints.now);
                return mappingBalanceNow(response);
            },
            getWithdrawalFee: async (
                payload: GetWithdrawalFeeRequest
            ): Promise<GetWithdrawalFeeResponse> =>
                httpService.client.post(
                    balanceApiEndpoints.getWithdrawalFee,
                    payload
                ),
            startWithdrawalProcess: async (
                payload: StartWithdrawalProcessRequest
            ): Promise<StartWithdrawalProcessResponse> =>
                httpService.client.post(
                    balanceApiEndpoints.startWithdrawalProcess,
                    payload
                ),
            sendEmailVerificationCode: async (
                payload: SendEmailVerificationCodeRequest
            ): Promise<SendEmailVerificationCodeResponse> =>
                httpService.client.post(
                    balanceApiEndpoints.sendEmailVerificationCode,
                    payload
                ),
            confirmWithdraw: async (
                payload: ConfirmWithdrawRequest
            ): Promise<ConfirmWithdrawResponse> =>
                httpService.client.post(
                    balanceApiEndpoints.confirmWithdraw,
                    payload
                ),
            getAddressDetail: async (
                coin: string
            ): Promise<GetAddressDetailResponse> =>
                httpService.client.get(
                    `${balanceApiEndpoints.getAddressDetail}/${coin}`
                ),
            historyDelete: (
                payload: HistoryDeleteRequest
            ): HttpResponse<HistoryDeleteResponse> =>
                httpService.client.post(
                    balanceApiEndpoints.historydelete,
                    payload
                ),
            getBalanceGraphic: (): Promise<any> =>
                httpService.client.get(balanceApiEndpoints.balanceGraphic),
            getWithdrawalControl: async (
                payload: WithdrawalControlRequest
            ): Promise<WithdrawalControlResponse> => {
                return httpService.client.get(
                    `${balanceApiEndpoints.withdrawalControl}?symbol=${payload.coin}`
                );
            },
        };
    };
    const songCallsRef = useRef<BalanceApiCalls>(generateApiCalls());

    return songCallsRef.current;
};

export default useBalanceApi;
