import styled from 'styled-components';
import { Table, TableProps } from 'antd';
import { fonts } from 'common/theme/font';

class WrapTableType<T> {
    wrapped(e: any) {
        return Table<TableProps<T>>(e);
    }
}

export const Container = styled(Table)`
    .ant-table {
        background: ${({ theme }) => theme.colors.color1.base};
        border-radius: 6px;
        table {
            border-radius: 6px 6px 0 0;
            overflow: hidden;
        }

        .ant-table-placeholder:hover {
            background: ${({ theme }) => theme.colors.color1.base};
            & > td {
                background: ${({ theme }) => theme.colors.color1.base};
            }
        }
    }

    .ant-empty-normal {
        color: ${({ theme }) => theme.colors.color1.base};
        .ant-empty-img-simple {
            g {
                fill: ${({ theme }) => theme.colors.color1.base};
            }
            .ant-empty-img-simple-ellipse {
                fill: ${({ theme }) => theme.colors.color1.base};
            }
        }
    }
    .ant-table-thead {
        background: ${({ theme }) => theme.colors.color1.base};
        box-shadow: 4px 4px 4px rgba(21, 21, 21, 0.25);

        .ant-table-cell-scrollbar {
            box-shadow: none;
        }

        & > tr > th {
            background: transparent;
            color: ${({ theme }) => theme.colors.color4.base};
            border: none !important;
            border-width: 3px;
            font-weight: ${fonts.weight.bold};
            padding: 12px 20px;

            &:before {
                display: none;
            }
        }
    }

    .ant-table-row {
        background: ${({ theme }) => theme.colors.color1.base};
        color: ${({ theme }) => theme.colors.color4.base};

        &:nth-child(odd) {
            background: ${({ theme }) => theme.colors.color1.second2};
        }

        &:last-child {
            border-radius: 0 0 6px 6px;
            overflow: hidden;
            td:last-child {
                border-radius: 0 0 6px 0;
                overflow: hidden;
            }

            td:first-child {
                border-radius: 0 0 0 6px;
                overflow: hidden;
            }
        }

        &:hover,
        .ant-table-cell-row-hover,
        .ant-table-cell-row:hover > td,
        &:hover > td {
            background: ${({ theme }) => theme.colors.color6.base} !important;
        }

        .datatable-action-btn {
            cursor: pointer;
        }
    }

    .ant-table-tbody {
        border-radius: 0 0 6px 6px;
        overflow: hidden;
    }

    .ant-table-tbody > tr > td {
        border: none !important;
        padding: 12px 20px;
        vertical-align: middle;
    }
` as React.ComponentType as <T>(
    props: TableProps<T>
) => ReturnType<WrapTableType<T>['wrapped']>;

export const DataTableFooter = styled.div`
    padding: 10px 0;
    display: flex;
    justify-content: flex-end;
`;
