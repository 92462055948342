import { Container } from './index.styles';

import { Row, Col } from 'antd';

import { useTranslation } from 'react-i18next';
// import { routesPaths } from 'config/routes';
import { SocialButtonList } from 'widgets/social-buttons';
import useBlogApi from 'api/blog/blog.api';
import { useCallback, useEffect, useState } from 'react';
import { useAuthentication } from 'common/auth/AuthenticationProvider';
import { getLanguage } from 'common/utils';

const Footer = () => {
    const { t } = useTranslation();
    const lang = getLanguage();
    const { isLoading } = useAuthentication();
    const { getFooter } = useBlogApi();
    const [_footer, setFooter] = useState<any>({});
    const fetchInitial = useCallback(async () => {
        const footer = await getFooter(lang);
        setFooter(footer.data.data);
    }, []);

    useEffect(() => {
        if (!isLoading) {
            fetchInitial();
        }
    }, [isLoading]);

    return (
        <>
            <Container>
                <Row gutter={[16, 8]} className="rowBetween">
                    {_footer.attributes?.groupList.map(
                        (item: any, index: any) => {
                            return (
                                <Col span={5} key={index}>
                                    <h3>{item.Title}</h3>
                                    {item.urlList?.map(
                                        (itm: any, indx: any) => {
                                            return (
                                                <ul key={indx}>
                                                    <li>
                                                        {/^https?:\/\//.test(
                                                            itm.url
                                                        ) ||
                                                        /^http?:\/\//.test(
                                                            itm.url
                                                        ) ? (
                                                            <a
                                                                href={itm.url}
                                                                className="item"
                                                                key={indx}
                                                            >
                                                                {itm.name}
                                                            </a>
                                                        ) : (
                                                            <a
                                                                href={
                                                                    window
                                                                        .location
                                                                        .origin +
                                                                    '/' +
                                                                    lang +
                                                                    itm.url
                                                                }
                                                                className="item"
                                                                key={indx}
                                                            >
                                                                {itm.name}
                                                            </a>
                                                        )}
                                                    </li>
                                                </ul>
                                            );
                                        }
                                    )}
                                </Col>
                            );
                        }
                    )}

                    <Col span={4}>
                        <a
                            href={
                                window.location.origin +
                                '/' +
                                lang +
                                '/privacy/information-security-policy'
                            }
                            className="iso"
                        >
                            <img src="/assets/images/iso.svg" alt="iso 27001" />
                        </a>
                        <div className="store">
                            <a href="/">
                                <img
                                    src="/assets/images/home-page/play-store-light.svg"
                                    alt="play-store"
                                />
                            </a>
                            <a href="/">
                                <img
                                    src="/assets/images/home-page/app-store-light.svg"
                                    alt="app-store"
                                />
                            </a>
                        </div>
                        <SocialButtonList />
                    </Col>
                    <div className="copyright">
                        <div className="info">
                            <img
                                src="/assets/images/logo-white.svg"
                                className="logo"
                                alt="logo"
                            />

                            <div className="boxs">
                                <div className="address">
                                    <strong>{t('footer.companyname')}</strong>
                                    <div>{t('footer.address')}</div>
                                </div>
                                <div className="tax">
                                    <div>{t('footer.tax')}</div>
                                    <div>{t('footer.mersis')}</div>
                                </div>
                                <strong className="email">
                                    <a href={`mailto:${t('footer.mail')}`}>
                                        {t('footer.mail')}
                                    </a>
                                </strong>
                            </div>
                        </div>
                        <span>{t('footer.copyright')}</span>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default Footer;
