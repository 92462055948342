import { useCallback } from 'react';
import * as S from './Select.styles';

import { Select as SelectAnt, SelectProps as SelectAntProps } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { SvgIcon } from 'components/svg-icon';
import { IconType } from 'components/svg-icon/icon-types';
import classNames from 'classnames';

export interface SelectOption {
    key?: string;
    value: string | number;
    label: string | React.ReactNode;
    disabled?: boolean;
    className?: string;
    render?: (record: SelectOption) => JSX.Element;
}

export interface SelectOptionGroup {
    key: string;
    label: string;
    options: SelectOption[];
}

export interface SelectProps
    extends SelectAntProps<SelectValue>,
        Omit<
            CommonFormProps,
            'value' | 'onChange' | 'onBlur' | 'onFocus' | 'form'
        > {
    options?: SelectOption[];
    prefixIcon?: IconType | undefined;
    groups?: SelectOptionGroup[];
    renderOption?: (record: SelectOption) => JSX.Element;
}

export const Select = ({
    options,
    groups,
    prefixIcon,
    renderOption,
    ...restProps
}: SelectProps) => {
    const renderOptions = useCallback((selectOptions: SelectOption[]) => {
        return (
            selectOptions &&
            selectOptions.map((option: SelectOption) => (
                <SelectAnt.Option
                    className={option.className}
                    disabled={option.disabled}
                    value={option.value}
                    key={option.key || option.value}
                    label={option.label}
                >
                    {(option.render && option.render(option)) ||
                        (renderOption && renderOption(option)) ||
                        option.label ||
                        'none'}
                </SelectAnt.Option>
            ))
        );
    }, []); // eslint-disable-line

    const renderGroups = useCallback(() => {
        return (
            groups &&
            groups.map((group: SelectOptionGroup) => {
                return (
                    <SelectAnt.OptGroup key={group.key} label={group.label}>
                        {renderOptions(group.options)}
                    </SelectAnt.OptGroup>
                );
            })
        );
    }, [groups, renderOptions]);
    return (
        // eslint-disable-next-line
        <S.SelectWrapper className="select-warapper">
            {prefixIcon && (
                <SvgIcon className="prefix-icon" name={prefixIcon} />
            )}
            <S.SelectStyled<any>
                {...restProps}
                suffixIcon={<SvgIcon name="chevron-down" />}
                clearIcon={<SvgIcon name="times-circle-filled" />}
                dropdownClassName={'tunety-select-dropdown'}
                className={classNames({
                    'prefix-style': !!prefixIcon,
                    error: restProps.error,
                })}
            >
                {groups ? renderGroups() : options && renderOptions(options)}
            </S.SelectStyled>
        </S.SelectWrapper>
    );
};

export default Select;
