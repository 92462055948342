import { useState } from 'react';
import { useCallback } from 'react';
import { FormikContextType } from 'formik';

export interface FormikEventsProps<T> {
    form: FormikContextType<T>;
    onChange?: Function;
    onBlur?: Function;
    onFocus?: Function;
}

export const useFormikEvents = <T extends {}>({
    form,
    onBlur,
    onFocus,
    onChange,
}: FormikEventsProps<T>) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const handleChangeGenerator = useCallback(
        (name: keyof T): Function =>
            (event: unknown, ...rest: unknown[]) => {
                if (form.touched[name]) {
                    form.setFieldTouched(name as string, true);
                }

                onChange ? onChange(event, ...rest) : form.handleChange(event);
            },
        [form, onChange]
    );

    const handleBlurGenerator = useCallback(
        (name: keyof T) => (event: unknown) => {
            if (!form.touched[name]) {
                form.setFieldTouched(name as string, true);
            }
            onBlur && onBlur(event);
            setIsFocused(false);
        },
        [form, onBlur]
    );

    const handleFocusGenerator = useCallback(
        (_: keyof T) => (event: unknown) => {
            onFocus && onFocus(event);
            setIsFocused(true);
        },
        [onFocus, setIsFocused]
    );
    const getErrorText = useCallback(
        (name: keyof T): string =>
            (form.touched[name] && !!form.errors[name]
                ? form.errors[name]
                : '') as string,
        [form]
    );

    return {
        handleChangeGenerator,
        handleBlurGenerator,
        handleFocusGenerator,
        getErrorText,
        isFocused,
    };
};

export default useFormikEvents;
