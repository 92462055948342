import * as S from './index.styles';
import { notification } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ConfigProps } from 'antd/lib/notification';

export interface MiniNotificationProps extends ConfigProps {
    url?: string;
    icon?: string;
    message: string;
    onButtonClick?: () => void;
}

export const MiniNotification = React.memo(function AlertConsumer(
    props: MiniNotificationProps
) {
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        setTimeout(() => {
            api.open({
                message: props.message,
                icon: props.icon && <img src={props.icon} width={44} alt="" />,
                placement: props.placement ? props.placement : 'bottomRight',
                duration: props.duration ? props.duration : 0,
                className: `mini-notification ${
                    props.icon ? 'icon-show' : 'icon-hide'
                }`,
                style: { cursor: props.url ? 'pointer' : 'default' },
                onClick: () => {
                    props.onButtonClick && props.onButtonClick();
                    if (props.url) {
                        navigate(props.url);
                    }
                },
                onClose: () => {
                    props.onButtonClick && props.onButtonClick();
                },
            });
        }, 2000);
    }, []);

    return (
        <>
            <S.Container>{contextHolder}</S.Container>
        </>
    );
});
