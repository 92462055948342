import styled from 'styled-components';
import { Button as AntButton } from 'antd';
import { fonts } from '../../common/theme/font';

export const ButtonStyled = styled(AntButton)`
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    font-size: ${fonts.size.normal};
    font-weight: ${fonts.weight.regular};
    height: 36px;
    padding: 6px 16px;
    border-radius: 4px;
    text-align: center;
    line-height: 1;
    letter-spacing: 0.04em;
    text-shadow: none !important;
    & > * {
        text-shadow: none !important;
    }

    &.ant-btn-primary {
        background-color: ${({ theme }) =>
            theme.colors.staticColor.color2.base};
        border: none;
        &:hover {
            background-color: ${({ theme }) =>
                theme.colors.staticColor.color2.second2} !important;
        }
        &:focus,
        &:active {
            background-color: ${({ theme }) =>
                theme.colors.staticColor.color2.second1} !important;
        }
        .app-icon {
            font-size: ${fonts.size.xlarge};
            svg {
                fill: ${({ theme }) =>
                    theme.colors.staticColor.color1.base}!important;
                color: ${({ theme }) =>
                    theme.colors.staticColor.color1.base}!important;
            }
        }
    }

    &.ant-btn-default {
        background-color: ${({ theme }) =>
            theme.colors.staticColor.color1.second2} !important;
        border: none;
        &:hover {
            background-color: ${({ theme }) =>
                theme.colors.staticColor.color1.second3}!important;
        }
        &:focus,
        &:active {
            background-color: ${({ theme }) =>
                theme.colors.staticColor.color1.second4} !important;
        }
    }

    &.app-btn-red {
        background-color: ${({ theme }) =>
            theme.colors.staticColor.color3.second2} !important;
        &:hover {
            background-color: ${({ theme }) =>
                theme.colors.staticColor.color3.base}!important;
        }
        &:focus,
        &:active {
            background-color: ${({ theme }) =>
                theme.colors.staticColor.color3.second1} !important;
        }
        .app-icon {
            font-size: ${fonts.size.xlarge};
            svg {
                fill: ${({ theme }) =>
                    theme.colors.staticColor.color1.base}!important;
                color: ${({ theme }) =>
                    theme.colors.staticColor.color1.base}!important;
            }
        }
    }

    &.app-btn-pastel-green {
        background-color: ${({ theme }) =>
            theme.colors.staticColor.color2.second2} !important;
        &:hover {
            background-color: ${({ theme }) =>
                theme.colors.staticColor.color2.base} !important;
        }
        &:focus,
        &:active {
            background-color: ${({ theme }) =>
                theme.colors.staticColor.color2.second1} !important;
        }
        .app-icon {
            font-size: ${fonts.size.xlarge};
            svg {
                fill: ${({ theme }) =>
                    theme.colors.staticColor.color1.base}!important;
                color: ${({ theme }) =>
                    theme.colors.staticColor.color1.base}!important;
            }
        }
    }

    &.app-btn-with-icon {
        padding: 6px 16px;
        .app-icon {
            font-size: ${fonts.size.xlarge};
            svg {
                fill: ${({ theme }) => theme.colors.color1.base};
                color: ${({ theme }) => theme.colors.color1.base};
            }
        }

        &.app-btn-suffix-icon {
            .app-icon {
                margin-left: 5px;
                margin-right: 0px;
            }
        }

        &.app-btn-prefix-icon {
            .app-icon {
                margin-right: 5px;
            }
        }
    }

    & > span {
        color: ${({ theme }) =>
            theme.colors.staticColor.color1.base} !important;
    }

    &:hover {
        cursor: pointer;
    }

    &:disabled {
        background-color: ${({ theme }) => theme.colors.color2.base} !important;
        color: ${({ theme }) =>
            theme.colors.staticColor.color1.second2} !important;
        cursor: not-allowed;
        box-shadow: none;
        .app-icon {
            svg {
                fill: ${({ theme }) =>
                    theme.colors.staticColor.color1.second2} !important;
                color: ${({ theme }) =>
                    theme.colors.staticColor.color1.second2} !important;
            }
        }
        & > span {
            color: ${({ theme }) =>
                theme.colors.staticColor.color1.second2} !important;
        }
        &:hover {
            background-color: ${({ theme }) =>
                theme.colors.color2.base} !important;
            color: ${({ theme }) =>
                theme.colors.staticColor.color1.second2} !important;
        }
    }

    &.ant-btn {
        &-sm {
            height: 25px;
            padding: 2px 5px;

            &.app-btn-with-icon {
                padding: 3px 16px;

                .app-icon {
                    font-size: ${fonts.size.xSmall};
                }
            }
        }

        &-lg {
            padding: 10px 40px;
            min-width: 180px;
            background-color: ${({ theme }) =>
                theme.colors.staticColor.color2.base};
            text-transform: uppercase;

            &.app-btn-with-icon {
                padding: 10px 24px;

                .app-icon {
                    font-size: ${fonts.size.normal};
                }
            }
        }
    }

    &.ant-btn-icon-only {
        .app-icon {
            margin-right: 0px;
        }
    }

    .app-icon {
        display: flex;
        align-items: center;

        svg {
            fill: ${({ theme }) =>
                theme.colors.staticColor.color1.second2} !important;
        }

        i {
            display: flex;
            align-items: center;
        }
    }

    .ant-btn-loading-icon {
        margin-right: 8px;
        width: 16px;
    }

    &.ant-btn-block {
        padding-left: 0;
        padding-right: 0;
    }

    &.ant-btn-ghost {
        background-color: transparent !important;
        border: 1px solid
            ${({ theme }) => theme.colors.staticColor.color1.second2}!important;

        & > span {
            color: ${({ theme }) =>
                theme.colors.staticColor.color1.second2}!important;
        }

        .app-icon {
            svg {
                fill: ${({ theme }) =>
                    theme.colors.staticColor.color1.second2}!important;
                color: ${({ theme }) =>
                    theme.colors.staticColor.color1.second2}!important;
            }
        }

        &:hover {
            border-color: ${({ theme }) =>
                theme.colors.staticColor.color1.second3}!important;
            & > span {
                color: ${({ theme }) =>
                    theme.colors.staticColor.color1.second3}!important;
            }
            .app-icon {
                svg {
                    fill: ${({ theme }) =>
                        theme.colors.staticColor.color1.second3}!important;
                    color: ${({ theme }) =>
                        theme.colors.staticColor.color1.second3}!important;
                }
            }
        }

        &:focus,
        &:active {
            border-color: ${({ theme }) =>
                theme.colors.staticColor.color1.second4}!important;
            & > span {
                color: ${({ theme }) =>
                    theme.colors.staticColor.color1.second4}!important;
            }
            .app-icon {
                svg {
                    fill: ${({ theme }) =>
                        theme.colors.staticColor.color1.second4}!important;
                    color: ${({ theme }) =>
                        theme.colors.staticColor.color1.second4}!important;
                }
            }
        }

        &:disabled {
            border-color: ${({ theme }) => theme.colors.color2.base}!important;
            & > span {
                color: ${({ theme }) => theme.colors.color2.base} !important;
            }
            .app-icon {
                svg {
                    fill: ${({ theme }) => theme.colors.color2.base} !important;
                    color: ${({ theme }) =>
                        theme.colors.color2.base} !important;
                }
            }
        }
    }

    &.ant-btn-link {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border: none !important;
        box-shadow: none !important;
        padding: 0 !important;

        background: transparent !important;

        & > span {
            color: ${({ theme }) =>
                theme.colors.staticColor.color2.base} !important;
            font-size: ${fonts.size.normal};
            font-weight: ${fonts.weight.regular};
        }

        .app-icon {
            svg {
                fill: ${({ theme }) =>
                    theme.colors.staticColor.color2.base} !important;
                color: ${({ theme }) =>
                    theme.colors.staticColor.color2.base} !important;
            }
        }

        &.app-btn-suffix-icon {
            .app-icon {
                margin-left: 16px;
            }
        }

        &.app-btn-prefix-icon {
            .app-icon {
                margin-right: 16px;
                color: red;
            }
        }

        &:hover {
            & > span {
                color: ${({ theme }) =>
                    theme.colors.staticColor.color2.second2} !important;
                font-size: ${fonts.size.normal};
                font-weight: ${fonts.weight.regular};
            }
            .app-icon {
                svg {
                    fill: ${({ theme }) =>
                        theme.colors.staticColor.color2.base} !important;
                    color: ${({ theme }) =>
                        theme.colors.staticColor.color2.base} !important;
                }
            }
        }

        &:focus {
            & > span {
                color: ${({ theme }) =>
                    theme.colors.staticColor.color2.second1} !important;
            }
            .app-icon {
                svg {
                    fill: ${({ theme }) =>
                        theme.colors.staticColor.color2.second1} !important;
                    color: ${({ theme }) =>
                        theme.colors.staticColor.color2.second1} !important;
                }
            }
        }

        &:active {
            & > span {
                color: ${({ theme }) =>
                    theme.colors.staticColor.color2.second1} !important;
            }
            .app-icon {
                svg {
                    fill: ${({ theme }) =>
                        theme.colors.staticColor.color2.second1} !important;
                    color: ${({ theme }) =>
                        theme.colors.staticColor.color2.second1} !important;
                }
            }
        }

        &:disabled {
            & > span {
                color: ${({ theme }) => theme.colors.color2.base} !important;
                text-decoration: none;
            }
            .app-icon {
                svg {
                    fill: ${({ theme }) => theme.colors.color2.base} !important;
                    color: ${({ theme }) =>
                        theme.colors.color2.base} !important;
                }
            }
        }
    }
`;
