import { fonts } from 'common/theme/font';
import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';

const desktopContainerCss = css`
    width: 991px;
`;

const mobileContainerCss = css`
    width: 100%;
    padding: 0 15px;
    .ant-row {
        position: relative;
        .ant-col {
            flex: 100%;
            max-width: 100%;
            &.right-side {
                position: static;
            }
            .time {
                margin-top: 20px;
                .date {
                    span {
                        font-size: 12px;
                    }
                }
                .time {
                    margin-top: 0;
                    span {
                        font-size: 12px;
                    }
                }
            }
            h1 {
                font-size: 16px;
                line-height: 24px;
            }
            .image {
                text-align: center;
                img {
                    max-width: 300px;
                }
            }
            .share-posts {
                padding-left: 0;
                padding-right: 0;
                margin-bottom: 0;
                padding-bottom: 0;
                .social-links {
                    position: absolute;
                    top: 20px;
                    right: 0;
                    ul {
                        li {
                            margin-right: 5px;
                            a {
                                width: 20px;
                                height: 20px;
                                i {
                                    line-height: 11px;
                                    font-size: 11px;
                                }
                            }
                        }
                    }
                }
                .blog-items {
                    margin-top: 20px;
                    .sub-banner {
                        text-align: center;
                        img {
                            margin: 0 auto;
                            max-width: 300px;
                        }
                    }
                }
                .bot-banner {
                    margin-top: 10px;
                }
            }
        }
    }
`;

export const Container = styled.section`
    width: 1200px;
    margin: 0 auto;
    padding-top: 50px;
    ${mediaQuery.lessThan('xldesktop')`${desktopContainerCss}`}
    ${mediaQuery.lessThan('xltablet')`${mobileContainerCss}`}
`;

export const Head = styled.h1`
    font-size: 30px;
    line-height: 45px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.color4.second1};
    margin-top: 5px;
    margin-bottom: 15px;
`;

export const Time = styled.div`
    .time {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 5px;
        span {
            font-size: ${fonts.size.small};
            font-weight: ${fonts.weight.semibold};
            color: ${({ theme }) => theme.colors.staticColor.color2.base};
        }
        .date {
            position: relative;
            padding-right: 10px;
            &:after {
                content: '';
                position: absolute;
                right: 0;
                top: 2px;
                width: 1px;
                height: 15px;
                background-color: ${({ theme }) =>
                    theme.colors.color3.opacity6};
            }
        }
        .time {
            margin-left: 10px;
            .app-icon {
                margin-right: 5px;
                line-height: 12px;
                svg {
                    color: ${({ theme }) =>
                        theme.colors.staticColor.color2.base};
                }
            }
            span {
            }
        }
    }
`;

export const Content = styled.section`
    max-width: 740px;
    display: block;
    img {
        max-width: 100%;
        width: 100%;
        border-radius: 15px;
    }
    .content {
        margin-top: 20px;
        .alert {
            position: relative;
            padding: 20px;
            margin-bottom: 20px;
            border-radius: 10px;
            background-color: ${({ theme }) => theme.colors.color2.opacity4};
            &:before {
                content: '';
                position: relative;
                width: 32px;
                height: 5px;
                display: block;
                margin-bottom: 10px;
                border-radius: 4px;
                background-color: ${({ theme }) =>
                    theme.colors.staticColor.color2.base};
            }
            ul {
                margin: 0;
                li:last-child {
                    margin: 0;
                }
            }
        }
    }
`;

export const RightSideBar = styled.section`
    display: block;
    .share-posts {
        padding: 20px;
        border-radius: 20px;
        margin-bottom: 35px;
        > .title {
            position: relative;
            margin-bottom: 30px;
            padding-bottom: 5px;
            h2 {
                font-size: ${fonts.size.large};
                font-weight: ${fonts.weight.semibold};
                color: ${({ theme }) => theme.colors.color4.second1};
                &:before {
                    content: '';
                    position: absolute;
                    bottom: -5px;
                    left: 0;
                    height: 4px;
                    width: 30px;
                    border-radius: 6px;
                    background-color: ${({ theme }) =>
                        theme.colors.staticColor.color2.base};
                }
            }
        }
        .social-links {
            margin-bottom: 70px;
            ul {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                li {
                    margin-right: 15px;
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        width: 27px;
                        height: 27px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 20px;
                        background-color: ${({ theme }) =>
                            theme.colors.staticColor.color1.second2};
                        i {
                            line-height: 12px;
                        }
                    }
                }
            }
        }
        .list {
            position: relative;
            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 4px;
                height: 100%;
                border-radius: 15px;
                background-color: ${({ theme }) => theme.colors.color2.base};
            }
            ul {
                li {
                    position: relative;
                    margin-bottom: 25px;
                    padding-left: 30px;
                    a {
                        font-size: ${fonts.size.normal};
                        font-weight: ${fonts.weight.regular};
                        color: ${({ theme }) => theme.colors.color4.second1};
                    }
                    &.active {
                        a {
                            font-weight: ${fonts.weight.semibold};
                            color: ${({ theme }) =>
                                theme.colors.staticColor.color2.base};
                        }
                        &:before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 5px;
                            width: 4px;
                            height: 15px;
                            border-radius: 10px;
                            background-color: ${({ theme }) =>
                                theme.colors.staticColor.color2.base};
                        }
                    }
                }
            }
        }
        .blog-items {
            margin-top: 50px;
        }
    }
    .bot-banner {
        margin-top: 50px;
        a {
            cursor: default !important;
        }
        a[href] {
            cursor: pointer !important;
        }
        img {
            border-radius: 10px;
        }
    }
`;

export const Info = styled.div`
    display: block;
    .title {
        h1 {
            font-size: 14px;
            font-weight: ${fonts.weight.bold};
            color: ${({ theme }) => theme.colors.color4.second1};
        }
    }
`;

export const Box = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    &:last-child {
        margin-bottom: 0;
    }
    .sub-banner {
        img {
            width: 100%;
        }
    }
`;
