import { fonts } from 'common/theme/font';
import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';

const mobileCss = css`
    .step-EmailForm {
        width: 100%;
    }
`;

export const EmailConfirmationTitle = styled.div`
    &.update-email-modal-title {
        text-align: center;

        h2 {
            color: ${({ theme }) => theme.colors.color4.base};
            font-weight: ${fonts.weight.semibold};
            font-size: ${fonts.size.normal};
            margin-bottom: 16px;
        }

        .app-icon {
            margin-bottom: 8px;
        }

        hr {
            opacity: 0.4;
            border: 1px solid ${({ theme }) => theme.colors.color1.base};
        }
    }
`;

export const CodeConfirmContent = styled.div`
    text-align: center;
    margin: 15px 30px 0px;

    .code-input-container {
        margin-top: 48px;
    }
`;

export const CancelNextButtons = styled.div`
    &.cancel-next-buttons {
        min-height: 60px;
        padding-top: 24px;
        margin: 0px 20px;

        .buttons-container {
            display: flex;
            flex-direction: row;
            float: right;

            .cancel-button {
                margin-right: 16px;
            }
        }
    }
`;

export const UpdateEmailStepsContainer = styled.div`
    .step-UserConfirmation {
        .update-email-modal-title .app-icon {
            color: ${({ theme }) =>
                theme.colors.staticColor.color3.base} !important;
        }
    }
    .step-SMSConfirmation {
        .update-email-modal-title .app-icon {
            color: ${({ theme }) =>
                theme.colors.staticColor.color2.base}!important;
        }
    }
    .step-GoogleAuthenticator {
    }
    .step-EmailForm {
        padding: 0 20px;
        width: 361px;

        .update-email-modal-title {
            text-align: left;

            hr {
                display: none;
            }
        }
        .update-email-form {
            margin: 15px 30px 0px;
            display: grid;
        }
    }

    .step-Success {
        text-align: center;
    }
    ${mediaQuery.lessThan('xltablet')`${mobileCss}`}
`;
