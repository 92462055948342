import { AnnouncementPageStyled } from './index.styles';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { VerticalTab } from 'components/vertical-tab';
import { useCallback, useEffect, useState } from 'react';
import {
    getLanguage,
    getLocaleDateFormat,
    getLocaleTimeFromISOFormat,
} from 'common/utils';
import useBlogApi from 'api/blog/blog.api';

export const AnnouncementPage = () => {
    const { t } = useTranslation();
    const lang = getLanguage();
    const { getAnnouncements } = useBlogApi();

    const [dateArr, setDateArr] = useState<any>([]);
    const [mainData, setMainData] = useState<any>([]);

    const fetchInitial = useCallback(async () => {
        const datas = await getAnnouncements(lang);
        const newArr: any = [];
        const data = datas.data.data;
        data.sort((a: any, b: any) => {
            const A = a.attributes.date !== null ? a.attributes.date : 0;
            const B = b.attributes.date !== null ? b.attributes.date : 0;
            if (A < B) {
                return 1;
            }
            if (A > B) {
                return -1;
            }
            return 0;
        });
        data.forEach((item: any) => {
            if (newArr.indexOf(item.attributes.date) == -1) {
                newArr.push(item.attributes.date);
            }
        });
        setDateArr(newArr);
        setMainData(data);
    }, [lang]);

    useEffect(() => {
        fetchInitial();
    }, [lang]);

    return (
        <>
            <Helmet>
                <title>
                    {t('seo.announcement.title', {
                        appTitle: process.env.REACT_APP_TITLE,
                    })}
                </title>
                <meta
                    name="description"
                    content={t('seo.announcement.description')}
                ></meta>
                <meta
                    name="keywords"
                    content={t('seo.announcement.keywords')}
                ></meta>
            </Helmet>
            <AnnouncementPageStyled>
                <div className="announcement">
                    <h3 className="page-title">
                        {t('announcement_page.title')}
                    </h3>
                    {dateArr.map((item: any, key: any) => (
                        <div key={key}>
                            <div className="date">
                                {getLocaleDateFormat(item)}
                            </div>
                            {mainData
                                .sort((a: any, b: any) => {
                                    const A =
                                        a.attributes.time !== null
                                            ? a.attributes.time
                                            : 0;
                                    const B =
                                        b.attributes.time !== null
                                            ? b.attributes.time
                                            : 0;
                                    if (A < B) {
                                        return 1;
                                    }
                                    if (A > B) {
                                        return -1;
                                    }
                                    return 0;
                                })
                                .filter((e: any) => e.attributes.date === item)
                                .map((items: any, keys: any) => {
                                    return (
                                        <div key={keys}>
                                            <VerticalTab
                                                title={items.attributes.title}
                                                subtitle={getLocaleTimeFromISOFormat(
                                                    items.attributes.date +
                                                        ' ' +
                                                        items.attributes.time
                                                ).slice(0, 5)}
                                                active={true}
                                                open={false}
                                                content={
                                                    items.attributes.content
                                                }
                                                index={keys}
                                            />
                                        </div>
                                    );
                                })}
                        </div>
                    ))}
                </div>
            </AnnouncementPageStyled>
        </>
    );
};
