import { fonts } from 'common/theme/font';
import styled from 'styled-components';

export const EmailCodeConfirmationStyled = styled.div`
    &.ConfirmCodeEmail-form {
        .ConfirmCodeEmail-button {
            width: 100%;
        }
        .invalid-ConfirmCodeEmail-error-message {
            display: flex;
            font-size: ${fonts.size.small};
            color: ${({ theme }) =>
                theme.colors.staticColor.color3.base} !important;
            margin-top: -10px;
            margin-bottom: 20px;
        }
        .ant-btn-primary {
            display: flex;
            margin-left: auto;
        }
        .codeInputDesc {
            font-size: 12px;
            line-height: 18px;
            font-weight: 600;
            color: ${({ theme }) => theme.colors.color4.base};
            text-align: initial;
            padding-bottom: 5px;
            span {
                text-align: end;
                font-size: 12px !important;
                padding-left: 5px;
            }
        }
    }
`;
