import { fonts } from 'common/theme/font';
import styled from 'styled-components';

export const AnnouncementPageStyled = styled.div`
    .announcement {
        .page-title {
            font-size: ${fonts.size.xlarge} !important;
            font-weight: ${fonts.weight.medium} !important;
            margin-bottom: 30px;
            padding-bottom: 15px;
            position: relative;
            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                width: 20px;
                height: 4px;
                background-color: ${({ theme }) =>
                    theme.colors.staticColor.color2.base};
                border-radius: 4px;
            }
        }
        .date {
            color: ${({ theme }) => theme.colors.staticColor.color1.base};
            background-color: ${({ theme }) =>
                theme.colors.staticColor.color2.base};
            border-radius: 4px;
            display: inline-block;
            padding: 5px 10px;
            font-size: ${fonts.size.medium};
            font-weight: ${fonts.weight.light};
            margin: 15px 0;
        }
        .app-icon {
            width: 24px;
            height: 24px;
        }
    }
`;
