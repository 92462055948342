//@ts-nocheck
import styled from 'styled-components';
import { Menu as SideBarMenu } from 'antd';
import { fonts } from 'common/theme/font';

export const SideBarMenuStyled = styled(SideBarMenu)`
    font-size: ${fonts.size.normal};
    font-weight: ${fonts.weight.regular};
    color: ${({ theme }) => theme.colors.color4.base};
    background: ${({ theme }) => theme.colors.color1.base};

    .ant-menu-item {
        height: 40px;

        &::after {
            right: inherit;
            left: 0;
        }

        i {
            font-size: 25px;
            display: flex;
        }

        i.ant-menu-item-icon {
            font-size: 25px;
            display: flex;
        }

        &:hover {
            border-color: ${({ theme }) => theme.colors.color1.base} !important;
            border-left: 3px;
        }
    }
    .ant-menu-submenu-title {
        cursor: default;
        &:hover {
            color: '${({ theme }) => theme.colors.color1.base} ' !important;
        }
    }
    .ant-menu-sub.ant-menu-inline {
        background: ${({ theme }) => theme.colors.color1.base};
    }
    .ant-menu-submenu-active,
    .ant-menu-submenu-open,
    .ant-menu-submenu-selected,
    .ant-menu-submenu-arrow {
        color: ${({ theme }) => theme.colors.color1.base} !important;
        cursor: def;
    }
    .ant-menu-submenu-title {
        .ant-menu-item-icon {
            font-size: 25px !important;
            display: flex;
        }
    }
    .ant-menu-submenu-selected {
        color: ${({ theme }) => theme.colors.color6.base} !important;
    }
    .ant-menu-item-active,
    .ant-menu-item-selected {
        color: ${({ theme }) =>
            theme.colors.staticColor.color2.base} !important;
        background-color: ${({ theme }) => theme.colors.color6.base} !important;
        &::after {
            border-color: ${({ theme }) =>
                theme.colors.staticColor.color2.base} !important;
        }

        svg {
            color: ${({ theme }) =>
                theme.colors.staticColor.color2.base} !important;
        }

        &.ant-menu-submenu-active::after,
        &.ant-menu-submenu-open::after,
        &.ant-menu-submenu-selected::after {
            border: none !important;
        }
    }

    .ant-menu-overflow-item-rest {
        .ant-menu-submenu-title {
            .anticon-ellipsis {
                font-size: 18px;
            }
        }
    }

    .ant-menu-submenu {
        .ant-menu-item-active,
        .ant-menu-item-selected {
            background-color: ${({ theme }) =>
                theme.colors.color6.base} !important;
            color: ${({ theme }) =>
                theme.colors.staticColor.color2.base} !important;
        }
    }
`;
