import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
// import { formatDigitLight } from 'common/utils';
import { NumberField } from 'components';
import { CoinExchangeStyled } from './index.styles';
import { t } from 'i18next';
import { formatDigit } from 'common/utils';

interface CoinExchangeValues {
    base: number;
    second: number;
}

export const CoinExchange = (props: any) => {
    const [baseValue, setBaseValue] = useState<any>(1);
    const [secondValue, setSecondValue] = useState<any>(0);
    const [price, setPrice] = useState<any>(0);

    const formik = useFormik<CoinExchangeValues>({
        initialValues: {
            base: 0,
            second: 0,
        },
        onSubmit: async () => {},
    });

    useEffect(() => {
        formik.setFieldValue('base', formatDigit(props.data.Bdigit, baseValue));
        formik.setFieldValue(
            'second',
            formatDigit(props.data.Sdigit, props.data.LastPrice)
        );
        setPrice(parseFloat(props.data.LastPrice + secondValue));
    }, [props]);

    const handleOnChange = (event: any) => {
        if (formik.isValid) {
            if (event.target.name === 'base') {
                const value = parseFloat(event.target.value.replace(',', ''));
                setBaseValue(value);
                const total = value * price;
                formik.setFieldValue(
                    'second',
                    formatDigit(props.data.Sdigit, String(total))
                );
            }
            if (event.target.name === 'second') {
                const value = parseFloat(event.target.value.replace(',', ''));
                setSecondValue(value);
                const total = value / price;
                formik.setFieldValue(
                    'base',
                    formatDigit(props.data.Bdigit, String(total))
                );
            }
        }
    };

    return (
        <CoinExchangeStyled className="coin-exchange">
            <form onChange={handleOnChange}>
                <>
                    <h6 className="coin-exchange-header">
                        {props.coinname}{' '}
                        {t('guide.detail.exchange_widget.circulation')}
                    </h6>
                    <div className="coin-exchange-container">
                        <div className="coin-exchange-content">
                            <NumberField
                                form={formik}
                                autoComplete="off"
                                formName="base"
                                suffixText={`${
                                    props.data.Base ? props.data.Base : ''
                                }`}
                            />
                        </div>
                        <div className="coin-exchange-content-icon">
                            {' '}
                            {t('≈')}{' '}
                        </div>
                        <div className="coin-exchange-content">
                            <NumberField
                                form={formik}
                                autoComplete="off"
                                formName="second"
                                suffixText={`${
                                    props.data.Second ? props.data.Second : ''
                                }`}
                            />
                        </div>
                    </div>
                </>
            </form>
        </CoinExchangeStyled>
    );
};
