import httpService from 'common/http/http.service';
import { useRef } from 'react';
import announcementApiEndpoints from './announcement.endpoint';
import { NotificationResponse, SupportApiCalls } from './announcement.model';

const useSupportApi = (): SupportApiCalls => {
    const generateApiCalls = (): SupportApiCalls => {
        return {
            readAnnouncement: async (): Promise<NotificationResponse> => {
                const response: any = await httpService.client.post(
                    announcementApiEndpoints.notificationOptions
                );
                return response;
            },
        };
    };

    const songCallsRef = useRef<SupportApiCalls>(generateApiCalls());
    return songCallsRef.current;
};

export default useSupportApi;
