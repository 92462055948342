import { FormikContextType } from 'formik/dist/types';
import { FormField } from '.';
import Input, {
    InputProps,
    TextAreaProps,
    Password,
    Number,
    Currency,
    TextArea,
} from 'components/input';
import { Checkbox, CheckboxProps } from 'components/checkbox';
import {
    ConfirmationCode,
    ConfirmationCodeProps,
} from 'widgets/confirmation-code';
import {
    AddressAutoComplete,
    AddressAutoCompleteProps,
} from 'widgets/forms/address-auto-complete';
import {
    IbanAutoComplete,
    IbanAutoCompleteProps,
} from 'widgets/forms/iban-auto-complete';
import PhoneNumber, { PhoneNumberProps } from 'components/phone-number';

import { DatePicker, DatePickerProps } from 'components/date-picker';
import { useCallback } from 'react';

export interface CommonFormComponentProps<T> {
    form: FormikContextType<T>;
    formName: keyof T;
    optional?: boolean;
    rows?: number;
    autoSize?: boolean;
    maskOptions?: {
        decimalLimit?: number;
        integerLimit?: number;
    };
}

// Input Field
export interface InputFieldProps<T>
    extends CommonFormComponentProps<T>,
        Partial<InputProps> {}
export interface TextAreaFieldProps<T>
    extends CommonFormComponentProps<T>,
        Partial<TextAreaProps> {}
export interface CheckboxFieldProps<T>
    extends CommonFormComponentProps<T>,
        Partial<CheckboxProps> {}
export interface ConfirmationCodeFieldProps<T>
    extends CommonFormComponentProps<T>,
        Partial<ConfirmationCodeProps> {}
export interface AddressAutoCompleteFieldProps<T>
    extends CommonFormComponentProps<T>,
        Partial<AddressAutoCompleteProps> {}
export interface IbanAutoCompleteFieldProps<T>
    extends CommonFormComponentProps<T>,
        Partial<IbanAutoCompleteProps> {}

//Currency-

export const InputField = <T extends {}>({
    form,
    formName,
    optional,
    ...rest
}: InputFieldProps<T>) => {
    return (
        <FormField
            Component={Input}
            form={form}
            name={formName}
            optional={optional}
            props={{ ...rest }}
        />
    );
};
export const TextAreaField = <T extends {}>({
    form,
    formName,
    optional,
    ...rest
}: TextAreaFieldProps<T>) => {
    return (
        <FormField
            Component={TextArea}
            form={form}
            name={formName}
            optional={optional}
            props={{ ...rest }}
        />
    );
};
//AutoCompleteTagField
export const AutoCompleteTagField = <T extends {}>({
    form,
    formName,
    optional,
    ...rest
}: InputFieldProps<T>) => {
    return (
        <FormField
            Component={Input}
            form={form}
            name={formName}
            optional={optional}
            props={{ ...rest }}
        />
    );
};
// Password Field
export const PasswordField = <T extends Empty>({
    form,
    formName,
    optional,
    ...rest
}: InputFieldProps<T>) => {
    return (
        <FormField
            Component={Password}
            form={form}
            name={formName}
            optional={optional}
            props={{ ...rest }}
        />
    );
};

// //TODO: Checkbox Field
export const CheckboxField = <T extends Empty>({
    form,
    formName,
    optional,
    ...rest
}: CheckboxFieldProps<T>) => {
    return (
        <FormField
            Component={Checkbox as (_: Partial<CheckboxProps>) => JSX.Element}
            form={form}
            name={formName}
            optional={optional}
            props={{ ...rest }}
        />
    );
};

// ConfirmationCode Field
export const ConfirmationCodeField = <T extends Empty>({
    form,
    formName,
    optional,
    ...rest
}: ConfirmationCodeFieldProps<T>) => {
    return (
        <FormField
            Component={
                ConfirmationCode as (
                    _: Partial<ConfirmationCodeProps>
                ) => JSX.Element
            }
            form={form}
            name={formName}
            optional={optional}
            props={{ ...rest }}
        />
    );
};

// AddressAutoComplete Field
export const AddressAutoCompleteField = <T extends Empty>({
    form,
    formName,
    optional,
    ...rest
}: AddressAutoCompleteFieldProps<T>) => {
    const handleChange = useCallback((value) => {
        form.setFieldValue(formName as any, value);
    }, []);
    return (
        <FormField
            Component={
                AddressAutoComplete as (
                    _: Partial<AddressAutoCompleteProps>
                ) => JSX.Element
            }
            form={form}
            name={formName}
            optional={optional}
            props={{ ...rest, onChange: handleChange }}
        />
    );
};

// IbanAutoComplete Field
export const IbanAutoCompleteField = <T extends Empty>({
    form,
    formName,
    optional,
    ...rest
}: IbanAutoCompleteFieldProps<T>) => {
    const handleChange = useCallback((value) => {
        form.setFieldValue(formName as any, value);
    }, []);
    return (
        <FormField
            Component={
                IbanAutoComplete as (
                    _: Partial<IbanAutoCompleteProps>
                ) => JSX.Element
            }
            form={form}
            name={formName}
            optional={optional}
            props={{ ...rest, onChange: handleChange }}
        />
    );
};

// Currency Field
export const CurrencyField = <T extends Empty>({
    form,
    formName,
    optional,
    ...rest
}: InputFieldProps<T>) => {
    return (
        <FormField
            Component={Currency}
            form={form}
            name={formName}
            optional={optional}
            props={{ ...rest }}
        />
    );
};
// Number Field
export const NumberField = <T extends Empty>({
    form,
    formName,
    optional,
    ...rest
}: InputFieldProps<T>) => {
    return (
        <FormField
            Component={Number}
            form={form}
            name={formName}
            optional={optional}
            props={{ ...rest }}
        />
    );
};

export interface PhoneNumberFieldProps<T>
    extends PhoneNumberProps,
        CommonFormComponentProps<T> {}

export const PhoneNumberField = <T extends {}>({
    form,
    formName,
    optional,
    ...rest
}: PhoneNumberFieldProps<T>) => {
    return (
        <FormField
            Component={PhoneNumber}
            form={form}
            name={formName}
            optional={optional}
            props={{ ...rest }}
        />
    );
};

///////
export interface DatePickerFieldProps<T>
    extends DatePickerProps,
        CommonFormComponentProps<T> {
    label: string;
}

export const DatePickerField = <T extends {}>({
    form,
    formName,
    optional,
    ...rest
}: DatePickerFieldProps<T>) => {
    const handleChangeDate = useCallback(
        (date: any) => {
            form.setFieldValue(formName as unknown as any, date);
        },
        [form]
    );
    return (
        <FormField
            Component={
                DatePicker as (_: Partial<DatePickerProps>) => JSX.Element
            }
            form={form}
            name={formName}
            optional={optional}
            props={{ ...rest, onChange: handleChangeDate }}
        />
    );
};
