import { mediaQuery } from 'common/theme/media';
import styled, { css } from 'styled-components';

const mobileCss = css`
    overflow: hidden !important;
`;

export const WideScreenLayoutStyled = styled.div`
    .ant-layout-content {
        overflow: auto !important;
        ${mediaQuery.lessThan('mobile')`${mobileCss}`}
    }
`;
