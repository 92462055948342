import styled, { css } from 'styled-components';
import { InputProps } from './index';
import { Input as AntInput } from 'antd';
import { fonts } from '../../common/theme/font';

export const inputDefaultCss = css`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    color: ${({ theme }) => theme.colors.color4.base};
    background-color: ${({ theme }) => theme.colors.color1.base};
    border-radius: 4px;
    border: 1px solid;
    border-color: ${({ theme }) => theme.colors.color2.base};
    height: 48px;
    padding: 13px 16px;
    font-size: ${fonts.size.normal};
    text-align: left;
    &.no-border {
        border: 1px solid transparent !important;
    }
`;

const disableInputCss = css`
    &.ant-input-disabled,
    &.ant-input-affix-wrapper-disabled {
        border: 1px solid ${({ theme }) => theme.colors.color2.base} !important;
        background-color: ${({ theme }) => theme.colors.color2.base} !important;
        color: ${({ theme }) => theme.colors.color3.base} !important;

        & > input {
            background-color: ${({ theme }) =>
                theme.colors.color2.base} !important;
        }
    }
`;

const inputBtn = css`
    position: absolute;
    top: 1px;
    right: 1px;
    left: inherit !important;
    z-index: 9;
    width: 46px;
    height: 46px;
    line-height: 46px;
    background: transparent;
    .ant-btn {
        border: none;
        width: 46px;
        height: 46px;
        background-color: transparent;
    }
`;

export const inputCss = css`
    &.ant-input {
        ${inputDefaultCss}

        &-sm {
            height: 24px;
            padding: 3px 10px;
            font-size: ${fonts.size.normal};
        }
        &-lg {
            height: 48px;
            padding: 10px 16px;
            font-size: 16px;
        }
    }

    &:focus {
        border: 1px solid ${({ theme }) => theme.colors.staticColor.color2.base} !important;
        background-color: ${({ theme }) =>
            theme.colors.staticColor.color2.opacity2};
    }

    &.ant-input-affix-wrapper {
        ${inputDefaultCss}

        position: relative;
        justify-content: space-between;
        align-items: stretch;
        overflow: hidden;
        padding: 0px !important;

        & > .ant-input-prefix,
        & > .ant-input-suffix {
            z-index: 1;
            margin: 0px !important;
            & > * {
                padding: 15px;
            }
        }
        & > .ant-input-prefix {
            & > * {
                padding-right: 0px !important;
            }
        }
        & > .ant-input-suffix {
            & > * {
                padding-left: 0px !important;
            }
        }
        .ant-input-password-icon {
            cursor: pointer;
        }

        & > input.ant-input {
            position: relative;
            height: initial !important;
            padding: 0 !important;
            border: none;
            border-radius: 0px;
            color: ${({ theme }) => theme.colors.color4.base}!important;
            background-color: ${({ theme }) => theme.colors.color1.base};
            z-index: 0;
            width: 100%;
            display: flex;
            padding: 13px 16px !important;
        }

        &-sm {
            height: 24px;
            padding: 0px 10px;
            font-size: ${fonts.size.normal};
        }
        &-lg {
            height: 48px;
            padding: 0px 16px;
            font-size: 16px;
        }

        &.ant-input-affix-wrapper {
            & > .ant-input-suffix,
            & > .ant-input-prefix {
                background-color: transparent !important;
                color: ${({ theme, error }: any) =>
                    error
                        ? theme.colors.staticColor.color3.base
                        : theme.colors.color3.base};
            }

            &.app-form-field-focused,
            &.ant-input-affix-wrapper-focused {
                color: ${({ theme }) =>
                    theme.colors.staticColor.color1.second3}!important;
                border: 1px solid
                    ${({ theme }) => theme.colors.staticColor.color2.base} !important;
                background-color: ${({ theme }) =>
                    theme.colors.staticColor.color2.second3} !important;
                outline: none !important;
                outline-width: 0 !important;
                box-shadow: none;
                input {
                    color: ${({ theme }) =>
                        theme.colors.staticColor.color1.second3}!important;
                    background-color: ${({ theme }) =>
                        theme.colors.staticColor.color2.second3} !important;
                }
                .ant-input-suffix,
                .ant-input-prefix {
                    background-color: transparent !important;
                    color: ${({ theme, error }: any) =>
                        error
                            ? theme.colors.staticColor.color2.second3
                            : theme.colors.color3.base};
                }
            }
        }
        ${disableInputCss}
    }
    &:focus,
    &.ant-input-focused,
    .error &:focus {
        color: ${({ theme }) =>
            theme.colors.staticColor.color1.second3}!important;
        border: 1px solid ${({ theme }) => theme.colors.staticColor.color2.base} !important;
        background-color: ${({ theme }) =>
            theme.colors.staticColor.color2.second3} !important;
        outline: none !important;
        outline-width: 0 !important;
        box-shadow: none;
    }

    ${disableInputCss}
`;

export const Input = styled(AntInput)<InputProps>`
    &.ant-input,
    &.ant-input-affix-wrapper,
    .ant-input-group {
        ${inputCss}
    }
`;
export const CurrencyInput = styled.div`
    .ant-input,
    .ant-input-affix-wrapper,
    .ant-input-group {
        ${inputCss}
    }

    .ant-input-prefix {
        order: 1;
        transition: all 0.3s;
    }
    .input-currency.ant-input-affix-wrapper {
        .ant-input {
            order: 2;
            transition: all 0.3s;
            text-align: right !important;
            font-weight: 500;
            font-size: 12px;

            &:focus,
            &:focus + span.ant-input-prefix,
            &:focus + span.ant-input-prefix + span.ant-input-suffix {
                color: ${({ theme }) =>
                    theme.colors.staticColor.color1.second3}!important;
                border: none !important;
                background-color: ${({ theme }) =>
                    theme.colors.staticColor.color2.second3} !important;
                outline: none !important;
                outline-width: 0 !important;
                box-shadow: none;
                transition: all 0.3s;
            }
            &:disabled,
            &:disabled + span.ant-input-prefix,
            &:disabled + span.ant-input-prefix + span.ant-input-suffix {
                background-color: ${({ theme }) =>
                    theme.colors.color2.base} !important;
                color: ${({ theme }) => theme.colors.color3.base} !important;
                user-select: none;
            }
        }
    }
    .ant-input-suffix {
        order: 3;
        transition: all 0.3s;
    }
    .ant-input-suffix > span,
    .ant-input-prefix > span {
        font-weight: 400;
        font-size: 12px;
    }
`;
export const Password = styled(AntInput.Password)<InputProps>`
    &.ant-input,
    &.ant-input-affix-wrapper,
    .ant-input-group {
        ${inputCss}
        padding: 0 16px!important;
    }
    & > .ant-input-suffix,
    & > .ant-input-prefix {
        & > * {
            background-color: transparent !important;
            color: ${({ theme, error }: any) =>
                error
                    ? theme.colors.color3.base
                    : theme.colors.color3.base}!important;
        }
    }
    &.ant-input-affix-wrapper-focused {
        border: 1px solid ${({ theme }) => theme.colors.staticColor.color2.base};
        background-color: ${({ theme }) =>
            theme.colors.staticColor.color2.second3};
        color: ${({ theme }) => theme.colors.staticColor.color2.base};
    }
`;

export const Search = styled(AntInput.Search)<InputProps>`
    &.ant-input-group,
    &.ant-input-affix-wrapper,
    .ant-input {
        ${inputCss};
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }
    .ant-input-wrapper {
        .ant-input-group-addon {
            ${inputBtn}
        }
    }
    &.ant-input-affix-wrapper-focused {
        border: 1px solid ${({ theme }) => theme.colors.staticColor.color2.base};
        background-color: ${({ theme }) =>
            theme.colors.staticColor.color2.second3};
        color: ${({ theme }) => theme.colors.staticColor.color2.base};
    }
`;

export const Number = styled(AntInput)<InputProps>`
    &.ant-input,
    &.ant-input-affix-wrapper,
    .ant-input-group {
        ${inputCss}
    }
`;

export const TextArea = styled(AntInput.TextArea)`
    &.ant-input,
    &.ant-input-affix-wrapper,
    .ant-input-group {
        ${inputCss}
        height: inherit;
    }
    &.ant-input-affix-wrapper-focused {
        border: 1px solid ${({ theme }) => theme.colors.staticColor.color2.base};
        background-color: ${({ theme }) =>
            theme.colors.staticColor.color2.second3};
        color: ${({ theme }) => theme.colors.staticColor.color2.base};
    }
`;
