import { useCallback, memo } from 'react';
import * as S from './index.styles';

export interface PaginationOptions {
    total: number;
    totalPages: number;
    selectedPage: number;
    limit: number;
}

export type PaginationProps = {
    handleChange: (selectedPage: number) => void;
    options?: PaginationOptions;
};

const PaginationConsume = ({ options, handleChange }: PaginationProps) => {
    const onChange = useCallback(
        (page: number) => {
            handleChange(page);
        },
        [handleChange]
    );
    return (
        <S.Container
            total={options?.total}
            pageSize={options?.limit}
            onChange={onChange}
            size="small"
            data-pagination={options?.selectedPage == 1 ? 'active' : ''}
        />
    );
};

export const Pagination = memo(PaginationConsume);
