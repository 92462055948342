import { AuthenticatorModalCss } from 'common/hooks/authenticator-modal/index.styles';
import { BuySellConfirmationModalCss } from 'common/hooks/buy-sell-confirmation-modal/index.styles';
import { ScanQrCss } from 'common/hooks/scan-qr-modal/index.styles';
import { EmailAntiPhishingCss } from 'common/hooks/email-anti-phishing-modal/index.styles';
import { SuccessModalCss } from 'common/hooks/success-modal/index.styles';
import { WarningModalCss } from 'common/hooks/warning-card-modal/index.styles';
import { UpdateEmailModalCss } from 'common/hooks/update-email-modal/index.styles';
import { AddressIbanAddFormCss } from 'common/hooks/address-iban-add-form-modal/index.styles';
import { ConfirmationCss } from 'components/confirmation/index.styles';
import { createGlobalStyle } from 'styled-components';
import { CoinSelectionDropdownCss } from 'widgets/coin-selection-dropdown/index.styles';
import { fonts } from './font';
import { ChangePasswordMailConfirmModalCss } from 'common/hooks/change-password-mail-confirm-modal/index.styles';
import { TimeoutPeriodModalCss } from 'common/hooks/timeout-period-modal/index.styles';
import { ChangePassword2FAConfirmModalCss } from 'common/hooks/change-password-2FA-confirm-modal/index.styles';
import { ForgetPasswordStepsModalCss } from 'common/hooks/forget-password-steps-modal/index.styles';
import { RegistrationMailConfirmationCss } from 'common/hooks/registration-mail-confirmation-modal/index.styles';
import { HeaderMenuDrawerCss } from 'widgets/header-mobile/index.styles';
import { CommissionInformationModalCss } from 'common/hooks/commission-information-modal/index.styles';
import { TransferDetailModalCss } from 'common/hooks/transfer-detail-modal/index.styles';
import { GiftCardModalCss } from 'common/hooks/gift-card-modal/index.styles';

const Styles = createGlobalStyle<Omit<AppTheme, 'handleChangeTheme'>>`
    /* http://meyerweb.com/eric/tools/css/reset/ 
    v2.0 | 20110126
    License: none (public domain)
    */

    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed, 
    figure, figcaption, footer, header, hgroup, 
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      font: inherit;
      color: inherit;
      vertical-align: baseline;
    }
    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure, 
    footer, header, hgroup, menu, nav, section {
      display: block;
    }
    ol, ul {
      list-style: none;
    }
    blockquote, q {
      quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
      content: '';
      content: none;
    }
    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
    input:-webkit-autofill,
    input:-webkit-autofill::first-line,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus
    {
        box-shadow: 0 0 0 1000px ${({ theme }) =>
            theme.colors.staticColor.color2.second3} inset !important;
        border-color: ${({ theme }) => theme.colors.color2.base};
        -webkit-text-fill-color: ${({ theme }) =>
            theme.colors.staticColor.color1.second3};
        color: ${({ theme }) =>
            theme.colors.staticColor.color1.second3}!important;
    }

    input:-webkit-autofill + .ant-input-suffix,
    input:-webkit-autofill:focus + .ant-input-suffix{
      background-color: ${({ theme }) =>
          theme.colors.staticColor.color2.second3} !important;
        color: ${({ theme }) =>
            theme.colors.staticColor.color1.second3}!important;
        box-shadow: 0 0 0 1000px ${({ theme }) =>
            theme.colors.staticColor.color2.second3} inset !important;
    }
    
    body, body.sb-show-main {
      font-family: ${fonts.family} !important;
      line-height: 1.5em;
      letter-spacing: 0.03em;
      background-color: ${({ theme }) => theme.colors.color1.second2};
      color: ${({ theme }) => theme.colors.color4.base};
    }
    h1,h2,h3,h4,h5,h6 {
      line-height: initial;
    }
    a {
    color: ${({ theme }) => theme.colors.color4.base};
      font-weight: ${fonts.weight.regular};
    }
    a:hover {
      color: ${({ theme }) => theme.colors.color4.base};
    }
    textarea{
      height: inherit !important;
    }
    .full-height {
      height: 100%;
    }

    .full-width {
      width: 100%;
    }

    .flex-end {
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      display: flex;
      text-align: right;
    }

    .flex-between {
        flex-direction: column;
        justify-content: space-between;
        display: flex;
        text-align: left;
    }

    .no-padding-top {
        padding-top: 0;
    }

    .app-svg-sprite {
          z-index: -1;
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          height: 0;
      }
      ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background: transparent;
      }

      ::-webkit-scrollbar-track {
        border-radius: 5px;
      }
      
      ::-webkit-scrollbar-thumb {
        background:  ${({ theme }) => theme.colors.color2.base};
        border-radius: 5px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background:  ${({ theme }) => theme.colors.staticColor.color2.base};
        border-radius: 5px;
      }

      .custom-popover {
          .ant-popover-arrow-content{
                &::before {
                    background:
                    linear-gradient(to left, ${({ theme }) =>
                        theme.colors.color4.second1} 50%, ${({ theme }) =>
    theme.colors.color4.second1} 50%) no-repeat -10px -10px;
                }
          }
          .ant-popover-inner {
              border-radius: 6px;
              background-color: ${({ theme }) => theme.colors.color4.second1};
              font-size: ${fonts.size.small};
              .ant-popover-inner-content{
                color: ${({ theme }) => theme.colors.color1.base};
              };
          }
      }
      .ant-modal-content {
            background: ${({ theme }) => theme.colors.color1.base} !important;
            .ant-modal-body .app-form-control .app-icon{
                  height: initial !important; 
                  line-height: 0px !important;
                  font-size: 26px !important;
            }
      }
/*       
    .ant-modal.authenticator-modal .ant-modal-content {
        max-width: 365px !important;
    } */
    
      .ant-notification-notice {
        border-radius: 4px;
        padding-left: 10px;
        background: ${({ theme }) =>
            theme.colors.staticColor.color1.base} !important;
        
        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          left: -1px;
          width: 3px;
          border-radius: 0 6px 6px 0;
          height: calc(100% - 25px);
        }
        
        .ant-notification-notice-icon {
          font-size: ${fonts.size.large};
          margin-top: 3px;
        }

        .ant-notification-notice-message {
          font-size: ${fonts.size.small};
          font-weight: ${fonts.weight.semibold};
          margin-left: 35px;
        }

        .ant-notification-notice-description {
          font-size: ${fonts.size.small};
          font-weight: ${fonts.weight.regular};
          color: ${({ theme }) => theme.colors.staticColor.color1.second3};
          margin-left: 35px;
        }

        &-error {
          &:before {
            background: ${({ theme }) => theme.colors.staticColor.color3.base};
          }

          .ant-notification-notice-icon,
          .ant-notification-notice-message {
            color: ${({ theme }) => theme.colors.staticColor.color3.base};
          }
          
        }
        &-success {
          &:before {
            background: ${({ theme }) => theme.colors.staticColor.color2.base};
          }

          .ant-notification-notice-icon,
          .ant-notification-notice-message {
            color: ${({ theme }) => theme.colors.staticColor.color2.base};
          }
        }
        &-warning {
          &:before {
            background: ${({ theme }) => theme.colors.staticColor.color5.base};
          }

          .ant-notification-notice-icon,
          .ant-notification-notice-message {
            color: ${({ theme }) => theme.colors.staticColor.color5.base};
          }
        }
        &-info {
          &:before {
            background: ${({ theme }) => theme.colors.staticColor.color4.base};
          }

          .ant-notification-notice-icon,
          .ant-notification-notice-message {
            color: ${({ theme }) => theme.colors.staticColor.color4.base};
          }
        }
      }
      .invalidAttempt{
        .ant-input-affix-wrapper{
                    background-color:${({ theme }) =>
                        theme.colors.staticColor.color3.second3} !important;
        }
        .app-form-control{          
          border: 1px solid ${({ theme }) =>
              theme.colors.staticColor.color3.base} !important;
              input{
                    background-color:${({ theme }) =>
                        theme.colors.staticColor.color3.second3} !important;
              }
        }
      }
     
      .ant-drawer{
        &.header-menu-drawer {
          /* top: 52px !important */
        }
      }
      .ant-dropdown > .block-height{
        height: 50vh!important;
        box-shadow: 4px 4px 17px rgba(0, 0, 0, 0.16);
      }


      .mini-notification{
        max-width: 220px;
        border-radius: 12px;
        overflow: visible;
        padding:10px;

        &.icon-show{
          .ant-notification-notice-description ,.ant-notification-notice-message{
              margin-left: 59px !important;
          }
        }
        &.icon-hide{
          .ant-notification-notice-description ,.ant-notification-notice-message{
              margin-left:5px !important;
          }
        }
        .ant-notification-notice-message{
          font-weight: ${fonts.weight.regular};
          color: ${({ theme }) => theme.colors.staticColor.color1.second3};
          font-size: ${fonts.size.medium};
          line-height: 1.3em;
          letter-spacing: 0.02em;
          word-spacing: 0.12em;
          min-height: 44px;
          display: flex; 
          align-items: center;
          margin-bottom: 0px;
          padding-right: 10px;
        }
        .ant-notification-notice-close{
          top: -7px;
          right: -5px;
          width: 24px;
          height: 24px;
          font-size: 12px;
          font-weight: bolder;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: ${({ theme }) =>
              theme.colors.staticColor.color2.base};
          color: ${({ theme }) => theme.colors.staticColor.color1.base};
          border-radius: 100%;
        }
      }

      .editor {
        font-weight: ${fonts.weight.light};
        color: ${({ theme }) => theme.colors.color4.base};
        font-size: ${fonts.size.large};
        line-height: 1.5em;
        letter-spacing: 0.02em;
        word-spacing: 0.12em;

        h1,h2,h3,h4,h5,h6 {
          margin-bottom: 10px;
        }
        h1 {
          font-size: 24px;
          font-weight: ${fonts.weight.semibold};
        }
        h2 {
          font-size: 20px;
          font-weight: ${fonts.weight.semibold};
        }
        h3 {
          font-size: 18px;
          font-weight: ${fonts.weight.medium};
        }
        h4 {
          font-size: 16px;
          font-weight: ${fonts.weight.medium};
        }
        h5 {
          font-size: 14px;
          font-weight: ${fonts.weight.medium};
        }
        h6 {
          font-size: 12px;
          font-weight: ${fonts.weight.medium};
        }
        p{
          margin-bottom: 10px;
        }
        strong {
          font-weight: ${fonts.weight.medium};
        }
        i {
          font-style: italic;
        }
        ul {
                margin-bottom: 20px;
                margin-left: 5px;
                li {
                    margin-bottom: 10px;
                    padding-left: 16px;
                    position: relative;
                    &:before {
                        content: '';
                        position: absolute;
                        left: 0px;
                        top: 8px;
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        background-color: ${({ theme }) =>
                            theme.colors.staticColor.color2.base};
                    }
                }
            }
        ol {
          list-style: decimal;
          margin-bottom: 20px;
          margin-left: 20px;
          li {
              margin-bottom: 10px;
              padding-left: 2px;
          }
        }
        hr {
          color: ${({ theme }) => theme.colors.color2.base};
          opacity: 0.4;
        }
        a {
        color: ${({ theme }) => theme.colors.color4.base};
          font-weight: ${fonts.weight.regular};
        }
        a:hover {
          color: ${({ theme }) => theme.colors.color4.base};
        }
        img { 
          max-width: 100%;
        }
        table {
          td,th {
            border:solid 1px ${({ theme }) => theme.colors.color2.base};
          }
        }
      }
      .HeaderWeb{
        display: block;
       }
       .HeaderMobile{
        display: none;
       }
      @media screen and (max-width: 1200px) {
       .HeaderWeb{
        display: none;
       }
       .HeaderMobile{
        display: block;
       }
}
      ${ConfirmationCss}
      ${SuccessModalCss}
      ${UpdateEmailModalCss}
      ${WarningModalCss}
      ${BuySellConfirmationModalCss}
      ${AuthenticatorModalCss}
      ${CoinSelectionDropdownCss}
      ${ScanQrCss}
      ${EmailAntiPhishingCss}
      ${ChangePasswordMailConfirmModalCss}
      ${TimeoutPeriodModalCss}
      ${ChangePassword2FAConfirmModalCss}
      ${ForgetPasswordStepsModalCss}
      ${AddressIbanAddFormCss}
      ${RegistrationMailConfirmationCss}
      ${HeaderMenuDrawerCss}
      ${CommissionInformationModalCss}
      ${TransferDetailModalCss}
      ${GiftCardModalCss}

      
`;

export default function GlobalStyle() {
    return (
        <>
            <Styles />
        </>
    );
}
