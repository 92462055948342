import { ButtonProps as AntButtonProps } from 'antd';
import { IconButtonStyled } from './index.styles';

import React from 'react';
import { IconType } from 'components/svg-icon/icon-types';
import { SvgIcon } from 'components/svg-icon';

type AllowedAntButtonTypes =
    | 'size'
    | 'disabled'
    | 'loading'
    | 'onClick'
    | 'shape';

export interface IconButtonProps
    extends Pick<AntButtonProps, AllowedAntButtonTypes> {
    icon?: IconType | undefined;
}

const WrappedButton = ({ icon = 'chevron-up', ...props }: IconButtonProps) => {
    const extraProps: Pick<AntButtonProps, 'icon' | 'className'> = {};

    if (icon) {
        extraProps.icon = <SvgIcon name={icon} />;
        extraProps.className = `app-icon-btn`;
    }

    return <IconButtonStyled {...props} {...extraProps}></IconButtonStyled>;
};

export const IconButton = React.memo(WrappedButton);
