import { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getLanguage } from 'common/utils';
import useBlogApi from 'api/blog/blog.api';
import parse from 'html-react-parser';
import { routesPaths } from 'config/routes';

interface DataType {
    title: string;
    slug: string;
    content: string;
    publishedAt: string;
    seo: {
        title: string;
        description: string;
        keywords: string;
    };
}
const StaticDetailPage = () => {
    const lang = getLanguage();
    const params = useParams();
    const { getPrivaciesDetail } = useBlogApi();
    const [data, setData] = useState<DataType>({
        title: '',
        slug: '',
        content: '',
        publishedAt: '',
        seo: {
            title: '',
            description: '',
            keywords: '',
        },
    });
    const fetchInitial = useCallback(async () => {
        const _data = await getPrivaciesDetail(
            lang,
            params.slug !== undefined ? params.slug : ''
        );
        setData(_data.data.data[0].attributes);
    }, [params.slug]);

    useEffect(() => {
        fetchInitial();
    }, [params.slug]);

    const navigate = useNavigate();
    const isInitialMount = useRef(true);
    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            navigate(routesPaths.root);
        }
    }, [lang]);

    const details: DataType = data;
    return <>{parse(details.content)}</>;
};

export default StaticDetailPage;
