import * as S from './index.styles';
import { SvgIcon } from 'components/svg-icon';
import { Col, Row } from 'antd';
import Footer from 'components/home-page/footer';
import useBlogApi from 'api/blog/blog.api';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getLanguage, getLocaleDateFormat } from 'common/utils';
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet';
import { routesPaths } from 'config/routes';
import { HashLink } from 'react-router-hash-link';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'common/theme/ThemeProvider';

export type BoxType = 'column' | 'row';
interface BlogType {
    attributes: {
        title: string;
        slug: string;
        publishedAt: string;
        readingtime: string;
        thumbnail: {
            data: {
                attributes: {
                    url: string;
                    name: string;
                    formats: {
                        thumbnail: {
                            url: string;
                        };
                    };
                };
            };
        };
    };
}
interface AdvertisingType {
    blogMiniUrl: string;
    blogMiniBanner: {
        data: {
            attributes: {
                url: string;
                name: string;
            };
        };
    };
}
interface BlogDetailType {
    title: string;
    slug: string;
    description: string;
    summary: string;
    content: string;
    publishedAt: string;
    readingtime: string;
    media: {
        data: {
            attributes: {
                name: string;
                url: string;
            };
        };
    };
    thumbnail: {
        data: {
            attributes: {
                name: string;
                url: string;
            };
        };
    };
    blogs: {
        data: BlogType[];
    };
    seo: {
        title: string;
        description: string;
        keywords: string;
    };
    taglink: [
        {
            title: string;
            tag: string;
        }
    ];
}

const BlogItem = ({ data }: { data: BlogType }) => {
    return (
        <S.Box>
            <Link to={`${routesPaths.blog.root}/${data.attributes.slug}`}>
                <div className="sub-banner">
                    <img
                        src={`${process.env.REACT_APP_STRAPI_BASE_URL}${data.attributes.thumbnail.data.attributes.url}`}
                        alt={data.attributes.thumbnail.data.attributes.name}
                    />
                </div>
                <S.Info>
                    <S.Time>
                        <div className="time">
                            <div className="date">
                                <span>
                                    {getLocaleDateFormat(
                                        data.attributes.publishedAt
                                    )}
                                </span>
                            </div>
                            {data.attributes.readingtime !== null && (
                                <div className="time">
                                    <SvgIcon name="time" />
                                    <span>
                                        {data.attributes.readingtime} dk
                                    </span>
                                </div>
                            )}
                        </div>
                    </S.Time>
                    <div className="title">
                        <h1 className="box-title">{data.attributes.title}</h1>
                    </div>
                </S.Info>
            </Link>
        </S.Box>
    );
};

export const BlogDetail = () => {
    const { theme } = useTheme();
    const lang = getLanguage();
    const params = useParams();
    const { getBlogDetail, getAdvertising } = useBlogApi();
    const [_blogs, setBlogs] = useState<BlogType[]>([]);
    const [_blogDetail, setDetail] = useState<BlogDetailType>({
        title: '',
        slug: '',
        description: '',
        summary: '',
        content: '',
        publishedAt: '',
        readingtime: '',
        media: {
            data: {
                attributes: {
                    name: '',
                    url: '',
                },
            },
        },
        thumbnail: {
            data: {
                attributes: {
                    name: '',
                    url: '',
                },
            },
        },
        blogs: {
            data: [],
        },
        seo: {
            title: '',
            description: '',
            keywords: '',
        },
        taglink: [
            {
                title: '',
                tag: '',
            },
        ],
    });
    const [_advertising, setAdvertising] = useState<AdvertisingType>();

    const fetchInitial = useCallback(async () => {
        const detail = await getBlogDetail(
            lang,
            params.slug !== undefined ? params.slug : ''
        );
        setDetail(detail.data.data[0].attributes);
        setBlogs(detail.data.data[0].attributes.blogs.data);

        const { hash } = window.location;
        if (hash) {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element)
                element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
        const advertising = await getAdvertising(lang);
        setAdvertising(advertising.data.data.attributes);
    }, [params.slug]);

    useEffect(() => {
        fetchInitial();
    }, [params.slug]);

    const navigate = useNavigate();
    const isInitialMount = useRef(true);
    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            navigate(routesPaths.blog.root);
        }
    }, [lang]);

    const blogDetail: BlogDetailType = _blogDetail;
    const blogList: BlogType[] = _blogs;
    return (
        <div>
            <style
                dangerouslySetInnerHTML={{
                    __html: `.ant-layout-content { background-color: ${
                        theme?.selectedTheme == 'light' ? '#ffffff' : '#202124'
                    } }`,
                }}
            />
            <Helmet>
                <title>
                    {blogDetail.seo == null
                        ? blogDetail.title
                        : blogDetail.seo.title}
                </title>
                <meta
                    name="description"
                    content={
                        blogDetail.seo == null
                            ? blogDetail.description
                            : blogDetail.seo.description
                    }
                ></meta>
                <meta
                    name="keywords"
                    content={
                        blogDetail.seo == null ? '' : blogDetail.seo.keywords
                    }
                ></meta>
            </Helmet>
            <S.Container>
                <Row>
                    <Col span={18} className="left-side">
                        <S.Content>
                            <S.Time>
                                <div className="time">
                                    <div className="date">
                                        <span>
                                            {getLocaleDateFormat(
                                                blogDetail.publishedAt
                                            )}
                                        </span>
                                    </div>
                                    {blogDetail.readingtime !== null && (
                                        <div className="time">
                                            <SvgIcon name="time" />
                                            <span>
                                                {blogDetail.readingtime} dk
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </S.Time>
                            <S.Head>{blogDetail.title}</S.Head>
                            {blogDetail.media !== null && (
                                <div className="image">
                                    <img
                                        src={`${process.env.REACT_APP_STRAPI_BASE_URL}${blogDetail.media.data.attributes.url}`}
                                        alt={
                                            blogDetail.media.data.attributes
                                                .name
                                        }
                                    />
                                </div>
                            )}
                            <div className="content editor">
                                {blogDetail.summary !== '' && (
                                    <div className="alert">
                                        {parse(blogDetail.summary)}
                                    </div>
                                )}
                                {blogDetail.content !== '' && (
                                    <div className="paragraph">
                                        {parse(blogDetail.content)}
                                    </div>
                                )}
                            </div>
                        </S.Content>
                    </Col>
                    <Col span={6} className="right-side">
                        <S.RightSideBar>
                            <div className="share-posts">
                                <div className="title">
                                    <h2>Share Posts</h2>
                                </div>
                                <div className="social-links">
                                    <ul>
                                        <li>
                                            <a
                                                href={`https://twitter.com/intent/tweet?text=${blogDetail.description} ${window.location.href}`}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <SvgIcon name="twitter"></SvgIcon>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href={`http://www.facebook.com/share.php?u=${window.location.href}`}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <SvgIcon name="facebook"></SvgIcon>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <SvgIcon name="linkedin"></SvgIcon>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href={`https://t.me/share/url?url=${window.location.href}&text=${blogDetail.description}`}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <SvgIcon name="mail"></SvgIcon>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                {blogDetail.taglink.length > 0 && (
                                    <div className="list">
                                        <ul>
                                            {blogDetail.taglink.map(
                                                (item, key) => {
                                                    return (
                                                        <li
                                                            key={key}
                                                            className={
                                                                location.hash ===
                                                                item.tag
                                                                    ? 'active'
                                                                    : ''
                                                            }
                                                        >
                                                            <HashLink
                                                                to={`${routesPaths.blog.root}/${blogDetail.slug}${item.tag}`}
                                                                scroll={(el) =>
                                                                    el.scrollIntoView(
                                                                        {
                                                                            behavior:
                                                                                'smooth',
                                                                        }
                                                                    )
                                                                }
                                                            >
                                                                {item.title}
                                                            </HashLink>
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    </div>
                                )}
                                <div className="blog-items">
                                    {blogList.map((item, key) => {
                                        return (
                                            <BlogItem key={key} data={item} />
                                        );
                                    })}
                                </div>
                                {_advertising?.blogMiniBanner.data != null && (
                                    <div className="bot-banner">
                                        <a
                                            href={
                                                _advertising.blogMiniUrl !==
                                                null
                                                    ? _advertising.blogMiniUrl
                                                    : undefined
                                            }
                                            target={
                                                _advertising.blogMiniUrl !==
                                                null
                                                    ? '_blank'
                                                    : undefined
                                            }
                                            rel="noreferrer"
                                        >
                                            <img
                                                src={`${process.env.REACT_APP_STRAPI_BASE_URL}${_advertising?.blogMiniBanner.data.attributes.url}`}
                                                alt={
                                                    _advertising?.blogMiniBanner
                                                        .data.attributes.name
                                                }
                                            />
                                        </a>
                                    </div>
                                )}
                            </div>
                        </S.RightSideBar>
                    </Col>
                </Row>
            </S.Container>
            <Footer />
        </div>
    );
};
