import { ReactNode } from 'react';

interface CentralizedInfoScreenLayoutProps {
    children: ReactNode;
}

const CentralizedInfoScreenLayout = ({
    children,
}: CentralizedInfoScreenLayoutProps) => {
    return (
        <>
            <div
                style={{
                    padding: 24,
                    textAlign: 'center',
                    alignItems: 'center',
                    height: 'calc(100vh - 80px)',
                    display: 'flex',
                    margin: '0px auto',
                    maxWidth: 900,
                }}
            >
                <div
                    style={{
                        flex: 1,
                    }}
                >
                    {children}
                </div>
            </div>
        </>
    );
};

export default CentralizedInfoScreenLayout;
