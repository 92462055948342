import { useRef } from 'react';
import { HttpResponse } from 'common/http/http.model';
import httpService from 'common/http/http.service';
import {
    PlaceOrderApiCalls,
    BuySellRequest,
    BuySellResponse,
    // BuySellNewResponse,
    BuySellApiResponse,
    GetComissionListResponse,
    GetCommissionRateResponse,
    BuySellNewRequest,
    BuySellNewResponse,
} from './placeOrder.model';
import placeOrderApiEndpoints from './placeOrder.endpoint';
import { mappingEasyBuySell } from './placeOrder.mapping';

const usePlaceOrderApi = (): PlaceOrderApiCalls => {
    const generateApiCalls = (): PlaceOrderApiCalls => {
        return {
            buySellNew: async (
                payload: BuySellNewRequest
            ): Promise<BuySellNewResponse> => {
                const response: any = await httpService.client.post(
                    placeOrderApiEndpoints.buySellNew,
                    payload
                );
                return response;
            },
            buySummary: async (
                payload: BuySellRequest
            ): Promise<HttpResponse<BuySellResponse>> => {
                const response: BuySellResponse = await httpService.client.get(
                    placeOrderApiEndpoints.buySummary,
                    {
                        params: payload,
                    }
                );
                return mappingEasyBuySell(
                    response as unknown as BuySellApiResponse,
                    'buy'
                );
            },
            sellSummary: async (
                payload: BuySellRequest
            ): Promise<HttpResponse<BuySellResponse>> => {
                const response: BuySellResponse = await httpService.client.get(
                    placeOrderApiEndpoints.sellSummary,
                    {
                        params: payload,
                    }
                );
                return mappingEasyBuySell(
                    response as unknown as BuySellApiResponse,
                    'sell'
                );
            },
            getComissionList: (): HttpResponse<GetComissionListResponse> =>
                httpService.client.get(placeOrderApiEndpoints.fees),
            getComissionListZero: (): HttpResponse<GetComissionListResponse> =>
                httpService.client.get(placeOrderApiEndpoints.feesZero),
            getNowList: (): HttpResponse<GetCommissionRateResponse> =>
                httpService.client.get(placeOrderApiEndpoints.now),
        };
    };
    const songCallsRef = useRef<PlaceOrderApiCalls>(generateApiCalls());

    return songCallsRef.current;
};

export default usePlaceOrderApi;
