import { Tabs } from 'antd';
import useCommonApi from 'api/common/common.api';
import { WitdrawCommission } from 'api/common/common.model';
import { SvgIcon } from 'components';
import { DataTable, DataTableRow } from 'components/data-table';
import { IconType } from 'components/svg-icon/icon-types';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { CommissionInformationTable } from 'widgets/table/commission-information-table';
import { ComissionPageStyled } from './index.styles';
const { TabPane } = Tabs;

export const ComissionPage = () => {
    const { t } = useTranslation();
    const { fetchWitdrawCommission } = useCommonApi();
    const [withdrawInfos, setWithdrawInfos] = useState<WitdrawCommission[]>([]);

    const fetchWithdrawCommission = useCallback(async () => {
        const res = await fetchWitdrawCommission();
        setWithdrawInfos(res);
    }, [setWithdrawInfos]);

    useEffect(() => {
        fetchWithdrawCommission();
    }, []);

    const witdrawcols: DataTableRow<WitdrawCommission>[] = [
        {
            title: t('commissions.crypto_commission_table.unit'),
            dataIndex: 'symbol',
            key: 'symbol',
            align: 'left',
            render: (symbol, record) => {
                return (
                    <div className="coin-pair">
                        <SvgIcon
                            name={`coin-${symbol}` as unknown as IconType}
                            size={24}
                        />
                        <span>{record.name}</span>
                    </div>
                );
            },
        },
        {
            title: t('commissions.crypto_commission_table.minamount'),
            dataIndex: 'minamount',
            key: 'minamount',
            align: 'right',
        },
        {
            title: t('commissions.crypto_commission_table.fee'),
            dataIndex: 'fee',
            key: 'fee',
            align: 'right',
        },
    ];

    const cols: DataTableRow[] = [
        {
            title: t('commissions.buy_sell_commission_table.unit'),
            dataIndex: 'birim',
            key: 'birim',
            align: 'left',
        },
        {
            title: t('commissions.buy_sell_commission_table.deposit'),
            dataIndex: 'deposit',
            key: 'deposit',
            align: 'left',
        },
        {
            title: t('commissions.buy_sell_commission_table.withdrawal'),
            dataIndex: 'withdrawal',
            key: 'withdrawal',
            align: 'left',
        },
    ];
    const sourcee = [
        {
            id: '0',
            birim: 'Banka',
            deposit: '0 TRY',
            withdrawal: '3 TRY',
        },
    ];
    return (
        <>
            <Helmet>
                <title>
                    {t('seo.commissions.title', {
                        appTitle: process.env.REACT_APP_TITLE,
                    })}
                </title>
                <meta
                    name="description"
                    content={t('seo.commissions.description')}
                ></meta>
                <meta
                    name="keywords"
                    content={t('seo.commissions.keywords')}
                ></meta>
            </Helmet>
            <ComissionPageStyled>
                <Tabs defaultActiveKey="1">
                    <TabPane
                        tab={t('commissions.tab.buy_sell_commission')}
                        key="1"
                    >
                        <div className="detail">
                            <div className="text">
                                <h2 className="title">
                                    {t('commissions.market')}
                                </h2>
                                <p className="info">
                                    {t('commissions.purchaseOr')}
                                </p>
                            </div>
                            <div className="text">
                                <h2 className="title">
                                    {t('commissions.orders')}
                                </h2>
                                <p className="info">
                                    {t('commissions.inTheMarket')}
                                </p>
                            </div>
                        </div>
                        <CommissionInformationTable />
                    </TabPane>
                    <TabPane
                        tab={t('commissions.tab.crypto_commission')}
                        key="2"
                    >
                        <DataTable
                            cols={witdrawcols}
                            source={withdrawInfos}
                            className="buy-sell-table"
                        />
                        <div className="sub-title">
                            <h2>{t('commissions.deposit')}</h2>
                        </div>
                        <DataTable
                            cols={cols}
                            source={sourcee}
                            className="crypto-table"
                        />
                        <div className="bank-transfer">
                            <p>{t('commissions.rule')}</p>
                        </div>
                        <p className="kdv">{t('commissions.kdv')}</p>
                    </TabPane>
                </Tabs>
            </ComissionPageStyled>
        </>
    );
};
