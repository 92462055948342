import {
    BalanceAssetList,
    BalanceLimits,
    BalanceLimitsResponse,
    BalanceNowResponse,
    BalanceNowResponseData,
} from './balance.model';

export const mappingBalanceLimits = (
    payload: BalanceLimitsResponse['limits']
): BalanceLimits => {
    return {
        limitDailyDeposit: payload.ldd,
        limitMonthlyDeposit: payload.lmd,
        limitDailyWithdraw: payload.ldw,
        limitMonthlyWithdraw: payload.lmd,
        currentDailyDeposit: payload.cdd,
        currentMonthlyDeposit: payload.cmd,
        currentDailyWithdraw: payload.cdw,
        currentMonthlyWithdraw: payload.cmw,
    };
};
export const mappingBalanceNow = (
    payload: BalanceNowResponse
): BalanceNowResponseData => {
    const assets: BalanceAssetList = {};

    const tryAssetIndex =
        payload.assets &&
        payload.assets.findIndex((item) => item.name === 'TRY');

    const tryAsset =
        tryAssetIndex < 0 || tryAssetIndex === null
            ? {
                  name: 'TRY',
                  asbtc: '0',
                  asunit: '0',
                  total: '0',
                  usable: '0',
                  uasunit: '0',
              }
            : payload.assets[tryAssetIndex];

    assets[tryAsset.name] = {
        asbtc: tryAsset.asbtc,
        asunit: tryAsset.asunit,
        name: tryAsset.name,
        total: tryAsset.total,
        usable: tryAsset.usable,
        usableSecond: tryAsset.uasunit,
    };

    if (payload.assets) {
        payload.assets
            .filter((item) => item.name !== 'TRY')
            .sort((a, b) => a.name.localeCompare(b.name))
            .forEach((item) => {
                assets[item.name] = {
                    asbtc: item.asbtc,
                    asunit: item.asunit,
                    name: item.name,
                    total: item.total,
                    usable: item.usable,
                    usableSecond: item.uasunit,
                };
            });
    }

    return {
        assets,
        totalBTC: payload.total_btc || '0',
        totalTRY: payload.total_try || '0',
        totalUnit: payload.total_unit || 'TRY',
        unit: payload.unit || 'TRY',
        usableBTC: payload.usable_btc || '0',
        usableUnit: payload.usable_unit || '0',
    };
};
