import * as S from './index.styles';
import { useMemo, useRef, useState } from 'react';
import { Button, SvgIcon } from 'components';
import { t } from 'i18next';
// import {
//     UpdateEmailForm,
//     UpdateEmailFormValues,
// } from 'widgets/forms/UpdateEmailForm';
import { StatusCard } from 'components/status-card';
import { IconType } from 'components/svg-icon/icon-types';
import { ModalWarningCard } from 'components/modal-warning-card';
// import { UpdateEmailCodeConfirm } from 'widgets/confirmations/update-email-code-confirm';
import { ConfirmPasswordForm } from 'widgets/cards/card-personal';
import { NewEmailConfirmation } from 'widgets/forms/NewEmailConfirmationForm';
import { EmailCodeConfirmation } from 'widgets/forms/EmailCodeConfirmationForm';

enum UpdateEmailStep {
    UserConfirmation = 0,
    // SMSConfirmation = 1,
    EmailForm = 2,
    Success = 3,
}

interface UpdateEmailModalTitleProps {
    icon?: IconType;
    title: string;
}

const UpdateEmailModalTitle = (props: UpdateEmailModalTitleProps) => (
    <S.EmailConfirmationTitle className="update-email-modal-title">
        {props.icon && <SvgIcon name={props.icon} size={24}></SvgIcon>}
        <h2>{props.title}</h2>
        <hr></hr>
    </S.EmailConfirmationTitle>
);

interface CancelNextButtonsProps {
    onCancel?: () => void;
    onNext: () => void;
    id?: boolean;
}

export const CancelNextButtons = (props: CancelNextButtonsProps) => {
    return (
        <S.CancelNextButtons className="cancel-next-buttons">
            <div className="buttons-container">
                <Button
                    type="ghost"
                    className="cancel-button"
                    onClick={props.onCancel}
                >
                    {t('updateEmail.modalButton.cancel')}
                </Button>
                <Button
                    type="primary"
                    onClick={props.onNext}
                    disabled={props.id}
                >
                    {t('updateEmail.modalButton.next')}
                </Button>
            </div>
        </S.CancelNextButtons>
    );
};

export interface UpdateEmailStepsProps {
    onCancelClick?: () => void;
    onSuccess?: () => void;
}
interface ConfirmPasswordFormRefObject {
    submitForm: () => Promise<void>;
    setError: (message: string) => void;
}
export const UpdateEmailSteps = ({
    onCancelClick,
    onSuccess,
}: UpdateEmailStepsProps) => {
    const [step, setStep] = useState<number>(0);
    // const userAuthMethod: AuthMethod = 'sms';
    const refConfirmPasswordForm = useRef<ConfirmPasswordFormRefObject>(null);
    const stepContent = useMemo(() => {
        if (step === UpdateEmailStep.UserConfirmation) {
            return (
                <div className={'step-' + UpdateEmailStep[step]}>
                    <ModalWarningCard
                        title={t('updateEmail.emailConfirmation.title')}
                        description={{
                            titletext: t('updateEmail.emailConfirmation.text'),
                            listtext: [
                                t('updateEmail.emailConfirmation.textItem1'),
                                t('updateEmail.emailConfirmation.textItem2'),
                            ],
                            bottomtext: t(
                                'updateEmail.emailConfirmation.question'
                            ),
                        }}
                        onCancel={onCancelClick}
                        onNext={async () => {
                            const result =
                                await refConfirmPasswordForm.current?.submitForm();
                            if (result) {
                                setStep(step + 2);
                                // onCancelClick && onCancelClick();
                            } else {
                                refConfirmPasswordForm.current?.setError(
                                    t(
                                        'updateEmail.emailConfirmation.confirmError'
                                    )
                                );
                            }
                        }}
                        extraComponent={
                            <ConfirmPasswordForm ref={refConfirmPasswordForm} />
                        }
                    />
                </div>
            );
        }
        //  else if (step === UpdateEmailStep.SMSConfirmation) {
        //     return (
        //         <div className={'step-' + UpdateEmailStep[step]}>
        //             <UpdateEmailCodeConfirm
        //                 authMethod={userAuthMethod}
        //                 onCancel={onCancelClick}
        //                 onSuccess={() => {
        //                     setStep(step + 1);
        //                 }}
        //             />
        //         </div>
        //     );
        // }
        else if (step === UpdateEmailStep.EmailForm) {
            return (
                <div className={'step-' + UpdateEmailStep[step]}>
                    <UpdateEmailModalTitle
                        title={t('updateEmail.emailForm.title')}
                    ></UpdateEmailModalTitle>
                    <NewEmailConfirmation />
                    <EmailCodeConfirmation
                        successCallback={() => {
                            onCancelClick && onCancelClick();
                            onSuccess && onSuccess();
                        }}
                    />
                </div>
            );
        } else if (step === UpdateEmailStep.Success) {
            return (
                <div className={'step-' + UpdateEmailStep[step]}>
                    <StatusCard
                        title={t('updateEmail.success.title')}
                        description={t('updateEmail.success.description')}
                        status="success"
                        buttontext={t('updateEmail.modalButton.ok')}
                        onButtonClick={onCancelClick}
                    ></StatusCard>
                </div>
            );
        }
        return <div></div>;
    }, [step]);
    return (
        <S.UpdateEmailStepsContainer>{stepContent}</S.UpdateEmailStepsContainer>
    );
};
