// import useSupportApi from 'api/support/support.api';
// import {
//     NotificationRequest,
//     NotificationResponse,
// } from 'api/support/support.model';
//import { getLanguage } from 'common/utils';
// import { Button } from 'components/button';
import { getLocaleDateFormat, getLocaleTimeFromISOFormat } from 'common/utils';
import { t } from 'i18next';
import React from 'react';
import { NotificationDropdownStyled } from './index.styles';
import parse from 'html-react-parser';
import { SvgIcon } from 'components/svg-icon';
import { Button } from 'components/button';
import { Link } from 'react-router-dom';
import { routesPaths } from 'config/routes';

const WrappedNotificationDropdown = (props: any) => {
    return (
        <NotificationDropdownStyled className="notification-dropdown">
            <>
                <div className="notification-dropdown-header">
                    {props.data?.is_new_count > 0 ? (
                        <span className="notification-dropdown-header-text">
                            {props.data?.is_new_count}{' '}
                            {t('notificationDropdown.newAnnouncement')}
                        </span>
                    ) : (
                        <span className="notification-dropdown-header-text">
                            {t('notificationDropdown.announcement')}
                        </span>
                    )}
                </div>
                <div className="notification-dropdown-body">
                    {props.data?.anouncements?.length > 0 ? (
                        <ul className="notification-dropdown-ul">
                            {props.data?.anouncements.map(
                                (item: any, key: any) => {
                                    return (
                                        <li
                                            key={key}
                                            className="notification-dropdown-li"
                                        >
                                            <div
                                                className={`notification-dropdown-li-title ${
                                                    !props.data.anouncements
                                                        .is_new
                                                        ? 'readed'
                                                        : ''
                                                }`}
                                            >
                                                {item.title}
                                            </div>
                                            <div className="notification-dropdown-li-content">
                                                {parse(item.content)}
                                            </div>
                                            <div className="notification-dropdown-li-date">
                                                {getLocaleTimeFromISOFormat(
                                                    item.date
                                                ).slice(0, 5)}{' '}
                                                {getLocaleDateFormat(item.date)}
                                            </div>
                                        </li>
                                    );
                                }
                            )}
                        </ul>
                    ) : (
                        <div className="noAnnouncement">
                            <SvgIcon name="notification" size={24} />
                            {t('notificationDropdown.noAnnouncement')}
                        </div>
                    )}
                </div>
                {props.data?.anouncements?.length > 0 && (
                    <div className="notification-dropdown-footer">
                        <Link to={routesPaths.announcement.root}>
                            <Button
                                type="link"
                                suffixIcon="chevron-right"
                                className="notification-dropdown-see-all-btn"
                            >
                                {t('notificationDropdown.seeAll')}
                            </Button>
                        </Link>
                    </div>
                )}
            </>
        </NotificationDropdownStyled>
    );
};

export const NotificationDropdown = React.memo(WrappedNotificationDropdown);
