import { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';

const mobileCss = css`
    .gift-card-modal {
        width: 300px;
    }
    .gift-card-out {
        background: ${({ theme }) => theme.colors.color1.base};
        color: ${({ theme }) => theme.colors.color4.base};
        position: absolute;
        right: 12px;
        top: 10px;
        font-size: 24px !important;
        height: 24px !important;
    }
    .ant-modal-content {
        padding-right: 20px !important;
    }
`;

export const GiftCardModalCss = css`
    .gift-card-modal {
        width: 500px;
        padding: 20px;
        margin-top: 30px;
        .ant-modal-close {
            top: 15px;
            right: 15px;
            opacity: 0;
        }
        .gift-card-out {
            background: ${({ theme }) => theme.colors.color1.base};
            color: ${({ theme }) => theme.colors.color4.base};
            position: absolute;
            right: 12px;
            top: 10px;
            font-size: 24px !important;
            height: 24px !important;
        }
        img {
            width: 100% !important;
            box-shadow: 10.3979px 10.3979px 31.1937px rgba(0, 0, 0, 0.2);
            border-radius: 8%;
        }
    }
    ${mediaQuery.lessThan('tablet')`${mobileCss}`}
`;
