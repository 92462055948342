import { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';

const mobileScanQrCodeCss = css`
    &.scanqr-modal {
        .ant-modal-content {
            max-width: 330px !important;
            width: 100%;

            .ant-modal-body {
                padding: 40px;
                .text {
                    h5 {
                    }
                }
            }
            .ant-modal-close {
                top: 10px;
                right: 10px;
            }
        }
    }
    .text {
        p {
            width: 100% !important;
        }
    }
    .ant-modal-content {
        width: 100%;
        max-width: 330px !important;
        margin: auto !important;

        .ant-modal-body {
            padding: 30px 20px !important;
        }
        .qr-and-form {
            flex-direction: column;
            .qr {
                width: 100%;
                margin-bottom: 15px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .key {
                    margin-right: 20px;
                    margin-bottom: 0;
                }
                .scan {
                    width: 88px;
                    height: 88px;
                    img {
                        width: 88px;
                        height: 88px;
                    }
                }
            }
            .form {
                width: 100%;
                .item {
                    &.info p {
                        width: 100%;
                    }
                    &.verification-code {
                        margin: 15px 0;
                    }
                }
            }
        }
    }
`;
export const ScanQrCss = css`
    &.scanqr-modal {
        .ant-modal-content {
            max-width: 655px !important;
            width: 100%;

            .ant-modal-body {
                padding: 40px;
            }
            .ant-modal-close {
                top: 10px;
                right: 10px;
            }
        }
    }
    ${mediaQuery.lessThan('tablet')`${mobileScanQrCodeCss}`}
`;
