import { fonts } from 'common/theme/font';
import styled from 'styled-components';

export const ChangePhoneConfirmStyled = styled.div`
    .modal-card-container {
        .title-container {
            /* padding-bottom: 16px; */
            padding-bottom: 0px !important;
            border-bottom: 0px !important;
            text-align: left;
            padding-left: 20px;
            font-size: ${fonts.size.medium};
            font-weight: ${fonts.weight.semibold};

            color: ${({ theme }) => theme.colors.color4.base};
        }

        .description-container {
            .invalid-login-error-message {
                display: flex;
                font-size: ${fonts.size.small};
                color: ${({ theme }) =>
                    theme.colors.staticColor.color3.base} !important;
                margin-top: 10px;
                margin-bottom: 20px;
            }
            text-align: center !important;
            margin: 10px 15px;
            .desc-text {
                font-size: ${fonts.size.small};
                font-weight: ${fonts.weight.regular};

                color: ${({ theme }) => theme.colors.color4.base};
            }

            .desc-list {
                padding-bottom: 8px;
                list-style: none;
                &:before {
                    content: '•';

                    color: ${({ theme }) =>
                        theme.colors.staticColor.color2.base};
                    display: inline-block;
                    width: 1em;

                    margin-left: -1em;
                }
            }
        }
        .app-form-field-code {
            padding-top: 26px;

            app-form-label {
                padding-bottom: 8px;

                font-weight: ${fonts.weight.semibold};
                font-size: ${fonts.size.small};

                color: ${({ theme }) => theme.colors.color4.base};
            }

            .app-form-control {
                background: ${({ theme }) => theme.colors.color1.base};
                opacity: 0.6;

                border: 1px solid ${({ theme }) => theme.colors.color2.base};
                border-radius: 4px;

                span {
                    padding: 12px 0px 12px 16px;
                }
            }
        }

        .code-input-container {
            max-width: 320px;
        }

        .cancel-next-buttons {
            padding-bottom: 18px;
        }
    }
    .descRow {
        padding-top: 10px;
    }
    .codeInputDesc {
        font-size: 12px;
        line-height: 18px;
        font-weight: 700;
        color: ${({ theme }) => theme.colors.color4.base};
        text-align: initial;
        padding-bottom: 5px;
        span {
            text-align: end;
            font-size: 12px !important;
            padding-left: 5px;
        }
    }
    .codeSubmitText {
        align-items: initial;
        text-align: end;
        padding-bottom: 5px;
        span {
            font-size: 12px !important;
            padding-left: 5px;
        }
    }
    .ant-alert.ant-alert-warning {
        color: ${({ theme }) => theme.colors.staticColor.color5.base};
        text-align: left;
        font-size: 12px;
        margin-bottom: 5px;
    }
`;
