import { useModal } from 'components/modal';
import { StatusCard, StatusCardProps } from 'components/status-card';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface UseSuccessModalProps extends StatusCardProps {
    lang?: boolean;
    hideCloseButton?: boolean;
}

interface ReturnUseSuccessModal {
    placeholder: () => void;
    openSuccessModal: () => void;
    openSuccessModalEmail?: () => void;
    closeSuccessModal: () => void;
    lang?: boolean;
}

export const useSuccessModal = (
    props: UseSuccessModalProps
): ReturnUseSuccessModal => {
    const { placeholder, openModal, closeModal } = useModal({
        className: 'success-modal',
        hideCloseButton: props.hideCloseButton
            ? props.hideCloseButton
            : undefined,
    });
    const { t } = useTranslation();

    const handleOpenSuccessModal = useCallback(() => {
        openModal(
            props?.lang && props.lang === true ? (
                <StatusCard
                    title={t('register.successEmail.title')}
                    description={t('register.successEmail.description')}
                    buttontext={t('register.successEmail.button')}
                    onButtonClick={
                        props.onButtonClick ? props.onButtonClick : closeModal
                    }
                />
            ) : (
                <StatusCard
                    title={props.title}
                    description={props?.description}
                    buttontext={props?.buttontext}
                    onButtonClick={
                        props.onButtonClick ? props.onButtonClick : closeModal
                    }
                />
            )
        );
    }, [openModal]);

    const handleOpenSuccessModalEmail = useCallback(() => {
        openModal(
            props?.lang && props.lang === true ? (
                <StatusCard
                    title={t('register.successEmail.title')}
                    description={t('register.successEmail.description')}
                    buttontext={t('register.successEmail.button')}
                    onButtonClick={
                        props.onButtonClick ? props.onButtonClick : closeModal
                    }
                />
            ) : (
                <StatusCard
                    title={props.title}
                    description={props?.description}
                    buttontext={props?.buttontext}
                    onButtonClick={
                        props.onButtonClick ? props.onButtonClick : closeModal
                    }
                />
            )
        );
    }, [openModal]);

    return {
        placeholder,
        openSuccessModal: handleOpenSuccessModal,
        closeSuccessModal: closeModal,
        openSuccessModalEmail: handleOpenSuccessModalEmail,
    };
};
