import { ReactNode } from 'react';
import BaseLayout from '../BaseLayout';
import { WideScreenLayoutStyled } from './index.styles';

interface WideScreenLayoutProps {
    children: ReactNode;
}

const WideScreenLayout = ({ children }: WideScreenLayoutProps) => {
    return (
        <WideScreenLayoutStyled>
            <BaseLayout>
                <div>{children}</div>
            </BaseLayout>
        </WideScreenLayoutStyled>
    );
};

export default WideScreenLayout;
