import { useState, useEffect, useCallback, useRef } from 'react';
// import ReactMarkdown from 'react-markdown';
import { useNavigate, useParams } from 'react-router-dom';
//import _ from 'lodash';
import { GuideBar } from 'widgets/guide-side-bar';
import { getLanguage, NumberFormat } from 'common/utils';
import useBlogApi from 'api/blog/blog.api';
import parse from 'html-react-parser';
import { routesPaths } from 'config/routes';
import { lowerCase, upperCase } from 'lodash';
import { IconType } from 'components/svg-icon/icon-types';
import { SvgIcon } from 'components/svg-icon';
import { Button } from 'components/button';
import { NavHashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import useSupportApi from 'api/support/support.api';
import { Helmet } from 'react-helmet';
import { CoinExchange } from 'widgets/coin-exchange';

interface DataType {
    title: string;
    slug: string;
    icon?: IconType;
    content: string;
    publishedAt: string;
    seo: {
        title: string;
        description: string;
        keywords: string;
    };
    guides: {
        data: [];
    };
    guide_category: {
        data: {
            attributes: {
                sidebar: boolean;
                slug: string;
            };
        };
    };
    localizations: {
        data: [];
    };
}
interface CirculationDataType {
    Base: string;
    Second: string;
    Bdigit: number;
    Sdigit: number;
    LastPrice: number;
    Circulating: number;
    Diff: number;
}
const CoinDetailPage = () => {
    const { t } = useTranslation();
    const lang = getLanguage();
    const params = useParams();
    const { getGuideDetail, getGuideMainCategory } = useBlogApi();
    const { circulationsList } = useSupportApi();
    const [data, setData] = useState<DataType>({
        title: '',
        slug: '',
        content: '',
        publishedAt: '',
        seo: {
            title: '',
            description: '',
            keywords: '',
        },
        guides: {
            data: [],
        },
        guide_category: {
            data: {
                attributes: {
                    sidebar: false,
                    slug: '',
                },
            },
        },
        localizations: {
            data: [],
        },
    });
    const [circulationdata, setCirculationData] = useState<any>({
        Base: '',
        Second: '',
        Bdigit: 0,
        Sdigit: 0,
        LastPrice: 0,
        Circulating: 0,
        Diff: 0,
    });
    const [mainCategory, setMainCategory] = useState<any>();
    const [coinInfo, setCoinInfo] = useState<any>({
        urlList: [],
    });

    const scrollWithOffset = (el: any) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -70;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    };

    const navigate = useNavigate();

    const isInitialMount = useRef(true);
    const fetchInitial = useCallback(async () => {
        const _mainCategory = await getGuideMainCategory(lang);

        let newArr: any = [];
        await _mainCategory.data.data.forEach((item: any) => {
            const obj = {
                title: item.attributes.title,
                link: item.attributes.slug,
                icon: '',
                subTitles: Array(),
                coinInfo: {},
            };
            if (item.attributes.coinInfo != undefined) {
                obj.coinInfo = item.attributes.coinInfo;
                obj.icon = 'coin-' + upperCase(item.attributes.coinInfo.Coin);
            }
            const sortArr = item.attributes.guides.data;
            sortArr.sort((a: any, b: any) => {
                const A = a.attributes.order !== null ? a.attributes.order : 0;
                const B = b.attributes.order !== null ? b.attributes.order : 0;
                if (A < B) {
                    return -1;
                }
                if (A > B) {
                    return 1;
                }
                return 0;
            });
            sortArr.forEach((items: any) => {
                const data = {
                    title: items.attributes.title,
                    link: items.attributes.slug + '-' + items.attributes.slugid,
                    order:
                        items.attributes.order !== null
                            ? items.attributes.order
                            : 0,
                };
                obj.subTitles.push(data);
            });
            newArr.push(obj);

            if (lowerCase(params.category) == obj.link) {
                setCoinInfo(obj.coinInfo);
            }

            // Seo için slug yoksa kategorinin ilk postunu aç
            if (params.category != undefined && params.slug == undefined) {
                if (
                    lowerCase(params.category) == lowerCase(obj.link) &&
                    obj.subTitles.length > 0
                ) {
                    navigate(
                        routesPaths.guide.root +
                            '/' +
                            params.category +
                            '/' +
                            obj.subTitles[0].link
                    );
                }
            }
        });
        setMainCategory(newArr);

        if (params.slug !== undefined) {
            let path = window.location.pathname;
            const tempPath = path.split('/').pop()?.split('-').pop();
            const detail = await getGuideDetail(
                lang,
                tempPath !== undefined ? tempPath : ''
            );
            setData(detail.data.data[0].attributes);
            // slug ile url uyusmuyorsa default url acılır

            if (isInitialMount.current) {
                isInitialMount.current = false;

                const tempUrl =
                    detail.data.data[0].attributes.slug +
                    '-' +
                    detail.data.data[0].attributes.slugid;

                if (tempUrl !== path.split('/').pop()) {
                    navigate(
                        routesPaths.guide.root +
                            '/' +
                            params.category +
                            '/' +
                            tempUrl
                    );
                }
            }

            window.scrollTo({
                top: 0,
                //behavior: 'smooth',
            });
            if (
                detail.data.data[0].attributes.guide_category.data.attributes
                    .sidebar
            ) {
                const circulationsListResponse = await circulationsList(
                    params.category !== undefined
                        ? params.category.toUpperCase()
                        : 'BTC',
                    circulation.Second
                );
                circulationsListResponse.success &&
                    setCirculationData(circulationsListResponse.circulation);
            }
        }
    }, [params.slug]);

    useEffect(() => {
        fetchInitial();
    }, [params.slug]);

    // const isInitialMount = useRef(true);
    // useEffect(() => {
    //     if (isInitialMount.current) {
    //         isInitialMount.current = false;
    //     } else {
    //         console.log('guide.localizations.data', guide.localizations.data);

    //         const langSlug: any = guide.localizations.data.find((x: any) => {
    //             return (
    //                 x.attributes.slugid === urlSlugID &&
    //                 x.attributes.locale === lang
    //             );
    //         });
    //         console.log('urlSlugID', urlSlugID);

    //         console.log('langSlug', langSlug);

    //         if (langSlug !== undefined) {
    //             navigate(
    //                 routesPaths.guide.root +
    //                     '/' +
    //                     params.category +
    //                     '/' +
    //                     langSlug?.attributes?.slug +
    //                     '-' +
    //                     langSlug?.attributes?.slugid
    //             );
    //         } else {
    //             navigate(routesPaths.guide.root);
    //         }
    //     }
    // }, []);

    const guide: DataType = data;
    const circulation: CirculationDataType = circulationdata;
    return (
        <>
            <Helmet>
                <title>
                    {guide.seo == null ? guide.title : guide.seo.title}
                </title>
                <meta
                    name="description"
                    content={guide.seo == null ? '' : guide.seo.description}
                ></meta>
                <meta
                    name="keywords"
                    content={guide.seo == null ? '' : guide.seo.keywords}
                ></meta>
            </Helmet>
            <div className="left-content">
                {guide.guide_category.data.attributes.sidebar && (
                    <div className="coin-info">
                        <div className="head">
                            <div className="name">
                                <SvgIcon
                                    name={
                                        `coin-${upperCase(
                                            coinInfo.Coin
                                        )}` as IconType
                                    }
                                />
                                {coinInfo.name}
                                <span className="subName">{coinInfo.Coin}</span>
                            </div>
                        </div>
                        <div className="body">
                            {NumberFormat(circulation.LastPrice) !== 0 && (
                                <div className="currentPrice">
                                    <div className="title">
                                        {t(
                                            'guide.detail.coin_card.current_price'
                                        )}
                                    </div>
                                    <div className="price">
                                        {`${NumberFormat(
                                            circulation.LastPrice
                                        )} ₺`}
                                        <span>{circulation.Second}</span>
                                        {circulation.Diff !== 0 && (
                                            <div
                                                className={`change ${
                                                    circulation.Diff < 0
                                                        ? 'negative'
                                                        : ''
                                                }`}
                                            >
                                                <SvgIcon
                                                    size={16}
                                                    className={`arrow-pointer`}
                                                    name={
                                                        `chevron-${
                                                            circulation.Diff < 0
                                                                ? 'down'
                                                                : 'up'
                                                        }` as IconType
                                                    }
                                                />
                                                {circulation.Diff}%
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            <ul>
                                <li>
                                    <div className="left">
                                        {t('guide.detail.coin_card.type')}
                                    </div>
                                    <div className="right">{coinInfo.type}</div>
                                </li>
                                <li>
                                    <div className="left">
                                        {t(
                                            'guide.detail.coin_card.maximum_supply'
                                        )}
                                    </div>
                                    <div className="right">
                                        {coinInfo.maximumSupply}
                                    </div>
                                </li>
                                {circulation.Circulating > 0 && (
                                    <li>
                                        <div className="left">
                                            {t(
                                                'guide.detail.coin_card.circulation_supply'
                                            )}
                                        </div>
                                        <div className="right">
                                            {Intl.NumberFormat().format(
                                                circulation.Circulating
                                            )}
                                        </div>
                                    </li>
                                )}

                                {coinInfo.urlList.length > 0 &&
                                    coinInfo.urlList.map(
                                        (item: any, key: any) => {
                                            return (
                                                <>
                                                    <li key={key}>
                                                        <div className="left">
                                                            {item.name}
                                                        </div>
                                                        <div className="right">
                                                            <a
                                                                href={item.url}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                key={key}
                                                            >
                                                                {item.url}
                                                            </a>
                                                        </div>
                                                    </li>
                                                </>
                                            );
                                        }
                                    )}
                            </ul>
                            <a
                                href={`${routesPaths.public.marketBasic}/${coinInfo.Coin}-try`}
                            >
                                <Button
                                    type="primary"
                                    disabled={
                                        NumberFormat(circulation.LastPrice) ===
                                        0
                                            ? true
                                            : false
                                    }
                                >
                                    {coinInfo.name}{' '}
                                    {t('guide.detail.coin_card.buy')}
                                </Button>
                            </a>
                        </div>
                    </div>
                )}
                <div className="share-posts">
                    <div className="title">
                        <h2>{t('guide.detail.share')}</h2>
                    </div>
                    <div className="social-links">
                        <ul>
                            <li>
                                <a
                                    href={`https://twitter.com/intent/tweet?text=${guide.title} ${window.location.href}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <SvgIcon name="twitter"></SvgIcon>
                                </a>
                            </li>
                            <li>
                                <a
                                    href={`http://www.facebook.com/share.php?u=${window.location.href}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <SvgIcon name="facebook"></SvgIcon>
                                </a>
                            </li>
                            <li>
                                <a
                                    href={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <SvgIcon name="linkedin"></SvgIcon>
                                </a>
                            </li>
                            <li>
                                <a
                                    href={`https://t.me/share/url?url=${window.location.href}&text=${guide.title}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <SvgIcon name="mail"></SvgIcon>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <GuideBar
                    items={mainCategory}
                    path={`guide/${guide.guide_category.data.attributes.slug}`}
                ></GuideBar>
            </div>
            <div className="right-content">
                <div className="content">
                    {NumberFormat(circulation.LastPrice) !== 0 && (
                        <CoinExchange
                            data={circulation}
                            coinname={coinInfo.name}
                        />
                    )}
                    <div className="editor">{parse(guide.content)}</div>
                </div>

                {guide.guides.data.length > 0 && (
                    <div className="links">
                        <h6>{t('guide.detail.related_links')}</h6>
                        <ul>
                            {guide.guides.data.map((item: any, key: any) => {
                                return (
                                    <li key={key}>
                                        <NavHashLink
                                            to={`${routesPaths.guide.root}/${item.attributes.guide_category.data.attributes.slug}/${item.attributes.slug}-${item.attributes.slugid}`}
                                            scroll={(el) =>
                                                scrollWithOffset(el)
                                            }
                                        >
                                            {item.attributes.title}
                                        </NavHashLink>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}
            </div>
        </>
    );
};

export default CoinDetailPage;
