import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';

const mobileWalletPageCss = css`
    .ant-layout-content {
        position: relative;
        flex-direction: column;
        padding-right: 15px;
        padding-left: 15px !important;
        padding-top: 15px;
        height: inherit !important;
        overflow: inherit !important;
        .ant-layout-sider {
            width: 100% !important;
            flex: 100% !important;
            min-width: 92% !important;
            margin-right: 0px !important;
            position: absolute;
            top: 70px;
            z-index: 999;
            height: inherit !important;
            border-radius: 4px;
            box-shadow: 0px 0px 5px rgb(0, 0, 0, 0.5) !important;
            overflow: hidden !important;
            border: none;
            background-color: ${({ theme }) =>
                theme.colors.color1.base} !important;
            .ant-layout-sider-children {
                div {
                    div {
                        div {
                            &:nth-child(1) {
                                padding-bottom: 0px !important;
                            }
                            &:nth-child(2) {
                                position: static !important;
                            }
                        }
                    }
                }
            }
        }
    }
    &.account-page {
        .ant-layout-content {
            padding-left: 0 !important;
            padding-right: 0 !important;
            .mobile-button-menu {
                margin-left: 15px;
                margin-right: 15px;
            }
        }
    }
`;

export const SideBarScreenLayoutStyled = styled.div`
    .container-children {
        width: 100%;
    }
    .ant-layout-content {
        display: flex;
        margin-top: 24px;
        margin-left: 0 !important;
        height: 100% !important;
        overflow: hidden;
        .ant-layout-sider {
            margin-right: 53px !important;
            display: flex;
            align-items: stretch;
            height: auto;
            min-height: calc(100vh - 77px);

            .ant-layout-sider-children {
                width: 100%;
                .ant-menu-submenu-title {
                    cursor: pointer;
                }
            }

            .ant-menu-inline {
                border-right-color: transparent !important;
                .ant-menu-item {
                    color: ${({ theme }) => theme.colors.color4.base};
                }
            }
        }
    }
    .ant-right-bar {
        width: 100%;
    }
    ${mediaQuery.lessThan('xltablet')`${mobileWalletPageCss}`}
`;

export const RightContent = styled.section`
    width: 100%;
    margin-top: 20px;
    margin-right: 53px;
`;
