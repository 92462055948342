import { fonts } from 'common/theme/font';
import { mediaQuery } from 'common/theme/media';
import styled, { css } from 'styled-components';

import { HelperMessageProps } from './index';

const tabletCss = css``;
const mobileCss = css``;

export const Container = styled.div<HelperMessageProps>`
    display: flex;

    align-items: center;
    font-size: ${fonts.size.small};

    color: ${({ error, theme }) =>
        error ? theme.colors.staticColor.color3.base : ''};

    ${mediaQuery.between('tablet', 'desktop')`${tabletCss}`}
    ${mediaQuery.lessThan('tablet')`${mobileCss}`}
`;
