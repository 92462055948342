import { fonts } from 'common/theme/font';
import { mediaQuery } from 'common/theme/media';
import styled, { css } from 'styled-components';

const mobileModalCardCss = css`
    &.modal-card-container {
        text-align: center;
        .app-icon {
            padding-top: 0px;
        }
        .buttonSession {
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
    }
`;
export const ModalCardStyled = styled.div`
    &.modal-card-container {
        text-align: center;
        .buttonSession {
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
        .app-icon {
            svg {
                color: ${({ theme }) => theme.colors.staticColor.color3.base};
            }
        }

        .title-container {
            padding-bottom: 15px;
            border-bottom: 1px solid ${({ theme }) => theme.colors.color2.base};

            font-size: ${fonts.size.large};
            font-weight: ${fonts.weight.semibold};
            color: ${({ theme }) => theme.colors.color4.base};
        }

        .description-container {
            margin: 15px 20px;
            text-align: left;
            .desc-text {
                padding-bottom: 23px;
            }
            font-size: ${fonts.size.small};
            font-weight: ${fonts.weight.regular};

            color: ${({ theme }) => theme.colors.color4.base};
            strong {
                font-weight: ${fonts.weight.bold};
            }
        }

        .cancel-next-buttons {
            min-height: 60px;
            padding-top: 24px;
            margin: 0px 20px;

            .buttons-container {
                display: flex;
                flex-direction: row;
                float: right;

                .cancel-button {
                    margin-right: 16px;
                }
            }
        }

        .ripple {
            display: flex;
            justify-content: space-between;
            span {
                :first-child {
                    font-weight: bold;
                }
                font-size: 12px;
            }
        }
    }
    ${mediaQuery.lessThan('xltablet')`${mobileModalCardCss}`}
`;
