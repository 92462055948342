import { Col, Row } from 'antd';
import { Button, SvgIcon } from 'components';
import { useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import * as S from './index.style';

interface PlanType {
    discount: string;
    min: string;
    duration: string;
    price: string;
}

const LoyaltyCardsPlanSelection = ({
    isSelect = false,
    data,
    onClick,
}: {
    isSelect: boolean;
    data: PlanType;
    onClick: () => void;
}) => {
    return (
        <S.LoyaltyCardsPlan isSelect={isSelect} onClick={onClick}>
            <div className="head">
                <div className="radio-selection"></div>
                <div className="commission-selection">
                    <div className="discount">
                        {data.discount} Komisyon İndirimi
                    </div>
                    <div className="min-price">Min Tutar: {data.min}</div>
                </div>
            </div>
            <ul className="plan-list">
                <li>Süre - {data.duration} Gun</li>
                <li>Fiyat - {data.price} USDT</li>
            </ul>
            <div className="selected-mark">Seçildi</div>
        </S.LoyaltyCardsPlan>
    );
};

export const LoyaltyCards = () => {
    const [selectedPlan, setSelectedPlan] = useState<number>(0);
    const sources: PlanType[] = [
        { discount: '%5', min: '100K', duration: '30', price: '100' },
        { discount: '%10', min: '100K', duration: '30', price: '150' },
        { discount: '%25', min: '100K', duration: '180', price: '1000' },
    ];

    const handleSelectPlan = useCallback(
        (key: number) => {
            setSelectedPlan(key);
        },
        [setSelectedPlan]
    );
    const { t } = useTranslation();
    return (
        <>
            <Helmet>
                <title>
                    {t('seo.loyalty_cards.title', {
                        appTitle: process.env.REACT_APP_TITLE,
                    })}
                </title>
                <meta
                    name="description"
                    content={t('seo.loyalty_cards.description')}
                ></meta>
                <meta
                    name="keywords"
                    content={t('seo.loyalty_cards.keywords')}
                ></meta>
            </Helmet>
            <S.Container>
                <S.Head>Loyalty Cards</S.Head>

                <Row className="description">
                    <Col span={16}>
                        <div style={{ paddingRight: 30 }}>
                            Loyalty Card satın alarak komisyon indirimi
                            fırsatlarından faydalanabilirsiniz.
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className="loyalty-card-img-container">
                            <img
                                src="/assets/images/loyalty-card.svg"
                                alt="Loyalty Card"
                            />
                        </div>
                    </Col>
                </Row>
                <div className="select-plan-head">Planını Seç:</div>

                <Row gutter={30}>
                    {sources.map((item, key) => {
                        return (
                            <Col span={8} key={key}>
                                <LoyaltyCardsPlanSelection
                                    isSelect={selectedPlan === key}
                                    data={item}
                                    onClick={() => {
                                        handleSelectPlan(key);
                                    }}
                                />
                            </Col>
                        );
                    })}
                </Row>

                <Row className="description">
                    <Col>
                        <S.SubHead>Neden Loyality Card almalısın?</S.SubHead>
                        <div>
                            Loyalty Card satın alarak komisyon indirimi
                            fırsatlarından faydalanabilirsiniz.
                        </div>

                        <div className="notice">
                            <div className="notice-icon">
                                <SvgIcon
                                    name="warning-circle-filled"
                                    size={24}
                                />
                            </div>

                            <div>
                                Aynı anda yalnızca bir adet Loyalty Card
                                kullanabilirsiniz. Birden fazla alımlarda
                                sistem, otomatik olarak değeri daha yüksek olan
                                kartı geçerli sayacaktır.
                            </div>
                        </div>
                        <div className="buy-btn-container">
                            <Button type="primary">Satın Al</Button>
                        </div>
                    </Col>
                </Row>
            </S.Container>
        </>
    );
};
