import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';
import { Layout } from 'antd';
import { fonts } from 'common/theme/font';

const mobileCss = css`
    display: flex;
    .ant-drawer-body {
        .close-button {
            button {
                margin: 10px !important;
            }
            .app-icon {
                color: ${({ theme }) => theme.colors.color2.base} !important;
                font-size: 40px;
                /* font-size: ${fonts.size.large} !important; */
            }
        }
    }
    .header-right-side {
        .account-container-list-item {
            &.wallet-item {
                span {
                    padding: 2px !important;
                }
            }
        }
    }
`;
const tabletCss = css`
    display: flex;
    font-size: 15px !important;
    .ant-drawer-body {
        .close-button {
            .app-icon {
                color: ${({ theme }) => theme.colors.color2.base} !important;
                font-size: 40px;

                /* font-size: ${fonts.size.large} !important; */
            }
        }
    }
    .ant-menu-title-content {
        font-size: ${fonts.size.large};
    }
    .header-right-side {
        .account-container-list-item {
            &.wallet-item {
                span {
                    padding: 8px !important;
                }
            }
        }
    }
`;

export const HeaderMobileStyled = styled(Layout.Header)`
    display: none;
    justify-content: left;
    box-sizing: border-box;
    height: 52px;
    padding: 0 20px;
    background: ${({ theme }) => theme.colors.color1.base};
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);

    .header-left-side {
        flex: 1;
        display: flex;
        align-items: center;

        svg {
            display: inline-block;
            // width: 120px;
        }
    }

    .header-right-side {
        display: flex;
        align-items: center;
        .hamburger-menu {
            position: relative;
            width: 18px;
            height: 14px;

            .line {
                display: block;
                width: 100%;
                height: 2px;
                border-radius: 50%;
                background-color: ${({ theme }) => theme.colors.color4.base};
                &:before,
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    background-color: ${({ theme }) =>
                        theme.colors.color4.base};
                }
                &:before {
                    top: 0;
                }
                &:after {
                    bottom: 0;
                }
            }
        }
        .register-btn {
            margin-right: 24px;
        }
        .login-btn {
            margin: 0px 24px;
            & > span {
                color: ${({ theme }) => theme.colors.color4.base} !important;
            }
        }

        .account-container-list-item {
            display: flex;
            height: 100%;
            align-items: center;
            padding: 0px 12px;

            &:not(:first-child) {
                border-left: 1px solid
                    ${({ theme }) => theme.colors.color2.base};
            }
            &.hamburger-item {
                padding-right: 0;
            }
            .ant-btn {
                .app-icon {
                    svg {
                        color: ${({ theme }) =>
                            theme.colors.color4.base} !important;
                    }
                }
                &:hover {
                    .app-icon {
                        svg {
                            color: ${({ theme }) =>
                                theme.colors.staticColor.color2
                                    .base} !important;
                        }
                    }
                }
            }

            &.wallet-item {
                .ant-btn {
                    & > span {
                        color: ${({ theme }) =>
                            theme.colors.color4.base} !important;
                    }

                    .app-icon {
                        margin-right: 0;
                        svg {
                            font-size: ${fonts.size.xlarge};
                            color: ${({ theme }) =>
                                theme.colors.color4.base} !important;
                        }
                    }
                }
            }
            &.account-item {
                padding: 0 !important;
                .ant-btn {
                    width: inherit;
                    padding: 0 20px;
                    height: 100%;
                    background-color: transparent;
                    .app-icon {
                        svg {
                            font-size: ${fonts.size.xlarge};
                        }
                    }
                }
            }
        }
    }

    .ant-menu {
        line-height: 52px !important;
        flex: 1;
        border-bottom: none !important;

        .app-icon {
            background-color: ${({ theme }) =>
                theme.colors.staticColor.color2.opacity2} !important;
            border-radius: 1rem;

            svg {
                color: ${({ theme }) =>
                    theme.colors.staticColor.color2.base}!important;
            }
        }
    }
    ${mediaQuery.lessThan('tablet')`${mobileCss}`}
    ${mediaQuery.between('mobile', 'desktop')`${tabletCss}`}
`;

export const HeaderMenuDrawerCss = css`
    .ant-drawer {
        .ant-drawer-content {
            background-color: ${({ theme }) => theme.colors.color1.base};
        }
        .ant-drawer-content-wrapper {
            width: 60% !important;
        }
        .ant-drawer-body {
            .select-warapper {
                margin: 0px 10px 10px 10px;
                width: 80px;
                .ant-select-selector {
                    border-color: transparent;
                    .ant-select-selection-item {
                        font-weight: 500;
                    }
                }
            }
            overflow: hidden;
            display: flex;
            flex-direction: column;
            position: relative;
            padding-left: 0 !important;
            padding-right: 0 !important;
            padding: 50px 10px;
            .close-btn {
                position: absolute;
                top: 5px;
                right: 0;
                svg {
                    color: ${({ theme }) =>
                        theme.colors.color2.base} !important;
                }
            }
            > .ant-menu {
                margin-top: 20px;
                border-right: none;
                .ant-menu-submenu {
                    .ant-menu-item {
                        padding-left: 35px !important;
                    }
                }
                .ant-menu-item {
                    font-weight: ${fonts.weight.medium};
                }
            }
            .buttons-application {
                position: absolute;
                bottom: 50px;
                right: 0;
                left: 0;
                margin: 0 auto;
                text-align: center;
                .buttons {
                    .ant-btn {
                        width: 161px;
                        margin: 0 auto;
                    }
                    .ant-btn-link {
                        margin: 10px 0;
                    }
                }

                .application {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    a {
                        border-right: solid 1px rgb(89 90 91 / 30%);
                        padding-right: 10px;
                        margin-right: 10px;
                        &:last-child {
                            border-right: none;
                            padding-right: 0;
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }

    ${mediaQuery.between('mobile', 'desktop')`${tabletCss}`}
`;
