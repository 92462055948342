import { HeaderMobileStyled } from './index.styles';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/button';
import { IconButton } from 'components/icon-button';
import { Logo } from 'components/logo';
import { useNavigate } from 'react-router-dom';
import { languages, routePathLang2, routesPaths } from 'config/routes';
import { Drawer, Dropdown } from 'antd';
import { WalletDropdown } from 'widgets/wallet-dropdown';
import { ProfileDropdown } from 'widgets/profile-dropdown';
import { useAuthentication } from 'common/auth/AuthenticationProvider';
import {
    HeaderMobileMenu,
    MobileMenuItemProps,
} from 'components/header-mobile-menu';
import { getLanguage, getLocalLanguage, setLanguage } from 'common/utils';
// import Select from 'components/Select/Select';
import useAuthApi from 'api/auth/auth.api';
import Select from 'components/Select/Select';
import { SvgIcon } from 'components/svg-icon';

export interface HeaderMobileProps {
    isLoggedIn?: boolean;
}
export const languageList = [
    {
        key: 'tr',
        name: 'TR',
    },
    {
        key: 'en',
        name: 'EN',
    },
    {
        key: 'de',
        name: 'DE',
    },
];

const WrappedHeaderMobile = ({ isLoggedIn = false }: HeaderMobileProps) => {
    const [currentLanguage, setCurrentLanguage] = useState<any>(getLanguage());
    const { userLanguage } = useAuthApi();

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const { isAccountVerified, isAuthenticated } = useAuthentication();
    // const unit = useMemo(() => {
    //     return currentBalance?.unit;
    // }, []);
    // const totalUnit = useMemo(() => {
    //     return currentBalance?.totalUnit;
    // }, []);

    const menuItems = useMemo((): MobileMenuItemProps[] => {
        const menu: MobileMenuItemProps[] = [
            {
                title: t('header.mainMenu.market'),
                link: routesPaths.public.marketBasic + '/btc-try',
            },
            {
                title: t('header.mainMenu.buySell'),
                link: routesPaths.public.buysell,
            },
            {
                title: t('header.mainMenu.aboutus'),
                link: routesPaths.public.aboutUs,
            },
            // {
            //     title: t('header.mainMenu.reference'),
            //     link: routesPaths.public.reference,
            // },
            {
                title: t('header.mainMenu.support'),
                link: routesPaths.public.support,
            },
            {
                title: t('header.mainMenu.Blog'),
                link: routesPaths.blog.root,
            },
        ];
        if (isAccountVerified) {
            menu.push({
                title: t('header.mainMenu.depoistWithdraw'),
                link: routesPaths.operations.deposit,
            });
        }
        return menu;
    }, [t]);

    const handleOpenSideMenu = useCallback(() => {
        setVisible(true);
    }, []);

    const handleCloseSideMenu = () => {
        setVisible(false);
    };

    const handleNavLoginPage = useCallback(() => {
        navigate(routesPaths.authorization.login);
    }, []);

    const handleNavRegisterPage = useCallback(() => {
        navigate(routesPaths.authorization.register);
    }, []);

    const handleNavHomePage = useCallback(() => {
        navigate(routesPaths.root);
    }, []);

    let accountContainer = (
        <>
            <Button
                type="primary"
                className="register-btn"
                onClick={handleNavRegisterPage}
            >
                {t('header.authBtn.register')}
            </Button>
            <Button
                type="link"
                className="hamburger-menu"
                onClick={handleOpenSideMenu}
            >
                <span className="line"></span>
            </Button>
        </>
    );
    if (isLoggedIn) {
        accountContainer = (
            <>
                <div className="account-container-list-item account-item">
                    <Dropdown
                        getPopupContainer={() =>
                            document.getElementById('yuex-mobile-header-ref') ||
                            document.body
                        }
                        overlay={
                            <ProfileDropdown
                                activeLevel="Level1"
                                setLevel="Level2"
                                referanceId={3285756544}
                            />
                        }
                    >
                        <IconButton icon="user-circle-filled"></IconButton>
                    </Dropdown>
                </div>
                {isAccountVerified && (
                    <div className="account-container-list-item wallet-item">
                        <Dropdown
                            overlay={<WalletDropdown />}
                            getPopupContainer={() =>
                                document.getElementById(
                                    'yuex-mobile-header-ref'
                                ) || document.body
                            }
                        >
                            <Button type="link" prefixIcon="wallet">
                                {/* {NumberFormat(
                                    totalUnit !== undefined ? totalUnit : ''
                                )}{' '}
                                {unit} */}
                            </Button>
                        </Dropdown>
                    </div>
                )}
                <div className="account-container-list-item hamburger-item">
                    <Button
                        type="link"
                        className="hamburger-menu"
                        onClick={handleOpenSideMenu}
                    >
                        <span className="line"></span>
                    </Button>
                </div>
            </>
        );
    }
    const clickLocalStorageLanguage = useCallback(async (lang: any) => {
        setCurrentLanguage(lang);
        setLanguage(lang);
        // routePathLang(lang);

        routePathLang2(lang);
    }, []);
    useEffect(() => {
        const url = window.location.pathname.split('/');
        if (languages.includes(url[1])) {
            clickLocalStorageLanguage(currentLanguage);
        } else {
            clickLocalStorageLanguage(getLocalLanguage());
        }
    }, []);
    return (
        <HeaderMobileStyled
            className="header-mobile"
            id="yuex-mobile-header-ref"
        >
            <Drawer
                // getContainer={false}
                placement="right"
                closable={false}
                onClose={handleCloseSideMenu}
                visible={visible}
            >
                <div className="close-btn">
                    <Button type="link" onClick={handleCloseSideMenu}>
                        <SvgIcon name="times-circle-filled" size={24}></SvgIcon>
                    </Button>
                </div>

                <HeaderMobileMenu
                    items={menuItems}
                    onCloseDrawer={handleCloseSideMenu}
                />
                <div>
                    {
                        <Select
                            value={currentLanguage}
                            options={languageList?.map((item) => {
                                return {
                                    value: item.key,
                                    label: item.name,
                                };
                            })}
                            dropdownStyle={{ maxWidth: '80px' }}
                            placement="bottomRight"
                            optionFilterProp="label"
                            optionLabelProp="label"
                            onChange={(e) => {
                                isLoggedIn &&
                                    userLanguage({ language: String(e) });

                                const url = window.location.pathname.split('/');
                                url[1] = String(e);
                                setTimeout(() => {
                                    window.location.href = url
                                        .join()
                                        .replaceAll(',', '/');
                                });
                            }}
                        />
                    }
                </div>

                <div className="buttons-application">
                    {!isAuthenticated && (
                        <div className="buttons">
                            <Button
                                type="primary"
                                className="register-btn"
                                onClick={handleNavRegisterPage}
                            >
                                {t('header.authBtn.register')}
                            </Button>
                            <Button
                                type="link"
                                className="register-btn"
                                onClick={handleNavLoginPage}
                            >
                                {t('header.authBtn.login')}
                            </Button>
                        </div>
                    )}

                    <div className="application">
                        <a href="javascipt:;">
                            <img
                                src="/assets/images/home-page/app-store.svg"
                                alt=""
                            />
                        </a>
                        <a href="javascipt:;">
                            <img
                                src="/assets/images/home-page/play-store.svg"
                                alt=""
                            />
                        </a>
                    </div>
                </div>
            </Drawer>
            <div className="header-left-side">
                <Logo type="logo" onClick={handleNavHomePage}></Logo>
            </div>
            <div className="header-right-side">{accountContainer}</div>
        </HeaderMobileStyled>
    );
};

export const HeaderMobile = React.memo(WrappedHeaderMobile);
