const balanceApiEndpoints = {
    limits: '/blc/balance/limits',
    addressibanadd: '/blc/withdrawal/saveaddr',
    addressgetlist: '/blc/withdrawal/listaddr',
    addressdelete: '/blc/withdrawal/deleteaddr',
    now: '/mc/balance/now',
    balanceGraphic: '/mc/balance/graphic',
    getWithdrawalFee: '/blc/withdrawal/fee',
    startWithdrawalProcess: '/blc/withdrawal/create',
    sendEmailVerificationCode: '/blc/withdrawal/sendconfirmmail',
    confirmWithdraw: '/blc/withdrawal/confirm',
    getAddressDetail: '/blc/address',
    historydelete: '/blc/withdrawal/cancel',
    withdrawalControl: '/blc/withdrawal/control',
};

export default balanceApiEndpoints;
