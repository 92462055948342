import { createContext, useCallback, useContext, useEffect } from 'react';
import useAuthApi from 'api/auth/auth.api';
import { useCommonSource } from 'api/common/common.api.context';
import { AuthUser } from 'api/auth/auth.model';
import { BalanceNowResponseData } from 'api/balance/balance.model';
import useBalanceApi from 'api/balance/balance.api';
import { useImmer } from 'use-immer';
import { detailedDiff } from 'deep-object-diff';
import { changeLangUrl, setLanguage, sleep } from 'common/utils';
import useCommonApi from 'api/common/common.api';
import { showSuccessNotification } from 'common/utils/UserNotifications';
import { appNotificationSocket, NotificationEvent } from 'common/socket';
import { useWarningModal } from 'common/hooks/warning-card-modal';
import { useNavigate } from 'react-router-dom';
import { routesPaths } from 'config/routes';
import { useIdleTimer } from 'react-idle-timer';
import { mappingBalanceNow } from 'api/balance/balance.mapping';
import { t } from 'i18next';
interface AuthenticationProviderProps {
    children: any;
}

interface AuthenticationProviderState {
    isLoading?: boolean;
    isAuthenticated?: boolean;
    isAccountVerified?: boolean;
    options: AuthOptions;
    user?: AuthUser;
    currentBalance?: BalanceNowResponseData;
    logout?: () => void;
    checkAuth?: (states?: string) => void;
    updateAuthOptions: (
        key: keyof AuthOptions,
        value: AuthOptions[keyof AuthOptions]
    ) => void;
    loginCheckout?: () => void;
    noticeBalance?: BalanceNowResponseData;
}

const INITIAL_VALUE = {
    isLoading: true,
    isAuthenticated: false,
    options: {},
};

interface AuthOptions {
    isHiddenAccount?: boolean;
}

const LOCALSTORAGE_KEY = 'yuex.options';

export const AuthenticationContext =
    createContext<Partial<AuthenticationProviderState>>(INITIAL_VALUE);

export const useAuthentication = () => useContext(AuthenticationContext);

const AuthenticationProvider = ({ children }: AuthenticationProviderProps) => {
    const { getLastActiveTime } = useIdleTimer({});
    const navigate = useNavigate();
    const { isReadyApp } = useCommonSource({});
    const { ping } = useCommonApi();
    const [state, setState] = useImmer<AuthenticationProviderState>(
        INITIAL_VALUE as AuthenticationProviderState
    );

    const { user: fetchUserApi, logout, userLanguage } = useAuthApi();
    const { currentBalance: fetchCurrentBalance } = useBalanceApi();

    const updateCurrenBalance = async (
        currentBalance: BalanceNowResponseData
    ) => {
        setState((draft) => {
            let prevBalanceStr: string = JSON.stringify(draft.currentBalance);
            let prevBalace;
            if (prevBalanceStr) {
                prevBalace = JSON.parse(prevBalanceStr);
            }
            const diff: any = detailedDiff(prevBalace || {}, currentBalance);

            if (
                !(
                    Object.keys(diff.added).length === 0 &&
                    Object.keys(diff.deleted).length === 0 &&
                    Object.keys(diff.updated).length === 0
                )
            ) {
                draft.currentBalance = currentBalance;
            }
        });
    };

    let loginSessionModal = useWarningModal({
        onClose: async () => {
            loginSessionModal.closeModal();
        },
        loginSession: true,
    });

    const loginCheckout = useCallback(async () => {
        loginSessionModal.openWarningModal();
    }, [useAuthentication]);

    const cookieControl = async () => {
        let cookie: any = {};
        document.cookie.split(';').forEach(function (el) {
            let [key, value] = el.split('=');
            cookie[key.trim()] = value;
        });

        const differenceTime = Math.abs(
            new Date().valueOf() - cookie.tokenTime
        );
        var minutes = Math.floor(differenceTime / 1000 / 60);
        return minutes;
    };
    const handleIdleTimer = useCallback(async () => {
        let cookie: any = {};
        document.cookie.split(';').forEach(function (el) {
            let [key, value] = el.split('=');
            cookie[key.trim()] = value;
        });
        const minute = await cookieControl();
        if (cookie.tokenTime !== '0' && minute > 0) {
            useEffectCallBack(minute);
        }
        await sleep(25000);
        await handleIdleTimer();
    }, [state.isAuthenticated]);

    const checkoutBalance = useCallback(async () => {
        try {
            if (state.isAuthenticated && state.user?.userState === 4) {
                const test = await fetchUserApi();
                if (test !== null) {
                    const currentBalance = await fetchCurrentBalance();
                    await updateCurrenBalance(currentBalance);
                    // const minute = await cookieControl();
                    // minute > 0 && useEffectCallBack(minute);

                    // await sleep(30000);

                    // await checkoutBalance();
                } else {
                    let cookie: any = {};
                    document.cookie.split(';').forEach(function (el) {
                        let [key, value] = el.split('=');
                        cookie[key.trim()] = value;
                    });
                    if (cookie.tokenTime !== '0') {
                        checkAuth();
                        loginCheckout();
                        await ping();
                        navigate(routesPaths.authorization.login);
                    }
                }
            }
        } catch (err: any) {}
    }, [state.isAuthenticated]);

    const getInitBalance = useCallback(async () => {
        const currentBalance = await fetchCurrentBalance();
        setState((draft) => {
            draft.isLoading = false;
            draft.currentBalance = currentBalance;
        });
    }, [fetchCurrentBalance, setState]);

    // const location = useLocation();
    const getNoticeBalance = useCallback((data: any) => {
        setState((draft) => {
            draft.currentBalance = mappingBalanceNow(data);
        });
    }, []);
    useEffect(() => {
        appNotificationSocket.subscribe(
            NotificationEvent.UsableBalance,
            getNoticeBalance
        );
    }, [appNotificationSocket]);
    const connectSocket = useCallback(() => {
        if (!appNotificationSocket.connected) {
            appNotificationSocket.subscribe(
                NotificationEvent.UsableBalance,
                getNoticeBalance
            );
            appNotificationSocket.subscribe(
                NotificationEvent.Notification,
                (data) => {
                    if (data.action !== 'order_status_change_8') {
                        showSuccessNotification(data.content, '', 2);
                    }
                }
            );

            appNotificationSocket.openConnection();
        }
    }, [appNotificationSocket]);

    const fetchUser = useCallback(async () => {
        setState((draft) => {
            draft.isLoading = true;
        });

        const user = await fetchUserApi();

        if (user) {
            const options: AuthOptions | undefined = JSON.parse(
                localStorage.getItem(LOCALSTORAGE_KEY) as never
            );

            setState((draft) => {
                draft.isLoading = false;
                draft.isAuthenticated = true;
                draft.isAccountVerified = user?.userState === 4 ? true : false;
                draft.user = user;
                draft.options = options || {};
            });
            setTimeout(() => {
                if (state.isAuthenticated && state.user?.userState === 4) {
                    connectSocket();
                }
                checkoutBalance();
                handleIdleTimer();
            });
        } else {
            setState((draft) => {
                draft.isLoading = false;
                draft.isAuthenticated = false;
                draft.user = undefined;
                draft.isAccountVerified = undefined;
                draft.currentBalance = undefined;
                draft.options = {};
            });
        }
    }, [
        setState,
        checkoutBalance,
        fetchUserApi,
        connectSocket,

        state.isAuthenticated,
    ]);

    const checkAuth = useCallback(
        async (states: string = '') => {
            setState((draft) => {
                draft.isLoading = true;
            });

            const user = await fetchUserApi();

            if (user) {
                const options: AuthOptions | undefined = JSON.parse(
                    localStorage.getItem(LOCALSTORAGE_KEY) as never
                );
                if (states !== '') {
                    if (user?.userState !== 4) {
                        window.location.href =
                            window.location.origin +
                            '/' +
                            user.language +
                            '/account/verification';
                        // navigate(routesPaths.account.verification);
                    } else {
                        if (states === '/') {
                            navigate(states);
                        } else {
                            window.location.href = changeLangUrl(
                                states,
                                user.language
                            );
                        }
                    }
                }
                setState((draft) => {
                    draft.isLoading = false;
                    draft.isAuthenticated = true;
                    draft.isAccountVerified =
                        user?.userState === 4 ? true : false;
                    draft.user = user;
                    draft.currentBalance = undefined;
                    draft.options = options || {};
                });

                userLanguage({ language: user.language });
                setLanguage(user.language);
                localStorage.setItem(
                    'userSessionTimeOut',
                    user?.sessionTimeout.toString()
                );

                getInitBalance();
            } else {
                localStorage.removeItem(LOCALSTORAGE_KEY);
                setState((draft) => {
                    draft.isLoading = false;
                    draft.isAuthenticated = false;
                    draft.user = undefined;
                    draft.isAccountVerified = undefined;
                    draft.currentBalance = undefined;
                    draft.options = {};
                });
            }
        },
        [setState, fetchUserApi, getInitBalance, appNotificationSocket]
    );

    const handleLogout = useCallback(async () => {
        await logout();
        localStorage.removeItem(LOCALSTORAGE_KEY);
        await ping();
        await checkAuth();
        showSuccessNotification(t('logout.message.title'), '', 1);
        appNotificationSocket.closeConnection();
    }, [logout, checkAuth, checkoutBalance, ping, appNotificationSocket]);

    const updateAuthOptions = useCallback(
        (key: keyof AuthOptions, value: AuthOptions[keyof AuthOptions]) => {
            const currentOptions: AuthOptions =
                JSON.parse(
                    localStorage.getItem(LOCALSTORAGE_KEY) as unknown as string
                ) || {};

            currentOptions[key] = value;

            localStorage.setItem(
                LOCALSTORAGE_KEY,
                JSON.stringify(currentOptions)
            );

            setState((draft) => {
                draft.options = currentOptions;
            });
        },
        [setState]
    );

    const useEffectCallBack = useCallback(async (minute: number) => {
        const lastDate = getLastActiveTime();

        const differentMinute = new Date(
            new Date().getTime() - (lastDate ? lastDate.getTime() : 0)
        ).getMinutes();
        const date = new Date().valueOf();

        const domainArray = window.location.hostname.split('.');
        const domain =
            domainArray.length !== 3
                ? window.location.hostname
                : domainArray.length === 3
                ? '.' + domainArray[1] + '.' + domainArray[2]
                : '.' + domainArray[0] + '.' + domainArray[1];
        if (differentMinute >= 30 || minute >= 1440) {
            //30 dakika hareketsiz kalırsa
            // 1 gün boyunca tarayıcı kapalı kalırsa çalışır
            await handleLogout();

            await loginCheckout();
            navigate(routesPaths.authorization.login);

            document.cookie = `tokenTime=0;domain=${domain};path=/`;
        } else {
            if (minute > 110) {
                //Back end deki süreden 10 dakika önce

                await ping();

                document.cookie = `tokenTime=${date};domain=${domain};path=/`;
            }
        }
    }, []);

    useEffect(() => {
        if (isReadyApp) {
            fetchUser();
        }
    }, [fetchUser, isReadyApp]);

    // useEffect(() => {
    //     if (isReadyApp) {
    //         fetchUser();
    //     }
    // }, [fetchUser, isReadyApp]);

    return (
        <>
            <AuthenticationContext.Provider
                value={{
                    isLoading: state.isLoading,
                    isAuthenticated: state.isAuthenticated,
                    isAccountVerified: state.isAccountVerified,
                    user: state.user,
                    currentBalance: state.currentBalance,
                    options: state.options,
                    logout: handleLogout,
                    loginCheckout: loginCheckout,
                    checkAuth,
                    updateAuthOptions,
                    noticeBalance: state.currentBalance,
                }}
            >
                {children}
            </AuthenticationContext.Provider>
            {loginSessionModal.placeholder()}
        </>
    );
};

export default AuthenticationProvider;
