import { mediaQuery } from 'common/theme/media';
import { css } from 'styled-components';

const mobileCss = css`
    .change-password-2fa-confirm-modal {
        .ant-modal-content {
            /* max-width: 360px !important; */
            width: 100%;
            max-width: 340px !important;
            .ant-modal-body {
                margin: 0px !important;
                padding: 20px 0px !important;
            }
        }
    }
`;
const tabletCss = css`
    .change-password-2fa-confirm-modal {
        .ant-modal-content {
            width: 100%;

            .ant-modal-body {
                margin: 0px !important;
                padding: 20px 0px !important;
            }
        }
    }
`;
export const ChangePassword2FAConfirmModalCss = css`
    .change-password-2fa-confirm-modal {
        .ant-modal-content {
            max-width: 360px !important;
            width: 100%;

            .ant-modal-body {
                margin: 0px !important;
                padding: 20px 0px !important;
            }
        }
    }
    ${mediaQuery.between('tablet', 'desktop')`${tabletCss}`}
    ${mediaQuery.lessThan('mobile')`${mobileCss}`}
`;
