import { fonts } from 'common/theme/font';
import styled from 'styled-components';

export const Container = styled.div`
    .verification-code-input {
        .verification,
        .code {
            .form-item {
                padding: 25px 0;
                position: relative;
                .send-code-info,
                .remaining-time {
                    position: absolute;
                }
                .send-code-info {
                    right: 0;
                    top: 0;

                    .ant-row {
                        display: flex;
                        justify-content: flex-end;
                    }
                    .ant-btn {
                        height: inherit;
                        span {
                            font-size: ${fonts.size.small};
                        }
                    }
                    .code-sent {
                        font-size: ${fonts.size.small};
                        color: ${({ theme }) =>
                            theme.colors.staticColor.color2.base};
                    }
                }
                .remaining-time {
                    bottom: 0;
                    left: 0;
                    font-size: ${fonts.size.small};
                    .text {
                        color: ${({ theme }) => theme.colors.color4.base};
                    }
                    .timer {
                        margin-left: 5px;
                        color: ${({ theme }) =>
                            theme.colors.staticColor.color2.base};
                    }
                }
            }
            .code-label-container {
                position: absolute;
                top: 0;
                left: 0;
                .code-label {
                    font-size: ${fonts.size.small};
                    font-weight: ${fonts.weight.medium};
                    color: ${({ theme }) => theme.colors.color4.base};
                }
            }
        }
    }
`;
