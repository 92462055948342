import styled from 'styled-components';
import { Card } from 'antd';
import { fonts } from '../../common/theme/font';

export const CardStyled = styled(Card)`
    .ant-card-head-wrapper {
        display: flex;
        justify-content: space-between;

        .ant-card-extra {
            a.primary-link {
                color: ${({ theme }) => theme.colors.staticColor.color2.base};
                font-weight: ${fonts.weight.regular};
            }
        }
    }

    p.card-text {
        color: ${({ theme }) => theme.colors.color4.base};
    }

    &.card-app-item {
        font-weight: ${fonts.weight.bold};
        color: ${({ theme }) => theme.colors.color4.base};
        background: ${({ theme }) => theme.colors.color1.base};
        padding: 20px;
        /* box-shadow: 0px 4px 20px rgba(221, 221, 221, 0.16); */
        border-radius: 6px;
        border-color: ${({ theme }) => theme.colors.color2.base};

        .ant-card-body {
            height: 100%;
            width: 100%;
            background: ${({ theme }) => theme.colors.color1.base};
            border-radius: 6px;
            padding: 20px;
            margin-top: 20px;

            .ant-row {
                height: 100%;
            }
        }
    }

    span.card-label-item {
        color: ${({ theme }) => theme.colors.color3.base};
        font-size: ${fonts.size.small};
        font-weight: ${fonts.weight.semibold};

        &.has-item {
            display: flex;
            align-items: center;
        }
    }

    span.card-text-xSmall-item {
        font-size: ${fonts.size.xSmall};
        font-weight: ${fonts.weight.regular};
    }

    span.card-text-small-item {
        font-size: ${fonts.size.small};
        font-weight: ${fonts.weight.regular};
    }

    span.btn-change {
        background: ${({ theme }) => theme.colors.staticColor.color2.base};
        box-shadow: 8px 0px 16px rgba(221, 221, 221, 0.16);
        border-radius: 3px;
        padding: 1px 7px;
        margin-bottom: 2px;
        margin-left: 5px;
        color: ${({ theme }) => theme.colors.staticColor.color1.base};
        font-size: ${fonts.size.medium};
        font-weight: ${fonts.weight.regular};
        cursor: pointer;
    }
`;
