import { Row, Col } from 'antd';
import { Container } from './index.styles';
import { useTranslation } from 'react-i18next';

const WorkWithUs = () => {
    const { t } = useTranslation();
    return (
        <>
            <Container>
                <div className="title-container">
                    <h2 className="title">{t('career.work_with_us.title')}</h2>
                    <hr />
                </div>
                <Row>
                    <Col span={12} className="image-col">
                        <div className="image-links">
                            <figure className="image">
                                <img
                                    src="/assets/images/career/employees.svg"
                                    alt=""
                                />
                            </figure>
                        </div>
                    </Col>
                    <Col span={12} className="image-col">
                        <div className="our-vision">
                            <div className="our-vision-header">
                                <img
                                    src="/assets/images/career/work-with-us.svg"
                                    alt=""
                                />
                                <h3>{t('career.work_with_us.vision.title')}</h3>
                            </div>
                            <ul className="our-vision-list">
                                <li className="text">
                                    {t('career.work_with_us.vision.list1')}
                                </li>
                                <li className="text">
                                    {t('career.work_with_us.vision.list2')}
                                </li>
                                <li className="text">
                                    {t('career.work_with_us.vision.list3')}
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default WorkWithUs;
