import { HeaderStyled } from './index.styles';

import {
    NotificationRequest,
    NotificationResponse,
} from 'api/support/support.model';
import useSupportApi from 'api/support/support.api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTheme } from 'common/theme/ThemeProvider';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/button';
import { IconButton } from 'components/icon-button';
import { SvgIcon } from 'components/svg-icon';
import { Logo } from 'components/logo';
import { HeaderMenu, HeaderMenuItemProps } from 'components/header-menu';
import { useLocation, useNavigate } from 'react-router-dom';
import { languages, routePathLang2, routesPaths } from 'config/routes';
import { Dropdown } from 'antd';
import { WalletDropdown } from 'widgets/wallet-dropdown';
import { ProfileDropdown } from 'widgets/profile-dropdown';
import { useAuthentication } from 'common/auth/AuthenticationProvider';
import {
    getLanguage,
    getLocalLanguage,
    NumberFormat,
    setLanguage,
} from 'common/utils';
import { NotificationDropdown } from 'widgets/notifications-dropdown';
import useAnnouncementApi from 'api/announcement/announcement.api';
import Select from 'components/Select/Select';
import useAuthApi from 'api/auth/auth.api';
import useCommonApi from 'api/common/common.api';
//import { getHeaderMenuRedirectLink } from 'common/utils';
// import { Avatar, Badge } from 'antd';

export interface HeaderProps {
    isLoggedIn?: boolean;
}

const secretBalance = '*********';
export const languageList = [
    {
        key: 'tr',
        name: 'TR',
    },
    {
        key: 'en',
        name: 'EN',
    },
    {
        key: 'de',
        name: 'DE',
    },
];

const WrappedHeader = ({ isLoggedIn = false }: HeaderProps) => {
    const { notificationList } = useSupportApi();
    const { userLanguage } = useAuthApi();
    const navigate = useNavigate();
    const location = useLocation();
    const [pathname, setPathname] = useState<string>(location.pathname);
    const [currentLanguage, setCurrentLanguage] = useState<any>(getLanguage());

    const { changeTheme, theme } = useTheme();
    const { t } = useTranslation();
    const {
        currentBalance,
        isAccountVerified,
        // isAuthenticated,
        options: authOptions,
        loginCheckout,
        checkAuth,
    } = useAuthentication();
    const { ping } = useCommonApi();
    const clickLocalStorageLanguage = useCallback(async (lang: any) => {
        setCurrentLanguage(lang);
        setLanguage(lang);
        // routePathLang(lang);

        routePathLang2(lang);
    }, []);
    const { isLoading } = useAuthentication();

    const [isLoad, setIsLoad] = useState<any>(isLoading);

    useEffect(() => {
        setCurrentLanguage(getLanguage());
    }, [isLoggedIn, isLoading]);

    useEffect(() => {
        const url = window.location.pathname.split('/');
        if (languages.includes(url[1])) {
            clickLocalStorageLanguage(currentLanguage);
        } else {
            clickLocalStorageLanguage(getLocalLanguage());
        }
    }, []);
    useEffect(() => {
        setPathname(location.pathname);
    }, [location.pathname]);

    //Bildirimleri getiren servis artık dropdown widget'ında çağrılmayıp burada çağrılıyor.Burda dönen data prop olarak
    //dropdown widget ına gönderiliyor.
    //Login olup olmama durumuna göre kontrol kaldırıldı.Çünkü duyurular genel duyurular olarak görüldüğü için.
    useEffect(() => {
        if (!isLoading) {
            fetchNotificationList();
        }
    }, [isLoading, currentLanguage]);

    const [data, setData] = useState<NotificationResponse>();

    const fetchNotificationList = useCallback(async () => {
        try {
            const response = await notificationList({
                locale: currentLanguage,
                limit: 15,
            } as unknown as NotificationRequest);
            setData(response);
        } catch (error: any) {
            if (error?.status === 403) {
                checkAuth && checkAuth();
                loginCheckout && loginCheckout();
                await ping();
                navigate(routesPaths.authorization.login);
                const domainArray = window.location.hostname.split('.');
                const domain =
                    domainArray.length !== 3
                        ? window.location.hostname
                        : domainArray.length === 3
                        ? '.' + domainArray[1] + '.' + domainArray[2]
                        : '.' + domainArray[0] + '.' + domainArray[1];
                document.cookie = `tokenTime=0;domain=${domain};path=/`;
            }
        }
    }, [currentLanguage]);

    const menuItems = useMemo((): HeaderMenuItemProps[] => {
        const menu: HeaderMenuItemProps[] = [
            {
                title: t('header.mainMenu.market'),
                redirectLink: `${routesPaths.public.marketBasic}/btc-try`,
                subTitles: [
                    {
                        title: t('header.mainMenu.marketBasic'),
                        redirectLink: `${routesPaths.public.marketBasic}/btc-try`,
                        icon: 'change',
                    },
                    {
                        title: t('header.mainMenu.marketPro'),
                        redirectLink: `${routesPaths.public.marketPro}/btc-try`,
                        icon: 'advanced-mode',
                    },
                ],
            },
            {
                title: t('header.mainMenu.buySell'),
                redirectLink: routesPaths.public.buysell,
            },
        ];

        if (isAccountVerified) {
            menu.push({
                title: t('header.mainMenu.depoistWithdraw'),
                redirectLink: routesPaths.operations.deposit,
            });
        }

        menu.push({
            title: t('header.mainMenu.aboutus'),
            redirectLink: routesPaths.public.aboutUs,
        });
        // menu.push({
        //     title: t('header.mainMenu.reference'),
        //     redirectLink: isAuthenticated
        //         ? routesPaths.account.reference
        //         : routesPaths.public.reference,
        // });
        menu.push({
            title: t('header.mainMenu.support'),
            redirectLink: routesPaths.public.support,
        });

        if (currentLanguage == 'tr') {
            menu.push({
                title: t('header.mainMenu.Blog'),
                redirectLink: routesPaths.blog.root,
            });
        }

        return menu;
    }, [isAccountVerified, currentLanguage]);

    const onMenuItemClick = useCallback((item: any) => {
        navigate(item.key);
    }, []);

    const handleNavLoginPage = useCallback(() => {
        navigate(routesPaths.authorization.login, {
            state: {
                redirectURI: location.pathname,
            },
        });
    }, [location]);

    const handleNavRegisterPage = useCallback(() => {
        navigate(routesPaths.authorization.register);
    }, []);

    const handleNavHomePage = useCallback(() => {
        navigate(routesPaths.root);
    }, []);
    const handleAnnouncementPage = useCallback(() => {
        navigate(routesPaths.announcement.root);
    }, []);

    const { readAnnouncement } = useAnnouncementApi();
    const fetchReadAnnouncement = useCallback(async () => {
        await readAnnouncement();
    }, [currentLanguage]);
    useEffect(() => {
        setTimeout(() => {
            setIsLoad(false);
        }, 1000);
    }, []);
    return (
        <HeaderStyled id="yuex-header-ref">
            {!isLoad ? (
                <div className="header-desktop" key={currentLanguage}>
                    <div className="header-left-side">
                        <Logo type="logo" onClick={handleNavHomePage}></Logo>
                        <HeaderMenu
                            defaultSelectedKeys={[pathname]}
                            selectedKeys={[pathname]}
                            items={menuItems}
                            onClick={onMenuItemClick}
                        />
                    </div>
                    <div className="header-right-side">
                        {!isLoggedIn && (
                            <>
                                <Button
                                    type="link"
                                    className="login-btn"
                                    onClick={handleNavLoginPage}
                                >
                                    {t('header.authBtn.login')}
                                </Button>
                                <Button
                                    type="primary"
                                    className="register-btn"
                                    onClick={handleNavRegisterPage}
                                >
                                    {t('header.authBtn.register')}
                                </Button>
                                <div className="account-container-list-item change-theme">
                                    <Button
                                        type="link"
                                        onClick={() => changeTheme()}
                                    >
                                        <SvgIcon
                                            name={
                                                theme?.selectedTheme == 'light'
                                                    ? 'dark-theme'
                                                    : 'light-theme'
                                            }
                                        ></SvgIcon>
                                    </Button>
                                </div>
                                {/* <div className="account-container-list-item notification-item">
                            <Button type="link">
                                <SvgIcon name="notification"></SvgIcon>
                            </Button>
                        </div> */}
                            </>
                        )}
                        {isLoggedIn && (
                            <>
                                {isAccountVerified && (
                                    <div
                                        className="account-container-list-item wallet-item"
                                        id="wallet-ref"
                                    >
                                        <Dropdown
                                            getPopupContainer={() =>
                                                document.getElementById(
                                                    'yuex-header-ref'
                                                ) || document.body
                                            }
                                            placement="bottomRight"
                                            overlay={<WalletDropdown />}
                                        >
                                            <Button
                                                onClick={() => {
                                                    navigate(
                                                        routesPaths.account
                                                            .wallet
                                                    );
                                                }}
                                                type="link"
                                                prefixIcon="wallet"
                                            >
                                                {currentBalance?.unit && (
                                                    <>
                                                        {!authOptions?.isHiddenAccount
                                                            ? NumberFormat(
                                                                  currentBalance?.totalTRY ||
                                                                      ''
                                                              )
                                                            : secretBalance}{' '}
                                                        {currentBalance?.unit}
                                                    </>
                                                )}
                                            </Button>
                                        </Dropdown>
                                    </div>
                                )}
                                <div className="account-container-list-item account-item">
                                    <Dropdown
                                        getPopupContainer={() =>
                                            document.getElementById(
                                                'yuex-header-ref'
                                            ) || document.body
                                        }
                                        overlay={
                                            <ProfileDropdown
                                                activeLevel="Level1"
                                                setLevel="Level2"
                                                referanceId={3285756544}
                                            />
                                        }
                                    >
                                        <IconButton
                                            onClick={() => {
                                                navigate(
                                                    routesPaths.account.main
                                                );
                                            }}
                                            icon="user-circle-filled"
                                        ></IconButton>
                                    </Dropdown>
                                </div>
                                <div className="account-container-list-item change-theme">
                                    <Button
                                        type="link"
                                        onClick={() => changeTheme()}
                                    >
                                        <SvgIcon
                                            name={
                                                theme?.selectedTheme == 'light'
                                                    ? 'dark-theme'
                                                    : 'light-theme'
                                            }
                                        ></SvgIcon>
                                    </Button>
                                </div>
                            </>
                        )}

                        <div
                            className="account-container-list-item notification-item"
                            onMouseEnter={() => {
                                if (data?.is_new_count !== 0 && isLoggedIn)
                                    fetchReadAnnouncement();
                            }}
                        >
                            <div>
                                {(data?.is_new_count !== undefined
                                    ? data?.is_new_count
                                    : 0) > 0 && (
                                    <span>{data?.is_new_count}</span>
                                )}

                                <Dropdown
                                    overlay={
                                        <NotificationDropdown
                                            data={data}
                                        ></NotificationDropdown>
                                    }
                                >
                                    <IconButton
                                        icon="notification"
                                        onClick={handleAnnouncementPage}
                                    />
                                </Dropdown>
                            </div>
                        </div>

                        <div className="account-container-list-item change-language">
                            <Select
                                value={currentLanguage}
                                options={languageList?.map((item) => {
                                    return {
                                        value: item.key,
                                        label: item.name,
                                    };
                                })}
                                dropdownStyle={{ maxWidth: '80px' }}
                                placement="bottomRight"
                                optionFilterProp="label"
                                optionLabelProp="label"
                                onChange={(e) => {
                                    isLoggedIn &&
                                        userLanguage({ language: String(e) });

                                    const url =
                                        window.location.pathname.split('/');
                                    url[1] = String(e);
                                    setTimeout(() => {
                                        window.location.href = url
                                            .join()
                                            .replaceAll(',', '/');
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="loading-logo">
                    <Logo type="logo" onClick={handleNavHomePage}></Logo>
                </div>
            )}
        </HeaderStyled>
    );
};

export const Header = WrappedHeader;
