import { useRef } from 'react';
import { blogHttpService } from 'common/http/http.service';
import { BlogApiCalls } from './blog.model';

const endpointUrlPath = {
    getDetail: (lang: string, slug: string) =>
        `/blogs?locale=${lang}&filters[slug]=${slug}&filters[active]=active&fields=title,slug,description,summary,content,publishedAt,readingtime&populate[seo][fields]=title,description,keywords&populate[taglink][fields]=title,tag&populate[media][fields]=url,name&populate[thumbnail]=*&populate[blogs][populate]populate[thumbnail][populate][]=*`,
    getCategories: (lang: string) =>
        `/blog-categories?locale=${lang}&sort[order]=asc&filters[active]=active&[fields]=title`,

    getBlogSlider: (lang: string) =>
        `/blogs?locale=${lang}&filters[active]=active&filters[slider]=active&fields=title,slug,description,publishedAt,readingtime&populate[sliderImage][fields]=name,url`,

    getBlogFeatured: (lang: string) =>
        `/blogs?locale=${lang}&filters[active]=active&filters[featured]=active&fields=title,slug,description,publishedAt,readingtime&populate[thumbnail]=*`,

    getBlog: (lang: string) =>
        `/blogs?locale=${lang}&sort[publishedAt]=desc&filters[active]=active&filters[featured]=false&fields=title,slug,description,publishedAt,readingtime&populate[categories][fields]=title&populate[thumbnail]=*`,

    getAboutUs: (lang: string) => `/about-us?locale=${lang}`,
    getCareer: (lang: string) =>
        `/career?locale=${lang}&populate[open_position][populate]=*`,

    getGuideDetail: (lang: string, slug: string) =>
        `/guides?locale=${lang}&filters[slugid]=${slug}&filters[active]=active&populate[seo][fields]=title,description,keywords&fields=title,slug,slugid,publishedAt,content&populate[guides][fields]=title,slug&populate[guides][populate][guide_category][fields]=slug&populate[guide_category][fields]=sidebar,slug&populate[localizations][fields]=locale,slug,slugid`,

    getAdvertising: (lang: string) =>
        `/media-control?locale=${lang}&populate[blogMiniBanner][fields]=url,name&populate[homeBanner][populate]=*`,

    getGuideMainCategory: (lang: string) =>
        `/guide-categories?locale=${lang}&filters[active]=active&populate[coinInfo][populate]=*&filters[sidebar]=true&populate[guides][fields]=title,slug,slugid,order`,
    getPrivaciesDetail: (lang: string, slug: string) =>
        `/privacies?locale=${lang}&filters[slug]=${slug}&populate[seo][fields]=title,description,keywords`,
    getPrivaciesList: (lang: string) =>
        `/privacies?locale=${lang}&fields=title,slug`,
    getDictionary: (lang: string, search: string) =>
        `/dictionaries?locale=${lang}&sort[title]=asc&filters[title][$containsi]=${search}`,
    getGuideMain: (lang: string, search: string) =>
        `/guide-home?locale=${lang}&populate[seo][fields]=title,description,keywords&populate[guideCustomCategory][populate][guides][fields]=title,slug,slugid,active&populate[guideCustomCategory][populate][guides][populate][guide_category][fields]=slug&populate[guideCustomCategory][populate][guides][filters][title][$containsi]=${search}&populate[guideCustomCategory][populate][guides][filters][active]=true`,
    getFooter: (lang: string) =>
        `/footer?locale=${lang}&populate[groupList][fields]=Title&populate[groupList][populate][urlList][fields]=name,url&fields=locale`,
    getSecurity: (lang: string) =>
        `/security?locale=${lang}&populate[seo]=*&populate[tab1]=*&populate[tab2]=*&populate[tab3]=*&populate[tab4]=*&populate[tab5]=*`,
    getAnnouncements: (lang: string) =>
        `/announcements?locale=${lang}&fields=title,content,time,date`,
};

const useBlogApi = (): BlogApiCalls => {
    const generateApiCalls = (): BlogApiCalls => {
        return {
            getBlogDetail: (lang: string, slug: string): Promise<any> =>
                blogHttpService.client.get(
                    endpointUrlPath.getDetail(lang, slug)
                ),
            getCategories: (lang: string): Promise<any> =>
                blogHttpService.client.get(endpointUrlPath.getCategories(lang)),

            getBlogSlider: (lang: string): Promise<any> =>
                blogHttpService.client.get(endpointUrlPath.getBlogSlider(lang)),

            getBlogFeatured: (lang: string): Promise<any> =>
                blogHttpService.client.get(
                    endpointUrlPath.getBlogFeatured(lang)
                ),
            getBlog: (lang: string): Promise<any> =>
                blogHttpService.client.get(endpointUrlPath.getBlog(lang)),

            getAboutUs: (lang: string): Promise<any> =>
                blogHttpService.client.get(endpointUrlPath.getAboutUs(lang)),
            getCareer: (lang: string): Promise<any> =>
                blogHttpService.client.get(endpointUrlPath.getCareer(lang)),
            getGuideDetail: (lang: string, slug: string): Promise<any> =>
                blogHttpService.client.get(
                    endpointUrlPath.getGuideDetail(lang, slug)
                ),
            getAdvertising: (lang: string): Promise<any> =>
                blogHttpService.client.get(
                    endpointUrlPath.getAdvertising(lang)
                ),
            getGuideMainCategory: (lang: string): Promise<any> =>
                blogHttpService.client.get(
                    endpointUrlPath.getGuideMainCategory(lang)
                ),
            getPrivaciesDetail: (lang: string, slug: string): Promise<any> =>
                blogHttpService.client.get(
                    endpointUrlPath.getPrivaciesDetail(lang, slug)
                ),
            getPrivaciesList: (lang: string): Promise<any> =>
                blogHttpService.client.get(
                    endpointUrlPath.getPrivaciesList(lang)
                ),
            getDictionary: (lang: string, search: string): Promise<any> =>
                blogHttpService.client.get(
                    endpointUrlPath.getDictionary(lang, search)
                ),
            getGuideMain: (lang: string, search: string): Promise<any> =>
                blogHttpService.client.get(
                    endpointUrlPath.getGuideMain(lang, search)
                ),
            getFooter: (lang: string): Promise<any> =>
                blogHttpService.client.get(endpointUrlPath.getFooter(lang)),
            getSecurity: (lang: string): Promise<any> =>
                blogHttpService.client.get(endpointUrlPath.getSecurity(lang)),
            getAnnouncements: (lang: string): Promise<any> =>
                blogHttpService.client.get(
                    endpointUrlPath.getAnnouncements(lang)
                ),
        };
    };
    const commonCallsRef = useRef<BlogApiCalls>(generateApiCalls());

    return commonCallsRef.current;
};

export default useBlogApi;
