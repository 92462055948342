import { RadioGroupProps as AntRadioGroupProps, Radio as AntRadio } from 'antd';
import { RadioGroupStyled } from './index.styles';

import React from 'react';
import { Radio } from 'components/radio';

type AllowedAntRadioGroupTypes =
    | 'disabled'
    | 'defaultValue'
    | 'name'
    | 'value'
    | 'onChange'
    | 'size'
    | 'optionType'
    | 'buttonStyle';

export interface RadioGroupItem {
    label: string;
    value: string;
    disabled?: boolean;
    selected?: boolean;
}

export interface RadioGroupProps
    extends Pick<AntRadioGroupProps, AllowedAntRadioGroupTypes> {
    options: RadioGroupItem[];
}

const WrappedRadioGroup = ({
    options = [
        { label: 'First Option', value: 'option1' },
        { label: 'Second Option', value: 'option2', selected: true },
        { label: 'Third Option', value: 'option3', disabled: true },
    ],
    optionType = 'button',
    buttonStyle = 'solid',
    ...props
}: RadioGroupProps) => {
    const radioGroupItems: JSX.Element[] = [];
    let defaultSelectedItem = undefined;

    if (options && options.length) {
        for (const item of options) {
            if (optionType === 'default') {
                radioGroupItems.push(
                    <Radio
                        key={item.value}
                        value={item.value}
                        disabled={item.disabled}
                    >
                        {item.label}
                    </Radio>
                );
            } else {
                radioGroupItems.push(
                    <AntRadio.Button
                        key={item.value}
                        value={item.value}
                        disabled={item.disabled}
                    >
                        {item.label}
                    </AntRadio.Button>
                );
            }

            if (item.selected) {
                defaultSelectedItem = item.value;
            }
        }
    }

    return (
        <RadioGroupStyled
            defaultValue={defaultSelectedItem}
            optionType={optionType}
            buttonStyle={buttonStyle}
            {...props}
        >
            {radioGroupItems}
        </RadioGroupStyled>
    );
};

export const RadioGroup = React.memo(WrappedRadioGroup);
