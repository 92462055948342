import { useModal } from 'components/modal';
import {
    ModalWarningCard,
    ModalWarningCardProps,
} from 'components/modal-warning-card';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface UseWarningModalProps extends ModalWarningCardProps {
    lang?: boolean;
    staticButton?: string;
    onClose?: () => void;
    loginSession?: boolean;
    changePhoneModal?: boolean;
}

interface ReturnUseWarningModal {
    placeholder: () => void;
    openWarningModal: () => void;
    closeModal: () => void;
    width?: number;
}

export const useWarningModal = (
    props: UseWarningModalProps
): ReturnUseWarningModal => {
    const { t } = useTranslation();
    const { placeholder, openModal, closeModal } = useModal({
        className: 'Warning-modal',
        width: props.width || 310,
    });

    const handleOpenWarningModal = useCallback(() => {
        openModal(
            <>
                {props?.lang === true && (
                    <ModalWarningCard
                        title={t('security.warningCard.title')}
                        description={{
                            titletext: t(
                                'security.warningCard.description.titletext'
                            ),
                            listtext: [
                                t(
                                    'security.warningCard.description.listtext.list1'
                                ),
                                t(
                                    'security.warningCard.description.listtext.list2'
                                ),
                            ],
                            bottomtext: t(
                                'security.warningCard.description.bottomtext'
                            ),
                        }}
                        onCancel={closeModal}
                        onNext={props.onNext}
                        extraComponent={props.extraComponent}
                    />
                )}
                {props?.loginSession === true && (
                    <ModalWarningCard
                        title={t('exceptions.sessionExpired.message')}
                        description={{
                            titletext: t(
                                'exceptions.sessionExpired.descriptionPopup'
                            ),
                        }}
                        staticButton={t('exceptions.sessionExpired.button')}
                        onCancel={closeModal}
                        extraComponent={props.extraComponent}
                        onNext={props.onNext}
                        onClose={props.onClose}
                    />
                )}
                {props?.changePhoneModal === true && (
                    <ModalWarningCard
                        title={t('card.personal.changePhoneModal.title')}
                        description={{
                            titletext: t(
                                'card.personal.changePhoneModal.description'
                            ),
                            listtext: [
                                t('card.personal.changePhoneModal.firstItem'),
                                t('card.personal.changePhoneModal.secondItem'),
                            ],
                            bottomtext: t(
                                'card.personal.changePhoneModal.inputLabel'
                            ),
                        }}
                        // staticButton={t('exceptions.sessionExpired.button')}
                        onCancel={closeModal}
                        extraComponent={props.extraComponent}
                        onNext={props.onNext}
                        onClose={props.onClose}
                    />
                )}
                {!props.lang &&
                    !props.loginSession &&
                    !props.changePhoneModal && (
                        <ModalWarningCard
                            title={props.title}
                            description={props?.description}
                            onCancel={closeModal}
                            onNext={props.onNext}
                            extraComponent={props.extraComponent}
                            staticButton={props.staticButton}
                            onClose={props.onClose}
                        />
                    )}
            </>
        );
    }, [openModal]);

    return {
        placeholder,
        openWarningModal: handleOpenWarningModal,
        closeModal,
    };
};
