import { useFormik } from 'formik';
import * as yup from 'yup';
import { Container } from './index.styles';
import { Button, InputField } from 'components';
import { useTranslation } from 'react-i18next';

interface BannerFormValues {
    name: string;
    lastname: string;
    email: string;
    message?: string;
}

const Apply = () => {
    const { t } = useTranslation();

    const validation = yup.object().shape({
        name: yup
            .string()
            .matches(
                /^[a-zA-Z ]|[^\wığüşöçĞÜŞÖÇİ]*$/,
                t('verification.form.validations.invalidNameFormat')
            )
            .required(t('verification.form.validations.invalidName')),
        lastname: yup
            .string()
            .matches(
                /^[a-zA-Z ] |[^\wığüşöçĞÜŞÖÇİ]*$/,
                t('verification.form.validations.invalidSurnameFormat')
            )
            .required(t('verification.form.validations.invalidLastname')),
        email: yup
            .string()
            .required(t('verification.form.validations.invalidEmailAddress')),
        message: yup
            .string()
            .required(t('verification.form.validations.invalidMessage')),
    });
    const formik = useFormik<BannerFormValues>({
        initialValues: {
            name: '',
            lastname: '',
            email: '',
        },
        onSubmit: async (_values: BannerFormValues) => {},
        validationSchema: validation,
    });

    return (
        <>
            <Container>
                <div className="title-container">
                    <h2 className="title">{t('career.apply.title')}</h2>
                    <hr />
                </div>
                <div className="apply-form">
                    <div className="apply-form-container">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="name-fields-container">
                                <InputField
                                    className="no-border"
                                    form={formik}
                                    formName="name"
                                    placeholder={t('career.apply.form.name')}
                                    bordered={true}
                                />
                                <InputField
                                    className="no-border"
                                    form={formik}
                                    formName="lastname"
                                    placeholder={t(
                                        'career.apply.form.lastname'
                                    )}
                                />
                            </div>
                            <InputField
                                className="no-border"
                                key="email"
                                form={formik}
                                formName="email"
                                placeholder={t('career.apply.form.email')}
                            />
                            <InputField
                                className="no-border"
                                key="message"
                                form={formik}
                                formName="message"
                                placeholder={t('career.apply.form.message')}
                            />
                            <Button
                                htmlType="submit"
                                type="primary"
                                size="large"
                                className="apply-button"
                            >
                                {t('career.apply.form.button')}
                            </Button>
                        </form>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default Apply;
