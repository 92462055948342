import { fonts } from 'common/theme/font';
import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';

const tabletCss = css`
    flex-direction: column !important;
    .ant-layout-content {
        margin: 0px;
        width: 100%;
        max-width: 100%;
        padding: 15px;
        > section {
            flex-direction: column;
            width: 100%;
            .left-content {
                max-width: 100% !important;
                width: 100% !important;
                margin: 0px;

                .ant-menu {
                    max-height: 250px;
                    padding: 0;
                }
            }
            .right-content {
                max-width: 100% !important;
                width: 100% !important;
                padding: 20px;
                margin-top: 20px;
                .coin-exchange-container
                    .coin-exchange-content
                    .ant-input-affix-wrapper {
                    min-width: inherit;
                }
                .links ul {
                    grid-template-columns: 100%;
                }
            }
        }
    }
`;
const mobileCss = css`
    .ant-layout-content {
        .right-content {
            .coin-exchange-container {
                display: flex;
                flex-direction: column;
                .coin-exchange-content-icon {
                    justify-content: center;
                    padding: 0px;
                    display: none;
                }
                .coin-exchange-content {
                    width: 100%;
                }
            }
        }
    }
`;

export const GuideScreenLayoutStyled = styled.div`
    .ant-layout-content {
        display: flex;
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        height: 100% !important;
        margin-top: 24px;
        overflow: inherit !important;
    }
    ${mediaQuery.lessThan('desktop')`${tabletCss}`}
    ${mediaQuery.lessThan('tablet')`${mobileCss}`}
`;

export const Content = styled.section`
    display: flex;
    flex: 1;

    flex-direction: row !important;
    .left-content {
        width: 300px;
        margin-right: 20px;

        .share-posts {
            border-radius: 4px;
            border: 1px solid ${({ theme }) => theme.colors.color2.base};
            padding: 20px;
            font-size: ${fonts.size.normal};
            font-weight: ${fonts.weight.regular};
            color: ${({ theme }) => theme.colors.color4.base};
            background-color: ${({ theme }) => theme.colors.color1.base};
            margin-bottom: 20px;

            > .title {
                position: relative;
                margin-bottom: 30px;
                padding-bottom: 5px;
                h2 {
                    font-size: ${fonts.size.large};
                    font-weight: ${fonts.weight.semibold};
                    color: ${({ theme }) => theme.colors.color4.second1};
                    &:before {
                        content: '';
                        position: absolute;
                        bottom: -5px;
                        left: 0;
                        height: 4px;
                        width: 30px;
                        border-radius: 6px;
                        background-color: ${({ theme }) =>
                            theme.colors.staticColor.color2.base};
                    }
                }
            }
            .social-links {
                ul {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    li {
                        margin-right: 15px;
                        &:last-child {
                            margin-right: 0;
                        }
                        a {
                            width: 27px;
                            height: 27px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 20px;
                            background-color: ${({ theme }) =>
                                theme.colors.color3.base};
                            i {
                                line-height: 12px;
                            }
                        }
                    }
                }
            }
        }
        .coin-info {
            border-radius: 4px;
            border: 1px solid ${({ theme }) => theme.colors.color2.base};
            padding: 20px;
            font-size: ${fonts.size.normal};
            font-weight: ${fonts.weight.regular};
            color: ${({ theme }) => theme.colors.color4.base};
            background-color: ${({ theme }) => theme.colors.color1.base};
            margin-bottom: 20px;
            .head {
                margin-bottom: 20px;
                .name {
                    font-size: 14px;
                    font-weight: ${fonts.weight.medium};
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    .subName {
                        opacity: 0.7;
                        font-weight: ${fonts.weight.light};
                        text-transform: uppercase;
                        border-left: solid 1px
                            ${({ theme }) => theme.colors.color2.base};
                        padding-left: 10px;
                        margin-left: 10px;
                    }
                    .app-icon {
                        margin-right: 10px;
                        font-size: 32px;
                        display: flex;
                        align-items: center;
                    }
                }
            }
            .body {
                ul {
                    li {
                        border-bottom: solid 1px
                            ${({ theme }) => theme.colors.color2.base};
                        padding-bottom: 10px;
                        margin-bottom: 10px;
                        display: flex;
                        justify-content: space-between;
                        .left {
                            text-align: left;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            width: 50%;
                        }
                        .right {
                            text-align: right;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            width: 50%;
                            font-weight: ${fonts.weight.light};
                        }
                        a:hover {
                            color: ${({ theme }) =>
                                theme.colors.staticColor.color2.base};
                        }
                    }
                    li:last-child {
                        border-bottom: none;
                        margin-bottom: 0px;
                    }
                }
                button {
                    margin-top: 15px;
                    width: 100%;
                }
                .currentPrice {
                    background-color: ${({ theme }) =>
                        theme.colors.color1.second2};
                    padding: 10px;
                    border-radius: 4px;
                    margin-bottom: 15px;
                    .title {
                        opacity: 0.7;
                        font-weight: ${fonts.weight.light};
                        margin-bottom: 10px;
                    }
                    .price {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        font-size: 120%;
                        span {
                            opacity: 0.7;
                            font-weight: ${fonts.weight.light};
                            margin-left: 5px;
                        }
                        .change {
                            font-size: 80%;
                            margin-left: 10px;
                            font-weight: ${fonts.weight.light};
                            color: ${({ theme }) =>
                                theme.colors.staticColor.color2.base};
                            display: flex;
                            align-items: center;
                            &.negative {
                                color: ${({ theme }) =>
                                    theme.colors.staticColor.color3.base};
                            }
                            .arrow-pointer {
                                line-height: 0px;
                            }
                        }
                    }
                }
            }
        }
    }
    .right-content {
        flex: 1;
        background-color: ${({ theme }) => theme.colors.color1.base};
        border-radius: 4px;
        padding: 30px;
        border: solid 1px ${({ theme }) => theme.colors.color2.base};
        width: calc(100% - 380px);
        .links {
            margin-top: 30px;
            padding-top: 30px;
            border-top: solid 1px ${({ theme }) => theme.colors.color2.base};
            h6 {
                margin-bottom: 10px;
                font-size: 14px;
                color: ${({ theme }) => theme.colors.color4.base};
            }
            ul {
                display: grid;
                grid-template-columns: 33% 33% 33%;
                flex-wrap: wrap;
                flex-direction: row;
                list-style: disc;
                li {
                    margin-left: 15px;
                    margin-right: 30px;
                    font-size: 14px;
                    &::marker {
                        font-size: 16px;
                        color: ${({ theme }) =>
                            theme.colors.staticColor.color5.base};
                    }
                    &:hover {
                        &::marker {
                            padding: 0;
                            color: ${({ theme }) =>
                                theme.colors.staticColor.color5.base};
                        }
                    }
                    a {
                        padding: 5px 0px;
                        display: block;
                        color: ${({ theme }) =>
                            theme.colors.staticColor.color2.base};
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    a:hover {
                        color: ${({ theme }) =>
                            theme.colors.staticColor.color5.base};
                    }
                }
            }
        }
    }
    ${mediaQuery.lessThan('desktop')`${tabletCss}`}
    ${mediaQuery.lessThan('mobile')`${mobileCss}`}
`;
