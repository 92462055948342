import { SvgIcon } from 'components/svg-icon';
import { IconType } from 'components/svg-icon/icon-types';
import { useTranslation } from 'react-i18next';
import { CoinDisplayStyled } from './index.styles';

export interface CoinDisplayProps {
    value: string;
    type: 'vertical' | 'horizontal';
}

export const CoinDisplay = ({ type = 'vertical', value }: CoinDisplayProps) => {
    const { t } = useTranslation();

    return (
        <CoinDisplayStyled>
            <div className={type}>
                <SvgIcon name={`coin-${value}` as IconType} />
                <div className="text-area">
                    <div className="short-value-name"> {value}</div>
                    <div className="long-value-name"> {t(`coin.${value}`)}</div>
                </div>
            </div>
        </CoinDisplayStyled>
    );
};
