const authApiEndpoints = {
    distribution: '/lg/user/api/distribution',
    checkEmail: '/lg/user/api/checkemail',
    changeEmail: '/lg/user/changeemail',
    confirmEmail: '/lg/user/confirmemail',
    confirmCodeEmail: '/lg/user/confirmemailchange',
    resendEmail: '/lg/user/resendconfirmemail',
    register: '/lg/user/register',
    login: '/lg/user/login',
    changePassword: '/lg/user/changepassword',
    logout: '/lg/user/logout',
    auth: '/lg/user/login/auth',
    confirmsms: '/lg/user/confirmsms',
    user: '/lg/user/user',
    changetimeout: '/lg/user/changetimeout',
    walletBalance: '/mc/balance/wallet-drawer',
    forgetPassword: '/lg/user/api/forgotpassword',
    sendForgotPasswordMail: '/lg/user/sendforgotpasswordmail',
    sendForgotPassword2fa: '/lg/user/sendforgotpassword2fa',
    checkForgotPassword: '/lg/user/checkforgotpsw',
    resetPassword: '/lg/user/resetpassword',
    resendSms: '/lg/user/resendsms',
    sendChangePasswordEmail: '/lg/user/sendchangepasswordmail',
    sendChangePassword2fa: '/lg/user/sendchangepassword2fa',
    sendChangeWithdraw2fa: '/lg/user/sendwithdraw2fa',
    checkChangePassword: '/lg/user/checkchangepsw',
    confirmChangePassword: '/lg/user/confirmpassword',
    checkPassword: '/lg/user/checkpsw',
    changePhoneNumberEmail: '/lg/user/changephonenumbermail',
    changePhoneNumberCode: '/lg/user/changephonenumbercode',
    confirmPhoneNumberChange: '/lg/user/confirmphonenumberchange',
    checkOtp: '/lg/user/checktotp',
    getOtpSecretCode: '/lg/user/totpsecret',
    changeOtp: '/lg/user/changeotp',
    changetOtp: '/lg/user/changetotp',
    confirmtOtp: '/lg/user/confirmtotp',
    confirmOtp: '/lg/user/confirmotp',
    emailAntiSpam: '/lg/user/changeantispam',
    freezeAccount: '/lg/user/freezeaccount',
    userLanguage: '/lg/user/language',
    sendtotpchangesms: '/lg/user/sendtotpchangesms',
};

export default authApiEndpoints;
