import { Col, Row } from 'antd';
import { getLanguage } from 'common/utils';
import { Button } from 'components/button';
import { routesPaths } from 'config/routes';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as S from './index.styles';

export const GiftCard = () => {
    const { t } = useTranslation();
    const lang = getLanguage();
    const navigate = useNavigate();
    const [giftList] = useState<any>([
        {
            id: 1,
            code: 'NY',
            image: `/assets/images/gift-cards/NY-${lang}.svg`,
            title: t('giftcard.cards.newyear'),
        },
        {
            id: 2,
            code: 'HB',
            image: `/assets/images/gift-cards/HB-${lang}.svg`,
            title: t('giftcard.cards.birthday'),
        },
        {
            id: 3,
            code: 'HB2',
            image: `/assets/images/gift-cards/HB2-${lang}.svg`,
            title: t('giftcard.cards.birthday'),
        },
        {
            id: 4,
            code: 'HR',
            image: `/assets/images/gift-cards/H-${lang}.svg`,
            title: t('giftcard.cards.holidays'),
        },
        {
            id: 5,
            code: 'S',
            image: `/assets/images/gift-cards/S-${lang}.svg`,
            title: t('giftcard.cards.suprise'),
        },
        {
            id: 6,
            code: 'VD',
            image: `/assets/images/gift-cards/VD-${lang}.svg`,
            title: t('giftcard.cards.valentinesday'),
        },
        {
            id: 7,
            code: 'NY2',
            image: `/assets/images/gift-cards/NY2-${lang}.svg`,
            title: t('giftcard.cards.newyear'),
        },
    ]);
    return (
        <>
            <Helmet>
                <title>
                    {t('seo.gift_card.title', {
                        appTitle: process.env.REACT_APP_TITLE,
                    })}
                </title>
                <meta
                    name="description"
                    content={t('seo.gift_card.description')}
                ></meta>
                <meta
                    name="keywords"
                    content={t('seo.gift_card.keywords')}
                ></meta>
            </Helmet>
            <S.Container>
                <div className="card-header">
                    <div className="content">
                        <div className="title">
                            {t('giftcard.page.header.title')}
                        </div>
                        <div className="text">
                            {t('giftcard.page.header.text')}
                        </div>
                        <Button
                            type="link"
                            suffixIcon="chevron-right"
                            onClick={() => {
                                navigate(routesPaths.gift.giftcardSend);
                            }}
                        >
                            {t('giftcard.page.header.button')}
                        </Button>
                    </div>
                </div>
                <div className="card-body">
                    <div className="content">
                        <div className="title">
                            {t('giftcard.page.content.title')}
                        </div>
                        <div className="text">
                            {t('giftcard.page.content.text')}
                        </div>
                        <Row className="row-card" gutter={[32, 32]}>
                            {giftList.map((item: any, id: number) => (
                                <Col key={id} span={8}>
                                    <img
                                        src={item.image}
                                        alt=""
                                        className="card"
                                    />
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            </S.Container>
        </>
    );
};
