import styled, { css } from 'styled-components';
import { Select as SelectAnt, SelectProps } from 'antd';
import { fonts } from 'common/theme/font';

const iconRotate = css`
    &.ant-select-open {
        .ant-select-arrow {
            i.tunety-icon {
                transform: rotateX(180deg);
            }
        }
    }
`;

const hoverSelectState = css`
    border: 1px solid ${({ theme }) => theme.colors.staticColor.color2.base};
    cursor: pointer;
`;

export const SelectDropdownCss = css`
    .tunety-select-dropdown {
        &.ant-select-dropdown {
            background-color: ${({ theme }) => theme.colors.color1.base};

            .ant-select-item {
                &.ant-select-item-group {
                    padding-left: 0px;

                    font-size: ${fonts.size.small};
                    color: ${({ theme }) =>
                        theme.colors.staticColor.color2.base};
                }

                &.ant-select-item-option-grouped {
                    padding-left: 0px;
                }
            }

            .ant-select-item-option-state,
            .ant-select-item-option-content {
                color: ${({ theme }) => theme.colors.staticColor.color2.base};
            }

            .ant-select-item-option-active,
            .ant-select-item-option-selected {
                background-color: ${({ theme }) => theme.colors.color1.base};

                .ant-select-item-option-content {
                    color: ${({ theme }) =>
                        theme.colors.staticColor.color2.base};
                }
            }
        }
    }
`;
export const SelectWrapper = styled.div`
    position: relative;
    .prefix-icon {
        position: absolute;
        left: 0;
        padding: 9px;

        color: ${({ theme }) => theme.colors.staticColor.color2.base};
        z-index: 1;
    }
    .prefix-style .ant-select-selector {
        padding-left: 30px !important;
    }
`;
export const SelectStyled = styled(SelectAnt)<SelectProps>`
    color: ${({ theme }) => theme.colors.color4.base};

    &.ant-select {
        width: 100%;
        ${iconRotate}

        .ant-select-arrow,
        .ant-select-clear {
            color: ${({ theme }) => theme.colors.color4.base};
            i {
                font-size: 20px;
                line-height: 0;
            }
        }

        &.error {
            .ant-select-selector {
                border: 1px solid
                    ${({ theme }) => theme.colors.staticColor.color3.base} !important;
            }
        }

        &.ant-select-allow-clear {
            &:hover {
                .ant-select-arrow + .ant-select-clear {
                    background-color: ${({ theme }) =>
                        theme.colors.color1.base};
                }
                .ant-select-clear {
                    right: 15px;
                }
            }
        }

        .ant-select-selector {
            width: 100%;
            display: inline-flex;
            align-items: center;

            box-sizing: border-box;
            background-color: ${({ theme }) =>
                theme.colors.color1.base}!important;
            height: 35px;

            padding: 10px 16px;
            border-radius: 6px;
            border: 1px solid;
            border-color: ${({ theme }) => theme.colors.color2.base};

            font-size: ${fonts.size.normal};

            &:hover {
                ${hoverSelectState}
            }

            .ant-select-selection-placeholder {
                color: ${({ theme }) => theme.colors.color4.base};
            }

            .ant-select-selection-item {
                color: ${({ theme }) => theme.colors.color4.base};
            }
        }

        &.ant-select-multiple {
            .ant-select-selector {
                display: inline-flex;
                align-items: center;
                height: inherit !important;

                padding: 0px 4px;
            }

            .ant-select-selection-overflow {
                padding-top: 0px;
                padding-bottom: 0px;

                height: inherit !important;

                .ant-select-selection-item {
                    border-radius: 6px;

                    background-color: ${({ theme }) =>
                        theme.colors.color1.base};
                    border: 1px solid
                        ${({ theme }) => theme.colors.staticColor.color2.base};

                    color: ${({ theme }) =>
                        theme.colors.staticColor.color2.base};
                    font-size: ${fonts.size.small};

                    .ant-select-selection-item-remove {
                        & > span {
                            color: ${({ theme }) =>
                                theme.colors.staticColor.color2.base};
                        }
                    }
                }
            }
        }

        &:not(.ant-select-disabled):hover .ant-select-selector {
            ${hoverSelectState}
        }
        .ant-select-arrow {
            display: inline-flex;
            align-items: center;
            i.tunety-icon {
                width: 12px;
                height: 12px;
                font-size: ${fonts.size.small};
                fill: ${({ theme }) => theme.colors.staticColor.color2.base};

                transform-origin: center;
                transform: rotateX(0deg);
            }
        }

        .ant-select-clear {
            display: inline-flex;
            align-items: center;

            background: none;
            i.tunety-icon {
                width: 12px;
                height: 12px;
                font-size: ${fonts.size.small};
                fill: ${({ theme }) => theme.colors.staticColor.color2.base};

                transform-origin: center;
                transform: rotateX(0deg);
            }
        }

        &.ant-select-sm {
            ${iconRotate}

            .ant-select-selector {
                display: inline-flex;
                align-items: center;

                box-sizing: border-box;
                background-color: ${({ theme }) => theme.colors.color1.base};
                height: 24px;

                padding: 0px 16px;
                border-radius: 6px;
                border: 1px solid;
                border-color: ${({ theme }) => theme.colors.color2.base};

                font-size: ${fonts.size.small};

                &:hover {
                    border: 1px solid
                        ${({ theme }) => theme.colors.staticColor.color2.base};
                    cursor: pointer;
                }

                .ant-select-selection-placeholder {
                    color: ${({ theme }) =>
                        theme.colors.staticColor.color2.base};
                }
            }

            .ant-select-arrow {
                display: inline-flex;
                align-items: center;

                i.tunety-icon {
                    width: 12px;
                    height: 12px;
                    font-size: ${fonts.size.small};
                    fill: ${({ theme }) =>
                        theme.colors.staticColor.color2.base};

                    transform-origin: center;
                    transform: rotateX(0deg);
                }
            }
        }

        &.ant-select-lg {
            ${iconRotate}

            .ant-select-selector {
                display: inline-flex;
                align-items: center;

                box-sizing: border-box;
                background-color: ${({ theme }) => theme.colors.color1.base};
                height: 40px;

                padding: 10px 16px;
                border-radius: 6px;
                border: 1px solid;
                border-color: ${({ theme }) => theme.colors.color2.base};

                font-size: ${fonts.size.small};

                &:hover {
                    border: 1px solid
                        ${({ theme }) => theme.colors.staticColor.color2.base};
                    cursor: pointer;
                }

                &.ant-select-selection-placeholder {
                    color: ${({ theme }) =>
                        theme.colors.staticColor.color2.base};
                }
            }

            .ant-select-arrow {
                display: inline-flex;
                align-items: center;
                i.tunety-icon {
                    width: 14px;
                    height: 14px;
                    font-size: ${fonts.size.small};
                    fill: ${({ theme }) =>
                        theme.colors.staticColor.color2.base};
                    transform-origin: center;
                    transform: rotateX(0deg);
                }
            }
        }
    }
`;
