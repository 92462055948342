//@ts-nocheck
import { DatePickerProps as AntDatePickerProps } from 'antd';
import { DatePickerStyled } from './index.styles';
import { getLanguage } from 'common/utils';

import React from 'react';
import { useTranslation } from 'react-i18next';

export type DatePickerPlacement =
    | 'topRight'
    | 'topLeft'
    | 'bottomRight'
    | 'bottomLeft';

type AllowedAnDatePickerTypes =
    | 'value'
    | 'name'
    | 'size'
    | 'onChange'
    | 'onOpenChange'
    | 'onPanelChange'
    | 'disabledDate'
    | 'dateRender';

export interface DatePickerProps
    extends Pick<AntDatePickerProps, AllowedAnDatePickerTypes> {
    placement?: DatePickerPlacement;
    className?: string;
}

const WrappedDatePicker = ({
    placement = 'bottomLeft',
    className: _className = '',
    ...props
}: DatePickerProps) => {
    const extraProps: Pick<AntDatePickerProps, 'className'> = {};
    const { t: translate } = useTranslation();
    const localeTR = {
        lang: {
            placeholder: 'Tarih seç',
            yearPlaceholder: 'Yıl seç',
            quarterPlaceholder: 'Çeyrek seç',
            monthPlaceholder: 'Ay seç',
            weekPlaceholder: 'Hafta seç',
            rangePlaceholder: ['Başlangıç tarihi', 'Bitiş tarihi'],
            rangeYearPlaceholder: ['Başlangıç yılı', 'Bitiş yılı'],
            rangeMonthPlaceholder: ['Başlangıç ayı', 'Bitiş ayı'],
            rangeWeekPlaceholder: ['Başlangıç haftası', 'Bitiş haftası'],
            locale: 'tr_TR',
            today: 'Bugün',
            now: 'Şimdi',
            backToToday: 'Bugüne Geri Dön',
            ok: 'tamam',
            clear: 'Temizle',
            month: 'Ay',
            year: 'Yıl',
            timeSelect: 'Zaman Seç',
            dateSelect: 'Tarih Seç',
            monthSelect: 'Ay Seç',
            yearSelect: 'Yıl Seç',
            decadeSelect: 'On Yıl Seç',
            yearFormat: 'YYYY',
            dateFormat: 'M/D/YYYY',
            dayFormat: 'D',
            dateTimeFormat: 'M/D/YYYY HH:mm:ss',
            monthBeforeYear: true,
            previousMonth: 'Önceki Ay (PageUp)',
            nextMonth: 'Sonraki Ay (PageDown)',
            previousYear: 'Önceki Yıl (Control + Sol)',
            nextYear: 'Sonraki Yıl (Control + Sağ)',
            previousDecade: 'Önceki On Yıl',
            nextDecade: 'Sonraki On Yıl',
            previousCentury: 'Önceki Yüzyıl',
            nextCentury: 'Sonraki Yüzyıl',
        },
        timePickerLocale: {
            placeholder: 'Zaman seç',
            rangePlaceholder: ['Başlangıç zamanı', 'Bitiş zamanı'],
        },
    };
    const localeDE = {
        lang: {
            placeholder: 'Datum auswählen',
            rangePlaceholder: ['Startdatum', 'Enddatum'],
            locale: 'de_DE',
            today: 'Heute',
            now: 'Jetzt',
            backToToday: 'Zurück zu Heute',
            ok: 'OK',
            clear: 'Zurücksetzen',
            month: 'Monat',
            year: 'Jahr',
            timeSelect: 'Zeit wählen',
            dateSelect: 'Datum wählen',
            monthSelect: 'Wähle einen Monat',
            yearSelect: 'Wähle ein Jahr',
            decadeSelect: 'Wähle ein Jahrzehnt',
            yearFormat: 'YYYY',
            dateFormat: 'D.M.YYYY',
            dayFormat: 'D',
            dateTimeFormat: 'D.M.YYYY HH:mm:ss',
            monthBeforeYear: true,
            previousMonth: 'Vorheriger Monat (PageUp)',
            nextMonth: 'Nächster Monat (PageDown)',
            previousYear: 'Vorheriges Jahr (Ctrl + left)',
            nextYear: 'Nächstes Jahr (Ctrl + right)',
            previousDecade: 'Vorheriges Jahrzehnt',
            nextDecade: 'Nächstes Jahrzehnt',
            previousCentury: 'Vorheriges Jahrhundert',
            nextCentury: 'Nächstes Jahrhundert',
        },
        timePickerLocale: { placeholder: 'Zeit auswählen' },
    };
    const dateFormatList = [
        translate('date.DDMMYYYY'),
        translate('date.DDMMYY'),
    ];
    const lang = getLanguage();
    return (
        <DatePickerStyled
            getPopupContainer={(trigger) => {
                return trigger;
            }}
            format={dateFormatList}
            placeholder={translate('date.DayMonthYear')}
            placement={placement}
            {...props}
            {...extraProps}
            locale={
                lang == 'tr' ? localeTR : lang == 'de' ? localeDE : undefined
            }
        ></DatePickerStyled>
    );
};

export const DatePicker = React.memo(WrappedDatePicker);
