import { fonts } from 'common/theme/font';
import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';

const mobileCss = css`
    &.container {
        padding-top: 0px;
        .img {
            width: 100%;
            &.desktop {
                display: none !important;
            }
            &.mobile {
                display: block !important;
            }
        }
        .warning-text {
            padding-top: 20px;
            font-size: ${fonts.size.large};
        }
        .ant-layout-content {
            .hr {
                width: 22px;
                height: 3px;
            }
            .coming-soon-text {
                padding-top: 10px;
                font-size: ${fonts.size.small};
            }
            .input-container {
                padding-top: 20px !important;
                flex-direction: column;
                align-items: center;
                .app-form-field {
                    padding-right: 0;
                }
                .ant-btn {
                    max-width: 150px;
                    min-height: 35px !important;
                    span {
                        font-size: ${fonts.size.xSmall};
                    }
                }
                .app-form-field .app-form-control .ant-input {
                    padding: 0 15px !important;
                    height: 35px;
                    font-size: ${fonts.size.xSmall};
                }
            }
            .social-links {
                margin-top: 25px;
            }
        }
    }
`;
export const InfoPageContainerStyled = styled.div`
    &.container {
        padding-top: 80px;
        text-align: center;

        .img {
            max-width: 890px;
            max-height: 370px;
            margin: 0 auto;
            &.mobile {
                display: none;
            }
        }

        .warning-text {
            font-weight: ${fonts.weight.bold};
            font-size: 44px;

            color: ${({ theme }) => theme.colors.staticColor.color2.base};

            padding-top: 44px;
        }

        .text {
            padding-top: 12px;

            font-weight: ${fonts.weight.light};
            font-size: ${fonts.size.xlarge};
            color: ${({ theme }) => theme.colors.color4.base};

            opacity: 0.8;

            strong {
                font-weight: ${fonts.weight.semibold};
            }
        }
        .input-container {
            .ant-btn {
                min-height: 46px;
            }
        }
        .ant-btn {
            margin: 48px auto;
            padding: 8px 40px;
            span {
                font-weight: ${fonts.weight.semibold};
                font-size: ${fonts.size.normal};

                color: ${({ theme }) => theme.colors.color4.base};
            }
        }
    }
    ${mediaQuery.lessThan('tablet')`${mobileCss}`}
`;
