import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';
import { fonts } from 'common/theme/font';

const mobileCss = css`
    height: 200px;
    .ant-row {
        width: 100%;
        justify-content: flex-start;
        padding-bottom: 0px;
        margin: 0 20px;
        .ant-col {
            &.ant-col-16 {
                max-width: 100%;
            }
            &.ant-col-8 {
                display: none;
            }
            .info {
                margin-left: 0px;
                .title {
                    margin-bottom: 5px;
                    h1 {
                        font-size: ${fonts.size.large};
                        .text {
                            font-size: 20px;
                            padding: 0 10px;
                            &:after {
                                top: 6px;
                                height: 60%;
                            }
                        }
                    }
                    .details {
                        font-size: ${fonts.size.small};
                        margin-bottom: 20px;
                        margin-top: 10px;
                        .text {
                            font-size: 18px;
                        }
                    }
                    .ant-btn {
                        width: 95%;
                        span {
                            font-size: ${fonts.size.normal};
                        }
                    }
                }
            }
        }
    }
`;

export const Container = styled.section`
    display: block;
    height: 300px;
    .ant-row {
        align-items: center;
        justify-content: center;
        max-width: 1200px;
        margin: 0 auto;
        height: 100%;
        .ant-col {
            .info {
                margin-left: 80px;
                .title {
                    margin-bottom: 50px;
                    h1 {
                        display: flex;
                        align-items: center;
                        margin-bottom: 15px;
                        font-size: ${fonts.size.xxlarge};
                        font-weight: ${fonts.weight.bold};
                        color: ${({ theme }) => theme.colors.color4.base};
                    }
                    .details {
                        margin-top: 20px;
                        margin-bottom: 32px;
                        max-width: 350px;
                        font-size: ${fonts.size.normal};
                        font-weight: ${fonts.weight.regular};
                        color: ${({ theme }) => theme.colors.color4.base};
                    }
                }
            }
            &.image-col {
                align-self: end;
                .image-links {
                    text-align: center;
                    margin-right: 100px;
                    .image {
                        img {
                            height: 220px;
                        }
                    }
                    .links {
                        a {
                        }
                    }
                }
            }
        }
    }
    ${mediaQuery.lessThan('tablet')`${mobileCss}`}
`;
