import {
    InfoPageContainer,
    InfoPageStatusType,
} from 'widgets/InfoPageContainer';
import { Button, InputField } from 'components';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { ComingSoonStyled } from './index.styles';
import { t } from 'i18next';
import { Helmet } from 'react-helmet';
import { SocialButtonList } from 'widgets/social-buttons';

export interface ComingSoonProps {}

interface MaintenancePageLayoutValues {
    text: string;
}

const ComingSoon = () => {
    const validation = yup.object().shape({
        name: yup.string().required(t('general.required')),
    });
    const formik = useFormik<MaintenancePageLayoutValues>({
        initialValues: {
            text: '',
        },
        onSubmit: () => {},
        validationSchema: validation,
    });

    const pageStatus: InfoPageStatusType = 'coming_soon';
    let pageData = {
        warningTitle: t('coming_soon.warningTitle'),
        description: t('coming_soon.description'),
    };
    return (
        <>
            <Helmet>
                <title>{t('seo.comingSoon.title')}</title>
                <meta
                    name="description"
                    content={t('seo.comingSoon.description')}
                ></meta>
                <meta
                    name="keywords"
                    content={t('seo.comingSoon.keywords')}
                ></meta>
                <meta name="robots" content="noindex"></meta>
            </Helmet>
            <ComingSoonStyled className="coming-soon">
                <InfoPageContainer
                    showButton={false}
                    status={pageStatus}
                    warningTitle={pageData.warningTitle}
                >
                    <hr className="hr" />
                    <h1
                        className="coming-soon-text"
                        dangerouslySetInnerHTML={{
                            __html: pageData.description
                                ? pageData.description
                                : '',
                        }}
                    ></h1>
                    <div className="input-container">
                        <InputField
                            form={formik}
                            formName="text"
                            placeholder="E-posta Adresiniz"
                        ></InputField>
                        <Button type="primary">Hazır Olduğunda Bildir</Button>
                    </div>
                    <SocialButtonList />
                </InfoPageContainer>
            </ComingSoonStyled>
        </>
    );
};

export default ComingSoon;
