import { fonts } from 'common/theme/font';
import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';

const mobileAccountPageCss = css`
    .banner-content {
        padding-left: 0;
    }
`;
export const Container = styled.div`
    .banner-container {
        display: flex;

        background: linear-gradient(87.08deg, #169dd6 0%, #2bb673 100%);
        border-radius: 6px;
        position: relative;
        padding-left: 10px;
        padding-right: 20px;

        img {
            border-radius: 6px;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 0;
        }

        button {
            margin-left: 40px;
        }
    }

    .banner-content {
        width: 100%;
        display: flex;
        justify-content: space-between;

        align-items: center;

        padding-left: 72px;
        position: relative;
        z-index: 1;
    }

    .banner-text {
        padding-top: 20px;
        padding-bottom: 4px;

        font-size: ${fonts.size.medium};
        font-weight: ${fonts.weight.semibold};
        color: ${({ theme }) => theme.colors.staticColor.color1.base};
    }

    .light-text {
        padding-bottom: 20px;

        font-size: ${fonts.size.small};
        font-weight: ${fonts.weight.regular};
        color: ${({ theme }) => theme.colors.staticColor.color1.base};
    }

    .ant-btn {
        font-size: ${fonts.size.medium};
        font-weight: ${fonts.weight.semibold};

        align-items: center;

        background-color: ${({ theme }) =>
            theme.colors.staticColor.color1.base}!important;
        box-shadow: 3px 0px 10px rgba(255, 255, 255, 0.3);
        border-radius: 4px;
        &:hover,
        &:focus,
        &:active {
            background-color: ${({ theme }) =>
                theme.colors.staticColor.color1.base}!important;
        }
        & > span {
            color: ${({ theme }) =>
                theme.colors.staticColor.color1.second4} !important;
        }
    }
    ${mediaQuery.lessThan('xltablet')`${mobileAccountPageCss}`}
`;
