import styled from 'styled-components';

export const SocialStyled = styled.div`
    ul {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: nowrap;
        li {
            margin-bottom: 0;
            margin-right: 10px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
`;
