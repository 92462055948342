import { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';

const mobileCss = css`
    .forget-password-steps-modal {
        .ant-modal-content {
            max-width: 330px !important;
            width: 100%;
            .ant-modal-body {
                .step-MailAdress,
                .step-ChangePassword,
                .step-Verification {
                    min-width: 100%;
                    max-width: 100%;
                    width: 100%;
                }
            }
        }
    }
`;

export const ForgetPasswordStepsModalCss = css`
    .forget-password-steps-modal {
        .ant-modal-content {
            .ant-modal-body {
                margin: 0px !important;
                padding: 20px 0px !important;
                max-width: 360px !important;
                width: 100%;
                .step-MailAdress,
                .step-ChangePassword,
                .step-Verification {
                    min-width: 360px;
                    max-width: 360px;
                    width: 100%;
                }

                .step-Verification {
                    .code-inputs {
                        input {
                            width: 41.5px;
                        }
                    }
                    .cancel-next-buttons {
                        margin-right: 24px;
                    }
                }

                .step-Success {
                    max-width: 510px;
                    width: 100%;
                }
            }
        }
    }

    ${mediaQuery.lessThan('xltablet')`${mobileCss}`}
`;
