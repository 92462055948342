import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { InputProps } from 'components/input';

import useBalanceApi from 'api/balance/balance.api';
import { AddressGetListData } from 'api/balance/balance.model';

import { useAddressIbanAddForm } from 'common/hooks/address-iban-add-form-modal';

import { Button } from 'components/button';
import { InputSelection, InputSelectionItem } from 'components/input-selection';
import { IbanAutoCompleteStyled } from './index.styles';
import { useConfirm } from 'components/confirmation';
import { useTranslation } from 'react-i18next';
import { showSuccessNotification } from 'common/utils/UserNotifications';

export interface IbanAutoCompleteProps extends InputProps {
    title: string;
    proKey: string;
    symbol: string;
    regexName: any;
    onChange?: (value: any) => void;
}

const IbanAutoCompleteWrapper = (props: IbanAutoCompleteProps) => {
    const { t } = useTranslation();
    const { addressGetList, addressDelete } = useBalanceApi();
    const [markAsInvalid, setInvalid] = useState<boolean | undefined>(
        props.error
    );
    const confirm = useConfirm({
        title: t('withdrawalForm.check.addressForm.deletePopupTitle'),
        okText: t('withdrawalForm.check.addressForm.deletePopupOk'),
        cancelText: t('withdrawalForm.check.addressForm.deletePopupCancel'),
        closable: true,
        icon: 'error-circle-filled',
    });
    const onAddressIbanFormSuccess = (): void => {
        ibanGetListData();
    };
    const providerCurrency = {
        title: props.title,
        provider: props.proKey,
        currency: props.symbol,
        regexName: props.regexName,
    };
    const { placeholder, openAddressIbanAddForm } = useAddressIbanAddForm({
        ...providerCurrency,
        triggerAddressIbanFormSuccess: onAddressIbanFormSuccess,
    });
    const [preSymbol, setPreSymbol] = useState<string>('');
    const [data, setData] = useState<AddressGetListData[]>();
    const addressList = useMemo<InputSelectionItem[]>(() => {
        const items: InputSelectionItem[] = [];
        data?.forEach((item) => {
            items.push({
                title: item.name,
                detail: item.addr,
            });
        });
        return items;
    }, [data]);
    const deleteAddressList = useCallback(
        async (address) => {
            try {
                await addressDelete({
                    address: address,
                    provider: props.proKey,
                    currency: props.symbol,
                });
                showSuccessNotification(
                    t('withdrawalForm.check.addressForm.deleteSuccess'),
                    ''
                );
                ibanGetListData();
            } catch (err) {}
        },
        [props.symbol]
    );
    const ibanGetListData = async () => {
        try {
            const response = await addressGetList({
                provider: props.proKey,
                currency: props.symbol,
            });
            setData(response.addresses);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (
            props.symbol &&
            preSymbol !== props.symbol &&
            props.title == 'Iban'
        ) {
            ibanGetListData();
        }
        setPreSymbol(props.symbol);
    }, [props.title]);
    useEffect(() => {
        setInvalid(props.error);
    }, [props.error]);

    const hanldeChange = (e: any) => {
        props.onChange && props.onChange(e);
    };

    return (
        <IbanAutoCompleteStyled className="address-auto-complete">
            {placeholder()}
            <InputSelection
                onChange={hanldeChange}
                markAsInvalid={markAsInvalid}
                buttonAction={(detail) =>
                    confirm.openConfirm({
                        onOk: () => {
                            deleteAddressList(detail);
                        },
                    })
                }
                items={addressList}
                label={t('withdrawalForm.check.addressForm.ibanLabel')}
            />
            <div className="add-button">
                <Button
                    type="link"
                    onClick={() => {
                        openAddressIbanAddForm();
                    }}
                >
                    {t('withdrawalForm.check.addressForm.ibanAdd')}
                </Button>
            </div>
        </IbanAutoCompleteStyled>
    );
};

export const IbanAutoComplete = React.memo(IbanAutoCompleteWrapper);
