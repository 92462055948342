import {
    createContext,
    useContext,
    useState,
    useCallback,
    useEffect,
} from 'react';
import useCommonApi from './common.api';
import {
    CurrencyModel,
    FetchCitiesResponse,
    PingResponse,
    IsBlockedResponse,
} from './common.model';

interface SourceState {
    ping: PingResponse;
    currencies?: CurrencyModel[];
    pairs?: any;
    cities?: FetchCitiesResponse;
    coins?: string[];
    isblocked?: IsBlockedResponse[];
}

interface CommonSourceContextState {
    source: SourceState;
    isReadyApp: boolean;
    updateData: (
        key: keyof SourceState,
        value: SourceState[keyof SourceState]
    ) => void;
}

const CommonSourceContext = createContext<CommonSourceContextState>(
    {} as CommonSourceContextState
);

interface CommonSourceProviderProps {
    children: React.ReactNode;
}

export const CommonSourceProvider = ({
    children,
}: CommonSourceProviderProps) => {
    const { ping } = useCommonApi();

    const [state, setState] = useState<SourceState>({} as SourceState);
    const [isReadyApp, setReady] = useState<boolean>(false);

    const fetchInit = useCallback(async () => {
        const results = await Promise.all([
            await ping(),
            // await fetchCurrencies(),
            // await fetchPairs(),
        ]);

        setState({
            ping: results[0],
        });
        setReady(true);
    }, []);

    useEffect(() => {
        if (!isReadyApp) {
            fetchInit();
        }
    }, [isReadyApp]);

    const updateData = useCallback(
        (key: keyof SourceState, value: SourceState[keyof SourceState]) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    [key]: value,
                };
            });
        },
        []
    );

    return (
        <CommonSourceContext.Provider
            value={{ source: state, isReadyApp, updateData }}
        >
            {children}
        </CommonSourceContext.Provider>
    );
};

// interface UseCommonSourceProps {
//     source: SourceType[];
// }

interface UseCommonSourceReturn {
    sources: SourceState;
    isReadyApp: boolean;
}

type SourceType = keyof SourceState;

interface UseCommonSourceProps {
    source?: SourceType[];
}

export const useCommonSource = ({
    source,
}: UseCommonSourceProps): UseCommonSourceReturn => {
    const {
        source: sources,
        isReadyApp,
        updateData,
    } = useContext(CommonSourceContext);
    const { fetchCurrencies, fetchPairs } = useCommonApi();

    // Currencies
    const getCurrencies = useCallback(async () => {
        const response = await fetchCurrencies();
        updateData('currencies', response);
    }, [updateData, fetchCurrencies]);

    // Currencies
    const getPairs = useCallback(async () => {
        const response = await fetchPairs();
        updateData(
            'pairs',
            response && response['TRY'] ? Object.values(response['TRY']) : []
        );
        updateData(
            'coins',
            response && response['TRY'] ? Object.values(response['TRY']) : []
        );
    }, [updateData, fetchPairs]);

    useEffect(() => {
        source?.forEach((item: SourceType) => {
            if (!sources[item]) {
                if (item === 'currencies') {
                    getCurrencies();
                } else if (item === 'pairs') {
                    getPairs();
                }
            }
        });
    }, []);

    return { sources, isReadyApp };
};
