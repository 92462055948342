import { useRef } from 'react';
import { HttpResponse } from 'common/http/http.model';
import httpService from 'common/http/http.service';
import {
    CoinPriority,
    CoinPriorityResponse,
    CommonApiCalls,
    CurrencyModel,
    FetchCitiesResponse,
    FetchCurrenciesResponse,
    PingResponse,
    WitdrawCommission,
    IsBlockedResponse,
    IsBlockedRequest,
    FiatProvidersResponse,
} from './common.model';
import {
    coinPriorityResponseMapping,
    currenciesMapping,
} from './common.mapping';

const endpointUrlPath = {
    ping: '/lg/assets.jsp',
    currencies: '/crr/currencies',
    pairs: '/crr/pairs',
    cities: '/lg/user/cities',
    status: '/lg/user/status',
    priority: 'crr/priority',
    withdrawinfo: 'crr/withdrawinfo',
    isblocked: 'crr/isblocked',
    fiatproviders: 'crr/fiatproviders',
};

const useCommonApi = (): CommonApiCalls => {
    const generateApiCalls = (): CommonApiCalls => {
        return {
            ping: (): HttpResponse<PingResponse> =>
                httpService.client.get(endpointUrlPath.ping),
            fetchCurrencies: async (): Promise<CurrencyModel[]> => {
                const res = (await httpService.client.get(
                    endpointUrlPath.currencies
                )) as FetchCurrenciesResponse;

                return currenciesMapping(res.currencies);
            },
            // (): HttpResponse<FetchCurrenciesResponse> =>
            //     httpService.client.get(endpointUrlPath.currencies),
            fetchPairs: (): HttpResponse<any> =>
                httpService.client.get(endpointUrlPath.pairs),
            fetchCities: (): HttpResponse<FetchCitiesResponse> =>
                httpService.client.get(endpointUrlPath.cities),
            fetchStatus: async (): Promise<any> => {
                const res = (await httpService.client.get(
                    endpointUrlPath.status
                )) as any;

                return res;
            },
            fetchPriorities: async (): Promise<CoinPriority[]> => {
                const res = (await httpService.client.get(
                    endpointUrlPath.priority
                )) as CoinPriorityResponse[];

                return coinPriorityResponseMapping(res);
            },

            fetchWitdrawCommission: (): Promise<WitdrawCommission[]> => {
                return httpService.client.get(endpointUrlPath.withdrawinfo);
            },
            fetchIsBlocked: async (
                payload: IsBlockedRequest
            ): Promise<IsBlockedResponse> => {
                return httpService.client.get(
                    `${endpointUrlPath.isblocked}/${payload.coin}`
                );
            },
            getFiatProvider: async (
                payload: string
            ): Promise<FiatProvidersResponse> => {
                return httpService.client.get(
                    `${endpointUrlPath.fiatproviders}/${payload}`
                );
            },
        };
    };
    const commonCallsRef = useRef<CommonApiCalls>(generateApiCalls());

    return commonCallsRef.current;
};

export default useCommonApi;
