import { CheckboxProps as AntCheckboxProps } from 'antd';
import { CheckboxStyled } from './index.styles';
import React from 'react';

export type CheckBoxType = 'checkbox';

type AllowedAntCheckboxTypes =
    | 'name'
    | 'disabled'
    | 'children'
    | 'onChange'
    | 'className'
    | 'checked'
    | 'defaultChecked';

export interface CheckboxProps
    extends Pick<AntCheckboxProps, AllowedAntCheckboxTypes> {
    type?: CheckBoxType;
    label?: string;
}

const WrappedCheckbox = ({
    type = 'checkbox',
    children,
    label,
    className: _className = '',
    ...props
}: CheckboxProps) => {
    const extraProps: Pick<AntCheckboxProps, 'className'> = {};

    return (
        <CheckboxStyled type={type} {...props} {...extraProps}>
            {label || children}
        </CheckboxStyled>
    );
};

export const Checkbox = React.memo(WrappedCheckbox);
