import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';
import { fonts } from 'common/theme/font';

const tabletCss = css`
    &.code-input-container {
        display: flex;
        flex-direction: column;
        align-items: start;
        max-width: 300px;
        padding-left: 5px;
        .code-inputs {
            display: flex;
            justify-content: start;
            align-items: center;

            input {
                border: none;
                background-image: none;
                background-color: transparent;
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none;
                text-align: center;
                height: 40px;
                width: 35px;
                border-radius: 4px;
                margin-right: 12px;
                border: 1px solid ${({ theme }) => theme.colors.color2.base};
                background-color: ${({ theme }) => theme.colors.color1.base};
                color: ${({ theme }) => theme.colors.color4.base};
                font-weight: ${fonts.weight.semibold};
                font-size: ${fonts.size.normal};
                padding: 13px 14px;

                &:focus,
                &.filled {
                    outline: none;
                    background: ${({ theme }) =>
                        theme.colors.staticColor.color2.opacity2};
                    border: 1px solid
                        ${({ theme }) => theme.colors.staticColor.color2.base};
                }

                &.invalid {
                    background-color: ${({ theme }) =>
                        theme.colors.staticColor.color3.second3};
                    border: 1px solid
                        ${({ theme }) => theme.colors.staticColor.color3.base};
                    color: ${({ theme }) =>
                        theme.colors.staticColor.color1.second3}!important;
                }
            }
        }
    }
`;
const mobileCss = css`
    &.code-input-container {
        display: flex;
        flex-direction: column;
        align-items: start;
        max-width: 300px;
        padding-left: 5px;
        .code-inputs {
            display: flex;
            justify-content: start;
            align-items: center;

            input {
                border: none;
                background-image: none;
                background-color: transparent;
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none;
                text-align: center;
                height: 40px;
                width: 37px;
                border-radius: 4px;
                margin-right: 12px;
                border: 1px solid ${({ theme }) => theme.colors.color2.base};
                background-color: ${({ theme }) => theme.colors.color1.base};
                color: ${({ theme }) => theme.colors.color4.base};
                font-weight: ${fonts.weight.semibold};
                font-size: ${fonts.size.normal};
                padding: 13px 14px;

                &:focus,
                &.filled {
                    outline: none;
                    background: ${({ theme }) =>
                        theme.colors.staticColor.color2.opacity2};
                    border: 1px solid
                        ${({ theme }) => theme.colors.staticColor.color2.base};
                }

                &.invalid {
                    background-color: ${({ theme }) =>
                        theme.colors.staticColor.color3.second3};
                    border: 1px solid
                        ${({ theme }) => theme.colors.staticColor.color3.base};
                    color: ${({ theme }) =>
                        theme.colors.staticColor.color1.second3}!important;
                }
            }
        }
    }
`;

export const Container = styled.div`
    &.code-input-container {
        display: flex;
        flex-direction: column;
        align-items: start;
        max-width: 300px;
        padding-left: 5px;
        .code-label-container {
            display: flex;
            width: 100%;

            .code-label {
                text-align: initial;
                flex: 1;
                margin-bottom: 10px;
                font-weight: ${fonts.weight.semibold};
                font-size: ${fonts.size.small};
                color: ${({ theme }) => theme.colors.color2.base};
            }

            .code-message {
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                .message {
                    font-size: 12px;
                    line-height: 18px;
                    font-weight: 700;
                    color: ${({ theme }) => theme.colors.color4.base};
                    -webkit-transition: all 0.2s;
                    transition: all 0.2s;
                }

                .message-icon {
                    color: ${({ theme }) =>
                        theme.colors.staticColor.color2.base};
                    margin-left: 8px;
                    svg {
                        margin-top: 4px;
                    }
                }

                &.invalid {
                    .message {
                        color: ${({ theme }) => theme.colors.color3.base};
                    }

                    .message-icon {
                        color: ${({ theme }) => theme.colors.color2.base};
                    }
                }
            }
        }

        .code-inputs {
            display: flex;
            justify-content: start;
            align-items: center;

            input {
                border: none;
                background-image: none;
                background-color: transparent;
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none;
                text-align: center;
                height: 48px;
                width: 40px;
                border-radius: 4px;
                margin-right: 12px;
                border: 1px solid ${({ theme }) => theme.colors.color2.base};
                background-color: ${({ theme }) => theme.colors.color1.base};
                color: ${({ theme }) => theme.colors.color4.base};
                font-weight: ${fonts.weight.semibold};
                font-size: ${fonts.size.normal};
                padding: 13px 14px;

                &:focus,
                &.filled {
                    outline: none;
                    background: ${({ theme }) =>
                        theme.colors.staticColor.color2.opacity2};
                    border: 1px solid
                        ${({ theme }) => theme.colors.staticColor.color2.base};
                }

                &.invalid {
                    background-color: ${({ theme }) =>
                        theme.colors.staticColor.color3.second3};
                    border: 1px solid
                        ${({ theme }) => theme.colors.staticColor.color3.base};
                    color: ${({ theme }) =>
                        theme.colors.staticColor.color1.second3}!important;
                }
            }
        }
        .invalid-ConfirmEmail-error-message {
            display: flex;
            font-size: ${fonts.size.small};
            color: ${({ theme }) =>
                theme.colors.staticColor.color3.base} !important;
            margin-top: 10px;
            margin-bottom: 20px;
        }
    }

    ${mediaQuery.between('mobile', 'desktop')`${tabletCss}`}
    ${mediaQuery.lessThan('tablet')`${mobileCss}`}
`;
