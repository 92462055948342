import * as S from './index.styles';
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { IconType } from 'components/svg-icon/icon-types';
import { SvgIcon } from 'components/svg-icon';
import { InputProps } from 'components/input';
import { useTranslation } from 'react-i18next';

export interface CodeInputProps extends InputProps {
    value?: string;
    length: number;
    loading: boolean;
    onChange: any;
    message?: string;
    messageIcon?: IconType;
    noFocus?: boolean;
}

export const CodeInput = (props: CodeInputProps) => {
    let { t } = useTranslation();
    const [code, setCode] = useState([...Array(props.length)].map(() => ''));
    const [markAsInvalid, setInvalid] = useState<boolean | undefined>(
        props.error
    );
    const inputs = useRef<(HTMLInputElement | null)[]>([]);
    useEffect(() => setInvalid(props.error), [props.error]);

    const processInput = useCallback(
        (e: any, slot: number) => {
            if (e.keyCode !== 8) {
                const numValue = e.target.value;
                if (/[^0-9]/.test(numValue)) {
                    return;
                }
                setInvalid(false);
                const newCode = [...code];
                newCode[slot] = numValue;
                setCode(newCode);
                if (!props.noFocus) {
                    if (slot !== props.length - 1 && numValue !== '') {
                        inputs.current[slot + 1]?.focus();
                    }
                }
                props.onChange(newCode.join(''));
            }
        },
        [props, code]
    );

    const onKeyUp = useCallback(
        (e: any, slot: number) => {
            if (e.keyCode === 8 && !code[slot] && slot !== 0) {
                const newCode = [...code];
                newCode[slot] = '';
                setCode(newCode);
                inputs.current[slot - 1]?.focus();
            }
            if (e.keyCode === 13 && slot === 5) {
                e.preventDefault();
            }
        },
        [props, code]
    );

    const onPaste = useCallback(
        (e: any) => {
            var clipboardData, pastedData: string;
            e.preventDefault();
            // Get pasted data via clipboard API
            clipboardData = e.clipboardData;
            pastedData = clipboardData.getData('Text');
            // split clipboard text into single characters
            const data = pastedData.split('');
            let isValidDataPasted = true;
            if (data.length !== props.length) {
                isValidDataPasted = false;
            } else {
                data.forEach((item) => {
                    if (isNaN(parseInt(item))) {
                        isValidDataPasted = false;
                    }
                });
            }
            if (isValidDataPasted) {
                setCode(data);
            }
        },
        [props, code]
    );

    return (
        <S.Container className="code-input-container">
            <div className="code-label-container">
                {props.message && (
                    <div
                        className={`code-message ${
                            markAsInvalid ? 'invalid' : ''
                        }`}
                    >
                        <span className="message">{props.message}</span>
                        {props.messageIcon && (
                            <SvgIcon
                                name={props.messageIcon}
                                className="message-icon"
                                size={16}
                            ></SvgIcon>
                        )}
                    </div>
                )}
            </div>

            <div className="code-inputs">
                {code.map((num, idx) => {
                    return (
                        <input
                            key={idx}
                            type="text"
                            inputMode="numeric"
                            maxLength={1}
                            value={num}
                            className={`
                              ${markAsInvalid ? 'invalid' : ''} 
                              ${num && num.length > 0 ? 'filled' : ''}
                            `}
                            autoFocus={!code[0].length && idx === 0}
                            readOnly={props.loading}
                            onChange={(e: any) => processInput(e, idx)}
                            onKeyUp={(e: any) => onKeyUp(e, idx)}
                            onPaste={(e: any) => onPaste(e)}
                            onFocus={(e: any) => e.target.select()}
                            ref={(ref: HTMLInputElement | null) =>
                                inputs.current.push(ref)
                            }
                            name={'code-' + idx}
                            autoComplete="off"
                        />
                    );
                })}
            </div>
            {props.error && (
                <div className="invalid-ConfirmEmail-error-message">
                    {t('changePassword2FAConfirm.codeInputError')}
                </div>
            )}
        </S.Container>
    );
};
