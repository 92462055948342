import { Button } from 'components/button';
import { StatusCardStyled } from './index.styles';

type StatusType = 'error' | 'success' | 'verify-account-success';

export interface StatusCardProps {
    title?: string;
    description?: string;
    status?: StatusType;
    buttontext?: string;
    onButtonClick?: () => void;
}

export const StatusCard = ({
    title,
    description,
    status = 'success',
    buttontext = 'Tamam',
    onButtonClick,
}: StatusCardProps) => {
    return (
        <StatusCardStyled className="status-card-container">
            <img
                src={`/assets/images/${status}.svg`}
                alt={title}
                className="status-img"
            />
            <div className="title-container">{title}</div>
            <div className="description-container">{description}</div>
            <Button type="primary" onClick={onButtonClick}>
                {buttontext}
            </Button>
        </StatusCardStyled>
    );
};
