import { notification } from 'antd';
import { t } from 'i18next';
import parse from 'html-react-parser';

export function showExceptionNotification(error: any) {
    if (error?.data?.error === 'tokent.notfound') {
        // showSessionExpiredNotification();
        return;
    }
    const knownError = allErrors.findIndex((err) => err === error.data.error);
    if (knownError !== -1) {
        showErrorNotification(allErrors[knownError]);
        return;
    }
    showErrorNotification(error?.data?.error || '');
}

export function showSessionExpiredNotification() {
    notification.open({
        message: t('exceptions.sessionExpired.message'),
        description: parse(t('exceptions.sessionExpired.description')),
        type: 'error',
        duration: 1000,
        top: 70,
    });
}

export function showErrorNotification(failedAction: string, duration?: number) {
    let description = '';
    if (failedAction && failedAction.length > 0) {
        const knownError = allErrors.findIndex((err) => err === failedAction);
        description =
            knownError !== -1
                ? t(`exceptions.knownErrors.${failedAction}`)
                : `${failedAction} `;
    }
    // description += t('exceptions.general.description');
    notification.open({
        message: t('exceptions.general.message'),
        description: parse(description),
        type: 'error',
        duration: duration || 2,
        top: 70,
    });
}

export function showSuccessNotification(
    successTitle: string,
    successDetail: string,
    duration?: number
) {
    notification.open({
        message: successTitle,
        description: parse(successDetail),
        type: 'success',
        duration: duration || 2,
        top: 70,
    });
}

export function showWarningNotification(
    warningTitle: string,
    warningDetail: string
) {
    notification.open({
        message: warningTitle,
        description: parse(warningDetail),
        type: 'warning',
        top: 70,
    });
}

export function showInfoNotification(infoTitle: string, infoDetail: string) {
    notification.open({
        message: infoTitle,
        description: parse(infoDetail),
        type: 'info',
        top: 70,
    });
}

export const errorsHandledByUI = [
    'invalid_code',
    'invalid_password',
    'user_have_email_change',
    'user_invalid_login',
    'email_not_available',
];

export const allErrors = [
    // yuex blc
    'bad_request',
    'address_field_is_empty',
    'user_balance_error',
    'page_num_not_available',
    'sorting_error',
    'pairs_generic_error',
    'currency_generic_error',
    'request_not_validated',
    'user_not_available',
    'unexpected_error',
    'insufficient_balance',
    'min_payment_violation',
    'max_payment_violation',
    'daily_deposit_limit_exceeded',
    'monthly_deposit_limit_exceeded',
    'daily_withdrawal_limit_exceeded',
    'monthly_withdrawal_limit_exceeded',
    'have_waiting_deposit',
    'dont_have_waiting_deposit',
    'have_unused_addr',
    'prepare_payment_error',
    'send_payment_error',
    'currency_disabled',
    'currency_deposit_disabled',
    'currency_withdrawal_disabled',
    'currency_provider_disabled',
    'currency_provider_deposit_disabled',
    'currency_provider_withdrawal_disabled',
    'address_not_validated',
    'err_kyc_policy',
    'currency_is_not_crypto',
    'send_addr_error_expiration',
    'has_waiting_payment',
    'check_payment_block_error',
    'crypto_withdraw_blocked',
    'fiat_withdraw_blocked',
    'crypto_deposit_blocked',
    'fiat_deposit_blocked',
    'invalid_withdrawal_confirmation_code',
    'invalid_saveaddr_confirmation_mailcode',
    'invalid_saveaddr_confirmation_passcode',
    'dont_have_waiting_withdrawal',
    'have_waiting_withdrawal',
    'withdrawal_expired',
    'withdrawal_confirm_mail_already_sent',
    'withdrawal_should_be_waiting',
    'user_payment_not_found',
    'user_payment_permission_error',
    'session_cant_retrieve',
    'user_not_found',
    'have_unexpired_save_addr',
    'user_have_saveaddr_request',
    'invalid_operation',
    // yuex lg
    'user_invalid_login',
    'user_password_invalid',
    'user_multiple_login',
    'user_mail_not_validated',
    'user_frozen_type_login_not_enabled',
    'login_sms_code_incorrect_or_expired',
    'same_phonenumber_error',
    'totp_can_not_validate',
    '2fa_passcode_required',
    'email_change_request_not_confirmed',
    'phonenumber_change_request_not_confirmed',
    'totp_not_confirmed',
    'totp_required',
    'totp_cant_generate',
    'phone_not_available',
    'email_not_available',
    'invalid_current_password',
    'cant_write_session',
    'cant_delete_file',
    'cant_delete_record',
    'file_cant_fetch',
    'file_cant_read',
    'file_cant_save',
    'file_cant_upload',
    'user_image_not_found',
    'user_images_not_found',
    'file_cant_secure_check',
    'file_not_secure',
    'pair_error',
    'invitation_code_required',
    'invitation_code_not_validated',
    'invitation_code_already_used',
    'invitation_code_not_available',
    'invitation_code_cancelled',
    'invitation_and_register_mails_not_match',
    'invitation_email_exists',
    'invitation_not_found',
    'invitation_limit_exceed',
    'invitation_system_not_enabled',
    'cities_not_found',
    'towns_not_found',
    'user_fav_coin_not_posted',
    'two_factor_auth_error',
    'id_number_not_validated',
    'id_number_registered',
    'barcode_not_validated',
    'email_not_found',
    'forgot_password_request_already_active',
    'forgot_password_hash_not_valid',
    'password_used_three_times',
    'invalid_mail_validation_code',
    'expired_mail_validation_code',
    'mail_validation_code_already_sent',
    'security_images_not_found',
    'recaptcha_verify',
    'login_request_not_validated',
    'same_antispam_key',
    'invalid_change_password_code',
    'expired_change_password_code',
    'have_unexpired_password_change',
    'login_required',
    'user_have_totp_change',
    'user_have_email_change',
    'user_have_phonenumber_change',
    'error_two_factor_sms_already_google',
    //yuex plc
    'amount_error',
    'price_error',
    'stop_error',
    'wait_process',
    'wait_60_sec_to_continue',
    'order_not_found',
    'order_not_updated',
    'order_not_enabled',
    'insufficient_funds',
    'insufficient_asset',
    'invalid_order_type',
    'invalid_order_side',
    'invalid_pair',
    'stop_price_error',
    'limit_price_error',
    'asset_not_found',
    'asset_not_block',
    'asset_not_enough',
    'order_total_less_min',
    'order_total_greater_max',
    //yuex crr
    'user_frozen_type',
    'coin_not_found',
    'db_action_error',
    //yuex cmp
    'user_ref_codes_not_available',
    'ref_code_not_exist',
    'def_camp_not_found',
    'ratios_not_available',
    'ref_user_list_not_available',
    //yuex spp
    'desk_unavailable',
    'article_not_found',
];
