import { useFormik } from 'formik';
import * as yup from 'yup';
import { Button, NumberField } from 'components';
import { EmailCodeConfirmationStyled } from './index.styles';
import { useTranslation } from 'react-i18next';
import useAuthApi from 'api/auth/auth.api';
// import { ConfirmCodeEmailResponse } from 'api/auth/auth.model';
import { useState } from 'react';

export interface EmailCodeConfirmationValues {
    hash: string;
}

interface EmailCodeConfirmationProps {
    successCallback?: (response: any) => void;
    errorCallback?: (err: any) => void;
}

export const EmailCodeConfirmation = (props: EmailCodeConfirmationProps) => {
    const { confirmCodeEmailChange } = useAuthApi();

    const { t } = useTranslation();
    const [invalidConfirmCodeEmailAttempt, setInvalidConfirmCodeEmailAttempt] =
        useState<boolean>(false);

    const validation = yup.object().shape({
        hash: yup
            .number()
            .required(t('ConfirmCodeEmail.form.validations.requiredcode')),
    });
    const formik = useFormik<EmailCodeConfirmationValues>({
        initialValues: {
            hash: '',
        },
        onSubmit: async (values: EmailCodeConfirmationValues) => {
            setInvalidConfirmCodeEmailAttempt(false);
            try {
                const response = await confirmCodeEmailChange({
                    hash: values.hash,
                });
                props.successCallback && props.successCallback(response);
            } catch (err: any) {
                //props.successCallback && props.successCallback({});
                if (err?.data?.error === 'user_have_email_change') {
                    setInvalidConfirmCodeEmailAttempt(true);
                } else {
                    //props.errorCallback && props.errorCallback(err);
                }
            }
        },
        validationSchema: validation,
    });

    return (
        <EmailCodeConfirmationStyled
            className={
                invalidConfirmCodeEmailAttempt
                    ? `ConfirmCodeEmail-form invalidAttempt`
                    : 'ConfirmCodeEmail-form'
            }
        >
            <form
                onSubmit={formik.handleSubmit}
                onChange={() => {
                    setInvalidConfirmCodeEmailAttempt(false);
                }}
            >
                <div className="codeInputDesc">
                    <label className="normal">
                        {t('ConfirmCodeEmail.form.codeLabel')}
                    </label>{' '}
                </div>

                <NumberField
                    form={formik}
                    autoComplete="off"
                    formName="hash"
                    placeholder={t('ConfirmCodeEmail.form.codePlaceholder')}
                />
                {invalidConfirmCodeEmailAttempt && (
                    <div className="invalid-ConfirmCodeEmail-error-message">
                        {t(
                            'ConfirmCodeEmail.form.validations.invalidConfirmCode'
                        )}
                    </div>
                )}
                {
                    <Button
                        htmlType="submit"
                        type="primary"
                        disabled={!formik.isValid}
                    >
                        {t('ConfirmCodeEmail.form.codeSubmit')}
                    </Button>
                }
            </form>
        </EmailCodeConfirmationStyled>
    );
};
