import styled from 'styled-components';
import { IconType } from './icon-types';
import SpriteSVG from './SpriteSVG';

export const LoadSVGSprite = () => (
    <div style={{ display: 'none' }}>
        <SpriteSVG />
    </div>
);

type IconSize = 4 | 8 | 10 | 12 | 14 | 16 | 18 | 20 | 22 | 24 | 32 | 48 | 64;

interface SvgIconStyledProps extends StyledProps {
    size: IconSize;
    className?: string;
}

export const SvgIconStyled = styled.i<SvgIconStyledProps>`
    font-size: ${(props: { size: IconSize }) => props.size}px;
    svg {
        fill: inherit;
    }
`;

export interface SvgIconProps {
    name: IconType;
    size?: IconSize;
    className?: string;
    fill?: string;
}

export const SvgIcon = ({
    name,
    size = 16,
    className = '',
}: SvgIconProps): JSX.Element => {
    return (
        <SvgIconStyled className={`app-icon ${className}`} size={size}>
            <svg width="1em" height="1em" fill="currentColor">
                <use xlinkHref={`#${name}`} />
            </svg>
        </SvgIconStyled>
    );
};
