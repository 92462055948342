import { fonts } from 'common/theme/font';
import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';

const desktopContainerCss = css`
    width: 991px;
`;

const mobileContainerCss = css`
    width: 100%;
`;

const mobileHeadCss = css`
    font-size: 16px;
    &:before {
        height: 4px;
        bottom: -7px;
    }
`;

const mobileBannerCss = css`
    display: block;
    width: 100%;
    padding: 15px;
    .banner {
        padding-right: 0;
        img {
            max-width: 100% !important;
            width: 100% !important;
        }
    }
    .ant-carousel {
        margin-top: 20px;
        .slick-list {
            .slick-track {
                .slick-slide {
                    .ant-row {
                        .ant-col {
                            width: 100%;
                            flex: 100%;
                            max-width: 100%;
                            &:first-child {
                                margin-bottom: 10px;
                            }
                            .banner {
                                img {
                                    max-width: 300px;
                                    margin: 0 auto;
                                }
                            }
                            .time {
                            }
                            .title {
                                margin-top: 10px;
                                .banner-title {
                                    font-size: 16px;
                                    line-height: 16px;
                                    margin-bottom: 10px;
                                }
                            }
                            .detail {
                                .banner-text {
                                    font-size: 12px;
                                    line-height: 16px;
                                }
                            }
                            .ant-btn {
                                margin-top: 5px;
                                span {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .slick-dots {
            bottom: inherit !important;
            top: -30px !important;
            right: 0 !important;
            flex-direction: row !important;
            li {
                width: 10px;
                height: 6px;
                &.slick-active {
                    width: 20px;
                    height: 6px;
                }
            }
        }
    }
`;

const mobileMiddleCss = css`
    padding: 15px;
    .sub-banner {
        text-align: center;
        margin-right: 0;
        margin-bottom: 20px;
    }
    a {
        width: 100%;
        .detail {
            p.box-text {
                max-width: 100%;
            }
        }
        .ant-btn {
            margin-top: 0px;
        }
    }
    .ant-row {
        .ant-col {
            flex: 50%;
            max-width: 50%;
            margin-bottom: 15px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .ant-tabs-nav {
            margin-bottom: 0;
        }
    }
`;

export const Container = styled.section`
    width: 1080px;
    margin: 0 auto;
    .ant-pagination {
        margin-top: 40px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    ${mediaQuery.lessThan('xldesktop')`${desktopContainerCss}`}
    ${mediaQuery.lessThan('xltablet')`${mobileContainerCss}`}
`;

export const Banner = styled.section`
    background-color: ${({ theme }) => theme.colors.color2.opacity4};
    padding: 40px 0;
    .banner {
        padding-right: 50px;
        img {
            border-radius: 15px;
        }
    }
    .ant-carousel {
        position: relative;
        margin-top: 40px;
        .slick-dots {
            left: inherit;
            margin: 0 !important;
            flex-direction: column !important;
            top: 0;
            bottom: 0;
            li {
                width: 5px;
                height: 10px;
                opacity: 1;
                margin-bottom: 5px;
                button {
                    border-radius: 20px;
                    background-color: ${({ theme }) =>
                        theme.colors.color3.opacity6};
                    height: 100%;
                }
                &.slick-active {
                    height: 22px;
                    button {
                        background-color: ${({ theme }) =>
                            theme.colors.staticColor.color2.base};
                    }
                }
            }
        }
        img {
            max-width: 100%;
        }
    }
    ${mediaQuery.lessThan('xltablet')`${mobileBannerCss}`}
`;

export const Head = styled.h1`
    font-size: 24px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.color4.base};
    position: relative;
    &:before {
        content: '';
        display: block;
        position: absolute;
        background-color: ${({ theme }) =>
            theme.colors.staticColor.color2.base};
        bottom: -10px;
        width: 31px;
        height: 5px;
        border-radius: 4px;
    }
    ${mediaQuery.lessThan('xltablet')`${mobileHeadCss}`}
`;

export const Info = styled.div`
    display: block;
    margin-bottom: 40px;
    .time {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        span {
            font-size: ${fonts.size.small};
            font-weight: ${fonts.weight.semibold};
            color: ${({ theme }) => theme.colors.staticColor.color2.base};
        }
        .date {
            position: relative;
            padding-right: 10px;
            &:after {
                content: '';
                position: absolute;
                right: 0;
                top: 2px;
                width: 1px;
                height: 15px;
                background-color: ${({ theme }) =>
                    theme.colors.color3.opacity6};
            }
        }
        .time {
            margin-left: 10px;
            .app-icon {
                margin-right: 5px;
                line-height: 12px;
                svg {
                    color: ${({ theme }) =>
                        theme.colors.staticColor.color2.base};
                }
            }
            span {
            }
        }
    }
    .title {
        h1 {
            font-weight: ${fonts.weight.bold};
            color: ${({ theme }) => theme.colors.color4.base};
            &.banner-title {
                margin-bottom: 25px;
                font-size: ${fonts.size.xlarge};
            }
            &.box-title {
                font-size: 16px;
                margin-top: 5px;
                margin-bottom: 10px;
            }
        }
    }
    .detail {
        p {
            font-weight: ${fonts.weight.light};
            color: ${({ theme }) => theme.colors.color4.base};
            &.banner-text {
                max-width: 406px;
                font-size: ${fonts.size.normal};
                line-height: 21px;
            }
            &.box-text {
                max-width: 192px;
                font-size: ${fonts.size.small};
                line-height: 16px;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
    .ant-btn {
        margin-top: 15px;
        span {
        }
        .app-icon {
            margin-left: 5px !important;
        }
    }
`;

export const Middle = styled.section`
    padding: 50px 0;
    background-color: ${({ theme }) => theme.colors.color1.base};
    ${mediaQuery.lessThan('tablet')`${mobileMiddleCss}`}
`;

export const Box = styled.article`
    display: flex;
    &.column {
        flex-direction: column;
        max-width: 275px;
    }
    &.row {
        flex-direction: row;
    }
    .sub-banner {
        margin-right: 20px;
        img {
            width: 100%;
            max-width: 275px;
            height: auto;
            max-height: 170px;
            object-fit: cover;
            border-radius: 15px;
        }
    }
`;

export const TabMenu = styled.div`
    width: 100%;
    margin-top: 20px;
    position: relative;
    .searchRow {
        .notFound {
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;
            align-items: center;
            flex: 1;
            color: ${({ theme }) => theme.colors.color3.base};
            line-height: 33px;
            padding: 30px;
            user-select: none;
        }
        .ant-col + .notFound {
            display: none;
        }
    }
    .ant-tabs {
        .ant-tabs-nav-operations {
            display: none !important;
        }
        .ant-tabs-nav {
            &:before {
                display: none;
            }
            .ant-tabs-nav-list {
                .ant-tabs-tab {
                    position: relative;
                    .ant-tabs-tab-btn {
                        font-size: ${fonts.size.normal};
                        font-weight: ${fonts.weight.medium};
                        color: ${({ theme }) => theme.colors.color4.base};
                    }
                    &.ant-tabs-tab-active {
                        .ant-tabs-tab-btn {
                            color: ${({ theme }) =>
                                theme.colors.staticColor.color2.base};
                        }
                    }
                    &:before {
                        content: '';
                        position: absolute;
                        top: 15px;
                        left: -15px;
                        width: 1px;
                        height: 15px;
                        background-color: ${({ theme }) =>
                            theme.colors.color3.opacity6};
                    }
                    &:first-child {
                        &:before {
                            display: none !important;
                        }
                    }
                }
                .ant-tabs-ink-bar {
                    background: ${({ theme }) =>
                        theme.colors.staticColor.color2.base};
                }
            }
        }
    }
    .ant-tabs-content-holder {
        margin-top: 30px;
    }
    .search-field {
        position: absolute;
        top: 10px;
        right: 0;
        max-width: 180px;
        .ant-input {
            border-color: #e8eaeb;
            height: 38px;
        }
        .ant-input-affix-wrapper {
            height: 38px;
            line-height: 0px;
        }
    }
`;
