import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { AddressIbanAddFormStyled } from './index.styles';
import { ModalCard } from 'components/modal-card';
import { InputField } from 'components';
import { useCallback, useState } from 'react';
import { AddressIbanAddFormRequest } from 'api/balance/balance.model';
import useBalanceApi from 'api/balance/balance.api';
import { showSuccessNotification } from 'common/utils/UserNotifications';
import { Checkbox } from 'components/checkbox';

export interface AddressIbanAddFormProps {
    onCancel: () => void;
    onSuccess: () => void;
    title: string;
    provider: string;
    currency: string;
    regexName?: any;
}

export const AddressIbanAddForm = ({
    onCancel,
    onSuccess,
    title,
    provider,
    currency,
    regexName,
}: AddressIbanAddFormProps) => {
    const { t } = useTranslation();
    const { addressIbanAddForm } = useBalanceApi();
    const [hideCheck, setHideCheck] = useState<Boolean>(false);

    const validation = yup.object().shape({
        name: yup
            .string()
            .min(4, t('modal.addressAddForm.nameBetween'))
            .max(20, t('modal.addressAddForm.nameBetween'))
            .required(t('modal.addressAddForm.nameRequired')),
        tag: yup.string(),
        address: yup
            .string()
            .required(t('modal.addressAddForm.addressRequired'))
            .when('provider', (provider): any => {
                if (provider) {
                    return yup
                        .string()
                        .matches(
                            regexName,
                            t('modal.addressAddForm.addressRegex', {
                                type: currency == 'TRY' ? 'IBAN' : currency,
                            })
                        )
                        .required(t('modal.addressAddForm.addressRequired'));
                }
            }),
    });
    const formik = useFormik<AddressIbanAddFormRequest>({
        initialValues: {
            name: '',
            address: '',
            tag: undefined,
            provider: provider,
            currency: currency,
        },
        onSubmit: async (values: AddressIbanAddFormRequest) => {
            try {
                if (currency === 'XRP') {
                    await addressIbanAddForm({
                        name: values.name,
                        address: values.address,
                        provider: provider,
                        currency: currency,
                        tag: values.tag,
                    });
                } else {
                    await addressIbanAddForm({
                        name: values.name,
                        address: values.address,
                        provider: provider,
                        currency: currency,
                    });
                }
                showSuccessNotification(t('modal.addressAddForm.success'), '');
                onSuccess && onSuccess();
                onCancel();
            } catch (err: any) {}
        },
        validationSchema: validation,
    });
    const handleNext = useCallback(() => {
        formik.submitForm();
    }, [formik]);
    const handleHideFilterChange = () => {
        if (!hideCheck) {
            setHideCheck(true);
            formik.setFieldValue('tag', undefined);
        } else {
            setHideCheck(false);
        }
    };
    return (
        <AddressIbanAddFormStyled>
            <ModalCard
                img="add"
                title={
                    title === 'Adres'
                        ? t('modal.addressAddForm.titleAddress')
                        : t('modal.addressAddForm.titleIban')
                }
                onNext={handleNext}
                onCancel={onCancel}
            >
                <div className="form">
                    <form onSubmit={formik.handleSubmit}>
                        <InputField
                            form={formik}
                            key="name"
                            formName="name"
                            label={
                                title === 'Adres'
                                    ? t('modal.addressAddForm.nameLabelAddress')
                                    : t('modal.addressAddForm.nameLabelIban')
                            }
                            placeholder={t(
                                'modal.addressAddForm.namePlaceHolder'
                            )}
                        />
                        {currency === 'XRP' && (
                            <>
                                <div className="ripple">
                                    <span> Destination Tag</span>
                                    <Checkbox
                                        label="Tag Yok"
                                        className="tag"
                                        onChange={() => {
                                            handleHideFilterChange();
                                        }}
                                        checked={hideCheck ? true : false}
                                    ></Checkbox>
                                </div>
                                <InputField
                                    form={formik}
                                    key="tag"
                                    formName="tag"
                                    placeholder={'XRP Destination Tag giriniz'}
                                    disabled={hideCheck === true}
                                />
                            </>
                        )}
                        <InputField
                            form={formik}
                            key="address"
                            formName="address"
                            label={
                                title === 'Adres'
                                    ? t('modal.addressAddForm.addressLabel')
                                    : t('modal.addressAddForm.ibanLabel')
                            }
                            placeholder={
                                title === 'Adres'
                                    ? t(
                                          'modal.addressAddForm.addressPlaceHolder'
                                      )
                                    : t('modal.addressAddForm.ibanPlaceHolder')
                            }
                        />
                    </form>
                </div>
            </ModalCard>
        </AddressIbanAddFormStyled>
    );
};
