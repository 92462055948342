import { CardProps as AntCardProps } from 'antd';
import { CardStyled } from './index.styles';

import React from 'react';

export type CardSizeType = 'default' | 'small';

export interface CardProps extends Pick<AntCardProps, 'title' | 'className'> {
    size?: CardSizeType;
    title?: string;
    children: JSX.Element;
    extra?: JSX.Element;
}

const WrappedCard = ({ title, size, extra, ...props }: CardProps) => {
    const extraProps: Pick<AntCardProps, 'title' | 'className'> = {};

    extraProps.className = `${props.className}`;

    return (
        <CardStyled
            title={title}
            {...props}
            extra={extra}
            {...extraProps}
            size={size}
        >
            {props.children}
        </CardStyled>
    );
};

export const Card = React.memo(WrappedCard);
