//@ts-nocheck
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { Button } from 'components/button';
import { SvgIcon } from 'components/svg-icon';
import { IconType } from 'components/svg-icon/icon-types';
import {
    useCallback,
    useState,
    useMemo,
    useRef,
    RefAttributes,
    ComponentType,
} from 'react';
import * as S from './index.styles';

type TabComponent = ComponentType<Empty & RefAttributes<TabPaneRef>>;

export interface TabItem<T> {
    label: string;
    key: T;
    className?: string;
    icon?: IconType;
    content?: React.ReactNode;
    component?: TabComponent;
}

export interface TabsProps<T> {
    tabs: TabItem<T>[];
    onChangeTab?: (key: T) => void;
    size?: SizeType;
    defaultActiveKey?: T;
    rightComp?: React.ReactNode;
    showActionBtn?: boolean;
    actionBtnText?: string;
    actionBtnIcon?: IconType;
    onActionClick?: () => void;
}

export interface TabPaneRef {
    onActionClick: () => void;
}

export const Tabs = function <T = object>({
    tabs,
    onChangeTab,
    defaultActiveKey,
    showActionBtn,
    actionBtnText,
    onActionClick,
}: TabsProps<T>) {
    const tabRef = useRef<TabPaneRef>(null);

    const handleChangeTab = useCallback(
        (key) => {
            setActiveKey(key);
            onChangeTab && onChangeTab(key as T);
        },
        [onChangeTab]
    );

    const firstKey: T = useMemo(() => {
        return tabs[0].key;
    }, [tabs]);
    const [activeKey, setActiveKey] = useState<T>(defaultActiveKey || firstKey);

    const handleActionClick = useCallback(() => {
        onActionClick && onActionClick();
        tabRef.current?.onActionClick();
    }, [onActionClick, tabRef]);

    const renderTabLabel = (tab: TabItem<T>) => {
        return (
            <>
                {tab.label} {tab.icon && <SvgIcon name={tab.icon} />}
            </>
        );
    };

    return (
        <S.Container
            defaultActiveKey={
                (defaultActiveKey || firstKey) as unknown as string
            }
            onChange={handleChangeTab}
            tabBarExtraContent={{
                right: showActionBtn ? (
                    <Button type="link" onClick={handleActionClick}>
                        {actionBtnText}
                    </Button>
                ) : null,
            }}
        >
            {tabs.map((tab) => {
                return (
                    <S.Tab
                        tab={renderTabLabel(tab)}
                        key={tab.key as unknown as string}
                    >
                        {activeKey === tab.key && (
                            <>
                                {tab.component ? (
                                    <tab.component ref={tabRef} />
                                ) : (
                                    tab.content
                                )}
                            </>
                        )}
                    </S.Tab>
                );
            })}
        </S.Container>
    );
};
