import { useModal } from 'components/modal';
import { useCallback } from 'react';
import { AddressIbanAddForm } from 'widgets/forms/address-iban-add-form';

export interface AddressIbanAddFormProps {
    title: string;
    provider: string;
    currency: string;
    regexName: any;
    triggerAddressIbanFormSuccess: () => void;
}

interface ReturnAddressIbanAddForm {
    placeholder: () => void;
    openAddressIbanAddForm: () => void;
}
export const useAddressIbanAddForm = (
    props: AddressIbanAddFormProps
): ReturnAddressIbanAddForm => {
    const { placeholder, openModal, closeModal } = useModal({
        className: 'address-iban-add-form-modal',
    });

    const handleOpenAddressIbanAddForm = useCallback(() => {
        openModal(
            <AddressIbanAddForm
                onCancel={closeModal}
                {...props}
                onSuccess={() => {
                    props.triggerAddressIbanFormSuccess();
                    closeModal();
                }}
            />
        );
    }, [openModal, props]);

    return {
        placeholder,
        openAddressIbanAddForm: handleOpenAddressIbanAddForm,
    };
};
