import Footer from 'components/home-page/footer';
import { ReactNode } from 'react';
import BaseLayout from '../BaseLayout';
import { GuideScreenLayoutStyled, Content } from './index.styles';

interface GuideScreenLayoutProps {
    children: ReactNode;
}

const CoinScreenLayout = ({ children }: GuideScreenLayoutProps) => {
    return (
        <GuideScreenLayoutStyled>
            <BaseLayout>
                <Content>{children}</Content>
            </BaseLayout>
            <Footer />
        </GuideScreenLayoutStyled>
    );
};

export default CoinScreenLayout;
