import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';
import { fonts } from 'common/theme/font';

const mobileCss = css`
    height: inherit;
    padding: 30px 0;
    .title-container {
        display: none;
    }
    .ant-row {
        padding-bottom: 0px;
        margin: 0 20px;
        max-width: 100%;
        .ant-col {
            &.ant-col-12:first-child {
                display: none;
            }
            &.ant-col-12:last-child {
                max-width: 100%;
                flex: 100%;
            }
            .our-vision {
                .our-vision-list {
                    padding-bottom: 0px !important;
                }
            }
        }
    }
`;

export const Container = styled.section`
    display: block;
    height: 525px;
    background-color: ${({ theme }) => theme.colors.color1.base};
    padding-top: 70px;
    padding-bottom: 100px;
    .title-container {
        align-items: center;
        text-align: center;
        .title {
            font-size: ${fonts.size.xlarge};
            font-weight: ${fonts.weight.bold};
            color: ${({ theme }) => theme.colors.color4.base};
        }
        hr {
            width: 30px;
            height: 4px;
            background-color: ${({ theme }) =>
                theme.colors.staticColor.color2.base};
            border: none;
            border-radius: 3px;
            margin-top: 10px;
        }
    }
    .ant-row {
        align-items: center;
        justify-content: center;
        max-width: 800px;
        margin: 0 auto;
        height: 100%;
        .ant-col {
            .info {
                margin-left: 80px;
                .title {
                    margin-bottom: 50px;
                    h1 {
                        display: flex;
                        align-items: center;
                        margin-bottom: 15px;
                        font-size: ${fonts.size.xxlarge};
                        font-weight: ${fonts.weight.bold};
                        color: ${({ theme }) => theme.colors.color4.base};
                    }
                    .details {
                        margin-top: 20px;
                        margin-bottom: 32px;
                        max-width: 350px;
                        font-size: ${fonts.size.normal};
                        font-weight: ${fonts.weight.regular};
                        color: ${({ theme }) => theme.colors.color4.base};
                    }
                }
            }
            &.image-col {
                .image-links {
                    text-align: center;
                    margin-right: 100px;
                    .image {
                        img {
                            height: 220px;
                        }
                    }
                    .links {
                        a {
                        }
                    }
                }
                .our-vision {
                    .our-vision-header {
                        display: flex;
                        align-items: center;

                        padding-bottom: 24px;
                        img {
                            font-size: ${fonts.size.xlarge};
                            width: 36px;
                            height: 36px;
                            padding: 6px;
                            background: ${({ theme }) =>
                                theme.colors.staticColor.color2.base};
                            border-radius: 4px;
                        }

                        h3 {
                            padding-left: 18px;
                            font-weight: ${fonts.weight.semibold};
                            font-size: ${fonts.size.medium};
                            color: ${({ theme }) => theme.colors.color4.base};
                        }
                    }

                    .our-vision-list {
                        padding-bottom: 30px;
                        .text {
                            position: relative;
                            font-weight: ${fonts.weight.light};
                            font-size: ${fonts.size.medium};
                            color: ${({ theme }) => theme.colors.color4.base};
                            padding-left: 40px;
                            padding-bottom: 8px;
                            &:before {
                                content: '✓';
                                display: block;
                                width: 18px;
                                height: 18px;
                                font-size: ${fonts.size.small};
                                top: 1em;
                                left: 12px;
                                position: absolute;
                                margin-top: -9px;

                                color: ${({ theme }) =>
                                    theme.colors.staticColor.color2.base};
                            }
                        }
                    }
                }
            }
        }
    }
    ${mediaQuery.lessThan('tablet')`${mobileCss}`};
`;
