import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';
import { fonts } from 'common/theme/font';

const tabletCss = css`
    .rowBetween {
        text-align: center;
    }
`;

const mobileCss = css`
    padding-bottom: 20px;
    .ant-row {
        margin: 0 12px !important;

        .ant-col {
            width: 50%;
            max-width: 50%;
            flex: inherit;
            h3 {
                margin-bottom: 10px;
            }
            ul {
                li {
                    margin-bottom: 2px;
                }
            }
            &:nth-child(3),
            &:nth-child(4) {
                margin-top: 30px;
            }
            &:nth-child(5) {
                margin-top: 30px;
                position: relative;
                width: 100%;
                max-width: 100%;
                padding: 0 !important;
                .store {
                    margin-top: 0;
                    position: absolute;
                    top: 0;
                    left: 10px;
                    a {
                        margin-bottom: 5px;
                        img {
                            padding-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
    .social-links {
        padding-left: 8px;
        padding-right: 8px;
        ul {
            display: flex;
            justify-content: flex-start !important;
        }
    }
    .copyright {
        padding-left: 8px;
        padding-right: 8px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .logo {
                margin-bottom: 15px;
            }
            .boxs {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                & > * {
                    border: none;
                    margin: 0;
                    padding: 0;
                    margin-bottom: 5px;
                }
            }
        }
    }
`;

export const Container = styled.footer`
    margin-top: 50px;
    background-color: ${({ theme }) => theme.colors.color4.second2};
    padding: 50px 0px 30px 0px;
    a:hover {
        color: ${({ theme }) => theme.colors.staticColor.color1.base};
    }
    .ant-row {
        max-width: 1200px;
        margin: 0 auto !important;
        &.rowBetween {
            justify-content: space-between;
        }
        .ant-col {
            h3 {
                margin-bottom: 20px;
                font-size: ${fonts.size.medium};
                color: ${({ theme }) => theme.colors.staticColor.color1.base};
            }
            ul {
                li {
                    margin-bottom: 12px;
                    a {
                        font-size: ${fonts.size.small};
                        color: ${({ theme }) =>
                            theme.colors.staticColor.color1.base} !important;
                        font-weight: ${fonts.weight.light};
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .store {
                display: flex;
                flex-direction: column;
                margin: 30px 0;
                text-align: right;
                a {
                    margin-bottom: 15px;
                    img {
                    }
                    &:first-child {
                        img {
                            padding-bottom: 15px;
                            border-bottom: 1px solid;
                            border-color: ${({ theme }) =>
                                theme.colors.staticColor.color1
                                    .second4}!important;
                        }
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .social-links {
                ul {
                    display: flex;
                    justify-content: flex-end;
                    li {
                        margin-bottom: 0;
                        a {
                            opacity: 1;
                        }
                    }
                }
            }
            .iso {
                display: flex;
                flex-direction: column;
                margin-bottom: 30px;
                align-items: flex-end;
                img {
                    width: 75px;
                }
            }
        }
    }
    .copyright {
        font-size: ${fonts.size.small};
        font-weight: ${fonts.weight.light};
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 25px;
        padding-top: 25px;
        border-top: 1px solid;
        border-color: ${({ theme }) =>
            theme.colors.staticColor.color1.second4} !important;
        span {
            font-size: ${fonts.size.small};
            font-weight: ${fonts.weight.light};
            color: ${({ theme }) => theme.colors.staticColor.color1.base};
        }
        .info {
            display: flex;
            color: #fff;
            font-size: ${fonts.size.xSmall};
            .logo {
                width: 90px;
            }
            .boxs {
                display: flex;
                & > * {
                    margin-left: 15px;
                    padding-left: 15px;
                    border-left: solid 1px
                        ${({ theme }) =>
                            theme.colors.staticColor.color1.second4};
                    display: block;
                    line-height: 1.7em;

                    strong {
                        font-weight: ${fonts.weight.regular};
                    }
                    a {
                        color: ${({ theme }) =>
                            theme.colors.staticColor.color1.base} !important;
                    }
                }
            }
        }
    }
    ${mediaQuery.between('tablet', 'desktop')`${tabletCss}`}
    ${mediaQuery.lessThan('tablet')`${mobileCss}`}
`;
