//@ts-nocheck
import styled from 'styled-components';
import { Menu as HeaderMenu } from 'antd';
import { fonts } from '../../common/theme/font';

export const HeaderMenuStyled = styled(HeaderMenu)`
    display: flex;
    justify-content: left;
    box-sizing: border-box;

    font-size: ${fonts.size.normal};
    font-weight: ${fonts.weight.regular};
    color: ${({ theme }) => theme.colors.color4.base};
    background: transparent;
    transition: none !important;

    &.ant-menu-root {
        gap: 24px;
        & > .ant-menu-submenu,
        & > .ant-menu-item {
            padding: 0px !important;
        }
        & > .ant-menu-submenu:first-child {
            order: 1 !important;
        }
    }

    .ant-menu-item {
        font-size: ${fonts.size.large};
        &:after {
            width: 25px;
            margin: 0 auto;
        }
    }
    .ant-menu-title-content {
        font-size: ${fonts.size.large};
    }
    .ant-menu-submenu-active {
        &:after {
            border-bottom: none !important;
        }
        .ant-menu-title-content {
            color: ${({ theme }) => theme.colors.color4.base} !important;
        }
        &.ant-menu-submenu-selected
            > .ant-menu-submenu-title
            > .ant-menu-title-content {
            color: ${({ theme }) =>
                theme.colors.staticColor.color2.base} !important;
        }
    }
    .ant-menu-title-content:hover {
        color: ${({ theme }) =>
            theme.colors.staticColor.color2.base} !important;
    }
    .ant-menu-submenu-active .ant-menu-submenu-title:after {
        width: 25px;
        height: 3px;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        right: 0;
        left: 0;
        margin: 0 auto;
        border-color: ${({ theme }) =>
            theme.colors.staticColor.color2.base} !important;
    }
    .ant-menu {
        &:not(.ant-menu-horizontal) {
            .ant-menu-item-selected {
                background: inherit;
                &:before {
                    content: '';
                    position: absolute;
                    z-index: 9;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
                span {
                    position: relative;
                    z-index: 99;
                    color: ${({ theme }) => theme.colors.color1.base};
                }
            }
        }
    }
    .ant-menu-submenu-popup {
        .ant-menu {
            border-radius: 10px;
            padding-top: 5px;
            padding-bottom: 5px;
            background: ${({ theme }) => theme.colors.color1.base};
        }
        .ant-menu-item-selected .ant-menu-title-content {
            color: ${({ theme }) =>
                theme.colors.staticColor.color2.base} !important;
        }
        .ant-menu-item {
            border-bottom: 1px solid ${({ theme }) => theme.colors.color2.base};

            margin-bottom: 0;
            margin-top: 0px;

            &:last-child {
                border-bottom: none;
            }
        }
    }
    .ant-menu-submenu-open {
        &:after {
            transition: none !important;
        }
    }
    .ant-menu-item-active,
    .ant-menu-item-selected,
    .ant-menu-submenu-open,
    .ant-menu-submenu-selected {
        color: ${({ theme }) =>
            theme.colors.staticColor.color2.base} !important;
        &::after {
            width: 25px;
            height: 3px;
            border-top-right-radius: 4px;
            border-top-left-radius: 4px;
            right: 0;
            left: 0;
            margin: 0 auto;
            border-bottom: 2px solid
                ${({ theme }) => theme.colors.staticColor.color2.base} !important;
        }
    }

    .ant-menu-overflow-item-rest {
        .ant-menu-submenu-title {
            color: ${({ theme }) =>
                theme.colors.staticColor.color2.base} !important;
            .anticon-ellipsis {
                font-size: 18px;
            }
            &:hover {
                color: ${({ theme }) =>
                    theme.colors.staticColor.color2.base} !important;
            }
        }
    }

    .ant-menu-submenu {
        .ant-menu-item-active,
        .ant-menu-item-selected {
            color: ${({ theme }) =>
                theme.colors.staticColor.color2.base} !important;
        }
    }
`;
