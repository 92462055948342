//@ts-nocheck
import {
    MenuProps as AntHeaderMenuProps,
    MenuItemProps as HeaderMenuItemType,
} from 'antd';
import { HeaderMenuStyled } from './index.styles';

import React from 'react';
import { SvgIcon } from 'components/svg-icon';
import { IconType } from 'components/svg-icon/icon-types';
import { useNavigate } from 'react-router-dom';

type AllowedAntMenuItemTypes = 'title';
export interface HeaderMenuSubMenuItemProps
    extends Pick<AntHeaderMenuProps, AllowedAntMenuTypes> {
    title: string;
    redirectLink: string;
    icon?: IconType;
}
export interface HeaderMenuItemProps
    extends Pick<HeaderMenuItemType, AllowedAntMenuItemTypes> {
    title: string;
    redirectLink: string;
    subTitles?: HeaderMenuSubMenuItemProps[];
}

type AllowedAntMenuTypes =
    | 'mode'
    | 'onClick'
    | 'children'
    | 'multiple'
    | 'selectedKeys'
    | 'defaultSelectedKeys';

export interface HeaderMenuProps
    extends Pick<AntHeaderMenuProps, AllowedAntMenuTypes> {
    items: HeaderMenuItemProps[];
}

const WrappedHeaderMenu = ({
    mode = 'horizontal',
    multiple: _multiple = false,
    items: menuItems,
    children: _children,
    selectedKeys,
    ...props
}: HeaderMenuProps) => {
    const itemList: JSX.Element[] = [];
    const navigate = useNavigate();
    if (menuItems && menuItems.length) {
        var i = 0;
        for (const item of menuItems) {
            i++;
            if (item.subTitles && item.subTitles.length > 0) {
                itemList.push(
                    <HeaderMenuStyled.SubMenu
                        onTitleClick={() => {
                            if (
                                !(
                                    selectedKeys &&
                                    selectedKeys[0] === '/market/pro' &&
                                    item.redirectLink === '/market/basic'
                                )
                            )
                                navigate(item.redirectLink);
                        }}
                        title={item.title}
                        key={i}
                    >
                        {item.subTitles &&
                            item.subTitles.map((subItem) => {
                                return (
                                    <HeaderMenuStyled.Item
                                        key={subItem.redirectLink}
                                        icon={
                                            subItem.icon && (
                                                <SvgIcon
                                                    name={subItem.icon}
                                                    size={64}
                                                />
                                            )
                                        }
                                    >
                                        {subItem.title}
                                    </HeaderMenuStyled.Item>
                                );
                            })}
                    </HeaderMenuStyled.SubMenu>
                );
            } else {
                itemList.push(
                    <HeaderMenuStyled.Item key={item.redirectLink}>
                        {item.title}
                    </HeaderMenuStyled.Item>
                );
            }
        }
    }
    return (
        <HeaderMenuStyled
            key={selectedKeys}
            mode={mode}
            {...props}
            getPopupContainer={(trigger) => {
                return trigger;
            }}
        >
            {itemList.length ? itemList : ''}
        </HeaderMenuStyled>
    );
};

export const HeaderMenu = React.memo(WrappedHeaderMenu);
