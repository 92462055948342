import { InputField } from 'components';
import { useFormik } from 'formik';
import { useState } from 'react';
import * as yup from 'yup';
import { ChangePhoneConfirmStyled } from './index.styles';
import { CodeInput } from 'components/code-input';
import { ModalCard } from 'components/modal-card';
import { t } from 'i18next';
import useAuthApi from 'api/auth/auth.api';
import { Row, Col, Alert } from 'antd';
import { useAuthentication } from 'common/auth/AuthenticationProvider';
import { SendCodeTimer } from 'components/sendcodetimer';

export interface ChangePassword2FAConfirmProps {
    onNext: (globalValue: boolean) => void;
    onCancel?: () => void;
    hash?: string;
}

interface ChangePassword2FAConfirmValues {
    passcode: string;
    hash?: string;
    mailCode: string;
}

export const ChangePhone = ({
    onNext,
    onCancel,
}: ChangePassword2FAConfirmProps) => {
    const [invalidCode, setInvalidCode] = useState(false);
    const { confirmPhoneNumberChange } = useAuthApi();
    const { user } = useAuthentication();

    const validation2fa = yup.object().shape({
        hash: yup
            .string()
            .matches(
                /^(\+|00|0|5)[0-9 ]{7,32}$/,
                t('verification.form.validations.invalidPhoneNumber')
            )
            .required(t('general.required')),
        mailCode: yup.string().required(t('general.required')),
        //passcode: yup.string().required(t('general.required')),
    });
    const validation = yup.object().shape({
        hash: yup.string().required(t('general.required')),
    });

    const formik = useFormik<ChangePassword2FAConfirmValues>({
        initialValues: {
            hash: '',
            passcode: '',
            mailCode: '',
        },
        onSubmit: async (values: ChangePassword2FAConfirmValues) => {
            if (values.passcode.length < 6) {
                setInvalidCode(true);
            } else {
                try {
                    const response = await confirmPhoneNumberChange({
                        mailCode: values.mailCode,
                        passcode: values.passcode,
                    });

                    if (response?.success) {
                        onNext(response.success);
                    } else {
                        throw new Error('invalid!');
                    }
                } catch (error) {
                    setInvalidCode(true);
                }
            }
        },
        validationSchema: user?.userState !== 1 ? validation2fa : validation,
    });

    const handleOnChange = (authCode: string) => {
        setInvalidCode(false);
        formik.setFieldValue('passcode', authCode);
    };

    const [timeOutForMail, setTimeOutForMail] = useState(true);

    const handleCallback = (childData: any) => {
        setTimeOutForMail(childData);
    };

    return (
        <ChangePhoneConfirmStyled>
            <form
                onSubmit={formik.handleSubmit}
                onChange={() => setInvalidCode(false)}
            >
                <ModalCard
                    title={t('changePhone.title')}
                    onNext={() => {
                        formik.submitForm();
                    }}
                    onCancel={onCancel}
                >
                    <div>
                        <Row justify="space-around" align="middle">
                            <Col span={12}>
                                <div className="codeInputDesc">
                                    {t('changePhone.description')}
                                </div>
                            </Col>
                            <Col span={12} className="codeSubmitText">
                                {Number(formik.values.hash?.length) >= 9 &&
                                    formik.values.hash?.match(
                                        /^(\+|00|0|5)[0-9 ]{7,32}$/
                                    ) && (
                                        <SendCodeTimer
                                            value={formik.values.hash}
                                            counterValue={60}
                                            authType={'changePhone'}
                                            buttonActive={handleCallback}
                                            key={formik.values.hash}
                                        />
                                    )}
                            </Col>
                        </Row>

                        {/* <InputField
                            form={formik}
                            placeholder={'+90 ___ __ __'}
                            formName="hash"
                            error={invalidCode}
                        /> */}
                        {/* <PhoneNumberField
                            form={formik}
                            formName="hash"
                            countryCode="+00"
                            error={invalidCode}
                        /> */}
                        <InputField
                            form={formik}
                            formName="hash"
                            // label={t('verification.form.label.phoneNumber')}
                            type="tel"
                            minLength={7}
                            maxLength={32}
                        />

                        {user?.userState !== 1 && (
                            <>
                                <CodeInput
                                    message={t(
                                        'changePhone.smsVerificationCode'
                                    )}
                                    length={6}
                                    loading={false}
                                    onChange={handleOnChange}
                                    error={invalidCode}
                                ></CodeInput>
                                {/* {invalidCode && (
                                    <div className="invalid-login-error-message">
                                        {t('changePhone.required')}
                                    </div>
                                )} */}
                            </>
                        )}

                        <Row
                            justify="space-around"
                            align="middle"
                            className="descRow"
                        >
                            <Col span={12}>
                                <div className="codeInputDesc">
                                    {t('changePhone.mailVerificationCode')}
                                </div>
                            </Col>
                            <Col span={12} className="codeSubmitText">
                                {Number(formik.values.hash?.length) >= 9 &&
                                    formik.values.hash?.match(
                                        /^(\+|00|0|5)[0-9 ]{7,32}$/
                                    ) && (
                                        <SendCodeTimer
                                            value={formik.values.hash}
                                            counterValue={60}
                                            authType={'changePhoneMail'}
                                            buttonActive={handleCallback}
                                            key={formik.values.hash}
                                        />
                                    )}
                            </Col>
                        </Row>

                        <InputField
                            form={formik}
                            placeholder={t('changePhone.inputFieldMail')}
                            formName="mailCode"
                            error={invalidCode}
                        />
                        {!timeOutForMail && (
                            <Alert
                                message={t('changePhone.timeInfo')}
                                type="warning"
                                showIcon
                                closable
                            />
                        )}
                    </div>
                </ModalCard>
            </form>
        </ChangePhoneConfirmStyled>
    );
};
