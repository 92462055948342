import * as S from './index.styles';

import React, { ReactNode, useCallback, useState } from 'react';
import { SvgIcon } from 'components/svg-icon';

type ModalSizes = 'small' | 'default' | 'large';

export type ModalProps = {
    open?: boolean;
    title?: string | ReactNode;
    content?: ReactNode;
    children?: ReactNode;
    closable?: boolean;
    onClose?: () => void;
    size?: ModalSizes;
    className?: string;
    hideCloseButton?: boolean;
    width?: number;
};

export const Modal = ({
    children,
    open,
    content,
    closable,
    onClose,
    title,
    className = '',
    size = 'default',
    hideCloseButton,
    width,
}: ModalProps) => {
    return (
        <S.Container
            visible={open}
            title={title ? <div>{title}</div> : null}
            footer={null}
            closable={closable}
            maskClosable={false}
            closeIcon={
                hideCloseButton ? (
                    ''
                ) : (
                    <SvgIcon name="times-circle-filled" size={24} />
                )
            }
            onCancel={onClose}
            className={`${className} yuex-modal-size-${size}`}
            width={width}
        >
            {content || children || (
                <div>There is no content! Please add something!</div>
            )}
        </S.Container>
    );
};

interface UseModalReturn {
    placeholder: () => JSX.Element;
    openModal: (content: React.ReactNode) => void;
    closeModal: () => void;
}

interface UseModalProps {
    title?: string | ReactNode;
    closable?: boolean;
    size?: ModalSizes;
    className?: string;
    hideCloseButton?: boolean;
    width?: number;
}

//TODO: use forwaredRef to trigger close.
export const useModal = (props: UseModalProps): UseModalReturn => {
    const [content, setContent] = useState<React.ReactNode | undefined>();
    const [visible, setVisible] = useState<boolean>(false);

    const closeModal = useCallback(() => {
        setContent(<></>);
        setTimeout(() => {
            setVisible(false);
        });
    }, [setVisible, setContent]);

    const openModal = useCallback((content: React.ReactNode) => {
        setContent(content);
        setVisible(true);
    }, []);

    const placeholder = () => {
        return (
            <Modal
                {...props}
                open={visible}
                content={content}
                onClose={closeModal}
                hideCloseButton={props.hideCloseButton}
            />
        );
    };

    return {
        placeholder,
        openModal,
        closeModal,
    };
};
