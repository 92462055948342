import { fonts } from 'common/theme/font';
import styled from 'styled-components';

export const CoinExchangeStyled = styled.div`
    &.coin-exchange {
        margin-bottom: 30px;

        .coin-exchange-header {
            margin-bottom: 5px;
            align-items: center;
            font-weight: ${fonts.weight.medium};
            font-size: ${fonts.size.large};
            color: ${({ theme }) => theme.colors.color4.base};
        }
        .coin-exchange-container {
            display: flex;
            .coin-exchange-content-icon {
                font-size: ${fonts.size.xlarge};
                padding: 0 10px;
                color: ${({ theme }) => theme.colors.color4.base};
                height: 56px;
                display: flex;
                align-items: center;
            }

            .coin-exchange-content {
                & > div {
                    padding-bottom: 0px !important;
                }

                .ant-input-affix-wrapper {
                    padding: 0 15px;
                    min-width: 280px;
                    height: 48px;
                    box-sizing: border-box;

                    input {
                        color: ${({ theme }) => theme.colors.color4.base};
                    }
                    .ant-input-suffix {
                        padding: 6px;
                        span {
                            margin-right: 0px;
                            font-weight: ${fonts.weight.regular};
                            font-size: 14px;
                            color: ${({ theme }) =>
                                theme.colors.staticColor.color2.base};
                        }
                    }
                }
            }
        }
    }
`;
