import { useCallback, useEffect, useState } from 'react';
import * as S from './index.styles';
import { useTranslation } from 'react-i18next';
import { routesPaths } from 'config/routes';
import { MiniNotification } from 'components/mini-notification';
import { isSurveyControl } from 'common/utils';
import { useLocation } from 'react-router-dom';
import { useAuthentication } from 'common/auth/AuthenticationProvider';

export const SurveyNotification = () => {
    const { t } = useTranslation();
    const [cookie] = useState<any>(
        localStorage.getItem('userSurveyPermission')
    );
    const [isHidden, setIsHidden] = useState<boolean>(false);
    const { isAuthenticated, isAccountVerified, user } = useAuthentication();
    const location = useLocation();

    useEffect(() => {
        if (cookie !== null) {
            let cookieArr = JSON.parse(cookie);
            if (
                cookieArr.time > Number(new Date(Date.now())) ||
                cookieArr.permission
            ) {
                setIsHidden(true);
            } else {
                setIsHidden(false);
            }
        } else {
            setIsHidden(false);
        }
    }, []);

    const controlModal = useCallback(() => {
        let data = {
            permission: false,
            time: 0,
        };

        data.time = Number(new Date(Date.now() + 1 * 24 * 60 * 60 * 1000));
        localStorage.setItem('userSurveyPermission', JSON.stringify(data));
        setIsHidden(true);
    }, []);

    return (
        <S.Container hidden={isHidden}>
            {/* Login olan, hesabını doğrulamış, anketi doldurmamış, anket sayfasında bulunmayan, close butonuna basmamış*/}
            {!isHidden &&
                isAuthenticated &&
                isAccountVerified &&
                isSurveyControl(user?.survey, 1) &&
                location.pathname !==
                    routesPaths.survey +
                        '/455b10c45f5c4520b4afabc240fc93fe' && (
                    <MiniNotification
                        onButtonClick={() => controlModal()}
                        message={t('survey.notificationMessage')}
                        url={
                            routesPaths.survey +
                            '/455b10c45f5c4520b4afabc240fc93fe'
                        }
                        icon={'/assets/images/survey-icon.svg'}
                    />
                )}
        </S.Container>
    );
};
