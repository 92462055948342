import { SvgIcon } from 'components';
import { Button } from 'components/button';
import { useCallback, useEffect, useState } from 'react';
import * as S from './index.styles';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { routesPaths } from 'config/routes';

export const CookieCard = () => {
    const { t } = useTranslation();
    const [cookie] = useState<any>(localStorage.getItem('cookiePermission'));
    const [cookieHidden, setCookieHidden] = useState<boolean>(false);

    useEffect(() => {
        if (cookie !== null) {
            let cookieArr = JSON.parse(cookie);
            if (
                cookieArr.time > Number(new Date(Date.now())) ||
                cookieArr.permission
            ) {
                setCookieHidden(true);
            } else {
                setCookieHidden(false);
            }
        } else {
            setCookieHidden(false);
        }
    }, []);

    const cookieModal = useCallback((permission: boolean) => {
        let data = {
            permission: permission,
            time: 0,
        };
        if (!permission) {
            data.time = Number(new Date(Date.now() + 1 * 24 * 60 * 60 * 1000));
        } else {
            data.time = Number(new Date(Date.now()));
        }
        localStorage.setItem('cookiePermission', JSON.stringify(data));
        setCookieHidden(true);
    }, []);

    return (
        <S.Container hidden={cookieHidden} className="cookie-card">
            <span onClick={() => cookieModal(false)}>
                <SvgIcon
                    className="close"
                    name="times-circle-filled"
                    size={32}
                />
            </span>
            <div className="item">
                <div className="left">
                    <div className="image">
                        <img alt="cookie" src={`/assets/images/cookie.svg`} />
                    </div>
                    <div className="description">
                        <p>
                            {parse(
                                t('cookie.description', {
                                    url: `${window.location.origin}${routesPaths.privacy.root}/cookie-privacy`,
                                })
                            )}
                        </p>
                    </div>
                </div>
                <div className="right">
                    <Button type="primary" onClick={() => cookieModal(true)}>
                        {t('cookie.okay')}
                    </Button>
                    <Button type="ghost" onClick={() => cookieModal(false)}>
                        {t('cookie.latter')}
                    </Button>
                </div>
            </div>
        </S.Container>
    );
};
