import { Row, Col } from 'antd';
import { Button } from 'components/button';
import { Container } from './index.styles';
import { useTranslation } from 'react-i18next';

const CareerBanner = () => {
    const { t } = useTranslation();
    return (
        <>
            <Container>
                <Row>
                    <Col span={16}>
                        <div className="info">
                            <div className="title">
                                <h1>{t('career.banner.title')}</h1>
                                <div className="details">
                                    {t('career.banner.detail')}
                                </div>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        window.open(
                                            'https://www.linkedin.com/jobs/search/?currentJobId=3231901597&f_C=81520506&geoId=92000000',
                                            '_blank'
                                        );
                                    }}
                                >
                                    {t('career.banner.button')}
                                </Button>
                            </div>
                        </div>
                    </Col>
                    <Col span={8} className="image-col">
                        <div className="image-links">
                            <figure className="image">
                                <img
                                    src="/assets/images/career/career-steps.svg"
                                    alt=""
                                />
                            </figure>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default CareerBanner;
