import Footer from 'components/home-page/footer';
import { routesPaths } from 'config/routes';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GuideBar, GuideBarMenuItemProps } from 'widgets/guide-side-bar';
import BaseLayout from '../BaseLayout';
import { GuideScreenLayoutStyled, Content } from './index.styles';
import { getLanguage } from 'common/utils';
import useBlogApi from 'api/blog/blog.api';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

interface GuideScreenLayoutProps {
    children: ReactNode;
}

const GuideScreenLayout = ({ children }: GuideScreenLayoutProps) => {
    const lang = getLanguage();
    const params = useParams();
    const location = useLocation();
    const { t } = useTranslation();
    const { getPrivaciesList } = useBlogApi();
    const [menuItems, setMenuItems] = useState<GuideBarMenuItemProps[]>(
        [] as unknown as GuideBarMenuItemProps[]
    );
    const [staticMenuItems, setStaticMenuItems] = useState<
        GuideBarMenuItemProps[]
    >([]);

    const fetchInitial = useCallback(async () => {
        const _list = await getPrivaciesList(lang);

        let newArr: any = [
            {
                title: t('privacy.title'),
                link: '',
                subTitles: [],
            },
        ];
        await _list.data.data.forEach((item: any) => {
            const data = {
                title: item.attributes.title,
                link: item.attributes.slug,
            };
            newArr[0].subTitles.push(data);
        });
        setMenuItems(newArr);
        setStaticMenuItems([
            {
                title: t('announcement_page.title'),
                link: routesPaths.announcement.root,
                subTitles: Array(),
            },
            {
                title: t('commissions.title'),
                link: routesPaths.commissions.root,
                subTitles: Array(),
            },
            {
                title: t('header.mainMenu.reference'),
                link: routesPaths.public.reference,
                subTitles: Array(),
            },
            {
                title: t('header.mainMenu.giftcard'),
                link: routesPaths.public.giftCard,
                subTitles: Array(),
            },
        ]);
    }, [lang, params.slug]);

    useEffect(() => {
        fetchInitial();
    }, [lang, params.slug]);

    return (
        <GuideScreenLayoutStyled>
            <BaseLayout>
                <Content>
                    <div className="left-content">
                        <GuideBar
                            items={[...staticMenuItems, ...menuItems]}
                            path="privacy"
                        />
                    </div>
                    <div
                        className={`right-content ${
                            location.pathname === '/' + lang + '/gift-card'
                                ? 'clear'
                                : ''
                        }${params.slug !== undefined ? 'editor' : ''}`}
                    >
                        {children}
                    </div>
                </Content>
            </BaseLayout>
            <Footer />
        </GuideScreenLayoutStyled>
    );
};

export default GuideScreenLayout;
