import styled from 'styled-components';
import { Radio as AntRadio } from 'antd';
import { fonts } from 'common/theme/font';

export const RadioStyled = styled(AntRadio)`
    display: inline-flex;
    align-items: center;

    position: relative;

    box-sizing: border-box;
    cursor: pointer;
    .ant-radio {
        display: inline-block;
        margin-right: 5px;
        margin-top: -6px;

        position: relative;

        outline: none;
        cursor: pointer;
        input {
            padding: 0;

            position: absolute;
            top: 2px;
            right: 0;
            bottom: 0;
            left: 2px;

            box-sizing: border-box;
            touch-action: manipulation;
            cursor: pointer;
            overflow: visible;

            z-index: 1;
            opacity: 0;
        }
        span {
            &.ant-radio-inner {
                display: block;
                width: 16px;
                height: 16px;

                position: relative;
                top: 1px;
                left: 2px;

                border: 2px solid
                    ${({ theme }) => theme.colors.staticColor.color1.second2};
                border-radius: 50%;

                background-color: transparent;
                &:after {
                    content: '';

                    display: block;
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 4px;
                    left: 4px;

                    opacity: 0;
                    border-radius: 50%;

                    background-color: ${({ theme }) =>
                        theme.colors.staticColor.color2.base};
                }
            }
        }
        &.ant-radio-checked {
            .ant-radio-inner {
                &:after {
                    opacity: 1;
                }
            }
        }
    }
    &.ant-radio-wrapper-disabled {
        cursor: not-allowed;
        span {
            opacity: 0.6;
        }
    }
    span {
        &:not(.ant-radio) {
            font-size: ${fonts.size.large};
            color: ${({ theme }) => theme.colors.color4.base} !important;
        }
    }
`;
