import { CommonSourceProvider } from 'api/common/common.api.context';
import GlobalStyle from 'common/theme/GlobalStyle';
import ThemeProvider from 'common/theme/ThemeProvider';
import AppRoutes from 'components/app-routes';
// import appSocket from 'common/socket';

// appSocket.init();

function App() {
    return (
        <ThemeProvider>
            <GlobalStyle />
            <CommonSourceProvider>
                <AppRoutes />
            </CommonSourceProvider>
        </ThemeProvider>
    );
}

export default App;
