import { mediaQuery } from 'common/theme/media';
import { css } from 'styled-components';

const tabletCss = css`
    &.ant-modal.authenticator-modal {
        .ant-modal-close {
            padding-top: 16px;
            padding-right: 24px;
            display: none;
        }
        .ant-modal-content {
            max-width: 365px !important;

            margin: 0 auto;
            .ant-modal-body {
                margin: 0px !important;
                padding: 20px 0px !important;
                .description-container {
                    .app-form-field-code {
                        padding-bottom: 0;
                        .verification-code-input .code .form-item {
                            padding-bottom: 0px !important;
                        }
                    }
                }
                .cancel-next-buttons {
                    padding-top: 5px;
                    min-height: inherit;
                    .buttons-container {
                        float: inherit;
                        justify-content: flex-end;
                    }
                }
                .code-inputs {
                    input {
                        height: 48px;
                        width: 40px;
                    }
                }
            }
        }
    }
`;

const mobileCss = css`
    &.ant-modal.authenticator-modal {
        .ant-modal-close {
            padding-top: 16px;
            padding-right: 24px;
            display: none;
        }
        .ant-modal-content {
            max-width: 365px;
            width: 100%;
            margin: 0 auto;
            .ant-modal-body {
                margin: 0px !important;
                padding: 20px 0px !important;
                .description-container {
                    .app-form-field-code {
                        padding-bottom: 0;
                        .verification-code-input .code .form-item {
                            padding-bottom: 0px !important;
                        }
                    }
                }
                .cancel-next-buttons {
                    padding-top: 5px;
                    min-height: inherit;
                    .buttons-container {
                        float: inherit;
                        justify-content: flex-end;
                    }
                }
            }
        }
    }
`;

export const AuthenticatorModalCss = css`
    &.ant-modal.authenticator-modal {
        .ant-modal-close {
            padding-top: 16px;
            padding-right: 24px;
            display: none;
        }
        .ant-modal-content {
            max-width: 365px;
            width: 100%;
            margin: 0 auto;
            .ant-modal-body {
                margin: 0px !important;
                padding: 20px 0px !important;
                .description-container {
                    .app-form-field-code {
                        padding-bottom: 0;
                        .verification-code-input .code .form-item {
                            padding-bottom: 0px !important;
                        }
                    }
                }
                .cancel-next-buttons {
                    padding-top: 5px;
                    min-height: inherit;
                    .buttons-container {
                        float: inherit;
                        justify-content: flex-end;
                    }
                }
            }
        }
    }
    ${mediaQuery.between('tablet', 'desktop')`${tabletCss}`}
    ${mediaQuery.lessThan('tablet')`${mobileCss}`}
`;
