import React from 'react';
import {
    Dropdown as AntDropdown,
    DropDownProps as AntDropdownProps,
} from 'antd';
import { MenuItem, Menu, Divider } from './index.styles';
import { IconType } from 'components/svg-icon/icon-types';
import { SvgIcon } from 'components/svg-icon';

export interface ActionItem {
    label?: string | React.ReactNode;
    component?: JSX.Element;
    key?: string;
    onClick?: (record?: any) => void;
    record?: any;
    className?: string;
    icon?: IconType;
    selected?: boolean;
    disabled?: boolean;
    divider?: boolean;
    colorType?: 'normal' | 'primary' | 'secondary';
}

interface MenuListProps {
    items: ActionItem[];
    onSelect?: (key: string, record?: any) => void;
    size: 'normal' | 'small' | 'large';
    showIcon?: boolean;
}

function MenuList({
    items,
    onSelect,
    showIcon,
    size,
    ...restProps
}: MenuListProps) {
    const handleSelect = (item: ActionItem) => {
        if (item.onClick) {
            item.onClick(item?.record);
        } else if (onSelect && item?.key) {
            onSelect(item.key as string, item?.record);
        }
    };

    return (
        <div onClick={(event) => event.stopPropagation()}>
            <Menu className={`dropdown-menu-${size}`} {...restProps}>
                {items.map((item, index) =>
                    item.divider ? (
                        <Divider key={`divider-${index}`} />
                    ) : (
                        <MenuItem
                            key={item.key}
                            onClick={() => handleSelect(item)}
                            disabled={item.disabled}
                            className={item.className}
                        >
                            {item.component ? (
                                <>{item.component}</>
                            ) : (
                                <>
                                    {showIcon && (
                                        <SvgIcon
                                            className="dropdown-item-icon"
                                            name={item.icon || 'chevron-right'}
                                        />
                                    )}
                                    <div className="dropdown-item-label">
                                        {item.label}
                                    </div>
                                </>
                            )}
                        </MenuItem>
                    )
                )}
            </Menu>
        </div>
    );
}

export interface ActionMenuProps extends Omit<AntDropdownProps, 'overlay'> {
    items: ActionItem[];
    children: string | JSX.Element;
    onSelect: (key: string, record?: any) => void;
    size?: 'normal' | 'small' | 'large';
    showIcon?: boolean;
}

export function ActionMenu({
    items,
    children,
    onSelect,
    trigger = ['click'],
    size = 'normal',
    showIcon,

    ...dropdownProps
}: ActionMenuProps) {
    return (
        <AntDropdown
            overlayClassName="tunety-dropdown"
            trigger={trigger}
            overlay={() => (
                <MenuList
                    items={items}
                    onSelect={onSelect}
                    size={size}
                    showIcon={showIcon}
                />
            )}
            {...dropdownProps}
        >
            <span onClick={(e) => e.preventDefault()}>{children}</span>
        </AntDropdown>
    );
}
