import styled from 'styled-components';
import { fonts } from 'common/theme/font';
import { AutoComplete } from 'antd';

export const FormField = styled.div`
    .app-form-label {
        display: flex;
        justify-content: space-between;
        padding-bottom: 5px;

        & > label {
            font-size: ${fonts.size.small};
            line-height: 18px;
            font-weight: ${fonts.weight.bold};
            color: ${({ theme }) => theme.colors.color4.base};
        }
    }
`;

export const InputSelectionStyled = styled(AutoComplete)`
    display: block;
    .ant-select-selector {
        height: 48px !important;
        border-radius: 6px !important;
        box-shadow: none !important;
        background-color: ${({ theme }) => theme.colors.color1.base} !important;
        border-color: ${({ theme }) => theme.colors.color2.base} !important;
        &:focus {
            outline: none !important;
            outline-width: 0 !important;
            box-shadow: none;
        }
        .ant-select-selection-search {
            .ant-select-selection-search-input {
                height: 46px !important;
                color: ${({ theme }) => theme.colors.color4.base} !important;
            }
        }
        .ant-select-selection-placeholder {
            margin: 6px 0;
        }
    }
    .ant-select-dropdown {
        .ant-select-item {
            .ant-select-item-option-content {
                .list-container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .info {
                        display: flex;
                        flex-direction: column;
                        .title {
                            font-size: ${fonts.size.small};
                            font-weight: ${fonts.weight.semibold};
                            color: ${({ theme }) => theme.colors.color4.base};
                        }
                        .text {
                            font-size: ${fonts.size.small};
                            font-weight: ${fonts.weight.regular};
                            color: ${({ theme }) => theme.colors.color4.base};
                        }
                    }
                    .link {
                    }
                }
            }
        }
        .rc-virtual-list {
            .rc-virtual-list-holder {
                .ant-select-item-option-selected {
                    background-color: inherit !important;
                    &.ant-select-item-option-active {
                        background-color: ${({ theme }) =>
                            theme.colors.color2.base} !important;
                    }
                }
            }
        }
    }
    &.invalid {
        .ant-select-selector {
            border-color: ${({ theme }) => theme.colors.color2.base}!important;
        }
    }

    &.ant-select-disabled {
        background: ${({ theme }) => theme.colors.color2.base} !important;
        .ant-select-selector {
            background: ${({ theme }) => theme.colors.color2.base} !important;
        }
        .ant-select-selection-search-input {
            background-color: ${({ theme }) =>
                theme.colors.color2.base} !important;
            border-color: ${({ theme }) => theme.colors.color2.base} !important;
        }
    }
`;
