import * as S from './index.styles';

export type HelperMessageProps = {
    error?: boolean;
    disabled?: boolean;
    text?: string;
};

export const HelperMessage = ({
    error,
    text,
    disabled,
}: HelperMessageProps) => {
    return (
        <S.Container disabled={disabled} error={error}>
            <div className="app-helper-content">{text}</div>
        </S.Container>
    );
};
