import { RadioProps as AntRadioProps } from 'antd';
import { RadioStyled } from './index.styles';

import React from 'react';

export type RadioType = 'default';

type AllowedAnRadioTypes =
    | 'onChange'
    | 'children'
    | 'autoFocus'
    | 'disabled'
    | 'checked'
    | 'className'
    | 'value';

export interface RadioProps extends Pick<AntRadioProps, AllowedAnRadioTypes> {
    type?: RadioType;
    className?: string;
    onChange?: () => void;
}

const WrappedRadio = ({
    type = 'default',
    children,
    onChange,
    className: _className = '',
    ...props
}: RadioProps) => {
    const extraProps: Pick<AntRadioProps, 'className'> = {};

    return (
        <RadioStyled type={type} {...props} {...extraProps} onChange={onChange}>
            {children}
        </RadioStyled>
    );
};

export const Radio = React.memo(WrappedRadio);
