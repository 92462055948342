import { SideBarStyled, MobileButtonStyled } from './index.styles';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SideBarMenu, SideBarMenuItemProps } from 'components/side-bar-menu';
import { routesPaths } from 'config/routes';
import { useNavigate, useLocation } from 'react-router-dom';
//  import { getSidebarRedirectLink } from 'common/utils';
import { StickyContainer, Sticky } from 'react-sticky';
import { Button } from 'components/button';
import { useAuthentication } from 'common/auth/AuthenticationProvider';
import { isSurveyControl } from 'common/utils';

export interface SideBarProps {
    width?: number;
}

const WrappedSideBar = ({ width = 250 }: SideBarProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [active, setActive] = useState(false);
    const { isAccountVerified, user } = useAuthentication();
    const [activeItem, setActiveItem] = useState<any>(null);
    const [pathname, setPathname] = useState<string>(location.pathname);

    const menuItems = useMemo((): SideBarMenuItemProps[] => {
        return [
            {
                title: t('sidebar.wallet.title'),
                link: routesPaths.account.wallet,
                icon: 'wallet',
            },
            ...(isAccountVerified
                ? ([
                      {
                          title: t('sidebar.deposit.title'),
                          link: routesPaths.operations.deposit,
                          icon: 'coin',
                          alternatives: [
                              routesPaths.operations.deposit,
                              routesPaths.operations.withdraw,
                          ],
                      },
                  ] as SideBarMenuItemProps[])
                : []),
            {
                title: t('sidebar.account.title'),
                subTitles: [
                    {
                        title: t('sidebar.account.main'),
                        link: routesPaths.account.main,
                    },
                    {
                        title: t('sidebar.account.accountVerify'),
                        link: routesPaths.account.verification,
                    },
                    {
                        title: t('sidebar.account.reference'),
                        link: routesPaths.account.reference,
                    },
                    ...(isAccountVerified && isSurveyControl(user?.survey, 1)
                        ? ([
                              {
                                  title: t('sidebar.account.surveyUser'),
                                  link: `${routesPaths.survey}/455b10c45f5c4520b4afabc240fc93fe`,
                                  icon: 'user-circle-filled',
                              },
                          ] as SideBarMenuItemProps[])
                        : []),
                    // {
                    //     title: t('sidebar.account.notifications'),
                    //     link: routesPaths.account.notification,
                    // },
                ],
                link: '/account',
                icon: 'user-circle-filled',
            },
            {
                title: t('sidebar.security.title'),
                link: routesPaths.account.security,
                icon: 'security',
            },
            {
                title: t('sidebar.reports.title'),
                subTitles: [
                    {
                        title: t('sidebar.reports.transferHistory'),
                        link: routesPaths.reports.withdrawHistory,
                        alternatives: [
                            routesPaths.reports.withdrawHistory,
                            routesPaths.reports.depositHistory,
                        ],
                    },
                    {
                        title: t('sidebar.reports.orderHistory'),
                        link: routesPaths.reports.orderHistory,
                    },
                    {
                        title: t('sidebar.reports.userMovement'),
                        link: routesPaths.reports.userHistory,
                    },
                ],
                link: '/',
                icon: 'reports',
            },
        ];
    }, [t]);
    useEffect(() => {
        setPathname(location.pathname);
    }, [location]);
    useEffect(() => {
        let selectedItem;
        menuItems.forEach((menuItem) => {
            if (
                menuItem?.alternatives?.includes(location.pathname) ||
                menuItem.link === location.pathname
            ) {
                selectedItem = menuItem;
                return;
            }

            menuItem.subTitles?.forEach((submenuItem) => {
                if (
                    submenuItem?.alternatives?.includes(location.pathname) ||
                    submenuItem.link === location.pathname
                ) {
                    selectedItem = submenuItem;
                }
            });
        });
        setActiveItem(selectedItem);
    }, [location.pathname]);

    const onMenuItemClick = useCallback(
        (item: any) => {
            setActive(false);
            navigate(item.key);
        },
        [setActive, navigate]
    );
    const handleSideMenuActive = useCallback(() => {
        setActive(!active);
    }, [active]);

    return (
        <>
            <MobileButtonStyled className="mobile-button-menu">
                <Button
                    type="link"
                    suffixIcon="chevron-down"
                    onClick={handleSideMenuActive}
                >
                    {activeItem ? activeItem.title : t('sidebar.menu')}
                </Button>
            </MobileButtonStyled>
            <SideBarStyled
                className={active ? 'active sidebar-item' : 'sidebar-item'}
                width={width}
            >
                <StickyContainer>
                    <Sticky topOffset={-52}>
                        {({ style }) => (
                            <div style={{ ...style, zIndex: 998, top: 52 }}>
                                <SideBarMenu
                                    items={menuItems}
                                    defaultSelectedKeys={[pathname]}
                                    selectedKeys={[pathname]}
                                    defaultOpenKeys={[
                                        t('sidebar.account.title'),
                                        t('sidebar.reports.title'),
                                    ]}
                                    onClick={onMenuItemClick}
                                />
                            </div>
                        )}
                    </Sticky>
                </StickyContainer>
            </SideBarStyled>
        </>
    );
};

export const SideBar = React.memo(WrappedSideBar);
