import { Content } from 'antd/lib/layout/layout';
import { Button } from 'components/button';
import { ReactNode } from 'react';
import { useTheme } from 'common/theme/ThemeProvider';
import { InfoPageContainerStyled } from './index.styles';
import { t } from 'i18next';

export type InfoPageStatusType =
    | '404'
    | '500'
    | '502'
    | 'under_construction'
    | 'coming_soon';

export interface InfoPageContainerProps {
    showButton?: boolean;
    warningTitle?: string;
    description?: string;
    status?: InfoPageStatusType;
    buttonText?: string;
    onButtonClick?: () => void;
    children?: ReactNode;
}

export const InfoPageContainer = ({
    showButton = true,
    warningTitle,
    description,
    status = '404',
    buttonText = t(`error.404.buttonText`),
    onButtonClick,
    children,
}: InfoPageContainerProps) => {
    const { theme } = useTheme();
    return (
        <InfoPageContainerStyled className="container">
            <img
                src={`/assets/images/${status}-page${
                    status == '404' && theme?.selectedTheme == 'dark'
                        ? '-dark'
                        : ''
                }.png`}
                alt={warningTitle}
                className="img desktop"
            />
            <img
                src={`/assets/images/${status}-page${
                    status == '404' && theme?.selectedTheme == 'dark'
                        ? '-dark'
                        : ''
                }.png`}
                alt={warningTitle}
                className="img mobile"
            />
            <h1 className="warning-text">{warningTitle}</h1>
            <h1
                className="text"
                dangerouslySetInnerHTML={{
                    __html: description ? description : '',
                }}
            ></h1>
            {showButton && (
                <Button type="primary" onClick={onButtonClick}>
                    {buttonText}
                </Button>
            )}
            <Content>{children}</Content>
        </InfoPageContainerStyled>
    );
};
