import { routesPaths } from 'config/routes';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import {
    InfoPageContainer,
    InfoPageStatusType,
} from 'widgets/InfoPageContainer';

export interface ErrorPageProps {}

const ErrorPage = () => {
    const { t } = useTranslation();
    const pageStatus: InfoPageStatusType = '404';
    const navigate = useNavigate();
    let pageData = {
        warningTitle: t(`error.${pageStatus}.warningTitle`),
        description: t(`error.${pageStatus}.description`),
    };
    return (
        <>
            <Helmet>
                <title>{t('seo.error.title')}</title>
                <meta
                    name="description"
                    content={t('seo.error.description')}
                ></meta>
                <meta name="keywords" content={t('seo.error.keywords')}></meta>
                <meta name="robots" content="noindex"></meta>
            </Helmet>
            <InfoPageContainer
                status={pageStatus}
                warningTitle={pageData.warningTitle}
                description={pageData.description}
                onButtonClick={() => {
                    navigate(routesPaths.root);
                }}
            ></InfoPageContainer>
        </>
    );
};

export default ErrorPage;
