import { ButtonProps as AntButtonProps } from 'antd';
import { SocialButtonStyled } from './index.styles';

import React from 'react';
import { IconType } from 'components/svg-icon/icon-types';
import { SvgIcon } from 'components/svg-icon';

type AllowedAntButtonTypes =
    | 'size'
    | 'disabled'
    | 'loading'
    | 'onClick'
    | 'shape';

export interface IconButtonProps
    extends Pick<AntButtonProps, AllowedAntButtonTypes> {
    icon?: IconType | undefined;
}

const WrappedButton = ({ icon = 'twitter', ...props }: IconButtonProps) => {
    const extraProps: Pick<AntButtonProps, 'icon' | 'className'> = {};

    if (icon) {
        extraProps.icon = <SvgIcon name={icon} />;
        extraProps.className = `social-links`;
    }

    return <SocialButtonStyled {...props} {...extraProps}></SocialButtonStyled>;
};

export const IconButtonSocial = React.memo(WrappedButton);
