import { useFormik } from 'formik';
import * as yup from 'yup';
import { InputField } from 'components';
import { NewEmailConfirmationStyled } from './index.styles';
import { useTranslation } from 'react-i18next';
import useAuthApi from 'api/auth/auth.api';
// import { ConfirmEmailResponse } from 'api/auth/auth.model';
import { useState } from 'react';
import { SendCodeTimer } from 'components/sendcodetimer';
import { Alert, Col, Row } from 'antd';
import { Regex } from 'common/enums';

export interface NewEmailConfirmationValues {
    new_mail: string;
}

interface NewEmailConfirmationProps {
    successCallback?: (response: any) => void;
    errorCallback?: (err: any) => void;
}

export const NewEmailConfirmation = (props: NewEmailConfirmationProps) => {
    const emailRegex = new RegExp(Regex.emailRegex, 'g');
    const { changeEmail } = useAuthApi();
    const { t } = useTranslation();
    const [invalidConfirmEmailAttempt, setInvalidConfirmEmailAttempt] =
        useState<boolean>(false);

    const validation = yup.object().shape({
        new_mail: yup
            .string()
            .email(t('ConfirmEmail.form.validations.invalidEmail'))
            .required(t('ConfirmEmail.form.validations.requiredEmail')),
    });
    const formik = useFormik<NewEmailConfirmationValues>({
        initialValues: {
            new_mail: '',
        },
        onSubmit: async (values: NewEmailConfirmationValues) => {
            setInvalidConfirmEmailAttempt(false);
            try {
                const response = await changeEmail(values.new_mail);
                props.successCallback && props.successCallback(response);
            } catch (err: any) {
                if (err?.data?.error === 'user_have_email_change') {
                    setInvalidConfirmEmailAttempt(true);
                } else {
                    //props.errorCallback && props.errorCallback(err);
                }
            }
        },
        validationSchema: validation,
    });
    const [timeOutForMail, setTimeOutForMail] = useState(true);

    const handleCallback = (childData: any) => {
        setTimeOutForMail(childData);
    };

    return (
        <>
            <NewEmailConfirmationStyled
                className={
                    invalidConfirmEmailAttempt
                        ? `ConfirmEmail-form invalidAttempt`
                        : 'ConfirmEmail-form'
                }
            >
                <form
                    onSubmit={formik.handleSubmit}
                    onChange={() => {
                        setInvalidConfirmEmailAttempt(false);
                    }}
                >
                    {!timeOutForMail && (
                        <Alert
                            message={t('changePassword2FAConfirm.timeInfo')}
                            type="warning"
                            showIcon
                            closable
                        />
                    )}
                    <Row justify="space-around" align="middle">
                        <Col span={12}>
                            <div className="codeInputDesc">
                                <label className="normal">
                                    {t('ConfirmEmail.form.emailLabel')}
                                </label>{' '}
                            </div>
                        </Col>
                        <Col span={12} className="codeSubmitText">
                            {emailRegex.test(formik.values.new_mail) && (
                                <SendCodeTimer
                                    counterValue={60}
                                    authType={'changeMail'}
                                    value={formik.values.new_mail}
                                    key={formik.values.new_mail}
                                    buttonActive={handleCallback}
                                />
                            )}
                        </Col>
                    </Row>
                    <InputField
                        form={formik}
                        formName="new_mail"
                        placeholder={t('ConfirmEmail.form.emailLabel')}
                    />
                    {invalidConfirmEmailAttempt && (
                        <div className="invalid-ConfirmEmail-error-message">
                            {t(
                                'ConfirmEmail.form.validations.invalidConfirmEmail'
                            )}
                        </div>
                    )}
                </form>
            </NewEmailConfirmationStyled>
        </>
    );
};
