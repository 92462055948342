import { Button } from 'components/button';
import * as S from './index.styles';

export interface BannerProps {
    text: string;
    secondaryText?: string;
    buttonText?: string;
    buttonAction?: any;
}

export const Banner = ({
    text,
    secondaryText,
    buttonText = 'OK',
    buttonAction,
}: BannerProps) => {
    return (
        <S.Container>
            <div className="banner-container">
                <img src="/assets/images/banner-img.svg" alt="" />
                <div className="banner-content">
                    <div>
                        <p className="banner-text">{text}</p>
                        <p className="light-text">{secondaryText}</p>
                    </div>
                    <Button type="default" onClick={buttonAction}>
                        {buttonText}
                    </Button>
                </div>
            </div>
        </S.Container>
    );
};
