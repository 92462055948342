import { ButtonProps as AntButtonProps } from 'antd';
import { ButtonStyled } from './index.styles';

import React from 'react';
import { IconType } from 'components/svg-icon/icon-types';
import { SvgIcon } from 'components/svg-icon';

export type ButtonType = 'primary' | 'default' | 'ghost' | 'link';

type AllowedAntButtonTypes =
    | 'size'
    | 'disabled'
    | 'children'
    | 'block'
    | 'loading'
    | 'onClick'
    | 'htmlType'
    | 'className'
    | 'shape';

export interface ButtonProps
    extends Pick<AntButtonProps, AllowedAntButtonTypes> {
    type?: ButtonType;
    suffixIcon?: IconType | undefined;
    prefixIcon?: IconType | undefined;
    red?: boolean;
    pastelGreen?: boolean;
}

const WrappedButton = ({
    type = 'default',
    prefixIcon,
    suffixIcon,
    red,
    pastelGreen,
    children,
    className = '',
    ...props
}: ButtonProps) => {
    const extraProps: Pick<AntButtonProps, 'icon' | 'className'> = {};

    extraProps.className = `${className}`;

    if (type === 'primary' && red) {
        extraProps.className = `app-btn-red ${extraProps.className}`;
    }

    if (type === 'primary' && pastelGreen) {
        extraProps.className = `app-btn-pastel-green ${extraProps.className}`;
    }

    if (prefixIcon) {
        extraProps.icon = <SvgIcon name={prefixIcon} />;
        extraProps.className = `app-btn-with-icon app-btn-prefix-icon ${extraProps.className}`;
    }

    if (suffixIcon) {
        extraProps.className = `app-btn-with-icon app-btn-suffix-icon ${extraProps.className}`;
        return (
            <ButtonStyled type={type} {...props} {...extraProps}>
                {children}
                <SvgIcon name={suffixIcon} fill="#fff" />
            </ButtonStyled>
        );
    }

    return (
        <ButtonStyled type={type} {...props} {...extraProps}>
            {children}
        </ButtonStyled>
    );
};

export const Button = React.memo(WrappedButton);
