import { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';

const mobileCss = css`
    &.ant-modal.success-modal {
        .ant-modal-content {
            width: 95% !important;
            padding: 0 0 !important;
            max-width: 360px !important;
            .ant-modal-body {
                padding: 0 0 !important;
                justify-content: center;
                .status-card-container {
                    .status-img {
                        padding-top: 60px;
                    }
                    .ant-btn {
                        margin-top: 60px;
                        margin-bottom: 40px;
                        width: 173px;
                        span {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
`;

export const SuccessModalCss = css`
    &.ant-modal.success-modal {
        .ant-modal-close {
            top: 15px;
            right: 15px;
        }
        .ant-modal-content {
            max-width: 500px !important;
            background: ${({ theme }) => theme.colors.color1.base};
            width: 100%;
            .ant-modal-body {
                display: flex;
                text-align: center;
                justify-content: center;
                margin: 0px !important;
                padding: 0px 120px !important;
            }
        }
    }
    ${mediaQuery.lessThan('tablet')`${mobileCss}`}
`;
