import styled from 'styled-components';

export const Container = styled.div`
    padding: 30px 40px;
    background-color: ${({ theme }) => theme.colors.color1.base};

    .description {
        margin-top: 40px;
        color: ${({ theme }) => theme.colors.color4.base};
        font-size: 16px;
        line-height: 24px;

        .loyalty-card-img-container {
            margin-top: -50px;
        }

        .notice {
            margin-top: 30px;
            margin-bottom: 60px;
            font-size: 16px;
            font-weight: 500;
            display: flex;
            .notice-icon {
                color: ${({ theme }) => theme.colors.staticColor.color3.base};
                margin-right: 20px;
            }
        }
    }

    .select-plan-head {
        font-size: 16px;
        font-weight: 600;
        color: ${({ theme }) => theme.colors.color4.base};
    }

    .buy-btn-container {
        display: flex;
        justify-content: end;
        .ant-btn {
            min-width: 200px;
        }
    }
`;

export const Head = styled.h1`
    font-size: 22px;
    font-weight: 600;
`;

export const SubHead = styled.h2`
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 30px;
`;

export const LoyaltyCardsPlan = styled.div<{ isSelect: boolean }>`
    position: relative;
    margin-top: 40px;
    margin-bottom: 20px;
    padding: 50px 20px;
    background-color: ${({ theme }) => theme.colors.color1.base};
    box-shadow: 0px 4px 20px rgba(221, 221, 221, 0.16);
    border-radius: 6px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.color4.base};

    border: 3px solid
        ${({ isSelect, theme }) =>
            isSelect
                ? theme.colors.staticColor.color2.base
                : theme.colors.staticColor.color1.second1};

    &:hover {
        .head {
            .radio-selection {
                &:before {
                    background-color: ${({ theme }) =>
                        theme.colors.staticColor.color2.base};
                }
            }
        }
    }

    .selected-mark {
        position: absolute;
        bottom: -35px;
        left: -3px;
        right: -3px;
        text-align: center;
        padding: 10px;
        border-radius: 0 0 6px 6px;
        background-color: ${({ theme }) =>
            theme.colors.staticColor.color2.base};
        color: ${({ theme }) => theme.colors.color1.base};
        display: ${({ isSelect }) => (isSelect ? 'block' : 'none')};
    }

    .head {
        display: flex;
        margin-bottom: 40px;
        .radio-selection {
            width: 24px;
            height: 24px;
            border: 1px solid
                ${({ theme }) => theme.colors.staticColor.color1.second2};
            border-radius: 50%;
            margin-right: 16px;
            position: relative;

            &:before {
                content: '';
                display: block;
                width: 18px;
                height: 18px;
                top: 50%;
                left: 50%;
                position: absolute;
                margin-top: -9px;
                margin-left: -9px;
                border-radius: 50%;
                transition: all 0.3s;
                background-color: ${({ isSelect, theme }) =>
                    isSelect
                        ? theme.colors.staticColor.color2.base
                        : 'transparent'};
            }
        }
        .commission-selection {
            flex: 1;

            .discount {
                font-size: 20px;
                font-weight: 600;
                line-height: 28px;
                color: ${({ isSelect, theme }) =>
                    isSelect
                        ? theme.colors.staticColor.color2.base
                        : theme.colors.color4.base};
            }

            .min-price {
                font-size: 14px;
                margin-top: 10px;
                color: ${({ theme }) => theme.colors.color3.base};
            }
        }
    }

    .plan-list {
        list-style: disc;
        color: ${({ theme }) => theme.colors.color4.base};
        padding-left: 28px;
        li {
            padding-bottom: 10px;
        }
    }
`;
