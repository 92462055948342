import {
    MenuProps as AntGuideBarMenuProps,
    MenuItemProps as GuideBarMenuItemType,
} from 'antd';
import { GuideBarStyled } from './index.styles';
import { NavHashLink } from 'react-router-hash-link';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconType } from 'components/svg-icon/icon-types';
import { SvgIcon } from 'components/svg-icon';
import { useState } from 'react';
import { getLanguage } from 'common/utils';

type AllowedAntMenuItemTypes = 'title';

export interface GuideBarSubMenuItemProps {
    title: string;
    link: string;
}

export interface GuideBarMenuItemProps
    extends Pick<GuideBarMenuItemType, AllowedAntMenuItemTypes> {
    title: string;
    link: string;
    icon?: IconType;
    subTitles?: GuideBarSubMenuItemProps[];
}

const scrollWithOffset = (el: any) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -70;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};

type AllowedAntMenuTypes =
    | 'mode'
    | 'onClick'
    | 'children'
    | 'multiple'
    | 'defaultOpenKeys'
    | 'selectedKeys'
    | 'openKeys'
    | 'defaultSelectedKeys';

export interface GuideBarProps
    extends Pick<AntGuideBarMenuProps, AllowedAntMenuTypes> {
    items: GuideBarMenuItemProps[];
    path: string;
}

const WrappedGuideBar = ({ items: menuItems, ...props }: GuideBarProps) => {
    const navigate = useNavigate();
    const [pathName, setPathName] = useState<string>(location.pathname);

    const lang = getLanguage();
    return (
        <GuideBarStyled {...props} mode="inline">
            {menuItems &&
                menuItems.map((menuItem) => {
                    if (menuItem.subTitles && menuItem.subTitles.length > 0) {
                        return (
                            <GuideBarStyled.SubMenu
                                key={menuItem.title}
                                title={menuItem.title}
                                icon={
                                    menuItem.icon && (
                                        <SvgIcon name={menuItem.icon} />
                                    )
                                }
                            >
                                {menuItem.subTitles &&
                                    menuItem.subTitles.map((subItem) => {
                                        let tempPath =
                                            '/' +
                                            lang +
                                            '/' +
                                            'guide/' +
                                            menuItem.link +
                                            '/' +
                                            subItem.link;

                                        setTimeout(() => {
                                            setPathName(location.pathname);
                                        });
                                        return (
                                            <GuideBarStyled.Item
                                                onClick={() => {
                                                    setPathName('');
                                                }}
                                                key={tempPath}
                                                className={
                                                    tempPath === pathName
                                                        ? 'ant-menu-item-selected'
                                                        : ''
                                                }
                                            >
                                                <NavHashLink
                                                    to={tempPath}
                                                    scroll={(el) =>
                                                        scrollWithOffset(el)
                                                    }
                                                >
                                                    {subItem.title}
                                                </NavHashLink>
                                            </GuideBarStyled.Item>
                                        );
                                    })}
                            </GuideBarStyled.SubMenu>
                        );
                    } else {
                        return (
                            <GuideBarStyled.Item
                                key={menuItem.link}
                                onClick={() => {
                                    navigate(menuItem.link);
                                    setPathName('');
                                }}
                                className={
                                    pathName === menuItem.link
                                        ? 'ant-menu-item-selected'
                                        : ''
                                }
                            >
                                {menuItem.title}
                            </GuideBarStyled.Item>
                        );
                    }
                })}
        </GuideBarStyled>
    );
};

export const GuideBar = React.memo(WrappedGuideBar);
