import { config } from 'config';

const SOCKET_BASE_URL = config().webSocketUrl;

const socketBasePath = {
    socket_hst: `${SOCKET_BASE_URL}/hst`,
    socket_lg: `${SOCKET_BASE_URL}/lg`,
    socket_nc: `${SOCKET_BASE_URL}/nc`,
    socket_ob: `${SOCKET_BASE_URL}/ob`,
    socket_blc: `${SOCKET_BASE_URL}/blc`,
    socket_blc_new: `${SOCKET_BASE_URL}/blc/balance`,
};

const getSocketPath = {
    ping() {
        return `${socketBasePath.socket_lg}/ping`;
    },
    notification() {
        return `${socketBasePath.socket_nc}/notice`;
    },
    orderbook(pair: string) {
        return `${socketBasePath.socket_ob}/orderbook/${pair}`;
    },
    history(pair: string) {
        return `${socketBasePath.socket_hst}/history/${pair}`;
    },
    parity() {
        return `${socketBasePath.socket_hst}/history/parity`;
    },
    balance() {
        return `${socketBasePath.socket_blc}/history/balance`;
    },
    balanceNew() {
        return `${socketBasePath.socket_blc_new}`;
    },
};

export class AppSocket {
    pingConnected: boolean = false;
    init() {
        const wsPing = new WebSocket(getSocketPath.ping());

        wsPing.onopen = () => {
            this.pingConnected = true;
        };

        wsPing.onclose = () => {
            this.pingConnected = false;
        };
    }
}

export enum NotificationEvent {
    UnReadedNotices = 'notices',
    Notification = 'notice',
    UsableBalance = 'ublc',
    OrderCanceled = 'ocncl',
    OrderFailed = 'ofail',
    OrderCompleted = 'ocmpl',
    UpdateOrderFAmount = 'ofilled',
    OrderCreated = 'lstd',
}

export class AppNotificationSocket {
    public connected: boolean = false;
    private ws: any;
    private listeners: PartialRecord<string, (message: any) => void> = {};

    subscribe(event: string, fn: (message: any) => void) {
        if (!this.listeners[event]) {
            this.listeners[event] = fn;
        }
    }

    unsubscribe(event: string) {
        if (this.listeners[event]) {
            delete this.listeners[event];
        }
    }

    private emitMessage(event: OrderBookEvent, message: any) {
        if (this.listeners[event]) {
            const callback = this.listeners[event];
            callback && callback(message);
        }
    }

    openConnection() {
        this.ws = new WebSocket(getSocketPath.notification());
        this.ws.onopen = () => {
            this.connected = true;
        };

        this.ws.onclose = () => {
            this.connected = false;
        };

        this.ws.onmessage = (evt: any) => {
            let message = JSON.parse(evt.data);
            if (message.success && message.cmd) {
                this.emitMessage(message.cmd as OrderBookEvent, message.data);
            }
        };
    }

    closeConnection() {
        if (this.connected && this.ws) {
            this.ws && this.ws.close();
            this.ws = undefined;
            this.listeners = {};
        }
    }
}

export enum OrderBookEvent {
    OrderBook = 'book',
}

class AppOrderBookSocket {
    private connected: boolean = false;
    private ws: any;
    private listeners: PartialRecord<string, (message: any) => void> = {};
    private pair: string = '';

    subscribe(event: string, fn: (message: any) => void) {
        if (!this.listeners[event]) {
            this.listeners[event] = fn;
        }
    }

    unsubscribe(event: string) {
        if (this.listeners[event]) {
            delete this.listeners[event];
        }
    }

    private emitMessage(event: OrderBookEvent, message: any) {
        if (this.listeners[event]) {
            const callback = this.listeners[event];
            callback && callback(message);
        }
    }

    changePair(pair: string) {
        if (this.ws) {
            this.closeConnection();
            setTimeout(() => {
                if (this.pair !== pair) {
                    this.openConnection(pair);
                }
            });
        }
    }

    openConnection(pair: string) {
        this.pair = pair;

        this.ws = new WebSocket(getSocketPath.orderbook(pair));
        this.ws.onopen = () => {
            this.connected = true;
        };

        this.ws.onclose = () => {
            this.connected = false;
        };

        this.ws.onmessage = (evt: any) => {
            let message = JSON.parse(evt.data);
            if (message.success && message.cmd) {
                this.emitMessage(message.cmd as OrderBookEvent, message.data);
            }
        };
    }

    closeConnection() {
        if (this.connected && this.ws) {
            this.ws && this.ws.close();
            this.ws = undefined;
            this.listeners = {};
        }
    }
}

export enum HistoryEvent {
    MarketHistory = 'market',
    MarketInfo = 'minfo',
    marketCmpl = 'marketCmpl',
    MarketBox = 'mbox',
}

class AppHistorySocket {
    private connected: boolean = false;
    private ws: any;
    private listeners: PartialRecord<string, (message: any) => void> = {};

    subscribe(event: string, fn: (message: any) => void) {
        if (!this.listeners[event]) {
            this.listeners[event] = fn;
        }
    }

    unsubscribe(event: string) {
        if (this.listeners[event]) {
            delete this.listeners[event];
        }
    }

    private emitMessage(event: HistoryEvent, message: any) {
        if (this.listeners[event]) {
            const callback = this.listeners[event];
            callback && callback(message);
        }
    }
    changePair(pair: string) {
        if (this.ws) {
            this.closeConnection();
            this.openConnection(pair);
        }
    }
    openConnection(pair: string) {
        this.ws = new WebSocket(getSocketPath.history(pair));
        this.ws.onopen = () => {
            this.connected = true;
        };

        this.ws.onclose = () => {
            this.connected = false;
        };

        this.ws.onmessage = (evt: any) => {
            let message = JSON.parse(evt.data);

            if (message.success && message.cmd) {
                this.emitMessage(message.cmd as HistoryEvent, message.data);
            }
        };
    }

    closeConnection() {
        if (this.connected && this.ws) {
            this.ws && this.ws.close();
            this.ws = undefined;
            this.listeners = {};
        }
    }
}

class AppNewBalanceSocket {
    private connected: boolean = false;
    private ws: any;
    private listeners: PartialRecord<string, (message: any) => void> = {};
    private pair: string = '';

    subscribe(event: string, fn: (message: any) => void) {
        if (!this.listeners[event]) {
            this.listeners[event] = fn;
        }
    }

    unsubscribe(event: string) {
        if (this.listeners[event]) {
            delete this.listeners[event];
        }
    }

    private emitMessage(event: OrderBookEvent, message: any) {
        if (this.listeners[event]) {
            const callback = this.listeners[event];
            callback && callback(message);
        }
    }

    changePair() {
        if (this.ws) {
            this.closeConnection();
            setTimeout(() => {
                this.openConnection();
            });
        }
    }

    openConnection() {
        this.ws = new WebSocket(getSocketPath.balanceNew());
        this.ws.onopen = () => {
            this.connected = true;
        };

        this.ws.onclose = () => {
            this.connected = false;
        };

        this.ws.onmessage = (evt: any) => {
            let message = JSON.parse(evt.data);
            if (message.success && message.cmd) {
                this.emitMessage(message.cmd as OrderBookEvent, message.data);
            }
        };
    }

    closeConnection() {
        if (this.connected && this.ws) {
            this.ws && this.ws.close();
            this.ws = undefined;
            this.listeners = {};
        }
    }
}

export const appOrderBookSocket = new AppOrderBookSocket();
export const appHistorySocket = new AppHistorySocket();
export const appNotificationSocket = new AppNotificationSocket();
export const appBalanceNewSocket = new AppNewBalanceSocket();

export class AppBalanceSocket {}
