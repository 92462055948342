import { fonts } from 'common/theme/font';
import styled from 'styled-components';

export const NotificationDropdownStyled = styled.div`
    &.notification-dropdown {
        border-radius: 6px;
        width: 310px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
        filter: ${({ theme }) =>
            theme.selectedTheme == 'dark'
                ? 'drop-shadow(0px 0px 9px rgba(0,0,0,0.5))'
                : 'none'};
        background-color: ${({ theme }) => theme.colors.color1.base};

        .notification-dropdown-header {
            padding: 8px 20px 0px 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .notification-dropdown-header-text {
                font-weight: ${fonts.weight.bold};
                font-size: ${fonts.size.medium};

                color: ${({ theme }) => theme.colors.color4.second1};
            }

            .notification-dropdown-read-seen-btn {
                .app-icon {
                    margin: 0px !important;

                    svg {
                        color: ${({ theme }) =>
                            theme.colors.color1.second1} !important;
                    }
                }

                span {
                    font-weight: ${fonts.weight.medium};
                    font-size: ${fonts.size.medium};

                    color: ${({ theme }) =>
                        theme.colors.color4.base} !important;
                }
            }
        }

        .notification-dropdown-body {
            max-height: 60vh;
            overflow: auto;
            color: ${({ theme }) => theme.colors.color4.base};
            font-weight: ${fonts.weight.medium};
            font-size: ${fonts.size.medium};
            .noAnnouncement {
                padding: 40px 15px;
                text-align: center;
                .app-icon {
                    margin: auto;
                    display: block;
                    position: relative;
                    color: ${({ theme }) => theme.colors.color4.base};
                    opacity: 0.7;
                }
            }
            .notification-dropdown-ul {
                .notification-dropdown-li {
                    padding: 0 15px 0 32px;
                    border-bottom: 1px solid
                        ${({ theme }) => theme.colors.color2.base};

                    .notification-dropdown-li-title {
                        position: relative;

                        padding: 16px 0 3px 0;
                        font-weight: ${fonts.weight.semibold};
                        font-size: ${fonts.size.medium};

                        color: ${({ theme }) =>
                            theme.colors.staticColor.color2.base};
                        &::before {
                            content: '';
                            width: 6px;
                            height: 6px;
                            border-radius: 50%;
                            top: 24px;
                            left: -16px;
                            position: absolute;

                            background-color: ${({ theme }) =>
                                theme.colors.staticColor.color2.base};
                        }
                        a:hover {
                            color: inherit !important;
                        }

                        &.readed {
                            font-weight: ${fonts.weight.regular};
                            color: ${({ theme }) =>
                                theme.colors.color4.second1};
                            &::before {
                                background-color: ${({ theme }) =>
                                    theme.colors.color4.base};
                                opacity: 0.8;
                            }
                        }
                    }
                    .notification-dropdown-li-content {
                        padding-bottom: 4px;
                        font-weight: ${fonts.weight.regular};
                        font-size: ${fonts.size.small};
                        line-height: 1.4em;
                        color: ${({ theme }) => theme.colors.color4.base};
                    }
                    .notification-dropdown-li-date {
                        padding-bottom: 16px;
                        font-weight: ${fonts.weight.medium};
                        font-size: ${fonts.size.xSmall};

                        color: ${({ theme }) => theme.colors.color3.base};
                    }
                }
            }
        }

        .notification-dropdown-footer {
            display: flex;
            justify-content: flex-end;

            padding: 0px 12px !important;

            .notification-dropdown-see-all-btn {
                .app-icon {
                    margin: 0px !important;
                    padding: 0px !important;
                    svg {
                        color: ${({ theme }) =>
                            theme.colors.staticColor.color2.base} !important;
                    }
                }

                span {
                    font-weight: ${fonts.weight.semibold};
                    font-size: ${fonts.size.medium};

                    color: ${({ theme }) =>
                        theme.colors.staticColor.color2.base};
                }
            }
        }
    }
`;
