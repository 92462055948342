import styled from 'styled-components';
import { Button as AntButton } from 'antd';
import { fonts } from '../../common/theme/font';

export const IconButtonStyled = styled(AntButton)`
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    font-size: ${fonts.size.normal};
    font-weight: ${fonts.weight.semibold};
    height: 36px;
    padding: 6px 16px;
    border-radius: 4px;
    text-align: center;
    line-height: 1;
    letter-spacing: 0.04em;

    &.app-icon-btn {
        border: none;
    }

    & > span {
        color: ${({ theme }) => theme.colors.color1.base} !important;
    }

    &:hover {
        cursor: pointer;
    }

    &:focus {
    }

    &:active {
    }

    &:loading {
    }

    &:disabled {
        background-color: ${({ theme }) => theme.colors.color2.base} !important;
        cursor: not-allowed;
        box-shadow: none;
    }

    &.ant-btn {
        &-sm {
            height: 25px;
            padding: 2px 5px;

            &.app-btn-with-icon {
                padding: 3px 16px;

                .app-icon {
                    font-size: ${fonts.size.xSmall};
                }
            }
        }

        &-lg {
            padding: 10px 40px;
            min-width: 180px;
            background-color: ${({ theme }) =>
                theme.colors.staticColor.color2.base};
            text-transform: uppercase;

            &.app-btn-with-icon {
                padding: 10px 24px;

                .app-icon {
                    font-size: ${fonts.size.normal};
                }
            }
        }
    }
`;
