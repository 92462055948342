import * as S from './index.styles';
import { Carousel, Col, Row, Tabs } from 'antd';
import { SvgIcon } from 'components/svg-icon';
import { Button } from 'components/button';
import { useCallback, useEffect, useState } from 'react';
// import Input from 'components/input';
import { routesPaths } from 'config/routes';
import { Link } from 'react-router-dom';
import Footer from 'components/home-page/footer';
import useBlogApi from 'api/blog/blog.api';
import { getLocaleDateFormat, getLanguage } from 'common/utils';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Input, Pagination } from 'components';

export type BoxType = 'column' | 'row';
interface CategoriesType {
    id: number;
    attributes: {
        title: string;
    };
}

interface PostType {
    attributes: {
        type?: BoxType;
        title: string;
        slug: string;
        description: string;
        publishedAt: string;
        readingtime: string;
        sliderImage: SliderType;
        thumbnail: ThumbnailType;
        categories: {
            data: [
                {
                    id: number;
                    attributes: {
                        title: string;
                    };
                }
            ];
        };
    };
}
interface SliderType {
    data: {
        attributes: {
            name: string;
            alternativeText: string;
            url: string;
            formats: {
                small: {
                    url: string;
                };
            };
        };
    };
}
interface ThumbnailType {
    data: {
        attributes: {
            name: string;
            alternativeText: string;
            url: string;
            formats: {
                thumbnail: {
                    url: string;
                };
            };
        };
    };
}

const BlogItem = ({ type, data }: { type: BoxType; data: PostType }) => {
    return (
        <S.Box className={type}>
            <Link to={`${routesPaths.blog.root}/${data.attributes.slug}`}>
                <div className="sub-banner">
                    <img
                        src={`${process.env.REACT_APP_STRAPI_BASE_URL}${data.attributes.thumbnail.data.attributes.url}`}
                        alt={
                            data.attributes.thumbnail.data.attributes
                                .alternativeText
                        }
                    />
                </div>
                <S.Info>
                    <div className="time">
                        <div className="date">
                            <span>
                                {getLocaleDateFormat(
                                    data.attributes.publishedAt
                                )}
                            </span>
                        </div>
                        {data.attributes.readingtime !== null && (
                            <div className="time">
                                <SvgIcon name="time" />
                                <span>{data.attributes.readingtime} dk</span>
                            </div>
                        )}
                    </div>
                    <div className="title">
                        <h1 className="box-title">{data.attributes.title}</h1>
                    </div>
                    <div className="detail">
                        <p className="box-text">
                            {data.attributes.description}
                        </p>
                    </div>
                    <Button type="link" suffixIcon="chevron-right">
                        Devamını gör
                    </Button>
                </S.Info>
            </Link>
        </S.Box>
    );
};

export const BlogPage = () => {
    const lang = getLanguage();
    const { getBlogSlider, getBlogFeatured, getCategories, getBlog } =
        useBlogApi();
    const { t } = useTranslation();
    const { TabPane } = Tabs;
    const [_slider, setSlider] = useState<PostType[]>([]);
    const [_featured, setFeatured] = useState<PostType[]>([]);
    const [_categories, setCategories] = useState<CategoriesType[]>([]);
    const [_blog, setBlog] = useState<PostType[]>([]);
    const [_blogAll, setBlogAll] = useState<PostType[]>([]);
    const [text, setText] = useState<string>('');
    const [activeKey, setActiveKey] = useState<string>('0');
    const showingPage = 9;
    const [pagination, setPagination] = useState<any>({
        total: 0,
        selectedPage: 1,
        limit: showingPage,
        totalPages: 0,
    });

    const fetchInitial = useCallback(async () => {
        const slider = await getBlogSlider(lang);
        setSlider(slider.data.data);

        const featured = await getBlogFeatured(lang);
        setFeatured(featured.data.data);

        const categories = await getCategories(lang);
        setCategories(categories.data.data);

        const blog = await getBlog(lang);

        setTimeout(() => {
            const length = blog.data.data.length;
            let tempData = blog.data.data.slice(0, showingPage);

            setBlogAll(blog.data.data);
            setBlog(tempData);
            setPagination({
                total: length,
                selectedPage: 1,
                limit: showingPage,
                totalPages: length / showingPage,
            });
        });
    }, [lang]);

    useEffect(() => {
        fetchInitial();
    }, [lang]);

    //const sources: BlogType[] = [];
    const data_slider: PostType[] = _slider;
    const data_featured: PostType[] = _featured;
    const data_categories: CategoriesType[] = _categories;
    const data_blog: PostType[] = _blog;
    const data_blog_all: PostType[] = _blogAll;

    const handleChangePage = useCallback((data, e, cat, catKey) => {
        const currentPage = e;
        setActiveKey(catKey);
        const categoryData = data.filter((item: any) =>
            item.attributes.categories.data.find(
                (x: any) => x.id == cat[catKey].id
            )
        );

        const length = categoryData.length;
        let tempData = categoryData.slice(
            pagination.limit * currentPage - pagination.limit,
            pagination.limit * currentPage
        );

        setTimeout(() => {
            setBlog(tempData);
            setPagination({
                total: length,
                selectedPage: currentPage,
                limit: showingPage,
                totalPages: length / showingPage,
            });
        });
    }, []);
    const handleSearch = useCallback((searchText: any, allData: any) => {
        setActiveKey('0');

        setBlog(allData.slice(0, showingPage));
        setText(searchText);

        setPagination({
            total: allData.length,
            selectedPage: 1,
            limit: showingPage,
            totalPages: allData.length / showingPage,
        });
    }, []);
    const subTabChange = useCallback((data, e, cat) => {
        setText('');
        setActiveKey(e);

        const categoryData = data.filter((item: any) =>
            item.attributes.categories.data.find((x: any) => x.id == cat[e].id)
        );

        setTimeout(() => {
            const length = categoryData.length;
            let tempData = categoryData.slice(0, showingPage);

            setBlog(tempData);
            setPagination({
                total: length,
                selectedPage: 1,
                limit: showingPage,
                totalPages: length / showingPage,
            });
        });
    }, []);
    return (
        <>
            <Helmet>
                <title>
                    {t('seo.blog.title', {
                        appTitle: process.env.REACT_APP_TITLE,
                    })}
                </title>
                <meta
                    name="description"
                    content={t('seo.blog.description')}
                ></meta>
                <meta name="keywords" content={t('seo.blog.keywords')}></meta>
            </Helmet>
            <S.Banner>
                <S.Container>
                    <S.Head>Yuex Blog</S.Head>
                    <Carousel autoplay>
                        {data_slider.map((data, key) => {
                            return (
                                <Link
                                    to={`${routesPaths.blog.root}/${data.attributes.slug}`}
                                    className="item"
                                    key={key}
                                >
                                    <Row>
                                        <Col span={12}>
                                            <div className="banner">
                                                <img
                                                    src={`${process.env.REACT_APP_STRAPI_BASE_URL}${data.attributes.sliderImage.data.attributes.url}`}
                                                    alt={
                                                        data.attributes
                                                            .sliderImage.data
                                                            .attributes
                                                            .alternativeText
                                                    }
                                                />
                                            </div>
                                        </Col>
                                        <Col span={12}>
                                            <S.Info>
                                                <div className="time">
                                                    <div className="date">
                                                        <span>
                                                            {getLocaleDateFormat(
                                                                data.attributes
                                                                    .publishedAt
                                                            )}
                                                        </span>
                                                    </div>
                                                    {data.attributes
                                                        .readingtime !==
                                                        null && (
                                                        <div className="time">
                                                            <SvgIcon name="time" />
                                                            <span>
                                                                {
                                                                    data
                                                                        .attributes
                                                                        .readingtime
                                                                }{' '}
                                                                dk
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="title">
                                                    <h1 className="banner-title">
                                                        {data.attributes.title}
                                                    </h1>
                                                </div>
                                                <div className="detail">
                                                    <p className="banner-text">
                                                        {
                                                            data.attributes
                                                                .description
                                                        }
                                                    </p>
                                                </div>
                                                <Button
                                                    type="link"
                                                    suffixIcon="chevron-right"
                                                >
                                                    Devamını gör
                                                </Button>
                                            </S.Info>
                                        </Col>
                                    </Row>
                                </Link>
                            );
                        })}
                    </Carousel>
                </S.Container>
            </S.Banner>
            <S.Middle>
                <S.Container>
                    <Row>
                        {data_featured.map((item, key) => {
                            return (
                                <Col span={8} key={key}>
                                    <BlogItem type="column" data={item} />
                                </Col>
                            );
                        })}
                    </Row>

                    <Row className="tab-menu">
                        <S.TabMenu>
                            <Tabs
                                onChange={(e) => {
                                    subTabChange(
                                        data_blog_all,
                                        e,
                                        data_categories
                                    );
                                }}
                                activeKey={activeKey}
                            >
                                {data_categories.map((data, key) => {
                                    return (
                                        <TabPane
                                            tab={data.attributes.title}
                                            key={key}
                                        >
                                            <Row className="searchRow">
                                                {text !== '' ? (
                                                    <>
                                                        {data_blog_all
                                                            .filter(
                                                                (x: any) => {
                                                                    return x.attributes.title
                                                                        .toLowerCase()
                                                                        .includes(
                                                                            text.toLowerCase()
                                                                        );
                                                                }
                                                            )
                                                            .slice(
                                                                0,
                                                                showingPage
                                                            )
                                                            .map(
                                                                (item, key) => {
                                                                    return (
                                                                        <Col
                                                                            span={
                                                                                8
                                                                            }
                                                                            key={
                                                                                key
                                                                            }
                                                                        >
                                                                            <BlogItem
                                                                                type="column"
                                                                                data={
                                                                                    item
                                                                                }
                                                                            />
                                                                        </Col>
                                                                    );
                                                                }
                                                            )}
                                                        <Col className="notFound">
                                                            <SvgIcon
                                                                name="warning-circle-filled"
                                                                size={48}
                                                            />
                                                            Aradığınız
                                                            bulunamadı.
                                                        </Col>
                                                    </>
                                                ) : (
                                                    <>
                                                        {data_blog.map(
                                                            (item, key) => {
                                                                return (
                                                                    <Col
                                                                        span={8}
                                                                        key={
                                                                            key
                                                                        }
                                                                    >
                                                                        <BlogItem
                                                                            type="column"
                                                                            data={
                                                                                item
                                                                            }
                                                                        />
                                                                    </Col>
                                                                );
                                                            }
                                                        )}
                                                    </>
                                                )}
                                            </Row>
                                        </TabPane>
                                    );
                                })}
                            </Tabs>

                            <div className="search-field">
                                <Input
                                    placeholder="Ara..."
                                    prefixIcon="search"
                                    onChange={(e: any) =>
                                        handleSearch(
                                            e.target.value,
                                            data_blog_all
                                        )
                                    }
                                    value={text}
                                />
                            </div>
                        </S.TabMenu>
                        {text == '' && (
                            <Pagination
                                key={activeKey + text.length}
                                handleChange={(e) => {
                                    handleChangePage(
                                        data_blog_all,
                                        e,
                                        data_categories,
                                        activeKey
                                    );
                                }}
                                options={pagination}
                            />
                        )}
                    </Row>
                </S.Container>
            </S.Middle>
            <Footer />
        </>
    );
};
