import {
    MenuProps as AntSideBarMenuProps,
    MenuItemProps as SideBarMenuItemType,
} from 'antd';
import { SideBarMenuStyled } from './index.styles';
import { IconType } from 'components/svg-icon/icon-types';
import { SvgIcon } from 'components/svg-icon';
import React from 'react';
import { useNavigate } from 'react-router-dom';

type AllowedAntMenuItemTypes = 'title';

export interface MobileSubMenuItemProps {
    title: string;
    icon?: IconType;
    link: string;
}

export interface MobileMenuItemProps
    extends Pick<SideBarMenuItemType, AllowedAntMenuItemTypes> {
    title: string;
    icon?: IconType;
    link: string;
    subTitles?: MobileSubMenuItemProps[];
}

type AllowedAntMenuTypes =
    | 'mode'
    | 'onClick'
    | 'children'
    | 'multiple'
    | 'defaultOpenKeys'
    | 'selectedKeys'
    | 'openKeys'
    | 'defaultSelectedKeys';

export interface MobileMenuProps
    extends Pick<AntSideBarMenuProps, AllowedAntMenuTypes> {
    items: MobileMenuItemProps[];
    onCloseDrawer: () => void;
}

const WrappedMobileMenu = ({
    mode = 'inline',
    multiple: _multiple = false,
    items: menuItems,
    children: _children,
    onCloseDrawer,
    ...props
}: MobileMenuProps) => {
    const navigate = useNavigate();
    return (
        <SideBarMenuStyled mode={mode} {...props}>
            {menuItems &&
                menuItems.map((menuItem) => {
                    if (menuItem.subTitles && menuItem.subTitles.length > 0) {
                        return (
                            <SideBarMenuStyled.SubMenu
                                key={menuItem.title}
                                title={menuItem.title}
                                icon={
                                    menuItem.icon && (
                                        <SvgIcon name={menuItem.icon} />
                                    )
                                }
                            >
                                {menuItem.subTitles &&
                                    menuItem.subTitles.map((subItem) => {
                                        return (
                                            <SideBarMenuStyled.Item
                                                key={subItem.link}
                                                onClick={() => {
                                                    navigate(subItem.link);
                                                    onCloseDrawer();
                                                }}
                                            >
                                                {subItem.title}
                                            </SideBarMenuStyled.Item>
                                        );
                                    })}
                            </SideBarMenuStyled.SubMenu>
                        );
                    } else {
                        return (
                            <SideBarMenuStyled.Item
                                key={menuItem.link}
                                onClick={() => {
                                    navigate(menuItem.link);
                                    onCloseDrawer();
                                }}
                                icon={
                                    menuItem.icon && (
                                        <SvgIcon name={menuItem.icon} />
                                    )
                                }
                            >
                                {menuItem.title}
                            </SideBarMenuStyled.Item>
                        );
                    }
                })}
        </SideBarMenuStyled>
    );
};

export const HeaderMobileMenu = React.memo(WrappedMobileMenu);
