import styled from 'styled-components';
import { Modal as AntModal } from 'antd';
import { ModalProps } from '.';

export const Container = styled(AntModal)<ModalProps>`
    .ant-modal-close {
        ${({ closeIcon }) =>
            closeIcon === '' ? 'display: none !important;' : ''}
    }
    &.ant-modal {
        display: flex;
        align-items: center;
        width: ${({ width }: ModalProps) => `${width}px !important`};
        top: 0;
        height: 100%;
        .ant-modal-content {
            margin: 0 auto;
            .ant-modal-header {
            }

            .ant-modal-body {
            }
        }

        &.no-padding {
            .ant-modal-content {
                background: transparent !important;
                .ant-modal-body {
                    padding: 0 !important;
                }
            }
        }

        &.yuex-modal-size-large {
            .ant-modal-content {
                width: 100%;
            }
        }
    }
`;
