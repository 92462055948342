import { InputProps as AntInputProps } from 'antd';
import { IconType } from 'components/svg-icon/icon-types';
import { SvgIcon } from 'components/svg-icon';
import * as S from './index.styles';
import { ChangeEventHandler, useCallback } from 'react';
import CurrencyInput from 'components/currency-input/CurrencyInput';

export interface InputProps
    extends CommonFormProps,
        Omit<
            AntInputProps,
            | 'onKeyPress'
            | 'suffix'
            | 'prefix'
            | 'value'
            | 'onChange'
            | 'onBlur'
            | 'onFocus'
            | 'form'
        > {
    suffixIcon?: IconType | undefined;
    prefixIcon?: IconType | undefined;
    suffixText?: string | undefined;
    prefixText?: string | undefined;
    maskOptions?: {
        decimalLimit?: number;
        integerLimit?: number;
    };
    preOnChange?: (
        e: ChangeEventHandler<HTMLInputElement>
    ) => void | ChangeEventHandler<HTMLInputElement>;
}

export const Input = (props: InputProps) => {
    const extraProps: any = {};
    if (props.suffixIcon || props.suffixText) {
        extraProps.suffix = (
            <>
                {props.suffixText && <span>{props.suffixText}</span>}
                {props.suffixIcon && <SvgIcon name={props.suffixIcon} />}
            </>
        );
    }

    if (props.prefixIcon) {
        extraProps.prefix = <SvgIcon name={props.prefixIcon} />;
    }

    if (props.error) {
        extraProps.suffix = <SvgIcon name="error-circle-filled" />;
    }
    return <S.Input {...props} {...extraProps} />;
};

export const Number = (props: InputProps) => {
    const extraProps: any = {};

    if (props.suffixIcon || props.suffixText) {
        extraProps.suffix = (
            <>
                {props.suffixText && <span>{props.suffixText}</span>}
                {props.suffixIcon && <SvgIcon name={props.suffixIcon} />}
            </>
        );
    }
    if (props.prefixIcon || props.prefixText) {
        extraProps.prefix = (
            <>
                {props.prefixText && <span>{props.prefixText}</span>}
                {props.prefixIcon && <SvgIcon name={props.prefixIcon} />}
            </>
        );
    }

    if (props.error) {
        extraProps.suffix = <SvgIcon name="error-circle-filled" />;
    }

    const handleChange = useCallback(
        (event: ChangeEventHandler<HTMLInputElement> & Function) => {
            if (props.preOnChange) {
                const returnedEvent = props.preOnChange(event);
                props.onChange && props.onChange(returnedEvent || event);
            } else {
                props.onChange && props.onChange(event);
            }
        },
        [props.preOnChange, props.onChange]
    );

    return (
        <>
            {/* {NumberFormat(props.value as string)} */}
            <S.Input
                // type={'number'}
                {...props}
                {...extraProps}
                onChange={handleChange}
                onKeyPress={(event: any) => {
                    if (
                        !/^[0-9,]*\.?[0-9]*$/.test(event.key) ||
                        (event.target.value.split('.').length === 2 &&
                            event.key === '.')
                    ) {
                        event.preventDefault();
                    }
                    props.onKeyPress && props.onKeyPress(event);
                }}
            />
        </>
    );
};

export const Currency = (props: InputProps) => {
    const handleChange = useCallback(
        (event: ChangeEventHandler<HTMLInputElement> & Function) => {
            if (props.preOnChange) {
                const returnedEvent = props.preOnChange(event);
                props.onChange && props.onChange(returnedEvent || event);
            } else {
                props.onChange && props.onChange(event);
            }
        },
        [props.preOnChange, props.onChange]
    );
    return (
        <S.CurrencyInput>
            <span className={`ant-input-affix-wrapper input-currency`}>
                <CurrencyInput
                    {...props}
                    className={props.className}
                    maskOptions={props.maskOptions}
                    autoComplete={props.autoComplete}
                    disabled={props.disabled}
                    name={props.name}
                    value={props.value}
                    onChange={handleChange}
                />
                <span className="ant-input-prefix">
                    {props.prefixText && <span>{props.prefixText}</span>}
                    {props.prefixIcon && <SvgIcon name={props.prefixIcon} />}
                </span>
                <span className="ant-input-suffix">
                    {props.suffixText && <span>{props.suffixText}</span>}
                    {props.suffixIcon && <SvgIcon name={props.suffixIcon} />}
                </span>
            </span>
        </S.CurrencyInput>
    );
};
export const Password = (props: InputProps) => {
    const extraProps: any = {};
    if (props.suffixIcon) {
        extraProps.suffix = <SvgIcon name={props.suffixIcon} />;
    }
    if (props.prefixIcon) {
        extraProps.prefix = <SvgIcon name={props.prefixIcon} />;
    }

    return <S.Password {...props} {...extraProps} />;
};

export const Search = (props: InputProps) => {
    const extraProps: any = {};
    if (props.suffixIcon) {
        extraProps.suffix = <SvgIcon name={props.suffixIcon} />;
    }
    if (props.prefixIcon) {
        extraProps.prefix = <SvgIcon name={props.prefixIcon} />;
    }

    return <S.Input.Search {...props} {...extraProps} />;
};

export interface TextAreaProps {
    rows?: number;
    label?: string;
    placeholder?: string; // autoSize?: boolean | { minRows?: number; maxRows?: number };
}

export const TextArea = (props: TextAreaProps) => {
    return <S.TextArea {...props} />;
};

export default Input;
