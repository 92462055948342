import { Loading } from 'components/loading';
import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthenticationContext } from './AuthenticationProvider';

export interface AuthGuardProps {
    children?: JSX.Element;
    guardCondition?: boolean;
    redirect?: string;
}

export default function AuthGuard({
    children,
    guardCondition,
    redirect,
}: AuthGuardProps): JSX.Element {
    const navigate = useNavigate();
    const { isLoading, isAuthenticated } = useContext(AuthenticationContext);

    const render = useCallback(() => {
        if (guardCondition === isAuthenticated) {
            return children;
        } else {
            if (redirect) {
                navigate(redirect, { replace: true });
            } else {
                return <>'No Permission'</>;
            }
        }
    }, [children, redirect, isAuthenticated]);

    return (
        <>
            {isLoading && <Loading />}
            {!isLoading && render()}
        </>
    );
}
