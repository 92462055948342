import { useModal } from 'components/modal';
import { useCallback } from 'react';
// import { ChangePassword2FAConfirm } from 'widgets/change-password/change-password-2FA-confirm';
import { ChangePhone } from 'widgets/change-phone/index';

interface ReturnUseChangPhoneConfirmModal {
    placeholder: () => void;
    openModal: () => void;
    closeModal: () => void;
}

export interface useChangePhoneConfirmModalProps {
    onNext: (globalValue: boolean) => void;
}

export const useChangePhoneModal = ({
    onNext,
}: useChangePhoneConfirmModalProps): ReturnUseChangPhoneConfirmModal => {
    const { placeholder, openModal, closeModal } = useModal({
        className: 'change-password-2fa-confirm-modal',
    });

    const handleOpenChangePhoneConfirmModal = useCallback(() => {
        openModal(<ChangePhone onCancel={closeModal} onNext={onNext} />);
    }, [openModal]);

    return {
        placeholder,
        openModal: handleOpenChangePhoneConfirmModal,
        closeModal: closeModal,
    };
};
