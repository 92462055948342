//@ts-nocheck
import styled from 'styled-components';
import { DatePicker as AntDatePicker } from 'antd';
import { fonts } from 'common/theme/font';
import { inputDefaultCss } from 'components/input/index.styles';

export const DatePickerStyled = styled(AntDatePicker)`
    /* position: relative; */
    display: inline-flex;
    align-items: center;

    box-sizing: border-box;
    cursor: pointer;
    ${inputDefaultCss};

    &:hover {
        border-color: ${({ theme }) => theme.colors.staticColor.color2.base};
    }
    .ant-picker-header {
        .ant-picker-header-view {
            button {
                &:hover {
                    color: ${({ theme }) =>
                        theme.colors.staticColor.color2.base};
                }
            }
        }
    }
    .ant-picker-content {
        .ant-picker-cell-selected {
            .ant-picker-cell-inner {
                background: ${({ theme }) =>
                    theme.colors.staticColor.color2.base};
            }
        }
        .ant-picker-cell-inner {
            &:before {
                border-color: ${({ theme }) =>
                    theme.colors.staticColor.color2.base};
            }
        }
    }
    .ant-picker-footer {
        a {
            color: ${({ theme }) => theme.colors.staticColor.color2.base};
        }
    }
    &.ant-picker-focused {
        border-color: ${({ theme }) => theme.colors.staticColor.color2.base};
        box-shadow: none;
    }
    .ant-picker-input {
        input {
            color: ${({ theme }) => theme.colors.color3.base};
            &::placeholder {
                color: ${({ theme }) => theme.colors.color3.base};
                opacity: 1;
            }

            &:-ms-input-placeholder {
                color: ${({ theme }) => theme.colors.color3.base};
            }

            &::-ms-input-placeholder {
                color: ${({ theme }) => theme.colors.color3.base};
            }
        }
        .ant-picker-suffix {
            color: ${({ theme }) => theme.colors.staticColor.color2.base};
            font-size: ${fonts.size.large};
        }
    }
    .ant-picker-clear {
        color: ${({ theme }) => theme.colors.color3.base};
        font-size: ${fonts.size.large};
    }
`;
