import { BuySellApiResponse, BuySellResponse } from './placeOrder.model';

export const mappingEasyBuySell = (
    payload: BuySellApiResponse,
    formType: string
): BuySellResponse => {
    const newPayload: BuySellResponse = {
        lastPrice: {
            value: Object.keys(
                payload.response.last_price
            )[0] as unknown as number,
            currency: payload.response.last_price[
                Object.keys(payload.response.last_price)[0]
            ] as unknown as string,
        },
        total: {
            value: Object.keys(payload.response.total)[0] as unknown as number,
            currency: payload.response.total[
                Object.keys(payload.response.total)[0]
            ] as unknown as string,
        },
        fee: {
            value: Object.keys(payload.response.fee)[0] as unknown as number,
            currency: payload.response.fee[
                Object.keys(payload.response.fee)[0]
            ] as unknown as string,
        },
        net: {
            value: Object.keys(payload.response.net)[0] as unknown as number,
            currency: payload.response.net[
                Object.keys(payload.response.net)[0]
            ] as unknown as string,
        },
        success: true,
        formType: formType,
    };
    if (formType === 'buy') {
        newPayload.purchaseAmount = {
            value: Object.keys(
                payload.response?.purchase_amount
            )[0] as unknown as number,
            currency: payload.response?.purchase_amount[
                Object.keys(payload.response?.purchase_amount)[0]
            ] as unknown as string,
        };
    }

    return newPayload;
};
