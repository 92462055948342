import { useRef } from 'react';
import httpService from 'common/http/http.service';
import {
    AccountApiCalls,
    ReportsBaseRequest,
    LastActionResponse,
    verificationAccountRequest,
    UserfavcoinsRequest,
    UserfavcoinsResponse,
} from './account.model';
import accountApiEndpoints from './account.endpoint';
import { mappingLastAction, mappingUserfavcoins } from './account.mapping';
import { HttpResponse } from 'common/http/http.model';

const useAccountApi = (): AccountApiCalls => {
    const generateApiCalls = (): AccountApiCalls => {
        return {
            lastAction: async (payload: ReportsBaseRequest) => {
                const response: LastActionResponse =
                    await httpService.client.get(
                        accountApiEndpoints.lastAction,
                        {
                            params: payload,
                        }
                    );
                return mappingLastAction(response);
            },

            userfavcoins: async () => {
                const response: UserfavcoinsResponse[] =
                    await httpService.client.get(
                        accountApiEndpoints.userfavcoins
                    );
                return mappingUserfavcoins(response);
            },
            addUserfavcoins: (
                payload: UserfavcoinsRequest
            ): HttpResponse<any> =>
                httpService.client.post(
                    accountApiEndpoints.userfavcoins,
                    payload
                ),
            deleteUserfavcoins: (
                payload: UserfavcoinsRequest
            ): HttpResponse<any> =>
                httpService.client.post(
                    accountApiEndpoints.userdeletefavcoins,
                    payload
                ),
            verificationAccount: (
                payload: verificationAccountRequest
            ): HttpResponse<any> =>
                httpService.client.post(
                    accountApiEndpoints.confirmAccount,
                    payload
                ),
        };
    };
    const accountCallsRef = useRef<AccountApiCalls>(generateApiCalls());

    return accountCallsRef.current;
};

export default useAccountApi;
