import styled from 'styled-components';
import { fonts } from 'common/theme/font';

export const IbanAutoCompleteStyled = styled.div`
    label {
        font-size: ${fonts.size.small};
    }
    &.address-auto-complete {
        position: relative;
        .add-button {
            position: absolute;
            top: -5px;
            right: 0;
            .ant-btn {
                height: inherit;
                span {
                    font-size: ${fonts.size.small};
                }
            }
        }
        .ant-select-auto-complete {
            border-radius: 4px;
            margin-top: 5px;
            margin-bottom: 16px;
            &.invalid {
                .ant-select-selector {
                    background-color: ${({ theme }) =>
                        theme.colors.staticColor.color2.opacity2} !important;
                }
            }
        }
    }
`;
