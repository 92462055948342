import { fonts } from 'common/theme/font';
import { mediaQuery } from 'common/theme/media';
import styled, { css } from 'styled-components';

const depositFormMobileCss = css`
    &.wallet-dropdown {
        margin-top: 10px;
        margin-right: -20px;
    }
`;

export const WalletDropDownStyled = styled.div`
    &.wallet-dropdown {
        border-radius: 6px;
        width: 310px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
        background-color: ${({ theme }) => theme.colors.color1.base};
        filter: ${({ theme }) =>
            theme.selectedTheme == 'dark'
                ? 'drop-shadow(0px 0px 9px rgba(0,0,0,0.5))'
                : 'none'};
    }
    .wallet-dropdown {
        &-header {
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            background-color: ${({ theme }) => theme.colors.color2.opacity4};
            padding: 20px;
            &-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                align-items: flex-start;
                &:nth-child(2) {
                    margin-bottom: 0;
                }

                i.app-icon {
                    font-size: 25px;
                    margin-right: 10px;
                    color: ${({ theme }) =>
                        theme.colors.staticColor.color2.base} !important;
                }
            }
            .ant-btn-icon-only {
                padding: 0;
                background: transparent;
                height: inherit;
                width: inherit;
                text-align: center;
                box-shadow: none;
                outline: 0;

                i {
                    margin-right: 0 !important;
                }

                &:focus {
                    outline: 0;
                }
            }
            .wallet-icons {
                display: flex;
            }
            &-text {
                font-weight: ${fonts.weight.semibold};
                max-width: 90%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: ${({ theme }) => theme.colors.staticColor.color2.base};
                &.light-text {
                    color: ${({ theme }) => theme.colors.color4.base};
                    font-size: ${fonts.size.small};
                }
                span {
                    display: block;
                    &.small-text {
                        font-size: ${fonts.size.xSmall};
                        margin-bottom: 8px;
                    }
                    &.small-text-total {
                        font-size: ${fonts.size.xSmall};
                        color: ${({ theme }) => theme.colors.color4.base};
                        margin-top: 16px;
                        margin-bottom: 8px;
                        font-weight: ${fonts.weight.regular};
                    }
                    &.total {
                        color: ${({ theme }) => theme.colors.color4.base};
                    }
                }
            }
        }

        &-content {
            padding: 0px 20px 20px;
            max-height: 260px;
            overflow: auto;
            .coin-balance {
                display: flex;
                justify-content: space-between;
                padding: 12px 0px;

                border-bottom: 1px solid
                    ${({ theme }) => theme.colors.color2.base};

                span.coin-total {
                    display: block;
                    font-size: ${fonts.size.small};
                    color: ${({ theme }) => theme.colors.color4.second1};
                    font-weight: ${fonts.weight.semibold};
                    padding-bottom: 5px;
                    display: block;
                    float: right;

                    .coin-name {
                        font-weight: ${fonts.weight.regular};
                    }
                }
                span.balance-total {
                    display: block;
                    font-size: ${fonts.size.small};
                    color: ${({ theme }) => theme.colors.color3.base};
                    float: right;
                }
                .short-value-name {
                    color: ${({ theme }) => theme.colors.color4.base};
                }
                .long-value-name {
                    color: ${({ theme }) => theme.colors.color3.base};
                }
            }
        }
        &-footer {
            display: flex;
            justify-content: space-between;

            padding: 20px 30px 20px 30px;
            button {
                width: 110px;
                height: 30px;
                background-color: ${({ theme }) =>
                    theme.colors.staticColor.color2.base} !important;
                color: ${({ theme }) =>
                    theme.colors.staticColor.color1.base} !important ;

                .app-icon {
                    margin-right: 6px;
                    font-size: 20px;
                    position: relative;
                    top: -2px;
                    svg {
                        color: ${({ theme }) =>
                            theme.colors.staticColor.color1.base} !important;
                    }
                }
                span {
                    font-size: 12px !important;
                    color: ${({ theme }) =>
                        theme.colors.staticColor.color1.base} !important;
                }
                &:hover {
                    span {
                        color: ${({ theme }) =>
                            theme.colors.staticColor.color1.base} !important;
                    }
                }
            }
        }
    }
    ${mediaQuery.lessThan('tablet')`${depositFormMobileCss}`}
`;
