import styled from 'styled-components';
import { Radio as AntRadio } from 'antd';
import { fonts } from 'common/theme/font';

export const RadioGroupStyled = styled(AntRadio.Group)`
    .ant-radio-wrapper {
        margin-right: 24px;
    }

    &.ant-radio-group-outline {
        .ant-radio-button-wrapper {
            border: 1px solid ${({ theme }) => theme.colors.color2.base};
            background: ${({ theme }) => theme.colors.color1.base};
            color: ${({ theme }) => theme.colors.color2.base};
            font-size: ${fonts.size.xxSmall};
            font-weight: ${fonts.weight.medium};

            &.ant-radio-button-wrapper-checked,
            &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
                color: ${({ theme }) => theme.colors.color1.base};
                font-weight: ${fonts.weight.semibold};
                border: none;
            }
        }
    }

    &.ant-radio-group-solid {
        .ant-radio-button-wrapper {
            background: ${({ theme }) => theme.colors.color2.base};
            border: none;
            font-size: ${fonts.size.xSmall};
            font-weight: ${fonts.weight.semibold};
            color: ${({ theme }) => theme.colors.color4.base};
        }
    }

    .ant-radio-button-wrapper {
        text-transform: uppercase;
        height: auto;
        position: relative;
        transition: none;
        &:not(:first-child)::before {
            display: none;
        }

        &.ant-radio-button-wrapper-checked,
        &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
            background: ${({ theme }) => theme.colors.staticColor.color2.base};
            border: none;
        }

        &:first-child {
            border-radius: 4px 0px 0px 4px;
            &.ant-radio-button-wrapper-checked {
                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: -3px;
                    display: block;
                    height: 100%;
                    width: 3px;
                    z-index: 99;
                    background-color: ${({ theme }) =>
                        theme.colors.staticColor.color2.base};
                }
                &:before {
                    display: none;
                }
            }
        }
        &:last-child {
            border-radius: 0px 4px 4px 0px;
            &.ant-radio-button-wrapper-checked {
                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: -3px;
                    display: block;
                    height: 100%;
                    width: 3px;
                    z-index: 99;
                    background-color: ${({ theme }) =>
                        theme.colors.staticColor.color2.base};
                }
                &:before {
                    display: none;
                }
            }
        }
        &.ant-radio-button-wrapper-checked {
            &:after,
            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: -3px;
                display: block;
                height: 100%;
                width: 3px;
                z-index: 99;
                background-color: ${({ theme }) =>
                    theme.colors.staticColor.color2.base};
            }
            &:before {
                left: -3px;
                height: 93%;
            }
        }
        &.ant-radio-button-wrapper-disabled {
            background-color: ${({ theme }) =>
                theme.colors.color2.base} !important;
            color: ${({ theme }) => theme.colors.staticColor.color3.base};
            &:after {
                display: none !important;
            }
        }
    }
`;
