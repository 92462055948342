import styled from 'styled-components';
import { Checkbox as AntCheckbox } from 'antd';
import { fonts } from 'common/theme/font';

export const CheckboxStyled = styled(AntCheckbox)`
    display: inline-flex;
    align-items: center;

    position: relative;

    box-sizing: border-box;
    cursor: pointer;
    .ant-checkbox {
        display: inline-block;

        position: relative;
        top: 0;

        outline: none;
        cursor: pointer;
        input {
            position: absolute;
            top: 2px;
            right: 0;
            bottom: 0;
            left: 2px;
            z-index: 1;

            padding: 0;
            opacity: 0;

            cursor: pointer;
            touch-action: manipulation;

            box-sizing: border-box;
            overflow: visible;
        }
        span {
            &.ant-checkbox-inner {
                display: block;
                width: 24px;
                height: 24px;

                position: relative;
                top: 0;
                left: 0;

                border-radius: 3px;
                border: 1px solid ${({ theme }) => theme.colors.color2.base};

                background-color: ${({ theme }) => theme.colors.color1.base};
                &:after {
                    content: '';

                    display: inline-block;
                    width: 7px;
                    height: 13px;

                    position: absolute;
                    top: 2px;
                    left: 7px;
                    z-index: 99;

                    opacity: 0;
                    transform: rotate(45deg);

                    border-bottom: 2px solid
                        ${({ theme }) => theme.colors.color1.base};
                    border-right: 2px solid
                        ${({ theme }) => theme.colors.color1.base};
                }
            }
        }
        &.ant-checkbox-checked {
            .ant-checkbox-inner {
                background-color: ${({ theme }) =>
                    theme.colors.staticColor.color2.base};
                border-color: ${({ theme }) =>
                    theme.colors.staticColor.color2.base};
                &:after {
                    opacity: 1;
                }
            }
        }
    }
    &.ant-checkbox-wrapper-disabled {
        cursor: not-allowed;
        input {
            cursor: not-allowed;
        }
        span {
            cursor: not-allowed;
            opacity: 0.6;
        }
    }
    span {
        &:not(.ant-checkbox) {
            font-size: ${fonts.size.large};
            color: ${({ theme }) => theme.colors.color4.base} !important;
        }
    }
`;
