import {
    MenuProps as AntSideBarMenuProps,
    MenuItemProps as SideBarMenuItemType,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { SideBarMenuStyled } from './index.styles';
import { IconType } from 'components/svg-icon/icon-types';
import { SvgIcon } from 'components/svg-icon';
import React from 'react';
import { useAuthentication } from 'common/auth/AuthenticationProvider';

type AllowedAntMenuItemTypes = 'title';

export interface SideBarSubMenuItemProps {
    title: string;
    icon?: IconType;
    link: string;
    alternatives?: string[];
}

export interface SideBarMenuItemProps
    extends Pick<SideBarMenuItemType, AllowedAntMenuItemTypes> {
    title: string;
    icon?: IconType;
    link: string;
    subTitles?: SideBarSubMenuItemProps[];

    alternatives?: string[];
}

type AllowedAntMenuTypes =
    | 'mode'
    | 'onClick'
    | 'children'
    | 'multiple'
    | 'defaultOpenKeys'
    | 'selectedKeys'
    | 'openKeys'
    | 'defaultSelectedKeys';

export interface SideBarMenuProps
    extends Pick<AntSideBarMenuProps, AllowedAntMenuTypes> {
    items: SideBarMenuItemProps[];
}

const WrappedSideBarMenu = ({
    mode = 'inline',
    multiple: _multiple = false,
    items: menuItems,
    children: _children,
    ...props
}: SideBarMenuProps) => {
    const { t } = useTranslation();
    const { isAccountVerified } = useAuthentication();
    // if (!isAccountVerified) {
    //     menuItems = menuItems.filter(
    //         (data) => data.title !== t('sidebar.deposit.title')
    //     );
    // }
    return (
        <SideBarMenuStyled mode={mode} {...props}>
            {menuItems &&
                menuItems.map((menuItem) => {
                    if (menuItem.subTitles && menuItem.subTitles.length > 0) {
                        return (
                            <SideBarMenuStyled.SubMenu
                                key={menuItem.title}
                                title={menuItem.title}
                                icon={
                                    menuItem.icon && (
                                        <SvgIcon name={menuItem.icon} />
                                    )
                                }
                            >
                                {isAccountVerified ? (
                                    <>
                                        {menuItem.subTitles &&
                                            menuItem.subTitles
                                                .filter(
                                                    (data) =>
                                                        data.title !==
                                                        t(
                                                            'sidebar.account.accountVerify'
                                                        )
                                                )
                                                .map((subItem) => {
                                                    return (
                                                        <SideBarMenuStyled.Item
                                                            key={subItem.link}
                                                        >
                                                            {subItem.title}
                                                        </SideBarMenuStyled.Item>
                                                    );
                                                })}
                                    </>
                                ) : (
                                    <>
                                        {menuItem.subTitles &&
                                            menuItem.subTitles.map(
                                                (subItem) => {
                                                    return (
                                                        <SideBarMenuStyled.Item
                                                            key={subItem.link}
                                                        >
                                                            {subItem.title}
                                                        </SideBarMenuStyled.Item>
                                                    );
                                                }
                                            )}
                                    </>
                                )}
                            </SideBarMenuStyled.SubMenu>
                        );
                    } else {
                        return (
                            <SideBarMenuStyled.Item
                                key={menuItem.link}
                                icon={
                                    menuItem.icon && (
                                        <SvgIcon name={menuItem.icon} />
                                    )
                                }
                            >
                                {menuItem.title}
                            </SideBarMenuStyled.Item>
                        );
                    }
                })}
        </SideBarMenuStyled>
    );
};

export const SideBarMenu = React.memo(WrappedSideBarMenu);
