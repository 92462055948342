import { ReactNode } from 'react';
import { SideBar } from 'widgets/side-bar';
import BaseLayout from '../BaseLayout';
import { SideBarScreenLayoutStyled, RightContent } from './index.styles';

interface SideBarScreenLayoutProps {
    children: ReactNode;
    className?: string;
}

const SideBarScreenLayout = ({
    children,
    className,
}: SideBarScreenLayoutProps) => {
    return (
        <SideBarScreenLayoutStyled className={`${className}-page`}>
            <BaseLayout>
                <SideBar></SideBar>
                <RightContent>{children}</RightContent>
            </BaseLayout>
        </SideBarScreenLayoutStyled>
    );
};

export default SideBarScreenLayout;
