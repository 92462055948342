import { ReactNode } from 'react';
import BaseLayout from '../BaseLayout';
import { LoginPageCss } from './centralizedScreenLayout.style';

interface CentralizedScreenLayoutProps {
    children: ReactNode;
}

const CentralizedScreenLayout = ({
    children,
}: CentralizedScreenLayoutProps) => {
    return (
        <BaseLayout>
            <LoginPageCss>{children}</LoginPageCss>
        </BaseLayout>
    );
};

export default CentralizedScreenLayout;
