import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';

const tabletCss = css`
    /* .ant-layout-content {
        width: 991px;
    } */
    .ant-layout-content {
        width: 100%;

        > section {
            flex-direction: column;
            .left-content {
                width: 100%;
                max-width: 700px;
                margin-top: 20px;
                margin-bottom: 20px;
                margin-right: 20px;
                margin-left: 20px;
                .ant-menu {
                    max-height: 250px;
                    padding: 0;
                }
            }
            .right-content {
                padding-top: 20px;
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
`;
const mobileCss = css`
    .ant-layout-content {
        width: 100% !important;

        > section {
            flex-direction: column;
            .left-content {
                width: 100%;
                max-width: 90%;
                margin-top: 20px;
                margin-bottom: 20px;
                margin: auto;
                .ant-menu {
                    max-height: 250px;
                    padding: 0;
                }
            }
            .right-content {
                width: 100%;
                max-width: 90%;
                margin: auto;
                padding-top: 20px;
                padding-left: 15px;
                padding-right: 15px;
                margin-top: 20px;
            }
        }
    }
    .right-content {
        &.clear {
            padding: 0 !important;
            margin: 0 !important;
            margin-top: 20px !important;
            max-width: 100% !important;
        }
    }
`;

export const GuideScreenLayoutStyled = styled.div`
    .ant-layout-content {
        display: flex;
        width: 1200px;
        margin: 0 auto;
        height: 100% !important;
        margin-top: 24px;
        overflow: inherit !important;
        flex-direction: column;
    }
    ${mediaQuery.between('xltablet', 'xldesktop')`${tabletCss}`}
    ${mediaQuery.lessThan('xltablet')`${mobileCss}`}
`;

export const Content = styled.section`
    display: flex;
    color: ${({ theme }) => theme.colors.color4.second1};
    .left-content {
        width: 300px;
        margin-right: 20px;
    }
    .right-content {
        width: 100%;
        background-color: ${({ theme }) => theme.colors.color1.base};
        border-radius: 4px;
        padding: 30px;
        border: solid 1px ${({ theme }) => theme.colors.color2.base};
        &.clear {
            background-color: initial;
            padding: initial;
            border: initial;
            border-radius: initial;
        }
    }
`;
