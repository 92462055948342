import { InputProps } from 'antd';
import { BaseOptionType } from 'antd/lib/select';
import { Button } from 'components/button';
import { SvgIcon } from 'components/svg-icon';
import React, { useEffect, useCallback } from 'react';
import { InputSelectionStyled, FormField } from './index.styles';

export interface InputSelectionItem {
    title: string;
    detail: string;
    tag?: string;
}
export interface InputSelectionProps
    extends Pick<InputProps, 'value' | 'disabled'> {
    items?: InputSelectionItem[];
    label: string;
    markAsInvalid?: boolean;
    buttonAction?: (value: any) => void;
    onChange?: (value: any) => void;
}

const renderItem = (data: any, buttonAction: any) => ({
    value: data.detail,
    label: (
        <div className="list-container">
            <div className="info">
                <span className="title">{data.title}</span>
                <span className="text">{data.detail}</span>
                {data.tag && <span className="text">{data.tag}</span>}
            </div>
            <Button
                type="link"
                onClick={() => {
                    buttonAction(data);
                }}
            >
                <SvgIcon name="trash-outline" size={32} />
            </Button>
        </div>
    ),
});

const WrappedInputSelection = (props: InputSelectionProps) => {
    const options: BaseOptionType[] = [];
    useEffect(() => {
        if (props.items) {
            for (let i = 0; i < props.items.length; i++) {
                options.push(renderItem(props.items[i], props.buttonAction));
            }
        }
    });

    const handleChange = useCallback(
        (e: any) => {
            props.onChange && props.onChange(e);
        },
        [props]
    );

    return (
        <FormField>
            <div className="app-form-label">
                <label>{props.label}</label>
            </div>
            <InputSelectionStyled
                onChange={handleChange}
                className={`
                    ${props.markAsInvalid ? 'invalid' : ''}
                `}
                options={options}
                onSelect={handleChange}
                getPopupContainer={(trigger) => {
                    return trigger;
                }}
                filterOption={(inputValue, option) =>
                    option!.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                }
                disabled={props.disabled}
                backfill={true}
            ></InputSelectionStyled>
        </FormField>
    );
};

export const InputSelection = React.memo(WrappedInputSelection);
