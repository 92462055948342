import { inputCss } from 'components/input/index.styles';
import styled from 'styled-components';
import { MaskedInput } from 'antd-mask-input';

export const PhoneNumberStyled = styled(MaskedInput)`
    &.ant-input,
    &.ant-input-affix-wrapper,
    .ant-input-group {
        ${inputCss}
    }
    &.ant-input-affix-wrapper-focused {
        background-color: ${({ theme }) =>
            theme.colors.staticColor.color2.second2};
    }
`;
