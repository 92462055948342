import { css } from 'styled-components';

export const RegistrationMailConfirmationCss = css`
    .mail-confirmation-modal {
        z-index: 999;
        .ant-modal-close {
            display: none;
        }
        .app-icon {
            padding-top: 0px !important;
        }
        .verification-code-input .verification .form-item {
            padding-bottom: 0px !important;
        }
        .remaining-time {
            top: 75px;
        }
        .checkbox-item {
            display: none !important;
        }
        .ant-modal-content {
            max-width: 378px !important;
            width: 100%;

            .ant-modal-body {
                margin: 0px !important;
                padding: 20px 0px !important;
            }
        }
    }
`;
