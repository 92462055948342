import { ThemeType } from './ThemeProvider';

export const staticColor = {
    color1: {
        base: '#FFFFFF',
        second1: '#E8EAEB',
        second2: '#A3A5A6',
        second3: '#595A5B',
        second4: '#3B3C3D',
        opacity3: '#FFFFFF4d',
    },
    color2: {
        base: '#2BB673',
        second1: '#16995A',
        second2: '#60DCA1',
        second3: '#EFFBF6',
        opacity2: '#2BB67326',
    },
    color3: {
        base: '#DC2649',
        second1: '#C80F34',
        second2: '#FB476B',
        second3: '#FCEBEF',
        opacity1: '#E6385A1a',
    },
    color4: {
        base: '#27AAE1',
    },
    color5: {
        base: '#F5BD83',
    },
};
export const colors: Partial<Record<ThemeType, Palette>> = {
    light: {
        staticColor: staticColor,
        color1: {
            base: '#FFFFFF',
            second1: '#FDFEFF',
            second2: '#F7F9FC',
            opacity6: '#FFFFFF99',
            opacity8: '#FFFFFFcc',
        },
        color2: {
            base: '#E8EAEB',
            opacity4: '#E8EAEB66',
        },
        color3: {
            base: '#A3A5A6',
            opacity6: '#A3A5A666',
        },
        color4: {
            base: '#595A5B',
            second1: '#3B3C3D',
            second2: '#1C1F1D',
        },
        color5: {
            base: '#EFFBF6',
            second1: '#EAF8F7',
        },
        color6: {
            base: '#EDF3FF',
        },
        color7: {
            base: '#FCEBEF',
        },
        color8: {
            base: '#F7F9FC',
        },
    },
    dark: {
        staticColor: staticColor,
        color1: {
            base: '#292A2D',
            second1: '#3B3C3D',
            second2: '#202124',
            opacity6: '#3B3C3D99',
            opacity8: '#292A2Dcc',
        },
        color2: {
            base: '#3B3C3D',
            opacity4: '#3B3C3D66',
        },
        color3: {
            base: '#A3A5A6',
            opacity6: '#A3A5A666',
        },
        color4: {
            base: '#E8EAEB',
            second1: '#FFFFFF',
            second2: '#292A2D',
        },
        color5: {
            base: '#3B3C3D',
            second1: '#292A2D',
        },
        color6: {
            base: '#373F4F',
        },
        color7: {
            base: '#3B3C3D',
        },
        color8: {
            base: '#333537',
        },
    },
};
