import { css } from 'styled-components';

export const BuySellConfirmationModalCss = css`
    &.ant-modal.buy-sell-confirmation-modal {
        .ant-modal-content {
            width: 100%;
            max-width: 360px;
            margin: 0 auto;
        }
    }
`;
