export interface LogoProps {
    type: 'logo';
    ext?: 'svg' | 'png';
    onClick: any;
}

export const Logo = ({ type = 'logo', ext = 'svg', onClick }: LogoProps) => {
    //TODO: theme based logo switch
    const filename = `/assets/images/${type}-green`;
    return <img src={`${filename}.${ext}`} alt="Yuex" onClick={onClick} />;
};
