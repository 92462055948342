import {
    CoinPriority,
    CoinPriorityResponse,
    CurrencyModel,
} from './common.model';

export const coinPriorityResponseMapping = (
    payload: CoinPriorityResponse[]
): CoinPriority[] => {
    return payload.map((item) => {
        return {
            ...item,
            coinSymbol: item.unit.split(' - ')[0],
            coinName: item.unit.split(' - ')[1],
        } as CoinPriority;
    });
};

export const currenciesMapping = (
    currencies: CurrencyModel[]
): CurrencyModel[] => {
    const orderedCurrencies: CurrencyModel[] = [];

    if (currencies) {
        const tryCurrency = currencies.filter((x) => x.crypto === false);
        tryCurrency &&
            tryCurrency.length > 0 &&
            orderedCurrencies.push(tryCurrency[0]);
        currencies
            .filter((item) => item.crypto === true)
            .sort((a, b) => a.symbol.localeCompare(b.symbol))
            .forEach((item) => {
                orderedCurrencies.push(item);
            });
    }
    return orderedCurrencies;
};
