import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import {
    errorsHandledByUI,
    showExceptionNotification,
} from 'common/utils/UserNotifications';
import { config } from 'config';

const axiosConfig: AxiosRequestConfig = {
    baseURL: config().apiURL,
    withCredentials: true,
};

const readCookie = function (key: string, defaultValue?: string) {
    let nameEQ = key + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0)
            return c.substring(nameEQ.length, c.length);
    }

    if (typeof defaultValue === 'undefined') {
        return null;
    }

    return defaultValue;
};
class HttpService {
    private _client: AxiosInstance;
    private _mock: AxiosInstance;
    private token?: string;
    private yuexToken?: string;

    constructor() {
        this._client = axios.create(axiosConfig);
        this._mock = axios.create(axiosConfig);
        this.applyInterceptor();
    }

    public setToken(token?: string) {
        this.token = token;
    }

    public getToken(): string {
        return this.token || 'No Access Token!';
    }

    private applyInterceptor() {
        this._client.interceptors.request.use((config: AxiosRequestConfig) => {
            if (this.getToken()) {
                return {
                    ...config,
                    headers: {
                        ...config.headers,
                        // Authorization: `Bearer ${this.getToken()}`,
                        ...(this.yuexToken
                            ? { 'x-token': this.yuexToken }
                            : {}),
                    },
                };
            }
            return config;
        });
        this._client.interceptors.response.use(
            (response: AxiosResponse) => {
                const yuexToken = readCookie('token');

                if (yuexToken) {
                    this.yuexToken = yuexToken;
                }

                return response && response.data;
            },
            (error: any) => {
                if (!['post', 'patch', 'put'].includes(error.config.method)) {
                    console.log(error);
                }
                const handledError = errorsHandledByUI.findIndex(
                    (err) => err === error.response.data.error
                );
                if (handledError === -1) {
                    showExceptionNotification(error.response);
                }
                return Promise.reject(error.response);
            }
        );
        this._mock.interceptors.request.use((config) => {
            console.log('REQUEST', config.url, config.headers, config.data);
            return config;
        });
        this._mock.interceptors.response.use((config) => {
            console.log(
                'RESPONSE',
                config.request.url,
                config.headers,
                config.data
            );
            return config;
        });
    }

    public get client(): AxiosInstance {
        return this._client;
    }

    public get mock(): AxiosInstance {
        return this._mock;
    }
}

export default new HttpService();

class BlogHttpService {
    private _client: AxiosInstance;

    constructor() {
        this._client = axios.create({
            baseURL: process.env.REACT_APP_STRAPI_BASE_URL + '/api',
            headers: {
                Authorization: ``, //`Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjYxMjUwNjE2LCJleHAiOjE2NjM4NDI2MTZ9.Ep8b9K8H_blu0ktvZcdwqBEzX6wlQT9Q_UwcWOfnc0E`,
            },
        });
    }

    public get client(): AxiosInstance {
        return this._client;
    }
}

export const blogHttpService = new BlogHttpService();
