import Footer from 'components/home-page/footer';
import Apply from 'widgets/career-page/apply';
import CareerBanner from 'widgets/career-page/career-banner';
import OpenPositions from 'widgets/career-page/open-positions';
import WorkWithUs from 'widgets/career-page/work-with-us';
import useBlogApi from 'api/blog/blog.api';
import { getLanguage } from 'common/utils';
import { useCallback, useEffect, useState } from 'react';

export interface CareerPageProps {}

const CareerPage = () => {
    const lang = getLanguage();
    const { getCareer } = useBlogApi();
    const [data, setData] = useState<any>({
        title: '',
        content: '',
    });
    const fetchInitial = useCallback(async () => {
        const datas = await getCareer(lang);
        setData(datas.data.data.attributes);
    }, [lang]);

    useEffect(() => {
        fetchInitial();
    }, [lang]);
    return (
        <>
            <CareerBanner />
            <WorkWithUs />
            {data.title !== '' && <OpenPositions data={data} />}
            <Apply />
            <Footer />
        </>
    );
};

export default CareerPage;
