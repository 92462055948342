export const Regex = {
    passwordRegex:
        /^(?=.*[a-zğçşüöıäéß])(?=.*[A-ZĞÇŞÜÖİÄß])(?=.*\d)(?=.*[@$+><'".,:;%|!%*?&_-])[A-Za-zĞÇŞÜÖİÄßğçşüöıäé\d@$+><'"%|!.,:;%*?&_-]{8,16}$/,
    ercAdressRegex: /^0x[0-9a-fA-F]{40}$/,
    btcAddressRegex:
        /^([13]{1}[a-km-zA-HJ-NP-Z1-9]{26,33}|bc1[a-z0-9]{39,59})$/,
    ltcAdressRegex: /^[LM3][a-km-zA-HJ-NP-Z1-9]{26,33}$/,
    xrpAdressRegex: /^r[0-9a-zA-Z]{24,34}$/,
    trIbanRegexOld: /^TR[0-9]{2}(?:[ ]?[0-9]{4}){5}(?:[ ]?[0-9]{2})?$/,
    trIbanRegex: /^TR\d{7}[0-9A-Z]{17}$/,
    emailRegex: /^[\w-\.]+@([\w-]+\.)+[\w-]{1,4}$/,
};

export enum BuySellPercentages {
    twentyfive = '25',
    fifty = '50',
    seventyfive = '75',
    onehundred = '100',
}

export enum BuySellRatioGroup {
    buy = 'buy',
    sell = 'sell',
}
