import { useEffect, useState, useCallback } from 'react';
import { Button } from 'components';
import * as S from './index.styles';

import { useTranslation } from 'react-i18next';
import useAuthApi from 'api/auth/auth.api';
import { Row, Col } from 'antd';
import useAccountApi from 'api/account/account.api';
import moment from 'moment';

interface StatusOptions {
    isTimerActive: boolean;
    sendCode: boolean;
    againCode: boolean;
    value?: string;
}

export const SendCodeTimer = (props: any) => {
    const {
        sendChangePasswordEmail,
        sendChangePassword2fa,
        sendResendSms,
        changeEmail,
        sendResendSmsPhoneChange,
        sendResendSmsPhoneChangeCode,
        sendtotpchangesms,
        resendEmail,
    } = useAuthApi();
    const { verificationAccount } = useAccountApi();

    const { t } = useTranslation();

    const counterValue = props.counterValue;
    const [counter, setCounter] = useState(counterValue);
    const [status, setStatus] = useState<StatusOptions>({
        isTimerActive: false,
        sendCode: true,
        againCode: false,
    });
    const handleResendEmail = async () => {
        props.buttonActive && props.buttonActive(true);
        try {
            await sendChangePasswordEmail();
            setCounter(counterValue);
            const nState = {
                ...status,
                isTimerActive: true,
                sendCode: false,
            };
            setStatus(nState);
        } catch (err: any) {
            //to delete
            setCounter(counterValue);
            const nState = {
                ...status,
                isTimerActive: true,
                sendCode: false,
            };
            setStatus(nState);
        }
    };
    const handleResendSms = async () => {
        props.buttonActive && props.buttonActive(true);

        try {
            await sendChangePassword2fa();
            setCounter(counterValue);
            const nState = {
                ...status,
                isTimerActive: true,
                sendCode: false,
            };
            setStatus(nState);
        } catch (err: any) {
            //to delete
            setCounter(counterValue);
            const nState = {
                ...status,
                isTimerActive: true,
                sendCode: false,
            };
            setStatus(nState);
        }
    };
    const handleResendSmsForLogin = async () => {
        props.buttonActive && props.buttonActive(true);

        try {
            await sendResendSms({
                type: 'login',
            });

            setCounter(counterValue);
            const nState = {
                ...status,
                isTimerActive: true,
                sendCode: false,
            };
            setStatus(nState);
        } catch (err: any) {
            //to delete
            setCounter(counterValue);
            const nState = {
                ...status,
                isTimerActive: true,
                sendCode: false,
            };
            setStatus(nState);
        }
    };

    const handleResendSmsForChangePhone = async (value: any) => {
        props.buttonActive && props.buttonActive(true);

        try {
            await sendResendSmsPhoneChange({
                new_phonenumber: value,
            });
            setCounter(counterValue);
            const nState = {
                ...status,
                isTimerActive: true,
                sendCode: false,
            };
            setStatus(nState);
        } catch (err: any) {
            //to delete
            setCounter(counterValue);
            const nState = {
                ...status,
                isTimerActive: true,
                sendCode: false,
            };
            setStatus(nState);
        }
    };

    const handleResendSmsForChangePhoneCode = async (value: any) => {
        props.buttonActive && props.buttonActive(true);

        try {
            await sendResendSmsPhoneChangeCode({
                new_phonenumber: value,
            });
            setCounter(counterValue);
            const nState = {
                ...status,
                isTimerActive: true,
                sendCode: false,
            };
            setStatus(nState);
        } catch (err: any) {
            //to delete

            if (!err.data.success) {
                setCounter(counterValue);
                const nState = {
                    ...status,
                    isTimerActive: false,
                    sendCode: true,
                };
                setStatus(nState);
            } else {
                setCounter(counterValue);
                const nState = {
                    ...status,
                    isTimerActive: true,
                    sendCode: false,
                };
                setStatus(nState);
            }
        }
    };
    const handleResendChangeEmail = async () => {
        props.buttonActive && props.buttonActive(true);
        try {
            await changeEmail(props.value);
            setCounter(counterValue);
            const nState = {
                ...status,
                isTimerActive: true,
                sendCode: false,
            };
            setStatus(nState);
        } catch (err: any) {
            //to delete
            setCounter(counterValue);
            const nState = {
                ...status,
                isTimerActive: true,
                sendCode: false,
            };
            setStatus(nState);
        }
    };
    const handleResendSmsForVerification = async () => {
        props.buttonActive && props.buttonActive(true);

        try {
            await verificationAccount({
                firstname: props.value.name,
                lastname: props.value.lastName,
                identnumber: props.value.identification,
                identserial: `${props.value.serialNumber}${
                    !props.value.chip ? '-' + props.value.walletNo : ''
                }`,
                phonenumber: props.value.phoneNumber,
                birthdate: moment(props.value.dateOfBirth).format('MM/DD/YYYY'),
                barcode: props.value.barcode,
                nationality: props.value.nationality,
                recaptcha: props.value.token,
            });

            setCounter(counterValue);
            const nState = {
                ...status,
                isTimerActive: true,
                sendCode: false,
            };
            setStatus(nState);
        } catch (err: any) {
            //to delete
            setCounter(counterValue);
            const nState = {
                ...status,
                isTimerActive: true,
                sendCode: false,
            };
            setStatus(nState);
        }
    };
    const handleSendtotpChangeSms = async () => {
        props.buttonActive && props.buttonActive(true);
        try {
            await sendtotpchangesms();
            setCounter(counterValue);
            const nState = {
                ...status,
                isTimerActive: true,
                sendCode: false,
            };
            setStatus(nState);
        } catch (err: any) {
            //to delete
            setCounter(counterValue);
            const nState = {
                ...status,
                isTimerActive: true,
                sendCode: false,
            };
            setStatus(nState);
        }
    };
    const handleRegisterResendMail = async () => {
        props.buttonActive && props.buttonActive(true);
        try {
            await resendEmail(props.value);
            setCounter(counterValue);
            const nState = {
                ...status,
                isTimerActive: true,
                sendCode: false,
            };
            setStatus(nState);
        } catch (err: any) {
            //to delete
            setCounter(counterValue);
            const nState = {
                ...status,
                isTimerActive: true,
                sendCode: false,
            };
            setStatus(nState);
        }
    };

    const handleSendCode = useCallback(() => {
        if (props.authType === 'mail') {
            handleResendEmail();
        } else if (props.authType === 'sms') {
            handleResendSms();
        } else if (props.authType === 'login') {
            handleResendSmsForLogin();
        } else if (props.authType === 'changeMail') {
            handleResendChangeEmail();
        } else if (props.authType === 'changePhone') {
            handleResendSmsForChangePhoneCode(props.value);
        } else if (props.authType === 'changePhoneMail') {
            handleResendSmsForChangePhone(props.value);
        } else if (props.authType === 'handleVerificationSms') {
            handleResendSmsForVerification();
        } else if (props.authType === 'changeotpsms') {
            handleSendtotpChangeSms();
        } else if (props.authType === 'registerResendMail') {
            handleRegisterResendMail();
        } else {
            setCounter(counterValue);
            const nState = {
                ...status,
                isTimerActive: true,
                sendCode: false,
            };
            setStatus(nState);
        }
    }, [status]);
    const handleTimer = () => {
        if (status.isTimerActive) {
            if (counter > 0) {
                setTimeout(() => setCounter(counter - 1), 1000);
            } else {
                const nState = {
                    isTimerActive: false,
                    sendCode: true,
                    againCode: true,
                };
                setStatus(nState);
                props.buttonActive && props.buttonActive(false);
            }
        }
    };
    useEffect(() => {
        handleTimer();
    }, [counter, status]);

    useEffect(() => {
        if (
            props.authType === 'login' ||
            props.authType === 'handleVerificationSms' ||
            props.authType === 'registerResendMail'
        ) {
            const nState = {
                isTimerActive: true,
                sendCode: false,
                againCode: false,
                value: '',
            };
            setStatus(nState);

            handleTimer();
        }
    }, []);
    return (
        <>
            <S.Container>
                <div className="verification-code-input">
                    <div className="verification">
                        <div className="form-item">
                            <div>
                                <div className="send-code-info">
                                    {status.sendCode && (
                                        <Button
                                            onClick={handleSendCode}
                                            type="link"
                                        >
                                            {status.againCode &&
                                                t('confirmationCode.again')}
                                            {t('confirmationCode.codeSubmit')}
                                        </Button>
                                    )}
                                    {status.isTimerActive && (
                                        <>
                                            <Row>
                                                <Col>
                                                    <span className="code-sent">
                                                        {t(
                                                            'confirmationCode.codeSend'
                                                        )}
                                                    </span>
                                                </Col>
                                                <Col>
                                                    <div className="remaining-time">
                                                        <span className="timer">
                                                            {counter}{' '}
                                                            {t(
                                                                'confirmationCode.second'
                                                            )}
                                                        </span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </S.Container>
        </>
    );
};
