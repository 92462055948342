import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { InputProps } from 'components/input';

import useBalanceApi from 'api/balance/balance.api';
import { AddressGetListData } from 'api/balance/balance.model';

import { useAddressIbanAddForm } from 'common/hooks/address-iban-add-form-modal';

import { Button } from 'components/button';
import { InputSelection, InputSelectionItem } from 'components/input-selection';
import { AddressAutoCompleteStyled } from './index.styles';
import { useConfirm } from 'components/confirmation';
import { useTranslation } from 'react-i18next';
import { showSuccessNotification } from 'common/utils/UserNotifications';

export interface AddressAutoCompleteProps extends InputProps {
    title: string;
    proKey: string;
    symbol: string;
    regexName: any;
    tag?: string;
    onChange?: (value: any) => void;
}

const AddressAutoCompleteWrapper = (props: AddressAutoCompleteProps) => {
    const { t } = useTranslation();
    const { addressGetList, addressDelete } = useBalanceApi();
    const [markAsInvalid, setInvalid] = useState<boolean | undefined>(
        props.error
    );
    const [preSymbol, setPreSymbol] = useState<string>('');
    // const [preCoin, setPreCoin] = useState<string>(props.symbol);
    // const [preKey, setPreKey] = useState<string>(props.proKey);
    const confirm = useConfirm({
        title: t('withdrawalForm.check.addressForm.deletePopupTitle'),
        okText: t('withdrawalForm.check.addressForm.deletePopupOk'),
        cancelText: t('withdrawalForm.check.addressForm.deletePopupCancel'),
        closable: true,
        icon: 'error-circle-filled',
    });
    const onAddressIbanFormSuccess = (): void => {
        addressGetListData();
    };
    const providerCurrency = {
        title: props.title,
        provider: props.proKey,
        currency: props.symbol,
        tag: props.tag,
        regexName: props.regexName,
    };
    const { placeholder, openAddressIbanAddForm } = useAddressIbanAddForm({
        ...providerCurrency,
        triggerAddressIbanFormSuccess: onAddressIbanFormSuccess,
    });
    const [data, setData] = useState<AddressGetListData[]>();
    const addressList = useMemo<InputSelectionItem[]>(() => {
        const items: InputSelectionItem[] = [];
        data?.forEach((item) => {
            items.push({
                title: item.name,
                detail: item.addr,
                tag: item.tag,
            });
        });
        return items;
    }, [data]);

    const deleteAddressList = useCallback(
        async (data: any) => {
            try {
                let tempData = {
                    address: data.detail,
                    provider: props.proKey,
                    currency: props.symbol,
                };
                let tempDataTag = {
                    address: data.detail,
                    provider: props.proKey,
                    currency: props.symbol,
                    tag: data.tag,
                };

                await addressDelete(data.tag ? tempDataTag : tempData);
                showSuccessNotification(
                    t('withdrawalForm.check.addressForm.deleteSuccess'),
                    ''
                );
                addressGetListData();
            } catch (err) {}
        },
        [props.symbol, props.name]
    );
    const addressGetListData = async () => {
        try {
            const response = await addressGetList({
                provider: props.proKey,
                currency: props.symbol,
            });
            setData(response.addresses);
        } catch (error) {}
    };
    // useEffect(() => {
    //     if (props.proKey) {
    //         addressGetListData();
    //     }
    // }, []);

    useEffect(() => {
        if (
            props.proKey &&
            props.symbol &&
            preSymbol !== props.symbol &&
            props.title == 'Adres'
        ) {
            addressGetListData();
        }
        setPreSymbol(props.symbol);
    }, [props.title]);
    useEffect(() => {
        setInvalid(props.error);
    }, [props.error]);

    const handleChange = (e: any) => {
        props.onChange && props.onChange(e);
    };
    return (
        <AddressAutoCompleteStyled className="address-auto-complete">
            {placeholder()}
            <InputSelection
                onChange={handleChange}
                markAsInvalid={markAsInvalid}
                buttonAction={(detail) =>
                    confirm.openConfirm({
                        onOk: () => {
                            deleteAddressList(detail);
                        },
                    })
                }
                items={addressList}
                label={
                    props.symbol + t('withdrawalForm.check.addressForm.label')
                }
                disabled={props.disabled}
            />
            <div className="add-button">
                <Button
                    type="link"
                    onClick={() => {
                        openAddressIbanAddForm();
                    }}
                >
                    {t('withdrawalForm.check.addressForm.addressAdd')}
                </Button>
            </div>
        </AddressAutoCompleteStyled>
    );
};

export const AddressAutoComplete = React.memo(AddressAutoCompleteWrapper);
