import { SvgIcon } from 'components/svg-icon';
import { IconType } from 'components/svg-icon/icon-types';
import { CancelNextButtons } from 'widgets/update-email-steps/index';
import { ModalCardStyled } from './index.styles';

import parse from 'html-react-parser';
import { Button } from 'components/button';

export interface ModalCardProps {
    title?: string;
    description?: string;
    onNext?: () => void;
    onCancel?: () => void;
    children?: JSX.Element;
    icon?: IconType;
    img?: string;
    staticButton?: string;
    onClose?: () => void;
    id?: boolean;
}

export const ModalCard = ({
    title,
    description,
    onNext,
    onCancel,
    children,
    icon,
    img,
    staticButton,
    onClose,
    id,
}: ModalCardProps) => {
    return (
        <ModalCardStyled className="modal-card-container">
            {icon && <SvgIcon size={48} name={icon} />}
            {img && !icon && (
                <img src={`/assets/images/${img}.svg`} alt=""></img>
            )}
            <div className="title-container">{title}</div>
            <div className="description-container">
                <div className="desc-text">
                    {description && parse(description)}
                </div>
                {children}
            </div>
            {onNext !== undefined && (
                <CancelNextButtons
                    id={id}
                    onCancel={onCancel}
                    onNext={function (): void {
                        onNext && onNext();
                    }}
                />
            )}
            {staticButton !== undefined && (
                <div
                    className="buttonSession"
                    onClick={() => {
                        onClose && onClose();
                    }}
                >
                    <Button type="primary">{staticButton}</Button>
                </div>
            )}
        </ModalCardStyled>
    );
};
