import { fonts } from 'common/theme/font';
import styled from 'styled-components';
export const FormFieldContainer = styled.div`
    padding-bottom: 20px;

    &.app-form-field-focused {
        .app-form-label {
            label {
                color: ${({ theme }) => theme.colors.color3.base};
            }
        }
    }

    .app-form-label {
        display: flex;
        justify-content: space-between;
        padding-bottom: 5px;

        & > label {
            font-size: ${fonts.size.small};
            line-height: 18px;
            font-weight: ${fonts.weight.bold};
            color: ${({ theme }) => theme.colors.color4.base};
        }
    }
    .app-form-control {
        &.error > * {
            color: ${({ theme }) =>
                theme.colors.staticColor.color1.second3}!important;
            background-color: ${({ theme }) =>
                theme.colors.staticColor.color3.second3}!important;
            border-color: ${({ theme }) =>
                theme.colors.staticColor.color3.base} !important;
            &.ant-input-affix-wrapper > .ant-input {
                background-color: ${({ theme }) =>
                    theme.colors.staticColor.color3.second3} !important;
                color: ${({ theme }) =>
                    theme.colors.staticColor.color1.second3}!important;
            }
        }
        &.no-border-field {
            .ant-input-affix-wrapper {
                border: none;
            }
        }
    }

    .app-form-helper {
        margin-top: 10px;
        * {
            text-align: left;
        }
    }
`;
