import { upperFirst } from 'lodash';
import { createContext, useState, useMemo, useContext } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { colors } from './colors';

export enum ThemeType {
    Light = 'light',
    Dark = 'dark',
}

export const defaultThemeType =
    localStorage.getItem('ThemeType') === ''
        ? ThemeType.Light
        : localStorage.getItem('ThemeType');

export const ThemeContext = createContext<AppTheme>({
    theme: {
        colors: colors.light as Palette,
        selectedTheme: defaultThemeIndex(),
    },
    handleChangeTheme: () => {},
});
interface IThemeProviderProps {
    children: JSX.Element[] | JSX.Element;
    defaultTheme?: ThemeType;
}

export function defaultThemeIndex() {
    let i = Object.keys(ThemeType).indexOf(
        upperFirst(defaultThemeType || 'Light')
    );
    if (i == 0) return ThemeType.Light;
    else return ThemeType.Dark;
}

export function ThemeProvider({ children }: IThemeProviderProps) {
    const [selectedTheme, setSelectedTheme] = useState<ThemeType>(
        defaultThemeIndex()
    );

    const handleChangeTheme = (theme?: ThemeType) => {
        setSelectedTheme((state?: ThemeType) => {
            if (theme) {
                localStorage.setItem('ThemeType', theme);
                return theme;
            }
            if (state === ThemeType.Dark) {
                localStorage.setItem('ThemeType', ThemeType.Light);
                return ThemeType.Light;
            } else {
                localStorage.setItem('ThemeType', ThemeType.Dark);
                return ThemeType.Dark;
            }
        });
    };

    const theme = useMemo(
        () => ({
            colors: colors[selectedTheme] as Palette,
            selectedTheme,
        }),
        [selectedTheme]
    );

    return (
        <ThemeContext.Provider value={{ theme, handleChangeTheme }}>
            <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
        </ThemeContext.Provider>
    );
}

export default ThemeProvider;

export function useTheme() {
    const { theme, handleChangeTheme: changeTheme } = useContext(ThemeContext);

    return { theme, changeTheme };
}
