import { Button } from 'components/button';
import { Container } from './index.styles';

interface Data {
    title: string;
    button_text: string;
    button_link: string;
    open_position: [
        {
            title: string;
            link: string;
            icon: {
                data: {
                    attributes: { url: string; name: string };
                };
            };
        }
    ];
}
const OpenPositions = (props: any) => {
    const data: Data = props.data;
    return (
        <>
            <Container>
                <div className="title-container">
                    <h2 className="title">{data.title}</h2>
                    <hr />
                </div>
                <div className="open-positions">
                    <div className="positions-row">
                        {data.open_position.map((item, key) => {
                            return (
                                <a
                                    href={item.link}
                                    target="_blank"
                                    className="position-item"
                                    key={key}
                                >
                                    <img
                                        src={`${process.env.REACT_APP_STRAPI_BASE_URL}${item.icon.data.attributes.url}`}
                                        alt={item.icon.data.attributes.name}
                                    />
                                    <span>{item.title}</span>
                                </a>
                            );
                        })}
                    </div>

                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={data.button_link}
                    >
                        <Button type="primary">{data.button_text}</Button>
                    </a>
                </div>
            </Container>
        </>
    );
};

export default OpenPositions;
