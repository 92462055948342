import { fonts } from 'common/theme/font';
import styled from 'styled-components';

export const CoinDisplayStyled = styled.div`
    .vertical {
        display: flex;

        .app-icon {
            font-size: 26px;
        }
        .text-area {
            display: flex;

            .long-value-name {
                font-size: ${fonts.size.small};
                font-weight: ${fonts.weight.regular};
                color: ${({ theme }) => theme.colors.color4.base};

                padding-left: 8px;
            }

            .short-value-name {
                font-size: ${fonts.size.normal};
                font-weight: ${fonts.weight.semibold};
                color: ${({ theme }) => theme.colors.color4.base};

                padding-left: 8px;
            }
        }
    }

    .horizontal {
        display: flex;

        .app-icon {
            font-size: 26px;
        }

        .long-value-name {
            font-size: ${fonts.size.small};
            font-weight: ${fonts.weight.regular};
            color: ${({ theme }) => theme.colors.color4.base};

            padding-left: 8px;
        }

        .short-value-name {
            font-size: ${fonts.size.normal};
            font-weight: ${fonts.weight.semibold};
            color: ${({ theme }) => theme.colors.color4.base};

            padding-left: 8px;
        }
    }
`;
