import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';
import { Layout } from 'antd';

const mobileBtnCss = css`
    display: block;
`;

export const MobileButtonStyled = styled.div`
    margin-bottom: 15px;
    box-shadow: 0px 0px 5px #cfcfcf !important;
    display: none;
    .ant-btn {
        width: 100%;
        justify-content: space-between;
        background-color: ${({ theme }) => theme.colors.color1.base} !important;

        padding: 0 15px !important;
        span {
            color: ${({ theme }) => theme.colors.color4.base} !important;
        }
        .app-icon {
            svg {
                color: ${({ theme }) => theme.colors.color4.base} !important;
            }
        }
    }
    ${mediaQuery.lessThan('xltablet')`${mobileBtnCss}`}
`;
const mobileSideBarCss = css`
    display: none !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    min-height: inherit !important;
    &.active {
        display: block !important;
    }
`;
export const SideBarStyled = styled(Layout.Sider)`
    .ant-menu {
        color: ${({ theme }) => theme.colors.color4.base};
    }
    &.sidebar-item {
        width: 200px;
        overflow: auto;
        border: 1px solid ${({ theme }) => theme.colors.color2.base};
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        padding-top: 16px;
        padding-bottom: 16px;
        height: calc(100vh - 94px);
        background-color: ${({ theme }) => theme.colors.color1.base};
        .ant-menu-light .ant-menu-submenu-title:hover {
            color: ${({ theme }) => theme.colors.color4.base};

            .ant-menu-submenu-arrow {
                color: ${({ theme }) =>
                    theme.colors.staticColor.color2.base} !important;
            }
        }

        .ant-menu-light .ant-menu-item:hover {
            color: ${({ theme }) => theme.colors.staticColor.color2.base};
        }
    }
    ${mediaQuery.lessThan('xltablet')`${mobileSideBarCss}`}
`;
