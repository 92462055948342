import { ModalWarningCardStyled } from './index.styles';
import { ModalCard } from 'components/modal-card';

export interface ModalWarningCardProps {
    title?: string;
    description?: DescriptionProps;
    onNext?: () => void;
    onCancel?: () => void;
    width?: number;
    extraComponent?: JSX.Element;
    staticButton?: string;
    onClose?: () => void;
}
export interface DescriptionProps {
    titletext?: string;
    listtext?: string[];
    bottomtext?: string;
}
export const ModalWarningCard = ({
    title,
    description,
    onNext,
    onCancel,
    extraComponent,
    staticButton,
    onClose,
}: ModalWarningCardProps) => {
    return (
        <ModalWarningCardStyled className="modal-warning-card-container">
            <ModalCard
                title={title}
                description={description?.titletext}
                icon="error-circle-filled"
                onNext={onNext}
                onCancel={onCancel}
                staticButton={staticButton}
                onClose={onClose}
            >
                <div>
                    <ul>
                        {description &&
                            description.listtext &&
                            description.listtext.map((text, i) => {
                                return (
                                    <li
                                        key={i}
                                        className="desc-list"
                                        dangerouslySetInnerHTML={{
                                            __html: text,
                                        }}
                                    ></li>
                                );
                            })}
                    </ul>
                    {description?.bottomtext !== '' ||
                        (undefined && (
                            <div className="desc-colored">
                                {description && description.bottomtext}
                            </div>
                        ))}

                    {extraComponent && <div>{extraComponent}</div>}
                </div>
            </ModalCard>
        </ModalWarningCardStyled>
    );
};
