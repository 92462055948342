import { VerticalTabStyled } from './index.styles';
import React, { useState } from 'react';
import { SvgIcon } from 'components/svg-icon';

export interface VerticalTabProps {
    title: string;
    subtitle?: string;
    active: boolean;
    open: boolean;
    content: string;
    index: number;
}
const VerticalTabElement = ({
    title,
    subtitle,
    active = false,
    content = '',
    index,
}: VerticalTabProps) => {
    const [statusState, setStatusState] = useState<boolean>(false);
    return (
        <VerticalTabStyled
            title={title}
            className={` ${active && 'active '}${statusState && ' open'}`}
        >
            <div key={index}>
                <div
                    className="tab-head"
                    onClick={() => {
                        active && setStatusState(!statusState);
                    }}
                >
                    <div className="title">
                        {title}
                        {subtitle && (
                            <span className="subtitle">{subtitle}</span>
                        )}
                    </div>
                    {active ? (
                        !statusState ? (
                            <SvgIcon name="chevron-down" size={24} />
                        ) : (
                            <SvgIcon name="chevron-up" size={24} />
                        )
                    ) : (
                        ''
                    )}
                </div>
                {statusState && (
                    <div
                        className="tab-content"
                        dangerouslySetInnerHTML={{
                            __html: content ? content : '',
                        }}
                    ></div>
                )}
            </div>
        </VerticalTabStyled>
    );
};

export const VerticalTab = React.memo(VerticalTabElement);
