import styled, { css } from 'styled-components';
import { List } from 'antd';
import { fonts } from '../../common/theme/font';
import { mediaQuery } from 'common/theme/media';

const depositFormMobileCss = css`
    .ant-select-dropdown {
        width: 176px !important;
        .rc-virtual-list {
            .rc-virtual-list-holder {
                min-width: inherit;
            }
        }
    }
`;
const mobileCss = css`
    .ant-select-dropdown {
        width: initial !important;
        padding: 0px;
        border-radius: 6px;
        background-color: ${({ theme }) =>
            theme.colors.color1.second2} !important;

        .rc-virtual-list {
            .rc-virtual-list-holder {
                min-width: 260px;

                .ant-select-item-option-selected {
                    background-color: ${({ theme }) =>
                        theme.colors.color1.base};
                    color: ${({ theme }) => theme.colors.color4.base}!important;
                }

                .ant-select-item-option-active {
                    background-color: ${({ theme }) =>
                        theme.colors.color1.second1};
                    color: ${({ theme }) => theme.colors.color4.base}!important;
                }
            }
        }
    }
    .ant-select-item {
        padding: 16px 12px 16px 12px;
        border-bottom: 1px solid ${({ theme }) => theme.colors.color2.base};
        color: ${({ theme }) => theme.colors.color4.base};
        &:last-child {
            border: none;
        }
    }
    .ant-list-item-meta {
        display: flex;
        align-items: center;
        .ant-list-item-meta-avatar {
            .app-icon {
                display: flex;
                align-items: center;

                font-size: ${fonts.size.xlarge};
            }
            padding: 0;
            align-items: center;
        }

        .ant-list-item-meta-content {
            .list-item-text {
                font-size: ${fonts.size.small};
                font-weight: ${fonts.weight.semibold};
                color: ${({ theme }) => theme.colors.color4.base};

                padding-left: 8px;
            }
            .list-item-subtext {
                font-size: ${fonts.size.normal};
                font-weight: ${fonts.weight.medium};
                color: ${({ theme }) => theme.colors.color3.base};

                padding-left: 8px;
            }
        }
    }
`;

export const SelectionDropDownStyled = styled(List)`
    .ant-select-focused .ant-select-selector,
    .ant-select-selector:focus,
    .ant-select-selector:active,
    .ant-select-open .ant-select-selector {
        border-color: ${({ theme }) => theme.colors.color2.base} !important;
        box-shadow: none !important;
    }

    .ant-select-selector {
        width: auto !important;
        height: 50px !important;
        border: 1px solid ${({ theme }) => theme.colors.color2.base} !important;
        background-color: transparent !important;
        border-radius: 4px !important;
        padding-left: 0px !important;
        .ant-select-selection-placeholder {
            padding-top: 8px;
        }
    }
    .ant-select-arrow {
        color: ${({ theme }) => theme.colors.color4.base} !important;
    }

    .selection-dropdown-list {
        padding-left: 5px;
        .ant-list-item-meta-description {
            display: flex;
        }
        .ant-list {
            &-item-meta {
                &-content {
                    margin-top: -1px;
                }
                &:hover {
                    cursor: pointer;
                }
                display: flex;
                align-items: center;
                padding: 10px 0px 10px 12px;

                &-avatar {
                    i {
                        font-size: ${fonts.size.xlarge};
                    }
                }
                .list-item {
                    &-coin {
                        margin-left: 0px;
                    }
                    &-text {
                        color: ${({ theme }) => theme.colors.color4.base};
                        font-weight: ${fonts.weight.semibold};
                    }
                    &-subtext {
                        margin-left: 0px;
                        font-weight: ${fonts.weight.medium};
                        color: ${({ theme }) => theme.colors.color3.base};
                    }
                }
            }
        }
    }
`;

export const CoinSelectionDropdownCss = css`
    .ant-select-dropdown {
        width: initial !important;
        padding: 0px;
        border-radius: 6px;
        background-color: ${({ theme }) =>
            theme.colors.color1.second2} !important;

        .rc-virtual-list {
            .rc-virtual-list-holder {
                min-width: 260px;

                .ant-select-item-option-selected {
                    background-color: ${({ theme }) =>
                        theme.colors.color1.base};
                    color: ${({ theme }) => theme.colors.color4.base}!important;
                }

                .ant-select-item-option-active {
                    background-color: ${({ theme }) =>
                        theme.colors.color1.second1};
                    color: ${({ theme }) => theme.colors.color4.base}!important;
                }
            }
        }
    }
    .ant-select-item {
        padding: 16px 12px 16px 12px;
        border-bottom: 1px solid ${({ theme }) => theme.colors.color2.base};
        color: ${({ theme }) => theme.colors.color4.base};
        &:last-child {
            border: none;
        }
    }
    .ant-list-item-meta {
        display: flex;
        align-items: center;
        .ant-list-item-meta-avatar {
            .app-icon {
                display: flex;
                align-items: center;

                font-size: ${fonts.size.xlarge};
            }
            padding: 0;
            align-items: center;
        }

        .ant-list-item-meta-content {
            .list-item-text {
                font-size: ${fonts.size.normal};
                font-weight: ${fonts.weight.semibold};
                color: ${({ theme }) => theme.colors.color4.base};

                padding-left: 8px;
            }
            .list-item-subtext {
                font-size: ${fonts.size.normal};
                font-weight: ${fonts.weight.medium};
                color: ${({ theme }) => theme.colors.color3.base};

                padding-left: 8px;
            }
        }
    }

    ${mediaQuery.lessThan('tablet')`${depositFormMobileCss}`}
    ${mediaQuery.lessThan('mobile')`${mobileCss}`}
`;
