import { fonts } from 'common/theme/font';
import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';

const mobileCss = css`
    .profile-dropdown {
        &-header {
            &-item {
                i.app-icon {
                    font-size: 35px;
                }
            }
        }
    }
`;
const tabletCss = css`
    .profile-dropdown {
        &-header {
            &-item {
                i.app-icon {
                    font-size: 30px;
                }
            }
        }
    }
`;

export const ProfileDropDownStyled = styled.div`
    .ant-dropdown {
        color: red !important;
    }
    &.profile-dropdown {
        justify-content: flex-end;
        border-radius: 6px;
        width: 310px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
        background-color: ${({ theme }) => theme.colors.color1.base};
        filter: ${({ theme }) =>
            theme.selectedTheme == 'dark'
                ? 'drop-shadow(0px 0px 9px rgba(0,0,0,0.5))'
                : 'none'};
    }
    .ant-menu-inline {
        border-color: transparent;
    }
    .profile-dropdown {
        &-header {
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            background-color: ${({ theme }) => theme.colors.color2.opacity4};
            color: ${({ theme }) => theme.colors.color4.second1};
            padding: 20px;
            h6 {
                font-weight: ${fonts.weight.semibold};
                font-size: ${fonts.size.medium};
            }
            &-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;

                &:nth-child(2) {
                    margin-bottom: 0;
                }

                i.app-icon {
                    font-size: 25px;
                    margin-right: 10px;
                    color: ${({ theme }) =>
                        theme.colors.staticColor.color2.base} !important;
                }
            }
            .ant-btn-icon-only {
                padding: 0;
                background: transparent;
                height: inherit;
                width: inherit;
                text-align: center;
                box-shadow: none;
                outline: 0;

                i {
                    margin-right: 0 !important;
                }

                &:focus {
                    outline: 0;
                }
            }
            &-text {
                font-weight: ${fonts.weight.regular};
                max-width: 90%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                &.light-text {
                    color: ${({ theme }) => theme.colors.color4.base};
                    font-size: ${fonts.size.small};
                }
            }
        }
        &-level {
            padding: 15px 20px;
            border-bottom: 1px solid ${({ theme }) => theme.colors.color2.base};

            &-item {
                background-color: ${({ theme }) => theme.colors.color1.base};
                display: inline-block;
                padding: 5px 15px;
                margin-right: 10px;
                border-radius: 4px;
                font-weight: ${fonts.weight.semibold};

                &.active-item {
                    color: ${({ theme }) =>
                        theme.colors.staticColor.color2.base};
                }
                &.disabled-item {
                    opacity: 0.4;
                }
            }
        }
        &-content {
            padding-top: 10px;
            padding-bottom: 10px;
        }
        &-footer {
            border-top: 1px solid ${({ theme }) => theme.colors.color2.base};
            padding: 10px 25px;
            .ant-btn-link {
                span.logout-text {
                    color: ${({ theme }) =>
                        theme.colors.color4.base} !important;
                }
                &:hover {
                    span.logout-text {
                        text-decoration: none;
                        color: ${({ theme }) =>
                            theme.colors.staticColor.color2.base} !important;
                    }
                }
            }

            i.app-icon {
                font-size: 25px;
                margin-right: 10px;
                color: ${({ theme }) =>
                    theme.colors.staticColor.color1.second3} !important;
            }
        }
    }
    ${mediaQuery.lessThan('desktop')`${tabletCss}`}
    ${mediaQuery.lessThan('mobile')`${mobileCss}`}
`;
