import { mappingAuthUser, mappingRegisterUser } from './auth.mapping';
import { useRef } from 'react';
import { HttpResponse } from 'common/http/http.model';
import httpService from 'common/http/http.service';
import {
    CheckEmailResponse,
    RegisterResponse,
    AuthApiCalls,
    RegisterRequest,
    LoginRequest,
    AuthResponse,
    LoginResponse,
    UserResponse,
    AuthUser,
    ChangePasswordResponse,
    ChangePasswordRequest,
    AuthWalletBalance,
    DistributionBaseRequest,
    DistributionResponse,
    TimeoutRequest,
    RegisterResponseData,
    ForgotPasswordRequest,
    CheckForgotPasswordRequest,
    ResetPasswordRequest,
    SendForgotPasswordMail,
    ForgotPasswordResponse,
    SendForgotPasswordMailResponse,
    SendForgotPassword2fa,
    CheckForgotPasswordResponse,
    ResetPasswordPassword,
    ChangePasswordAuthCodeResponse,
    CheckChangePasswordRequest,
    CheckChangePasswordResponse,
    ConfirmChangePasswordRequest,
    ConfirmChangePasswordResponse,
    CheckPasswordRequest,
    CheckPasswordResponse,
    SendChangeWitdraw2faResponse,
    CheckOtpResponse,
    GetOtpSecretCodeResponse,
    ChangeOtpRequest,
    ChangeOtpResponse,
    ConfirmOtpResponse,
    ConfirmOtpRequest,
    SendResendSmsRequest,
    SendResendSmsResponse,
    EmailAntiSpamRequest,
    EmailAntiSpamResponse,
    ConfirmTOtpRequest,
    ConfirmTOtpResponse,
    ConfirmCodeEmailChangeRequest,
    ConfirmCodeEmailChangeResponse,
    SendResendSmsPhoneChangeRequest,
    SendResendSmsPhoneChangeResponse,
    SendResendSmsPhoneChangeCodeRequest,
    SendResendSmsPhoneChangeCodeResponse,
    ConfirmPhoneNumberChangeRequest,
    ConfirmPhoneNumberChangeResponse,
    UserLanguageRequest,
    UserLanguageResponse,
    FreezeAccountResponse,
    SendtOtpChangeSmsResponse,
} from './auth.model';
import authApiEndpoints from './auth.endpoint';
import { BaseResponse } from 'api/common/common.model';

const useAuthApi = (): AuthApiCalls => {
    const generateApiCalls = (): AuthApiCalls => {
        return {
            distribution: (
                payload: DistributionBaseRequest
            ): HttpResponse<DistributionResponse> =>
                httpService.mock.get(authApiEndpoints.distribution, {
                    params: payload,
                }),
            checkEmail: (email: string): HttpResponse<CheckEmailResponse> =>
                httpService.client.get(authApiEndpoints.checkEmail, {
                    params: { email },
                }),
            changeEmail: (new_mail: string): HttpResponse<CheckEmailResponse> =>
                httpService.client.post(authApiEndpoints.changeEmail, {
                    new_mail,
                }),
            changePassword: (
                payload: ChangePasswordRequest
            ): HttpResponse<ChangePasswordResponse> =>
                httpService.client.post(
                    authApiEndpoints.changePassword,
                    payload
                ),
            confirmEmail: (hash: string): HttpResponse<any> =>
                httpService.client.post(authApiEndpoints.confirmEmail, {
                    hash,
                }),
            confirmCodeEmail: (hash: number): HttpResponse<any> =>
                httpService.client.post(authApiEndpoints.confirmCodeEmail, {
                    hash,
                }),
            resendEmail: (userGuid: string): HttpResponse<any> =>
                httpService.client.post(authApiEndpoints.resendEmail, {
                    user_guid: userGuid,
                }),
            register: async (
                payload: RegisterRequest
            ): Promise<RegisterResponseData> => {
                const response: RegisterResponse =
                    await httpService.client.post(
                        authApiEndpoints.register,
                        payload
                    );
                return mappingRegisterUser(response);
            },

            login: (payload: LoginRequest): HttpResponse<LoginResponse> =>
                httpService.client.post(authApiEndpoints.login, payload),
            logout: (): HttpResponse<LoginResponse> =>
                httpService.client.post(authApiEndpoints.logout),
            auth: (passcode: string): HttpResponse<AuthResponse> =>
                httpService.client.post(authApiEndpoints.auth, {
                    passcode: passcode,
                }),
            confirmsms: (passcode: string): HttpResponse<AuthResponse> =>
                httpService.client.post(authApiEndpoints.confirmsms, {
                    passcode: passcode,
                }),
            user: async (): Promise<AuthUser | null> => {
                const response: AuthResponse = await httpService.client.get(
                    authApiEndpoints.user
                );
                if (!response?.success) {
                    return null;
                }
                return mappingAuthUser(
                    response?.user as unknown as UserResponse
                );
            },
            walletBalance: (): HttpResponse<AuthWalletBalance | null> =>
                httpService.client.get(authApiEndpoints.walletBalance),
            timeout: (payload: TimeoutRequest): HttpResponse<BaseResponse> => {
                return httpService.client.post(
                    authApiEndpoints.changetimeout,
                    payload
                );
            },
            forgetPassword: (
                payload: ForgotPasswordRequest
            ): HttpResponse<ForgotPasswordResponse> =>
                httpService.client.post(
                    authApiEndpoints.forgetPassword,
                    payload
                ),
            sendForgotPasswordMail: (
                payload: SendForgotPasswordMail
            ): HttpResponse<SendForgotPasswordMailResponse> =>
                httpService.client.post(
                    authApiEndpoints.sendForgotPasswordMail,
                    payload
                ),
            sendForgotPassword2fa: (
                payload: SendForgotPassword2fa
            ): HttpResponse<SendForgotPasswordMailResponse> =>
                httpService.client.post(
                    authApiEndpoints.sendForgotPassword2fa,
                    payload
                ),
            sendChangeWithdraw2fa:
                (): HttpResponse<SendChangeWitdraw2faResponse> =>
                    httpService.client.post(
                        authApiEndpoints.sendChangeWithdraw2fa,
                        {}
                    ),
            checkForgotPassword: (
                payload: CheckForgotPasswordRequest
            ): HttpResponse<CheckForgotPasswordResponse> =>
                httpService.client.post(
                    authApiEndpoints.checkForgotPassword,
                    payload
                ),
            resetPassword: (
                payload: ResetPasswordRequest
            ): HttpResponse<ResetPasswordPassword> =>
                httpService.client.post(
                    authApiEndpoints.resetPassword,
                    payload
                ),
            sendResendSms: (
                payload: SendResendSmsRequest
            ): HttpResponse<SendResendSmsResponse> =>
                httpService.client.post(authApiEndpoints.resendSms, payload),

            sendChangePasswordEmail:
                (): HttpResponse<ChangePasswordAuthCodeResponse> =>
                    httpService.client.post(
                        authApiEndpoints.sendChangePasswordEmail
                    ),
            sendChangePassword2fa:
                (): HttpResponse<ChangePasswordAuthCodeResponse> =>
                    httpService.client.post(
                        authApiEndpoints.sendChangePassword2fa
                    ),

            checkChangePassword: (
                payload: CheckChangePasswordRequest
            ): HttpResponse<CheckChangePasswordResponse> =>
                httpService.client.post(
                    authApiEndpoints.checkChangePassword,
                    payload
                ),
            confirmChangePassword: (
                payload: ConfirmChangePasswordRequest
            ): HttpResponse<ConfirmChangePasswordResponse> =>
                httpService.client.post(
                    authApiEndpoints.confirmChangePassword,
                    payload
                ),
            checkPassword: (
                payload: CheckPasswordRequest
            ): HttpResponse<CheckPasswordResponse> =>
                httpService.client.post(
                    authApiEndpoints.checkPassword,
                    payload
                ),

            checkOtp: (): HttpResponse<CheckOtpResponse> =>
                httpService.client.get(authApiEndpoints.checkOtp),
            getOtpSecretCode: (): HttpResponse<GetOtpSecretCodeResponse> =>
                httpService.client.get(authApiEndpoints.getOtpSecretCode),
            changeOtp: (
                payload: ChangeOtpRequest
            ): HttpResponse<ChangeOtpResponse> =>
                httpService.client.post(authApiEndpoints.changeOtp, payload),
            emailAntiSpam: (
                payload: EmailAntiSpamRequest
            ): HttpResponse<EmailAntiSpamResponse> =>
                httpService.client.post(
                    authApiEndpoints.emailAntiSpam,
                    payload
                ),
            freezeAccount: (): HttpResponse<FreezeAccountResponse> =>
                httpService.client.post(authApiEndpoints.freezeAccount),
            changetOtp: (
                payload: ChangeOtpRequest
            ): HttpResponse<ChangeOtpResponse> =>
                httpService.client.post(authApiEndpoints.changetOtp, payload),
            confirmtOtp: (
                payload: ConfirmTOtpRequest
            ): HttpResponse<ConfirmTOtpResponse> =>
                httpService.client.post(authApiEndpoints.confirmtOtp, payload),
            confirmOtp: (
                payload: ConfirmOtpRequest
            ): HttpResponse<ConfirmOtpResponse> =>
                httpService.client.post(authApiEndpoints.changeOtp, payload),
            confirmCodeEmailChange: (
                payload: ConfirmCodeEmailChangeRequest
            ): HttpResponse<ConfirmCodeEmailChangeResponse> =>
                httpService.client.post(
                    authApiEndpoints.confirmCodeEmail,
                    payload
                ),
            sendResendSmsPhoneChange: (
                payload: SendResendSmsPhoneChangeRequest
            ): HttpResponse<SendResendSmsPhoneChangeResponse> =>
                httpService.client.post(
                    authApiEndpoints.changePhoneNumberEmail,
                    payload
                ),
            confirmPhoneNumberChange: (
                payload: ConfirmPhoneNumberChangeRequest
            ): HttpResponse<ConfirmPhoneNumberChangeResponse> =>
                httpService.client.post(
                    authApiEndpoints.confirmPhoneNumberChange,
                    payload
                ),
            sendResendSmsPhoneChangeCode: (
                payload: SendResendSmsPhoneChangeCodeRequest
            ): HttpResponse<SendResendSmsPhoneChangeCodeResponse> =>
                httpService.client.post(
                    authApiEndpoints.changePhoneNumberCode,
                    payload
                ),
            userLanguage: (
                payload: UserLanguageRequest
            ): HttpResponse<UserLanguageResponse> =>
                httpService.client.post(authApiEndpoints.userLanguage, payload),

            sendtotpchangesms: (): HttpResponse<SendtOtpChangeSmsResponse> =>
                httpService.client.post(authApiEndpoints.sendtotpchangesms),
        };
    };
    const songCallsRef = useRef<AuthApiCalls>(generateApiCalls());

    return songCallsRef.current;
};

export default useAuthApi;
