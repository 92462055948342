import { css } from 'styled-components';

export const ChangePasswordMailConfirmModalCss = css`
    .change-password-mail-confirm-modal {
        .ant-modal-close {
            display: none;
        }
        .ant-modal-content {
            max-width: 350px !important;
            width: 100%;

            .ant-modal-body {
                margin: 0px !important;
                padding: 20px 0px !important;
            }
        }
    }
`;
