import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import tr from './translations/tr.json';
import en from './translations/en.json';
import de from './translations/de.json';
import LanguageDetector from 'i18next-browser-languagedetector';
export enum Language {
    Turkis = 'tr',
    English = 'en',
    Deutch = 'de',
}

i18n.use(initReactI18next)
    .use(LanguageDetector) // passes i18n down to react-i18next
    .init({
        resources: {
            tr: {
                translation: tr,
            },
            en: {
                translation: en,
            },
            de: {
                translation: de,
            },
        },

        // fallbackLng: defaultLang == null ? 'en' : defaultLang,
        // interpolation: {
        //     escapeValue: false,
        // },
        // lng: 'en',
        // lng: document.querySelector('html')?.lang,
        supportedLngs: ['en', 'tr', 'de'],
        fallbackLng: 'tr',
        detection: {
            order: ['path', 'navigator'],
            caches: ['cookie'],
        },
    });

export default i18n;
