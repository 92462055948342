import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';

import { fonts } from 'common/theme/font';

import { CardPersonalProps } from './index';

const tabletAccountPageCss = css`
    .ant-card-body {
        .ant-row {
            flex-direction: column;
            .ant-col-8 {
                .personal-right-item {
                    display: block;
                }
            }
        }
    }
`;
export const CardPersonalStyled = styled.div<CardPersonalProps>`
    p.card-text {
        margin-top: 5px;
    }

    .ant-card {
        background-color: ${({ theme }) => theme.colors.color1.base} !important;
        border-color: ${({ theme }) => theme.colors.color2.base} !important;
    }
    .ant-card-head {
        padding-left: 0;
        padding-right: 0;
        border-bottom: none;
        color: ${({ theme }) => theme.colors.color4.second1} !important;
        font-size: 18px;
    }
    .ant-card-body {
        margin-top: 0px !important;
        font-size: 16px;
        background-color: ${({ theme }) =>
            theme.colors.color1.second2} !important;
        .card-label-item {
            font-size: 14px;
        }
    }
    .warning-with-icon {
        margin-top: 15px;
        display: flex;

        line-height: 10px;
        color: ${({ theme }) => theme.colors.staticColor.color3.base};
    }
    .clipped-item {
        margin-top: 10px;

        .ant-btn-link {
            .app-icon {
                font-size: ${fonts.size.large};
                margin-right: 10px;
            }
            &:hover {
                text-decoration: none;

                span {
                    text-decoration: none;
                }
            }
            span {
                font-size: ${fonts.size.small};
                font-weight: ${fonts.weight.semibold};
            }
        }
    }

    .personal-left-item {
        .card-item:nth-child(2) {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }

    .personal-right-item {
        height: calc(100% - 22px);
    }
    ${mediaQuery.between('xltablet', 'desktop')`${tabletAccountPageCss}`}
`;
