const placeOrderApiEndpoints = {
    buySellNew: '/plc/order/new',
    buySummary: '/plc/order/easy/buy',
    sellSummary: '/plc/order/easy/sell',
    orderHistory: '/plc/order/history/all',
    transferHistory: '/blc/lasttransactions/v2',
    getTransactionDetail: '/blc/transaction',
    openOrder: '/plc/order/active/all',
    deleteOrder: '/plc/order/cancel',
    fees: '/plc/fees',
    feesZero: '/plc/fees/zero',
    now: '/plc/fee/now',
    openOrderSummary: 'plc/order/active/summary',
    orderHistorySummary: 'plc/order/history/summary',
};

export default placeOrderApiEndpoints;
