import { css } from 'styled-components';

export const AddressIbanAddFormCss = css`
    &.address-iban-add-form-modal {
        .ant-modal-content {
            max-width: 465px;
            width: 100%;
            margin: 0 auto;
            .ant-modal-body {
                margin: 0px !important;
                padding: 20px 0px !important;
                .modal-card-container {
                    .description-container {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
`;
