import { CardPersonalStyled } from './index.styles';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import React, {
    useCallback,
    forwardRef,
    useImperativeHandle,
    Ref,
    useRef,
} from 'react';
import { Card } from 'components/card';
import { useModal } from 'components/modal';
import { SvgIcon } from 'components/svg-icon';
import { HelperMessage, PasswordField } from 'components';
import { UpdateEmailSteps } from 'widgets/update-email-steps';
import { useAuthentication } from 'common/auth/AuthenticationProvider';
import { useWarningModal } from 'common/hooks/warning-card-modal';
import { useFormik } from 'formik';
import useAuthApi from 'api/auth/auth.api';
import { useSuccessModal } from 'common/hooks/success-modal';
import * as yup from 'yup';
import { t } from 'i18next';
import { useChangePhoneModal } from 'common/hooks/change-phone';

export interface CardPersonalProps {
    verifiedAccount?: boolean;
}

export interface CardItemProps {
    title: string;
    content?: string;
    className?: string;
    hasItem?: boolean;
    onClick?: () => void;
}

function CardItem(props: CardItemProps) {
    const { t } = useTranslation();
    return (
        <div className="card-item">
            <span className={`card-label-item ${props.className}`}>
                {props.title}
                {props.hasItem && (
                    <span
                        className="btn-change"
                        role="button"
                        onClick={() => {
                            props.onClick && props.onClick();
                        }}
                    >
                        {t('card.personal.buttons.change')}
                    </span>
                )}
            </span>
            <p>{props.content}</p>
        </div>
    );
}

interface ConfirmPasswordFormType {
    password: string;
}

interface ConfirmPasswordFormRefObject {
    submitForm: () => Promise<void>;
    setError: (message: string) => void;
}

const ConfirmPasswordFormWrapper = (
    _props: Empty,
    ref: Ref<ConfirmPasswordFormRefObject>
) => {
    const { checkPassword } = useAuthApi();
    const validation = yup.object().shape({
        password: yup.string().required(t('general.required')),
    });

    const formik = useFormik<ConfirmPasswordFormType>({
        initialValues: {
            password: '',
        },
        onSubmit: async (values: ConfirmPasswordFormType) => {
            try {
                const response = await checkPassword(values);

                if (response?.success) {
                    return true;
                }
            } catch (error) {
                return false;
            }
        },
        validationSchema: validation,
    });

    const setError = useCallback(
        (message: string) => {
            formik.setFieldError('password', message);
        },
        [formik]
    );

    const submitForm = useCallback(async () => {
        return await formik.submitForm();
    }, [formik]);

    useImperativeHandle(ref, () => ({
        submitForm,
        setError,
    }));

    return (
        <form onSubmit={formik.handleSubmit}>
            <PasswordField
                form={formik}
                formName="password"
                placeholder={t(
                    'card.personal.changePhoneModal.passwordButtonPlaceholder'
                )}
            />
        </form>
    );
};

export const ConfirmPasswordForm = forwardRef(ConfirmPasswordFormWrapper);

const WrappedCardPersonal = ({ verifiedAccount = true }: CardPersonalProps) => {
    const { t } = useTranslation();
    const { user } = useAuthentication();
    let changePassword2FAConfirmModal = useChangePhoneModal({
        onNext: (globalValue: boolean) => {
            if (globalValue) {
                changePassword2FAConfirmModal.closeModal();
                openSuccessModal();
            }
        },
    });
    const { placeholder: SuccessPlaceholder, openSuccessModal } =
        useSuccessModal({
            title: 'İşleminiz başarılı!',
            description: 'Telefonunuz başarıyla değiştirilmiştir',
        });
    const { placeholder: SuccessPlaceholderEmail, openSuccessModalEmail } =
        useSuccessModal({
            title: 'İşleminiz başarılı!',
            description: 'Email başarıyla değiştirilmiştir',
        });

    const refConfirmPasswordForm = useRef<ConfirmPasswordFormRefObject>(null);

    const updateEmailModal = useModal({
        className: 'update-email-modal',
    });
    const onEditEmailClick = useCallback(() => {
        updateEmailModal.openModal(
            <UpdateEmailSteps
                onCancelClick={updateEmailModal.closeModal}
                onSuccess={openSuccessModalEmail}
            />
        );
    }, [updateEmailModal.openModal]);

    const {
        placeholder: changePhoneNumberWarningPlaceholder,
        openWarningModal: openChangePhoneNumberWarningModal,
        closeModal: closeChangePhoneNumberWarningModal,
    } = useWarningModal({
        // title: t('card.personal.changePhoneModal.title'),
        // description: {
        //     titletext: t('card.personal.changePhoneModal.description'),
        //     listtext: [
        //         t('card.personal.changePhoneModal.firstItem'),
        //         t('card.personal.changePhoneModal.secondItem'),
        //     ],
        //     bottomtext: t('card.personal.changePhoneModal.inputLabel'),
        // },
        changePhoneModal: true,

        width: 370,
        extraComponent: <ConfirmPasswordForm ref={refConfirmPasswordForm} />,
        onNext: async () => {
            const result = await refConfirmPasswordForm.current?.submitForm();

            if (result) {
                closeChangePhoneNumberWarningModal();
                changePassword2FAConfirmModal.openModal();
            }
        },
    });

    const openEmailChangeModal = useCallback(() => {
        openChangePhoneNumberWarningModal();
    }, [openChangePhoneNumberWarningModal, refConfirmPasswordForm]);

    return (
        <CardPersonalStyled>
            {updateEmailModal.placeholder()}
            {SuccessPlaceholder()}
            {SuccessPlaceholderEmail()}

            <Card
                title={t('card.personal.cardTitle')}
                className="card-app-item card-personal-item"
            >
                <Row gutter={16} justify="space-between">
                    <Col span={16}>
                        <div className="flex-between full-height personal-left-item">
                            {verifiedAccount && (
                                <CardItem
                                    title={t('card.personal.items.nameSurname')}
                                    content={`${user?.firstName} ${user?.lastName}`}
                                />
                            )}
                            <CardItem
                                title={t('card.personal.items.email')}
                                content={user?.email}
                                className="has-item"
                                hasItem={true}
                                onClick={onEditEmailClick}
                            />
                            {!verifiedAccount && (
                                <div className="warning-with-icon">
                                    <SvgIcon
                                        name="error-circle-filled"
                                        size={24}
                                    />
                                    <HelperMessage
                                        error={true}
                                        text={t(
                                            'card.personal.AccountVerification'
                                        )}
                                        disabled={true}
                                    />
                                </div>
                            )}
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className="flex-end personal-right-item">
                            {verifiedAccount && (
                                <CardItem
                                    title={t('card.personal.items.phone')}
                                    content={user?.phone}
                                    className="has-item"
                                    hasItem={true}
                                    onClick={openEmailChangeModal}
                                />
                            )}
                        </div>
                    </Col>
                </Row>
            </Card>
            {changePhoneNumberWarningPlaceholder()}
            {changePassword2FAConfirmModal.placeholder()}
        </CardPersonalStyled>
    );
};

export const CardPersonal = React.memo(WrappedCardPersonal);
