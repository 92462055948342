import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';
import { fonts } from 'common/theme/font';

const mobileCss = css`
    padding: 30px 0px;
    height: inherit;
    .title-container {
        margin-bottom: 20px;
        .title {
            font-size: ${fonts.size.large};
            font-weight: ${fonts.weight.bold};
        }
        hr {
            margin-top: 10px;
        }
    }
    .apply-form {
        .apply-form-container {
            width: 100%;
            max-width: 100%;
            margin-bottom: 0;
            align-items: center;
            justify-content: center;
            form {
                width: 100%;
                margin: 0 15px;
                .app-form-field {
                    padding-bottom: 15px;
                }
                .name-fields-container {
                    flex-direction: column;
                    & > * + * {
                        margin-left: 0px;
                    }
                    .app-form-field-name {
                        margin-right: 0;
                    }
                }
                .ant-input {
                    height: 36px;
                    padding-top: 0 !important;
                    padding-bottom: 0 !important;
                    font-size: ${fonts.size.small};
                }
                .apply-button {
                    float: inherit;
                    margin: 0 auto;
                }
            }
        }
    }
`;

export const Container = styled.section`
    display: block;
    padding-top: 70px;
    padding-bottom: 30px;
    background-color: ${({ theme }) => theme.colors.color1.base};
    .title-container {
        align-items: center;
        text-align: center;
        margin-bottom: 50px;
        .title {
            font-size: ${fonts.size.xlarge};
            font-weight: ${fonts.weight.bold};
            color: ${({ theme }) => theme.colors.color4.base};
        }
        hr {
            width: 30px;
            height: 4px;
            background-color: ${({ theme }) =>
                theme.colors.staticColor.color2.base};
            border: none;
            border-radius: 3px;
            margin-top: 10px;
        }
    }
    .apply-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        &-container {
            display: flex;
            margin-bottom: 50px;
            max-width: 500px;
            form {
                .name-fields-container {
                    display: flex;
                    .app-form-field-name {
                        margin-right: 20px;
                    }
                    .app-form-field {
                        flex: 1;
                    }

                    & > * + * {
                        margin-left: 30px;
                    }
                }
                .apply-button {
                    float: right;
                }
                .app-form-control > .ant-input {
                    background: ${({ theme }) => theme.colors.color2.opacity4};
                    border: 1px solid;
                    border-color: ${({ theme }) => theme.colors.color2.base};
                }
                .file-uploader {
                    &-box {
                        background: ${({ theme }) =>
                            theme.colors.color2.opacity4};
                        border: 1px dashed;
                        border-color: ${({ theme }) =>
                            theme.colors.color2.base};
                        border-radius: 4px;
                        padding: 24px 100px 18px;
                        color: ${({ theme }) => theme.colors.color3.base};
                        .upload-icon {
                            text-align: center;
                            margin-bottom: 12px;
                            justify-content: center;
                            display: flex;
                            font-size: ${fonts.size.normal};
                            font-weight: ${fonts.weight.semibold};
                            .app-icon {
                                margin-right: 7px;
                            }
                            span {
                                margin-top: 7px;
                            }
                        }
                        .drag-drop-message {
                            text-align: center;
                            font-size: ${fonts.size.small};
                            font-weight: ${fonts.weight.medium};
                        }
                    }
                    &-message {
                        margin-top: 16px;
                        margin-bottom: 32px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: baseline;
                        color: ${({ theme }) => theme.colors.color3.base};
                        .app-icon {
                            top: 3px;
                            position: relative;
                            color: ${({ theme }) =>
                                theme.colors.staticColor.color5.base};
                            transform: rotate(180deg);
                        }
                        span {
                            font-size: ${fonts.size.small};
                            font-weight: ${fonts.weight.regular};
                            color: ${({ theme }) => theme.colors.color4.base};
                        }
                    }
                }
            }
        }
    }

    ${mediaQuery.lessThan('tablet')`${mobileCss}`}
`;
