import styled from 'styled-components';
import { fonts } from 'common/theme/font';

export const VerticalTabStyled = styled.div`
    position: relative;
    margin-bottom: 15px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.color2.base};
    color: ${({ theme }) => theme.colors.color4.base};
    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 3.5px;
        height: calc(100% - 38px);
        border-radius: 4px;
        top: 0;
        left: 0;
        margin: 0 auto;
        border-radius: 2px;
        background-color: ${({ theme }) => theme.colors.color2.base};
    }
    .tab-head {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding-left: 15px;
        padding-bottom: 16px;
        font-size: ${fonts.size.normal};
        cursor: pointer;

        .app-icon {
            background-color: ${({ theme }) => theme.colors.color5.base};
            color: ${({ theme }) => theme.colors.staticColor.color2.base};
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &:before {
            content: '';
            display: block;
            position: absolute;
            width: 3.5px;
            height: calc(100% - 15px);
            border-radius: 4px;
            top: 0;
            left: 0;
            margin: 0 auto;
            border-radius: 2px;
            background-color: ${({ theme }) =>
                theme.colors.staticColor.color2.base};
        }
        .title {
            font-weight: ${fonts.weight.regular};
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;
            .subtitle {
                font-weight: ${fonts.weight.medium};
                font-size: ${fonts.size.small};
                color: ${({ theme }) => theme.colors.color4.base};
            }
        }
    }

    &.active {
        .tab-head {
            cursor: pointer;
        }
    }
    &.open {
        padding-bottom: 38px;
        .tab-head {
            border-bottom: 0;
        }
        &:before {
            height: calc(100% - 38px);
        }
    }
    .tab-content {
        font-size: ${fonts.size.medium};
        line-height: ${fonts.size.xlarge};
        font-weight: ${fonts.weight.light};
        padding-left: 15px;
    }
`;
