import { useEffect, useState, useCallback } from 'react';
import { Button, Input, InputProps } from 'components';
import * as S from './index.styles';
import { CodeInput } from 'components/code-input';
import { useTranslation } from 'react-i18next';

export type AuthMethod = 'google-authenticator' | 'sms' | 'email';
interface StatusOptions {
    isTimerActive: boolean;
    sendCode: boolean;
    againCode: boolean;
}

export interface ConfirmationCodeProps extends InputProps {
    authMethod?: AuthMethod;
    counterValue: number;
    value?: string;
    onResendEmail?: () => void;
    onChange?: (value: any) => void;
}

function SendCode(props: ConfirmationCodeProps) {
    const { t } = useTranslation();

    const counterValue = props.counterValue;
    const [counter, setCounter] = useState(counterValue);
    const [status, setStatus] = useState<StatusOptions>({
        isTimerActive: false,
        sendCode: true,
        againCode: false,
    });
    const handleResendEmail = async () => {
        try {
            props.onResendEmail && props.onResendEmail();

            setCounter(counterValue);
            const nState = {
                ...status,
                isTimerActive: true,
                sendCode: false,
            };
            setStatus(nState);
        } catch (err: any) {
            //to delete
            setCounter(counterValue);
            const nState = {
                ...status,
                isTimerActive: true,
                sendCode: false,
            };
            setStatus(nState);
        }
    };
    const handleSendCode = useCallback(() => {
        if (props.authMethod === 'email') {
            handleResendEmail();
        } else {
            setCounter(counterValue);
            const nState = {
                ...status,
                isTimerActive: true,
                sendCode: false,
            };
            setStatus(nState);
        }
    }, [status]);
    const handleTimer = () => {
        if (status.isTimerActive) {
            if (counter > 0) {
                setTimeout(() => setCounter(counter - 1), 1000);
            } else {
                const nState = {
                    isTimerActive: false,
                    sendCode: true,
                    againCode: true,
                };
                setStatus(nState);
            }
        }
    };
    useEffect(() => {
        handleTimer();
    }, [counter, status]);
    return (
        <>
            <div className={status.isTimerActive ? 'active-reminder' : ''}>
                <div className="send-code-info">
                    {status.sendCode && (
                        <Button onClick={handleSendCode} type="link">
                            {status.againCode && t('confirmationCode.again')}
                            {t('confirmationCode.codeSubmit')}
                        </Button>
                    )}
                    {status.isTimerActive && (
                        <span className="code-sent">
                            {t('confirmationCode.codeSend')}
                        </span>
                    )}
                </div>
                {status.isTimerActive && (
                    <div className="remaining-time">
                        <span className="text">
                            {t('confirmationCode.codeInformation')}
                        </span>
                        <span className="timer">
                            {counter} {t('confirmationCode.second')}
                        </span>
                    </div>
                )}
            </div>
        </>
    );
}

export const ConfirmationCode = (props: ConfirmationCodeProps) => {
    const { t } = useTranslation();
    const [code, setCode] = useState('');
    const handleOnChange = (authCode: string) => {
        setCode(authCode);
        props?.onChange && props.onChange(authCode);
    };
    return (
        <S.Container>
            <div className="verification-code-input">
                {props.authMethod === 'email' ? (
                    <div className="verification">
                        <div className="form-item">
                            <Input
                                onChange={(e: any) =>
                                    handleOnChange(e.target.value)
                                }
                                value={code}
                                name={props.name}
                                placeholder={t('confirmationCode.placeholder')}
                                {...props}
                            />
                            <SendCode
                                counterValue={props.counterValue}
                                authMethod="email"
                                onResendEmail={props.onResendEmail}
                            />
                            <div className="code-label-container">
                                <label className="code-label">
                                    {t('confirmationCode.codeVerification')}
                                </label>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="code">
                        <div className="form-item">
                            <SendCode counterValue={props.counterValue} />
                            <CodeInput
                                name={props.name}
                                length={6}
                                loading={false}
                                onChange={handleOnChange}
                                {...props}
                            ></CodeInput>
                            <div className="code-label-container">
                                <label className="code-label">
                                    {t('confirmationCode.codeVerification')}
                                </label>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </S.Container>
    );
};
