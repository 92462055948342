import { fonts } from 'common/theme/font';
import styled from 'styled-components';

export const StatusCardStyled = styled.div`
    &.status-card-container {
        text-align: center;
        .status-img {
            padding-top: 32px;
        }

        .title-container {
            margin-top: 16px;

            font-size: 18px;
            font-weight: ${fonts.weight.semibold};

            color: ${({ theme }) => theme.colors.color4.base};
        }

        .description-container {
            margin-top: 16px;

            font-size: ${fonts.size.medium};
            font-weight: ${fonts.weight.regular};

            color: ${({ theme }) => theme.colors.color4.base};
        }

        .ant-btn {
            margin: auto;
            margin-top: 16px;
            margin-bottom: 32px;
            padding: auto;
            span {
                font-size: ${fonts.size.large};
            }
        }
    }
`;
