import styled from 'styled-components';

export const AddressIbanAddFormStyled = styled.div`
    .modal-card-container {
        .app-icon {
            padding-top: 0 !important;
        }
        > img {
            float: left;
            margin: 0 10px;
        }
        .title-container {
            position: relative;
            top: 2px;
            text-align: left;
        }
    }
`;
